import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { getStyles } from './Modal.styles';
import { LoadingButton } from '@mui/lab';
import { LoadingIndicator } from '../../components';
import Fade from '@mui/material/Fade';

export function Modal({
  open,
  closeModal,
  title,
  onSubmit,
  submitButtonText = 'Submit',
  submitButtonIsLoading,
  submitButtonIsDisabled,
  showModalLoader,
  children,
  fullWidth,
  maxWidth,
  additionalActionButtons,
}) {
  const styles = getStyles(fullWidth, maxWidth);

  const handleModalClose = () => {
    if (submitButtonIsLoading) return;
    closeModal();
  };

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      scroll='body'
      sx={styles.dialogRoot}
      PaperProps={{ elevation: 0, sx: { mx: '0 !important' } }}
    >
      <DialogTitle sx={styles.dialogTitle}>{title}</DialogTitle>
      <Box component='form' onSubmit={onSubmit}>
        <DialogContent dividers={true} sx={styles.dialogContent}>
          {children}
          <Fade in={showModalLoader} unmountOnExit>
            <Box sx={styles.overlayLoaderWrapper}>
              <LoadingIndicator />
            </Box>
          </Fade>
        </DialogContent>
        <DialogActions sx={styles.dialogActions}>
          <Stack direction='row' spacing='20px'>
            <Button onClick={handleModalClose} variant='outlined' color='secondary'>
              Cancel
            </Button>
            {additionalActionButtons}
            <LoadingButton
              type='submit'
              variant='contained'
              loading={submitButtonIsLoading}
              disabled={showModalLoader || submitButtonIsDisabled}
            >
              {submitButtonText}
            </LoadingButton>
          </Stack>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
