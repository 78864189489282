import Button from '@mui/material/Button';
import FilterIcon from '../../assets/images/icons/Filter';
import { styles } from './FilterButton.styles';
import Box from '@mui/material/Box';

export const FilterButton = ({ activeFiltersCount, ...props }) => {
  return (
    <Button startIcon={<FilterIcon />} sx={styles.root} {...props}>
      Filters
      {activeFiltersCount ? <Box sx={styles.activeFiltersCount}>{activeFiltersCount}</Box> : null}
    </Button>
  );
};
