import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooks } from '../../redux/slices/books/books.read';
import { useCallback, useEffect, useState } from 'react';
import { booksSelector } from '../../redux/slices/books';
import Box from '@mui/material/Box';
import { BookDuration } from '../../components';
import { Link } from 'react-router-dom';
import { pathnames } from '../../routes/pathnames';
import { styles } from './Books.styles';
import { handleBookStatusChip } from '../../utils';

export default function useBooksTable() {
  const dispatch = useDispatch();
  const { books, isFetchingBooks } = useSelector(booksSelector);
  const [booksTableRows, setBooksTableRows] = useState(null);
  const [booksTableRowsDraft, setBooksTableRowsDraft] = useState(null);

  const booksTableColumns = [
    {
      id: 'bookName',
      label: 'Book Name',
      minWidth: 170,
    },
    {
      id: 'bookAuthor',
      label: 'Book Author',
      minWidth: 170,
    },
    {
      id: 'bookCategory',
      label: 'Book Category',
      minWidth: 170,
    },
    {
      id: 'bookDuration',
      label: 'Duration',
      minWidth: 140,
    },
    {
      id: 'addedDate',
      label: 'Added Date',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
    },
  ];

  const createData = (bookName, bookAuthor, bookCategory, bookDuration, addedDate, status, actions) => ({
    bookName,
    bookAuthor,
    bookCategory,
    bookDuration,
    addedDate,
    status,
    actions,
  });

  // Fetch Books Data
  useEffect(() => {
    if (books === null) dispatch(fetchBooks());
  }, [books, dispatch]);

  useEffect(() => {
    if (books) {
      const rows = books.map((book) => {
        const bookName = (
          <Box display='flex' alignItems='center'>
            <Box
              component='img'
              width={40}
              height={60}
              src={book.cover}
              alt='Book Cover'
              loading='lazy'
              sx={styles.booksTableBookCoverImage}
            />
            <Link to={`${pathnames.books}/${book.id}`}>{book.name}</Link>
          </Box>
        );

        return createData(
          bookName,
          book.author.name,
          book.category.name,
          <BookDuration duration={book.duration} />,
          book.createdAt,
          <Chip color={handleBookStatusChip(book.status)} label={book.status} />,
        );
      });

      setBooksTableRows(rows);
      setBooksTableRowsDraft(rows);
    }
  }, [books]);

  const searchCompareFunction = useCallback((row, searchInputValue) => {
    const bookName = row.bookName?.props?.children?.[1]?.props?.children.toLowerCase?.();
    const searchValue = searchInputValue?.toLowerCase()?.trim();
    return bookName.includes(Boolean(searchValue) ? searchValue : null);
  }, []);

  return {
    booksTableColumns,
    booksTableRows,
    isFetchingBooks,
    booksTableRowsDraft,
    setBooksTableRows,
    searchCompareFunction,
  };
}
