export default function SearchIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' data-name='Icons/User-Interface/search' width={20} height={19} {...props}>
      <path
        d='M19.721 17.398l-3.708-3.5a8.255 8.255 0 00-1.015-11.715 9.336 9.336 0 00-12.363.322 8.249 8.249 0 00-.338 11.754 9.329 9.329 0 0012.324.962l3.678 3.5a1.036 1.036 0 001.419 0 .917.917 0 00.003-1.323zM9.016 15.221a6.83 6.83 0 01-6.995-6.65 6.83 6.83 0 017-6.651 6.83 6.83 0 017 6.651 6.489 6.489 0 01-2.049 4.7 7.184 7.184 0 01-4.956 1.95z'
        fill='currentColor'
      />
    </svg>
  );
}
