import { forwardRef } from 'react';
import { SnackbarContent, useSnackbar } from 'notistack';
import { Alert } from '@mui/material';
import CheckmarkIcon from '../../../assets/images/icons/Checkmark';
import ErrorIcon from '../../../assets/images/icons/Error';
import { styles } from './CustomSnackbar.styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '../../../assets/images/icons/Close';

const CustomSnackbar = forwardRef(({ id, message, variant = 'success', action }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleCloseSnackbar = () => closeSnackbar(id);

  return (
    <SnackbarContent ref={ref}>
      <Alert
        severity={variant}
        color={variant}
        sx={styles.alertRoot}
        iconMapping={{
          success: <CheckmarkIcon />,
          error: <ErrorIcon />,
          warning: <ErrorIcon />,
        }}
        action={
          action ? (
            action
          ) : (
            <IconButton onClick={handleCloseSnackbar}>
              <CloseIcon />
            </IconButton>
          )
        }
      >
        {message}
      </Alert>
    </SnackbarContent>
  );
});

CustomSnackbar.displayName = 'Snackbar';

export default CustomSnackbar;
