import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingIndicator, Section } from '../../../../components';
import { Box, Stack } from '@mui/material';
import AndroidNotificationView from './AndroidNotificationView';
import AppleNotificationView from './AppleNotificationView';
import Chip from '@mui/material/Chip';
import { handleNotificationStatusChip } from '../../Notifications.table';
import { styles } from './NotificationDetails.styles';
import { NotificationStatus } from '../../../../enums';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { sendNotification } from '../../../../redux/slices/notifications/notifications.send';
import { updateNotification } from '../../../../redux/slices/notifications/notifications.update';
import { useEffect, useRef } from 'react';
import { notificationsSelector } from '../../../../redux/slices/notifications';
import { snackbar } from '../../../../utils';
import {
  CreateUpdateNotificationForm,
  createUpdateNotificationFormOptions,
} from '../../forms/CreateUpdateNotificationForm/CreateUpdateNotificationForm';

const NotificationDetails = () => {
  const { notification, isUpdatingNotification, isSendingNotification, isFetchingSingleNotification } =
    useSelector(notificationsSelector);
  const { title, content, type, status } = notification || {};
  const form = useForm(createUpdateNotificationFormOptions);
  const isSent = status?.toUpperCase() === NotificationStatus.SENT;
  const isDraft = status?.toUpperCase() === NotificationStatus.DRAFT;
  const dispatch = useDispatch();
  const { id: notificationId } = useParams();
  const snackbarUnsavedChangesWarningKey = useRef('');

  function getUpdateNotificationRequestPayload(data) {
    const { title, content, releaseAt } = data;

    return {
      title,
      content,
      ...(releaseAt && { release_at: releaseAt }),
    };
  }

  const handleSendNotification = () => {
    dispatch(sendNotification(notification));
  };

  const handleUpdateNotification = (data) => {
    const payload = getUpdateNotificationRequestPayload(data);
    dispatch(updateNotification({ notificationId, payload }));
  };

  useEffect(() => {
    if (notification) {
      const formDefaultValues = {
        title: notification.title,
        content: notification.content,
        releaseAt: notification.releaseAt ? new Date(notification.releaseAt) : null,
      };

      form.reset(formDefaultValues);
    }
  }, [notification, form]);

  useEffect(() => {
    const doesThereUnsavedChanges = form.formState.isDirty;

    if (doesThereUnsavedChanges) {
      snackbarUnsavedChangesWarningKey.current = snackbar.warning(
        'There are unsaved changes. You should first save them so you could send the notification.',
        {
          persist: true,
        },
      );
    } else {
      snackbar.dismiss(snackbarUnsavedChangesWarningKey.current);
    }
  }, [form.formState.isDirty]);

  const NotificationViewSectionJSX = (
    <Box sx={styles.contentWrapper}>
      <Stack spacing='40px'>
        <Box sx={styles.contentItem}>
          <Box sx={styles.contentItemTitle}>Notification Title</Box>
          <Box sx={styles.contentItemText}>{title}</Box>
        </Box>
        <Box sx={styles.contentItem}>
          <Box sx={styles.contentItemTitle}>Notification Content</Box>
          <Box sx={styles.contentItemText}>{content}</Box>
        </Box>
        <AndroidNotificationView style={{ margin: 'auto auto 0 auto' }} title={title} content={content} />
      </Stack>
      <Stack spacing='40px'>
        <Box sx={styles.contentItem}>
          <Box sx={styles.contentItemTitle}>Type</Box>
          <Box sx={styles.contentItemText}>{type}</Box>
        </Box>
        <Box sx={styles.contentItem}>
          <Box sx={styles.contentItemTitle}>Status</Box>
          <span>
            <Chip color={handleNotificationStatusChip(status)} label={status} />
          </span>
        </Box>
        <AppleNotificationView title={title} content={content} style={{ margin: 'auto auto 0 auto' }} />
      </Stack>
    </Box>
  );

  return (
    <Section
      component='form'
      title='Notification Details'
      {...(isDraft && {
        toolbar: (
          <Stack direction='row' spacing='24px'>
            <LoadingButton
              type='submit'
              variant='outlined'
              loading={isUpdatingNotification}
              disabled={!form.formState.isDirty}
              onClick={form.handleSubmit(handleUpdateNotification)}
            >
              Save
            </LoadingButton>
            <LoadingButton
              variant='contained'
              onClick={form.handleSubmit(handleSendNotification)}
              loading={isSendingNotification}
              disabled={form.formState.isDirty}
            >
              Send Now
            </LoadingButton>
          </Stack>
        ),
      })}
    >
      {!isFetchingSingleNotification && notification ? (
        isSent ? (
          NotificationViewSectionJSX
        ) : (
          <CreateUpdateNotificationForm form={form} notification={notification} isDraft={isDraft} />
        )
      ) : (
        <LoadingIndicator />
      )}
    </Section>
  );
};

export default NotificationDetails;
