import axiosLib from 'axios';
import { StatusCodes } from 'http-status-codes';
import { authActions } from '../redux/slices/auth';
import { logout } from '../redux/slices/auth/auth.logout';
import { store } from '../redux/store';
import { version } from '../../package.json';
import { snackbar } from '../utils/snackbar';
import { Button } from '@mui/material';
import ReloadIcon from '../assets/images/icons/Reload';

const singletonEnforcer = Symbol();
const TIMEOUT_CODE = 'ECONNABORTED';
const NETWORK_ERROR_MESSAGE_CONTENT = 'Network Error';

class Axios {
  static axiosInstance;

  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot initialize Axios client single instance');
    }
    this.isRefreshing = false;
    this.failedQueue = [];
    this.axiosClient = axiosLib.create({
      baseURL: process.env.REACT_APP_BACKEND_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        version: version,
        'Accept-Language': 'en',
      },
      timeout: 30000,
    });
    this.axiosClient.CancelToken = axiosLib.CancelToken;
    this.axiosClient.isCancel = axiosLib.isCancel;
    this.setResponseInterceptor();
    this.setRequestInterceptor();
  }

  static get instance() {
    if (!this.axiosInstance) {
      this.axiosInstance = new Axios(singletonEnforcer);
    }

    return this.axiosInstance;
  }

  get CancelToken() {
    return this.axiosClient.CancelToken;
  }

  get defaults() {
    return this.axiosClient.defaults;
  }

  client() {
    return this.axiosClient;
  }

  processFailedQueue() {
    this.failedQueue.forEach((failedRequest) => {
      failedRequest.resolve();
    });
    this.failedQueue = [];
  }

  setRequestInterceptor() {
    this.axiosClient.interceptors.request.use(
      (request) => {
        const { isLoggedIn, token } = store.getState().auth;

        if (isLoggedIn && token) {
          request.headers.common.Authorization = `Bearer ${token}`;
        }

        return request;
      },
      (error) => Promise.reject(error),
    );
  }

  setResponseInterceptor() {
    this.axiosClient.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error?.config;
        const isSpecificError = originalRequest?.headers?.specificError;
        const responseErrorStatus = error.isAxiosError && !error.response ? 0 : error?.response?.status;
        const responseData = error?.response?.data;
        /* eslint-disable  unused-imports/no-unused-vars */
        const errorMessage = responseData?.message;
        const refreshTokenEndpoint = '/auth/refresh';
        if (this.axiosClient.isCancel(error)) {
          // its a cancel request reject error directly
          return Promise.resolve();
        }
        if (responseErrorStatus >= 402) {
          snackbar.error('Uh-oh! Something went wrong.');
        }
        if (error.message === NETWORK_ERROR_MESSAGE_CONTENT || error.code === TIMEOUT_CODE) {
          snackbar.error('No connection!', {
            action: (
              <Button
                variant='outlined'
                size='small'
                color='inherit'
                onClick={() => window.location.reload()}
                startIcon={<ReloadIcon />}
              >
                Reload
              </Button>
            ),
            persist: true,
          });
        }
        if (isSpecificError) {
          // it will be handled in a specific manner
          return Promise.reject(error);
        }
        if (originalRequest.url === '/auth/login') {
          // if unauthorized 401 due to wrong login credentials reject error directly
          return Promise.reject(error);
        }
        if (originalRequest.url === '/auth/logout') {
          // if unauthorized 401 due to wrong token resolve error directly and complete logout anyway
          return Promise.resolve(error);
        }
        if (responseErrorStatus === StatusCodes.UNAUTHORIZED && originalRequest.url === refreshTokenEndpoint) {
          this.isRefreshing = false;
          this.failedQueue = [];
          store.dispatch(logout());
          return Promise.reject(error);
        }
        if (this.isRefreshing) {
          return new Promise((resolve, reject) => {
            this.failedQueue.push({ resolve, reject });
          })
            .then(() => {
              delete originalRequest.headers;
              return this.axiosClient(originalRequest);
            })
            .catch(() => {
              return Promise.reject(error); // return actual "error" for the original request
            });
        }
        if (responseErrorStatus === StatusCodes.UNAUTHORIZED && !originalRequest._retry) {
          originalRequest._retry = true;
          this.isRefreshing = true;
          const response = await this.axiosClient.post(refreshTokenEndpoint);
          const token = response.data.token;
          this.setHeaderToken(token);
          store.dispatch(authActions.refreshToken(token));
          delete originalRequest.headers;
          return this.axiosClient(originalRequest)
            .then((res) => {
              this.processFailedQueue();
              return res;
            })
            .finally(() => {
              this.isRefreshing = false;
            });
        }
        if (responseErrorStatus === StatusCodes.UNPROCESSABLE_ENTITY) {
          const error = responseData?.errors;
          return Promise.reject(error);
        }

        return Promise.reject(error);
      },
    );
  }

  setLanguageHeader(lang) {
    this.axiosClient.defaults.headers['Accept-Language'] = lang;
  }

  setHeaderToken(userToken) {
    return new Promise((resolve, reject) => {
      const jwt = /^([A-Za-z0-9\-_~+]+[=]{0,2})\.([A-Za-z0-9\-_~+]+[=]{0,2})(?:\.([A-Za-z0-9\-_~+]+[=]{0,2}))?$/;

      if (jwt.test(userToken)) {
        this.axiosClient.defaults.headers.common.Authorization = `Bearer ${userToken}`;
        resolve();
      } else {
        reject();
      }
    });
  }

  resetHeaderToken() {
    delete this.axiosClient.defaults.headers.common.Authorization;
  }

  get(url, config = {}) {
    return this.axiosClient.get(url, config);
  }

  post(url, data, config = {}) {
    return this.axiosClient.post(url, data, config);
  }

  update(url, data, config = {}) {
    return this.axiosClient.put(url, data, config);
  }

  put(url, data, config = {}) {
    return this.axiosClient.put(url, data, config);
  }

  patch(url, data, config = {}) {
    return this.axiosClient.patch(url, data, config);
  }

  delete(url, config = {}) {
    return this.axiosClient.delete(url, config);
  }
}

export const axios = Axios.instance;
