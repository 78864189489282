import { alpha, Box } from '@mui/material';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';

const secondaryDashedBorder = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23BCB8D0' stroke-width='2' stroke-dasharray='1%2c 5.6' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e")`;
const secondaryDashedBorderHover = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%235B5675' stroke-width='2' stroke-dasharray='1%2c 5.6' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e")`;
const errorDashedBorder = `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FE3E3E' stroke-width='2' stroke-dasharray='1%2c 5.6' stroke-dashoffset='3' stroke-linecap='square'/%3e%3c/svg%3e")`;

export const FileUploadButton = memo(({ children, sx, error }) => {
  return (
    <Box
      sx={{
        ...(error && { '&, & > *': { color: (theme) => `${theme.palette.error.main} !important` } }),
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 14,
        fontWeight: 700,
        backgroundColor: error ? (theme) => alpha(theme.palette.error.main, 0.05) : 'primary.100',
        backgroundImage: error ? errorDashedBorder : secondaryDashedBorder,
        borderRadius: '8px',
        overflow: 'hidden',
        userSelect: 'none',
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          backgroundImage: error ? errorDashedBorder : secondaryDashedBorderHover,
        },
        '&:active': { transform: 'scale(.975)' },
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}, componentPropsAreEqual);

FileUploadButton.displayName = 'FileUploadButton';
