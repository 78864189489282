export default function LogoutIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <g fill='currentColor'>
        <path d='M26.195 19.72a1.2 1.2 0 00-1.608.54 9.6 9.6 0 110-8.519 1.208 1.208 0 002.161-1.08 12 12 0 100 10.679 1.2 1.2 0 00-.552-1.62z' />
        <path d='M26.797 14.8H15.289l2.761-2.748a1.202 1.202 0 00-1.7-1.7l-4.8 4.8a1.24 1.24 0 000 1.7l4.8 4.8a1.202 1.202 0 101.7-1.7L15.289 17.2h11.508a1.2 1.2 0 100-2.4z' />
      </g>
    </svg>
  );
}
