import { axios } from '../../../services/axios';

import { adminsActions } from '.';
import { Admin } from '../../../models';

const { fetchAdminsRequestLoading, fetchAdminsRequestSuccess } = adminsActions;

export const fetchAdmins = () => {
  return async (dispatch) => {
    dispatch(fetchAdminsRequestLoading(true));

    return axios
      .get('/admins')
      .then((res) => {
        const admins = res.data?.admins?.map((admin) => new Admin(admin));
        if (admins) dispatch(fetchAdminsRequestSuccess(admins));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(fetchAdminsRequestLoading(false)));
  };
};
