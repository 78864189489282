import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../components';
import AddIcon from '../../../../assets/images/icons/Add';
import { useModal } from '../../../../hooks';
import { TextField, Autocomplete } from '../../../../components';
import Stack from '@mui/material/Stack';
import { formOptions } from './AddAuthorModal.form';
import { useDispatch, useSelector } from 'react-redux';
import { createAuthor } from '../../../../redux/slices/authors/authors.create';
import { memo, useEffect } from 'react';
import { authorsSelector } from '../../../../redux/slices/authors';
import { componentPropsAreEqual, generateOptionsListFromEnumObject } from '../../../../utils';
import { AuthorStatus } from '../../../../enums';

const authorStatusOptions = generateOptionsListFromEnumObject(AuthorStatus);

const AddAuthorModal = ({
  initialValues,
  authorInitialInputValue,
  resetAuthorInitialInputValue = () => null,
  hideTrigger,
}) => {
  const { modalIsOpen, openModal, closeModal } = useModal();
  const form = useForm(formOptions);
  const { handleSubmit } = form;
  const { isCreatingAuthor } = useSelector(authorsSelector);
  const dispatch = useDispatch();

  // Set Initial Values
  useEffect(() => {
    if (initialValues) Object.keys(initialValues).forEach((value) => form.setValue(value, initialValues[value]));
  }, [initialValues, form]);

  // Handle AddAuthorModal open/close state as nested modal
  useEffect(() => {
    if (authorInitialInputValue) openModal();
    else closeModal();
  }, [authorInitialInputValue, openModal, closeModal]);

  function submitAddAuthorModal(data) {
    const { name, status } = data;
    const requestPayload = {
      name,
      status: status.value.toUpperCase(),
    };

    dispatch(createAuthor(requestPayload)).then(() => closeModal());
  }

  return (
    <>
      {hideTrigger ? null : (
        <Button variant='contained' startIcon={<AddIcon />} onClick={openModal}>
          Add Author
        </Button>
      )}

      <Modal
        title='Add Author'
        open={modalIsOpen}
        onSubmit={handleSubmit(submitAddAuthorModal)}
        submitButtonText='Add'
        submitButtonIsLoading={isCreatingAuthor}
        closeModal={() => {
          resetAuthorInitialInputValue();
          closeModal();
        }}
      >
        <Stack spacing='26px'>
          <TextField form={form} name='name' id='authorName' label='Author Name' placeholder='Ex: Naguib Mahfouz' />
          <Autocomplete
            form={form}
            name='status'
            id='authorStatus'
            label='Status'
            placeholder='Select Status'
            options={authorStatusOptions}
          />
        </Stack>
      </Modal>
    </>
  );
};

export default memo(AddAuthorModal, componentPropsAreEqual);
