import * as React from 'react';
import CheckboxComp from '@mui/material/Checkbox';
import { FormControlLabel } from '@mui/material';
import { useState } from 'react';
import { FormController } from '../index';
import { CheckedIcon, Icon } from './components/CheckedIcon';
import { getStyles } from './Checkbox.styles';

export const Checkbox = ({ form = {}, name, label, plain, labelSx, onChange: externalOnChange, ...props }) => {
  const [{ value = false, onChange }, setFormControllerValues] = useState({});
  const styles = getStyles({ plain });

  const handleOnChange = (e) => {
    onChange(e.target.checked);
    externalOnChange?.(e);
  };

  const checkboxJsx = (
    <>
      {form && name ? (
        <FormController form={form} name={name} setFormControllerValues={setFormControllerValues} />
      ) : null}
      <CheckboxComp
        sx={styles.checkboxRoot}
        checked={value}
        disableRipple
        checkedIcon={<CheckedIcon />}
        icon={<Icon />}
        {...(form && name && { value, onChange: handleOnChange })}
        {...props}
      />
    </>
  );

  return label ? (
    <FormControlLabel sx={{ ...styles.formControlLabel, ...labelSx }} control={checkboxJsx} label={label} />
  ) : (
    checkboxJsx
  );
};
