import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import MUI from './ui/MUI';
import SnackbarProvider from './utils/snackbar';
import ReduxProvider from './redux';

const DEV_MODE = process.env.NODE_ENV === 'development';

ReactDOM.render(
  <React.StrictMode>
    <ReduxProvider>
      <MUI>
        <SnackbarProvider>
          <App />
        </SnackbarProvider>
      </MUI>
    </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

if (DEV_MODE) {
  const consoleMessageStyles =
    'text-shadow: 2px 2px 0px #fff; font-size: 30px; font-weight: bold; font-family: monospace; background-color: #c4d19e; color: #0B2454; padding: 4px 20px; border-radius: 50px; margin:15px 0';
  console.log('%cSnackBook-logz 🤔', consoleMessageStyles);
}
