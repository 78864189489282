import { Link } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../redux/slices/categories/categories.read';
import { useCallback, useEffect, useState } from 'react';
import { CategoryStatus } from '../../enums';
import { categoriesSelector } from '../../redux/slices/categories';
import { pathnames } from '../..//routes/pathnames';

export default function useCategoriesTable() {
  const dispatch = useDispatch();
  const { categories, isFetchingCategories } = useSelector(categoriesSelector);
  const [categoriesTableRows, setCategoriesTableRows] = useState(null);
  const [categoriesTableRowsDraft, setCategoriesTableRowsDraft] = useState(null);

  const categoriesTableColumns = [
    {
      id: 'categoryName',
      label: 'Category Name',
      minWidth: 170,
    },
    {
      id: 'addedDate',
      label: 'Added Date',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
    },
  ];

  const createData = (categoryName, addedDate, status) => ({
    categoryName,
    addedDate,
    status,
  });

  const handleCategoryStatusChip = (categoryStatus = '') => {
    switch (categoryStatus.toUpperCase()) {
      case CategoryStatus.ACTIVE:
        return 'success';
      case CategoryStatus.INACTIVE:
        return 'error';
      default:
        return 'default';
    }
  };

  const searchCompareFunction = useCallback((row, searchInputValue) => {
    const categoryName = row.categoryName?.props?.children?.toLowerCase?.();
    const searchValue = searchInputValue?.toLowerCase?.()?.trim?.();
    return categoryName.includes(Boolean(searchValue) ? searchValue : null);
  }, []);

  // Fetch Categories Data
  useEffect(() => {
    if (categories === null) dispatch(fetchCategories());
  }, [categories, dispatch]);

  useEffect(() => {
    if (categories) {
      const rows = categories.map((category) => {
        return createData(
          <Link to={`${pathnames.categories}/${category.id}`}>{category.name}</Link>,
          category.createdAt,
          <Chip color={handleCategoryStatusChip(category.status)} label={category.status} />,
        );
      });

      setCategoriesTableRows(rows);
      setCategoriesTableRowsDraft(rows);
    }
  }, [categories]);

  return {
    categoriesTableColumns,
    categoriesTableRows,
    isFetchingCategories,
    categoriesTableRowsDraft,
    setCategoriesTableRows,
    searchCompareFunction,
  };
}
