import { Link } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { UnprocessableEntity } from '../../../components';
import { useSelector } from 'react-redux';
import { booksSelector } from '../../../redux/slices/books';
import { Helmet } from 'react-helmet';
import BookAnalytics from './BookAnalytics/BookAnalytics';
import BookDetails from './BookDetails/BookDetails';

const BookProfile = () => {
  const { book, bookIdIsNotValid } = useSelector(booksSelector);

  if (bookIdIsNotValid) {
    return (
      <UnprocessableEntity
        message='Oops! The book you’re looking for does not exist.'
        linkComponent={
          <Link to='/library/books'>
            <span>←</span> Back to Books
          </Link>
        }
      />
    );
  }

  return (
    <Box sx={{ maxWidth: '1000px', mx: 'auto' }}>
      <Helmet>
        <title>{`${book?.name || 'Book'} Profile | SnackBook`}</title>
      </Helmet>
      <Stack spacing='30px'>
        <BookAnalytics />
        <BookDetails />
      </Stack>
    </Box>
  );
};

export default BookProfile;
