import { axios } from '../../../services/axios';
import { booksActions } from '.';
import { snackbar } from '../../../utils';
import { Book } from '../../../models';

const { createBookRequestLoading, createBookRequestSuccess } = booksActions;

export const createBook = (createBookPayload) => {
  return async (dispatch) => {
    dispatch(createBookRequestLoading(true));

    return axios
      .post('/books', createBookPayload)
      .then((res) => {
        const book = new Book(res.data?.book);

        dispatch(createBookRequestSuccess({ book })); // Update books list after new book has been created
        snackbar.success('Book has been created successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(createBookRequestLoading(false)));
  };
};
