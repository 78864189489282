import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../components';
import AddIcon from '../../../../assets/images/icons/Add';
import { useModal } from '../../../../hooks';
import { TextField } from '../../../../components';
import Stack from '@mui/material/Stack';
import { formOptions } from './AddAdminModal.form';
import { useDispatch, useSelector } from 'react-redux';
import { createAdmin } from '../../../../redux/slices/admins/admins.create';
import { memo } from 'react';
import { adminsSelector } from '../../../../redux/slices/admins';
import { componentPropsAreEqual } from '../../../../utils';
import { authSelector } from '../../../../redux/slices/auth';
import { ADMIN_ROLES } from '../../../../constants/roles';

const AddAdminModal = () => {
  const { modalIsOpen, openModal, closeModal } = useModal();
  const form = useForm(formOptions);
  const { handleSubmit } = form;
  const { isCreatingAdmin } = useSelector(adminsSelector);
  const { user } = useSelector(authSelector);
  const dispatch = useDispatch();

  function submitAddAdminModal(data) {
    const { name, email, password } = data;
    const requestPayload = { name, email, password };

    dispatch(createAdmin(requestPayload)).then(() => closeModal());
  }

  return (
    <>
      {user?.role?.toUpperCase?.() === ADMIN_ROLES.SUPER_ADMIN ? (
        <Button variant='contained' startIcon={<AddIcon />} onClick={openModal}>
          Add Admin
        </Button>
      ) : null}

      <Modal
        title='Add Admin'
        open={modalIsOpen}
        onSubmit={handleSubmit(submitAddAdminModal)}
        submitButtonText='Add'
        submitButtonIsLoading={isCreatingAdmin}
        closeModal={closeModal}
      >
        <Stack spacing='26px'>
          <TextField form={form} name='name' id='adminName' label='Admin Name' placeholder='Ex: Ahmad Nasr' />
          <TextField
            form={form}
            type='email'
            name='email'
            id='adminEmail'
            label='Admin Email'
            placeholder='exmaple@internet.com'
          />
          <TextField
            form={form}
            type='password'
            name='password'
            id='adminPassword'
            label='Admin Password'
            placeholder='Enter a password'
          />
        </Stack>
      </Modal>
    </>
  );
};

export default memo(AddAdminModal, componentPropsAreEqual);
