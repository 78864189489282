export const FIELD_VARIANTS = {
  TEXT_FIELD: 'TEXT_FIELD',
  AUTOCOMPLETE_SINGLE: 'AUTOCOMPLETE_SINGLE',
  DATE_PICKER: 'DATE_PICKER',
};

export const FIELD_DEFAULT_VALUES = {
  [FIELD_VARIANTS.TEXT_FIELD]: '',
  [FIELD_VARIANTS.AUTOCOMPLETE_SINGLE]: null,
  [FIELD_VARIANTS.DATE_PICKER]: null,
};
