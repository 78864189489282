import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { componentPropsAreEqual } from '../../utils';
import { getStyles } from './FullCalendar.styles';

const Days = ({ calendar, renderDay }) => {
  const styles = getStyles();

  return (
    <Box sx={styles.daysGrid}>
      {calendar?.gridDayObjects?.map((calendarDayObject) => (
        <Box key={calendarDayObject.dateIsoString} sx={styles.day}>
          {renderDay(calendarDayObject)}
        </Box>
      ))}
    </Box>
  );
};

Days.propTypes = {
  calendar: PropTypes.object.isRequired,
  renderDay: PropTypes.func.isRequired,
};

export default memo(Days, componentPropsAreEqual);
