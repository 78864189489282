import { alpha } from '@mui/system';

export function getMenuDesignTokens(theme) {
  const { palette, breakpoints } = theme;

  const menuItemState = {
    '&:hover': { backgroundColor: palette.secondary[100] },
    '&:active': { backgroundColor: palette.secondary[200], transform: 'scale(.98)' },
  };

  return {
    MuiMenu: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            borderRadius: 8,
            marginTop: 9,
            boxShadow: `0 9px 28px ${alpha(theme.palette.secondary.main, 0.16)}`,
            border: `1px solid ${theme.palette.secondary[200]}`,
          },
        },
        list: { padding: '7px 0' },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableTouchRipple: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          padding: '9.5px 9px',
          [breakpoints.up('md')]: { padding: '9.5px 12px' },
          margin: '0 4px',
          borderRadius: 4,
          fontSize: 14,
          '&.Mui-selected.MuiButtonBase-root': {
            backgroundColor: palette.secondary[200],
            marginTop: '3px',
            marginBottom: '3px',
            '&:first-of-type': { marginTop: 0 },
            '&:last-of-type': { marginBottom: 0 },
          },
          ...menuItemState,
        },
      },
    },
  };
}
