export default function BooksIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path
        style={{ transform: 'translate(6px, 5px) scale(1.2)' }}
        d='M17.242.169A11.889 11.889 0 0015.292 0 11.715 11.715 0 009 1.824 11.716 11.716 0 002.713.044a11.888 11.888 0 00-1.95.169.893.893 0 00-.75.891v10.68a.887.887 0 00.315.69.905.905 0 00.737.2 9.873 9.873 0 017.415 1.7l.108.062h.1a.825.825 0 00.629 0h.1l.108-.062a9.871 9.871 0 017.415-1.8.905.905 0 00.737-.2.887.887 0 00.315-.69V1.006a.893.893 0 00-.75-.837zM8.101 11.997a11.66 11.66 0 00-5.388-1.317h-.9v-8.9a7.888 7.888 0 01.9 0 9.831 9.831 0 015.393 1.6zm8.089-1.282h-.9a11.66 11.66 0 00-5.391 1.315V3.382a9.831 9.831 0 015.393-1.6 7.888 7.888 0 01.9 0zm1.052 3.693a11.889 11.889 0 00-1.95-.169A11.715 11.715 0 009 16.063a11.715 11.715 0 00-6.287-1.823 11.889 11.889 0 00-1.95.169.9.9 0 00-.588.358.883.883 0 00-.162.663.9.9 0 001.052.7 9.873 9.873 0 017.415 1.7.906.906 0 001.043 0 9.873 9.873 0 017.415-1.7.9.9 0 001.052-.7.883.883 0 00-.158-.665.9.9 0 00-.588-.358z'
        fill='currentColor'
      />
    </svg>
  );
}
