import Box from '@mui/material/Box';
import { iframeStyles } from './Iframe.styles';

export const Iframe = ({ src, title }) => {
  return (
    <Box sx={iframeStyles.container}>
      {title ? (
        <Box component='h2' sx={iframeStyles.heading}>
          Report:{' '}
          <Box component='span' sx={iframeStyles.title}>
            {title}
          </Box>
        </Box>
      ) : null}
      <Box sx={iframeStyles.innerWrapper}>
        <Box sx={iframeStyles.skeleton} />
        <Box component='iframe' src={src} sx={iframeStyles.iframe} />
      </Box>
    </Box>
  );
};
