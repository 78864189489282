import { axios } from '../../../services/axios';
import { authActions } from '.';
import { persistor } from '../../store';

const { logoutRequestSuccess } = authActions;

export const logout = () => {
  return async (dispatch) => {
    return axios.post('/auth/logout').then(async () => {
      dispatch(logoutRequestSuccess());

      axios.resetHeaderToken();

      persistor.pause();
      await persistor.flush();
      await persistor.purge();
      persistor.persist();
    });
  };
};
