import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifications } from '../../redux/slices/notifications/notifications.read';
import { useCallback, useEffect, useState } from 'react';
import { notificationsSelector } from '../../redux/slices/notifications';
import { generateNewRelativePathWithSearchParams, parseSearchParams } from '../../utils';
import Chip from '@mui/material/Chip';
import { NotificationStatus } from '../../enums';
import { pathnames } from '../../routes/pathnames';

export const handleNotificationStatusChip = (notificationStatus = '') => {
  switch (notificationStatus.toUpperCase()) {
    case NotificationStatus.SENT:
      return 'success';
    case NotificationStatus.DRAFT:
      return 'default';
    default:
      return 'default';
  }
};

export default function useNotificationsTable() {
  const getSearchParams = useCallback(parseSearchParams, []);
  const history = useHistory();

  const dispatch = useDispatch();
  const { notifications, isFetchingNotifications } = useSelector(notificationsSelector);
  const [notificationsTableRows, setNotificationsTableRows] = useState(null);
  const [notificationsTableRowsDraft, setNotificationsTableRowsDraft] = useState(null);

  const notificationsTableColumns = [
    {
      id: 'notificationTitle',
      label: 'Title',
      minWidth: 170,
    },
    {
      id: 'notificationType',
      label: 'Type',
      minWidth: 100,
    },
    {
      id: 'addedDate',
      label: 'Added Date',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
    },
  ];

  const createData = (notificationTitle, notificationType, addedDate, status) => ({
    notificationTitle,
    notificationType,
    addedDate,
    status,
  });

  useEffect(() => {
    if (notifications) {
      const rows = notifications?.data.map((notification) => {
        return createData(
          <Link to={`${pathnames.notifications}/${notification.id}`}>{notification.title}</Link>,

          notification.type,
          notification.createdAt,
          <Chip color={handleNotificationStatusChip(notification.status)} label={notification.status} />,
        );
      });

      setNotificationsTableRows(rows);
      setNotificationsTableRowsDraft(rows);
    }
  }, [notifications]);

  const fetchPageData = useCallback((params) => dispatch(fetchNotifications(params)), [dispatch]);

  const searchData = useCallback(
    (searchInputValue) => {
      dispatch(fetchNotifications({ ...getSearchParams(), page: 0, title: searchInputValue.trim() }));

      history.push(
        generateNewRelativePathWithSearchParams({
          ...getSearchParams(),
          page: 0,
          title: searchInputValue.trim(),
        }),
      );
    },
    [dispatch, history, getSearchParams],
  );

  return {
    fetchPageData,
    notificationsTotalCount: notifications?.paginatorInfo?.total,
    notificationsTableColumns,
    notificationsTableRows,
    isFetchingNotifications,
    notificationsTableRowsDraft,
    setNotificationsTableRows,
    searchData,
    notificationsNameAndEmailSearchDefaultValue: getSearchParams().title,
  };
}
