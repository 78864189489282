import { useTheme } from '@mui/system';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import AuthorDetails from './AuthorDetails/AuthorDetails';
import { getStyles } from './AuthorProfile.styles';
import { useDispatch, useSelector } from 'react-redux';
import { authorsActions } from '../../../redux/slices/authors';
import { Link } from 'react-router-dom';
import { UnprocessableEntity } from '../../../components';
import { authorsSelector } from '../../../redux/slices/authors';
import AuthorBookList from './AuthorBookList/AuthorBookList';
import AuthorShareLink from './AuthorShareLink/AuthorShareLink';

const AuthorProfile = () => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { authorIdIsNotValid } = useSelector(authorsSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch(authorsActions.resetAuthorIdIsNotValidToBeFalse());
  }, [dispatch]);

  if (authorIdIsNotValid) {
    return (
      <UnprocessableEntity
        message='Oops! The author you’re looking for does not exist.'
        linkComponent={
          <Link to='/library/authors'>
            <span>←</span> Back to Authors
          </Link>
        }
      />
    );
  }

  return (
    <Box sx={styles.authorProfileGridContainer}>
      <Box>
        <AuthorDetails />
      </Box>
      <Box>
        <AuthorShareLink />
      </Box>
      <Box sx={styles.authorTopBooksWrapper}>
        <AuthorBookList />
      </Box>
    </Box>
  );
};

export default AuthorProfile;
