import dayjs from 'dayjs';
import { axios } from '../../../services/axios';
import { bookOfTheDayActions } from '.';
import { snackbar } from '../../../utils';
import { BookOfTheDay } from '../../../models';
const { addBookOfTheDayRequestLoading, addBookOfTheDayRequestSuccess } = bookOfTheDayActions;

export const addBookOfTheDay = ({ bookName, bookId, date }) => {
  return async (dispatch) => {
    dispatch(addBookOfTheDayRequestLoading({ bookId, date, isAddingBookOfTheDay: true }));

    const addBookOfTheDayPayload = { book_id: bookId, date };
    const formattedDate = dayjs(date).format('dddd, DD MMM YYYY');

    return axios
      .post('/book-of-the-day', addBookOfTheDayPayload)
      .then((res) => {
        const bookOfTheDay = new BookOfTheDay(res.data);

        dispatch(addBookOfTheDayRequestSuccess({ bookOfTheDay }));
        snackbar.success(`${bookName} has been added successfully to ${formattedDate}.`);
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(addBookOfTheDayRequestLoading({ bookId, date, isAddingBookOfTheDay: false })));
  };
};
