import { alpha } from '@mui/system';

export function getModalDesignTokens() {
  return {
    MuiModal: {
      styleOverrides: {
        root: {
          '&:not(.MuiPopover-root), &:not(.MuiMenu-root)': {
            '.MuiBackdrop-root': {
              backgroundColor: alpha('#141225', 0.7),
            },
          },
        },
      },
    },
  };
}
