export function getFormLabelDesignTokens() {
  return {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          display: 'flex',
          fontWeight: 500,
          fontSize: '15px',
          paddingBottom: 10,
        },
      },
    },
  };
}
