import { alpha, lighten } from '@mui/system';

export const getStyles = ({ isUploadedSuccessfully, percentage, isUploading, isDeletingBookIconFile }) => ({
  iconFileUploadOuterWrapper: {
    position: 'relative',
    ...((isUploading || isDeletingBookIconFile) && { pointerEvents: 'none' }),
  },
  contentWrapper: {
    width: '100%',
    height: '205px',
  },
  uploadStatusWrapper: {
    display: 'grid',
    height: '100%',
    position: 'absolute',
    width: '100%',
    borderRadius: 'inherit',
  },
  previewImgWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'transform 300ms ease',
    border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    bgcolor: '#fff',
    padding: '60px',
    borderRadius: 'inherit',
  },
  progressBar: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    transform: `translateX(${Number(isUploadedSuccessfully ? 0 : percentage)}%)`,
    transition: 'transform 200ms ease',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.6),
    opacity: isUploadedSuccessfully ? 0 : 1,
  },
  progressDetails: {
    backgroundColor: (theme) => lighten(theme.palette.secondary[200], 0.1),
    color: 'secondary.main',
    position: 'absolute',
    bottom: '7px',
    left: '7px',
    width: 'calc(100% - 14px)',
    height: '40px',
    fontWeight: '600',
    display: 'grid',
    placeItems: 'center',
    fontSize: '10px',
    paddingTop: '8px',
    borderRadius: '6px',
    zIndex: 1,
  },
  progressPercentage: {
    color: '#fff',
    backgroundColor: (theme) => theme.palette.primary.main,
    position: 'absolute',
    top: '0',
    transform: 'translateY(-50%)',
    borderRadius: '22px',
    display: 'grid',
    placeItems: 'center',
    fontSize: '10px',
    padding: '4px 10px',
    boxShadow: '0 3px 5px rgba(0, 0, 0, .2)',
  },
  fileUploadButton: {
    flexDirection: 'column',
  },
  deleteFileButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 11,
  },
  replaceButton: {
    position: 'absolute',
    left: 10,
    top: 10,
    pointerEvents: 'none',
  },
  deleteButton: {
    position: 'absolute',
    left: 10,
    top: 84,
    zIndex: 10,
  },
});
