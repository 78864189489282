import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EMAIL_REGEX } from '../../../../constants';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Admin name is required'),
  email: Yup.string().required('*Required!').matches(EMAIL_REGEX, 'Invalid Email Address'),
  password: Yup.string().required('*Required!').min(8, 'Password should be at least 8 characters!'),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    name: '',
    email: '',
    password: '',
  },
};
