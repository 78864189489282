import { memo } from 'react';
import { Box } from '@mui/material';
import { componentPropsAreEqual } from '../../../../utils';
import { styles } from './BookAnalytics.styles';

const BookAnalyticsItem = ({ title, data, icon }) => {
  return (
    <Box sx={styles.item.outerWrapper}>
      <Box sx={styles.item.detailsWrapper}>
        <p>{title}</p>
        <h2>{data}</h2>
      </Box>
      <Box>{icon ? icon : null}</Box>
    </Box>
  );
};

export default memo(BookAnalyticsItem, componentPropsAreEqual);
