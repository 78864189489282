import { Box } from '@mui/system';
import { Table, Section, TableSearchbar, ListCount } from '../../components';
import useNotificationsTable from './Notifications.table';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';
import { Stack } from '@mui/material';
import CreateNotificationModal from './modals/CreateNotificationModal/CreateNotificationModal';
import NotificationFilters from './components/NotificationFilters/NotificationFilters';

const Notifications = () => {
  const {
    notificationsTotalCount,
    notificationsTableColumns,
    notificationsTableRows,
    isFetchingNotifications,
    notificationsTableRowsDraft,
    setNotificationsTableRows,
    searchData,
    fetchPageData,
    notificationsNameAndEmailSearchDefaultValue,
  } = useNotificationsTable();

  return (
    <Section
      contentPadding={0}
      sectionHeader={
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <TableSearchbar
            async
            searchData={searchData}
            searchDefaultValue={notificationsNameAndEmailSearchDefaultValue}
            rowsDraft={notificationsTableRowsDraft}
            setRows={setNotificationsTableRows}
            placeholder='Search Notifications..'
          />
          <Stack flexShrink={0} direction='row' spacing='20px' alignItems='center'>
            <ListCount count={notificationsTotalCount} sx={{ height: '48px' }} />
            <NotificationFilters />
            <CreateNotificationModal />
          </Stack>
        </Box>
      }
    >
      <Table
        hasPaginator={true}
        columns={notificationsTableColumns}
        rows={notificationsTableRows}
        isLoading={isFetchingNotifications}
        dataTotalCount={notificationsTotalCount}
        fetchPageData={fetchPageData}
      />
    </Section>
  );
};

export default memo(Notifications, componentPropsAreEqual);
