import moment from 'moment';
import { AccountType } from '../enums/AccountType';
import EmailIcon from '../assets/images/icons/Email';
import AppleIcon from '../assets/images/icons/Apple';
import GoogleIcon from '../assets/images/icons/Google';

export class Client {
  constructor(data) {
    this.id = data.id || null;
    this.name = data.name || null;
    this.countryCode = data.country_code || null;
    this.countryDialCode = data.country_dial_code || null;
    this.countryName = data.country_name || null;
    this.email = data.email || null;
    this.emailVerified = data.email_verified === undefined ? null : data.email_verified;
    this.phone = data.phone || null;
    this.lastLoginAt = data.last_login_at ? moment(data.last_login_at).format('DD MMM YYYY') : null;
    this.createdAt = data.created_at ? moment(data.created_at).format('DD MMM YYYY') : null;
    this.role = data.role || null;
    this.picture = data.picture || null;
    this.source = data.source || null;
    this.appVersion = data.app_version || null;
    this.totalBookmarks = data.total_bookmarks !== undefined ? data.total_bookmarks : null;
    this.totalListeningTime = data.total_listening_time || null;
  }

  get accountType() {
    switch (this.source?.toUpperCase?.()) {
      case AccountType.EMAIL:
        return 'Email';
      case AccountType.SOCIAL_APPLE:
        return 'Apple Account';
      case AccountType.SOCIAL_GOOGLE:
        return 'Google Account';
      default:
        return 'Email';
    }
  }

  get accountTypeIcon() {
    switch (this.source?.toUpperCase?.()) {
      case AccountType.EMAIL:
        return <EmailIcon width={17} height={17} />;
      case AccountType.SOCIAL_APPLE:
        return <AppleIcon />;
      case AccountType.SOCIAL_GOOGLE:
        return <GoogleIcon />;
      default:
        return;
    }
  }
}
