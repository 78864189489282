import Button from '@mui/material/Button';
import LogoutIcon from '../../../../../assets/images/icons/Logout';
import { logout } from '../../../../../redux/slices/auth/auth.logout';
import Box from '@mui/material/Box';
import { getStyles } from './LogoutButton.styles';
import { useDispatch } from 'react-redux';

const LogoutButton = ({ isDrawerOpened }) => {
  const dispatch = useDispatch();
  const styles = getStyles(isDrawerOpened);

  function handleLogout() {
    dispatch(logout());
  }

  return (
    <Box sx={styles.logoutButtonWrapper}>
      <Button
        startIcon={<LogoutIcon />}
        size='large'
        color='error'
        fullWidth
        sx={styles.logoutButtonRoot}
        onClick={handleLogout}
      >
        Logout
      </Button>
    </Box>
  );
};

export default LogoutButton;
