import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { LoadingIndicator, Section, SectionDataItem, SectionH4 } from '../../../../components';
import { clientsSelector } from '../../../../redux/slices/clients';
import { useEffect } from 'react';
import { fetchClientActiveSubscription } from '../../../../redux/slices/clients/clients.read';
import { getStyles } from '../ClientProfile.styles';
import { useTheme } from '@mui/styles';
import EmptyList from '../../../../components/EmptyList/EmptyList';

const ActiveSubscription = ({ clientId }) => {
  const { client, clientActiveSubscription, isFetchingClientActiveSubscription } = useSelector(clientsSelector);
  const dispatch = useDispatch();
  const { startDate, endDate, autoRenewal } = clientActiveSubscription || {};
  const { productStoreId, productName, paymentType, paymentTypeIcon, transactionId, transactionDate } =
    clientActiveSubscription?.payment || {};
  const theme = useTheme();
  const styles = getStyles(theme);

  useEffect(() => {
    dispatch(fetchClientActiveSubscription(clientId));
  }, [clientId, dispatch]);

  return (
    <Section outerHeader title='Active Subscription' sx={{ height: '100%' }}>
      {isFetchingClientActiveSubscription || client === null ? (
        <LoadingIndicator />
      ) : clientActiveSubscription === null ? (
        <EmptyList title='No active subscription' />
      ) : (
        <Box>
          <SectionDataItem label='Subscriptions Type' value={productName} />
          <SectionDataItem label='Start Date' value={startDate} />
          <SectionDataItem label='End Date' value={endDate} />
          <SectionDataItem label='Auto Renewal' value={autoRenewal} />
          <hr />
          <SectionH4>Payment Info</SectionH4>
          <SectionDataItem
            label='Payment Type'
            value={
              <Box sx={styles.paymentTypeWrapper}>
                {paymentTypeIcon}
                {paymentType}
              </Box>
            }
          />
          <SectionDataItem label='Transaction Id' value={transactionId} />
          <SectionDataItem label='Transaction Date' value={transactionDate} />
          <SectionDataItem label='Product Id' value={productStoreId} />
        </Box>
      )}
    </Section>
  );
};

export default ActiveSubscription;
