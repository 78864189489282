import { lighten, useTheme } from '@mui/system';
import { Stack, LinearProgress, Box } from '@mui/material';

const TopBooksListSkeleton = () => {
  const theme = useTheme();

  return Array(3)
    .fill()
    .map((item, i) => (
      <Box
        key={i}
        sx={{
          display: 'flex',
          alignItems: 'center',
          zIndex: 1,
          padding: '0 60px 0 20px',
          width: '100%',
          height: '90px',
          '& + *': {
            borderTop: (theme) => `1px solid ${lighten(theme.palette.secondary[200], 0.41)}`,
          },
        }}
      >
        <LinearProgress
          color='secondary'
          sx={{
            width: 30,
            height: 30,
            borderRadius: '50%',
            display: 'inline-block',
            opacity: 0.35,
            mr: '21px',
            pointerEvents: 'none',
          }}
        />
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LinearProgress
            color='secondary'
            sx={{
              width: 40,
              height: 60,
              borderRadius: '3px',
              display: 'inline-block',
              verticalAlign: 'middle',
              mr: '14px',
              opacity: 0.35,
            }}
          />
          <Box>
            <Box
              component='h5'
              sx={{
                mb: '7px',
                fontSize: '14px',
                fontWeight: 700,
                [theme.breakpoints.only('sx', 'sm', 'lg')]: {
                  overflowX: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  maxWidth: '140px',
                },
              }}
            >
              <LinearProgress
                color='secondary'
                sx={{
                  width: '80px',
                  height: '7px',
                  display: 'inline-block',
                  opacity: 0.35,
                  borderRadius: 7,
                }}
              />
            </Box>
            <Box sx={{ fontSize: '12px', color: 'secondary.400' }}>
              <LinearProgress
                color='secondary'
                sx={{
                  width: '50px',
                  height: '7px',
                  display: 'inline-block',
                  opacity: 0.35,
                  borderRadius: 7,
                }}
              />
            </Box>
          </Box>
        </Box>
        <Stack direction='row' spacing='15px' sx={{ ml: 'auto', alignItems: 'center' }}>
          <LinearProgress
            color='secondary'
            sx={{
              width: 34,
              height: 34,
              borderRadius: '8px',
              display: 'inline-block',
              opacity: 0.28,
            }}
          />
          <LinearProgress
            color='secondary'
            sx={{
              width: 34,
              height: 34,
              borderRadius: '8px',
              display: 'inline-block',
              opacity: 0.28,
            }}
          />
          <LinearProgress
            color='secondary'
            sx={{
              width: 24,
              height: 24,
              borderRadius: '50%',
              display: 'inline-block',
              opacity: 0.28,
              m: '0 -40px 0 18px !important',
            }}
          />
        </Stack>
      </Box>
    ));
};

export default TopBooksListSkeleton;
