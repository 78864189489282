import { useCallback } from 'react';
import { CircularProgress, FileUpload, FileUploadButton, ValidationMessage } from '../../../../../components';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import ImageUploadIcon from '../../../../../assets/images/icons/ImageUpload';
import { getStyles } from './CategoryCoverUpload.styles';
import { useDispatch, useSelector } from 'react-redux';
import { storageSelector } from '../../../../../redux/slices/storage';
import { deleteFile } from '../../../../../redux/slices/storage/storage.delete';
import fallbackImg from '../../../../../assets/images/fallback-img.png';
import { FormLabel, IconButton } from '@mui/material';
import ArrowsExchangeIcon from '../../../../../assets/images/icons/ArrowsExchange';

const CategoryCoverUpload = ({ form, text, label }) => {
  const fieldName = 'cover';
  const { watch, formState } = form;
  const dispatch = useDispatch();
  const { storage } = useSelector(storageSelector);
  const {
    loaded,
    total,
    percentage,
    isUploading,
    isUploadedSuccessfully,
    uploadedFileLink: categoryCoverLink,
  } = storage.upload[fieldName] || {};

  const fieldHasError = Boolean(formState.errors[fieldName]);
  const fieldErrorMessage = formState.errors[fieldName]?.message;
  const categoryCoverUploadStyles = getStyles({ isUploadedSuccessfully, percentage });
  const categoryCover = watch(fieldName);
  const categoryCoverName = categoryCover?.[0]?.name || 'Category Cover Image';

  const getCategoryCoverUrl = useCallback(() => {
    const categoryCoverImageUrl = categoryCover;
    const categoryCoverFile = categoryCover?.[0];
    if (typeof categoryCoverImageUrl === 'string') return categoryCoverImageUrl;
    if (categoryCoverFile && typeof categoryCoverFile === 'object') return URL.createObjectURL(categoryCoverFile);
    if (categoryCoverLink) return categoryCoverLink;
  }, [categoryCover, categoryCoverLink]);

  const isCategoryCoverURLExisted = Boolean(getCategoryCoverUrl());

  const handleCategoryCoverDeletion = () => {
    return dispatch(deleteFile(categoryCoverLink || categoryCover));
  };

  return (
    <div>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <FileUpload
        form={form}
        name={fieldName}
        id='categoryCover'
        accept='.png, .jpg, .jpeg'
        {...((categoryCover || categoryCoverLink) && { handleFileDelete: handleCategoryCoverDeletion })}
      >
        <Box sx={categoryCoverUploadStyles.contentWrapper}>
          <FileUploadButton sx={categoryCoverUploadStyles.fileUploadButton} error={fieldHasError}>
            <Fade in={isCategoryCoverURLExisted} unmountOnExit>
              <Box sx={categoryCoverUploadStyles.uploadStatusWrapper}>
                <Box
                  component='img'
                  src={getCategoryCoverUrl() || fallbackImg}
                  alt={categoryCoverName}
                  sx={categoryCoverUploadStyles.previewImg}
                />
                {isUploadedSuccessfully || isCategoryCoverURLExisted ? (
                  <IconButton
                    variant='square'
                    color='primary'
                    sx={categoryCoverUploadStyles.replaceButton}
                    title='Replace the cover'
                  >
                    <ArrowsExchangeIcon />
                  </IconButton>
                ) : null}
                <Fade in={Boolean(!(!isUploading && !isUploadedSuccessfully))} timeout={700} unmountOnExit>
                  <Box display='flex'>
                    {isUploading ? <CircularProgress size={45} /> : null}
                    <Box sx={categoryCoverUploadStyles.progressBar} />
                    <Box sx={categoryCoverUploadStyles.progressDetails}>
                      <Box sx={categoryCoverUploadStyles.progressPercentage}>{Number(percentage)}%</Box>
                      {isUploadedSuccessfully ? '✓ Cover uploaded successfully! 🎉' : `${loaded} of ${total} Uploaded`}
                    </Box>
                  </Box>
                </Fade>
              </Box>
            </Fade>
            <ImageUploadIcon style={{ marginBottom: '14px' }} />
            {text}
          </FileUploadButton>
        </Box>
        <ValidationMessage message={fieldErrorMessage} />
      </FileUpload>
    </div>
  );
  x;
};

export default CategoryCoverUpload;
