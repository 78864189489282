import { axios } from '../../../services/axios';
import { categoriesActions } from '.';
import { snackbar } from '../../../utils';
import { CategoryTopBook } from '../../../models';

const {
  addTopBookRequestLoading,
  fetchCategoryTopBooksRequestSuccess,
  fetchCategoryTopBooksRequestLoading,
  deleteTopBookRequestLoading,
  addTopBookRequestSuccess,
  deleteTopBookRequestSuccess,
  replaceTopBookRequestSuccess,
} = categoriesActions;

export const fetchCategoryTopBooks = (categoryId) => {
  return async (dispatch) => {
    dispatch(fetchCategoryTopBooksRequestLoading(true));

    return axios
      .get(`/categories/${categoryId}/top-books`)
      .then((res) => {
        const categoryCategoryTopBooks = res.data?.category_top_books?.map((order) => new CategoryTopBook(order));

        if (categoryCategoryTopBooks) {
          dispatch(fetchCategoryTopBooksRequestSuccess(categoryCategoryTopBooks));
        }
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(fetchCategoryTopBooksRequestLoading(false)));
  };
};

export const addTopBook = ({ bookId, bookIndex, bookName, categoryId }) => {
  const requestPayload = { book_id: bookId };

  return async (dispatch) => {
    dispatch(addTopBookRequestLoading({ bookId, bookIndex, isAddingTopBook: true }));

    return axios
      .post(`/categories/${categoryId}/top-books`, requestPayload)
      .then((res) => {
        const topBook = res.data?.category_top_book && new CategoryTopBook(res.data?.category_top_book);

        if (topBook) {
          dispatch(addTopBookRequestSuccess({ bookId, bookIndex, topBook }));
          snackbar.success(
            `${bookName} has been added successfully to the top books list with order (${bookIndex}) 🎉`,
          );
        }
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(addTopBookRequestLoading({ bookId, bookIndex, isAddingTopBook: false })));
  };
};

export const replaceTopBook = ({ categoryTopBookId, bookId, bookIndex, bookName, categoryId }) => {
  const requestPayload = { book_id: bookId };

  return async (dispatch) => {
    dispatch(addTopBookRequestLoading({ bookId, bookIndex, isAddingTopBook: true }));

    return axios
      .patch(`/categories/${categoryId}/top-books/${categoryTopBookId}`, requestPayload)
      .then((res) => {
        const topBook = res.data?.category_top_book && new CategoryTopBook(res.data?.category_top_book);

        if (topBook) {
          dispatch(replaceTopBookRequestSuccess({ bookIndex, topBook }));
          snackbar.success(
            `${bookName} has been added successfully to the top books list with order (${bookIndex}) 🎉`,
          );
        }
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(addTopBookRequestLoading({ bookId, bookIndex, isAddingTopBook: false })));
  };
};

export const deleteTopBook = ({ categoryTopBookId, bookIndex, bookName, categoryId }) => {
  return async (dispatch) => {
    dispatch(deleteTopBookRequestLoading({ categoryTopBookId, bookIndex, isDeletingTopBook: true }));

    return axios
      .delete(`/categories/${categoryId}/top-books/${categoryTopBookId}`)
      .then(() => {
        dispatch(deleteTopBookRequestSuccess({ categoryTopBookId }));
        snackbar.success(`${bookName} has been removed successfully from the top books list! 🎉`);
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(deleteTopBookRequestLoading({ categoryTopBookId, bookIndex, isDeletingTopBook: false })));
  };
};
