import { axios } from '../../../services/axios';
import { adminsActions } from '.';
import { snackbar } from '../../../utils';
import { Admin } from '../../../models';

const { createAdminRequestLoading, createAdminRequestSuccess } = adminsActions;

export const createAdmin = (createAdminPayload) => {
  return async (dispatch) => {
    dispatch(createAdminRequestLoading(true));

    return axios
      .post('/admins', createAdminPayload)
      .then((res) => {
        const admin = new Admin(res.data?.admin);

        dispatch(createAdminRequestSuccess({ admin })); // Update admins list after new admin has been created
        snackbar.success('Admin has been added successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(createAdminRequestLoading(false)));
  };
};
