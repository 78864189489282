import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

export const initialState = {
  isFetchingAuthors: false,
  isFetchingSingleAuthor: false,
  isCreatingAuthor: false,
  isUpdatingAuthor: false,
  authors: null,
  author: null,
  authorIdIsNotValid: false,
};

const authorsSlice = createSlice({
  name: 'authors',
  initialState,
  reducers: {
    fetchAuthorsRequestLoading: (state, { payload }) => {
      state.isFetchingAuthors = payload;
    },
    fetchSingleAuthorRequestLoading: (state, { payload }) => {
      state.isFetchingSingleAuthor = payload;
    },
    createAuthorRequestLoading: (state, { payload }) => {
      state.isCreatingAuthor = payload;
    },
    updateAuthorRequestLoading: (state, { payload }) => {
      state.isUpdatingAuthor = payload;
    },
    fetchAuthorsRequestSuccess: (state, { payload }) => {
      state.authors = payload;
    },
    fetchSingleAuthorRequestSuccess: (state, { payload }) => {
      state.author = payload;
    },
    fetchSingleAuthorRequestFailure: (state, { payload }) => {
      const statusCode = payload;

      if (statusCode === StatusCodes.BAD_REQUEST) state.authorIdIsNotValid = true;
    },
    resetSingleAuthor: (state) => {
      state.author = null;
    },
    createAuthorRequestSuccess: (state, { payload }) => {
      const { author } = payload;

      state.authors.unshift(author);
    },
    updateAuthorRequestSuccess: (state, { payload }) => {
      const { authorId, updatedAuthor } = payload;

      state.author = updatedAuthor;
      state.authors = state.authors?.map((author) => {
        if (author.id === authorId) return updatedAuthor;
        else return author;
      });
    },
    resetAuthorIdIsNotValidToBeFalse: (state) => {
      state.authorIdIsNotValid = false;
    },
  },
});

export const authorsActions = authorsSlice.actions;
export const authorsSelector = (state) => state.authors;
export default authorsSlice.reducer;
