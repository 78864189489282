import EudoxusSansFontFaceDeclarations from '../../../assets/fonts/EudoxusSans/stylesheet';

export function getCssBaselineTokens(theme) {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '@fontFace': [EudoxusSansFontFaceDeclarations],
        'h1, h2, h3, h4, h5, h6': { margin: 0, fontSize: 'initial' },
        p: { marginTop: 0 },
        a: {
          '&:not(.MuiButton-root), &:not(.MuiButtonBase-root)': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
            fontWeight: 600,
            transition: 'opacity 120ms ease',
            '&:hover': { opacity: 0.75 },
          },
        },
        img: {
          maxWidth: '100%',
        },
      },
    },
  };
}
