import { getShapeBaseTokens } from './shape';
import { getTypographyBaseTokens } from './typography';

export function getThemeBaseTokens() {
  return {
    direction: 'ltr',
    ...getShapeBaseTokens(),
    ...getTypographyBaseTokens(),
  };
}
