import { LinearProgressWithLabel } from '../../../../components';
import { Box } from '@mui/material';

const ListeningTimeProgressBar = ({ ListeningTimeInPercentage }) => {
  return (
    <Box sx={{ maxWidth: 170 }}>
      <LinearProgressWithLabel variant='determinate' value={ListeningTimeInPercentage} height={7} />
    </Box>
  );
};

export default ListeningTimeProgressBar;
