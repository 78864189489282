import { alpha } from '@mui/system';
import { Box } from '@mui/material';

export const ListCount = ({ count = 0, sx }) => {
  return (
    <Box
      sx={{
        width: '32px',
        height: '32px',
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: '32px',
        fontSize: '14px',
        color: 'primary.main',
        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
        fontWeight: 600,
        ...sx,
      }}
    >
      {count}
    </Box>
  );
};
