import { useCallback, useState } from 'react';

export default function useModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = useCallback(() => setModalIsOpen(true), []);

  const closeModal = useCallback(() => setModalIsOpen(false), []);

  const isConfirmed = () => confirm('You have unsaved changes, are you sure you want to leave?');

  const closeModalWithConfirm = useCallback(() => isConfirmed() && setModalIsOpen(false), []);

  return { modalIsOpen, openModal, closeModal, closeModalWithConfirm, setModalIsOpen };
}
