import { capitalizeWords } from '../utils';
import dayjs from 'dayjs';

export class Notification {
  constructor(data) {
    this.id = data.id || null;
    this.title = data.title || null;
    this.content = data.content || null;
    this.type = data.type ? capitalizeWords(data.type.split('_').join(' ')) : null;
    this._status = data.status ? this.prettifyStatus(data.status) : null;
    this.createdAt = data.created_at ? dayjs(data.created_at).format('DD MMM YYYY') : null;
    this.releaseAt = data.release_at ? dayjs(data.release_at).format('YYYY-MM-DD') : null;
    this.analytics = data.analytics ? data.analytics : null;
  }

  prettifyStatus = (status) => capitalizeWords(status);

  get status() {
    return this._status;
  }

  set status(value) {
    this._status = this.prettifyStatus(value);
  }
}
