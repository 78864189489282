import { memo } from 'react';
import { Box, IconButton } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './BookAudioUpload.player.styles.css';
import { getStyles } from './BookAudioUpload.styles';
import Fade from '@mui/material/Fade';
import ArrowsExchangeIcon from '../../../assets/images/icons/ArrowsExchange';
import { useDispatch } from 'react-redux';
import { storageActions } from '../../../redux/slices/storage';
import { componentPropsAreEqual } from '../../../utils';

const BookAudioPlayer = ({
  form,
  audioSrc,
  audioName,
  audioFieldName,
  showAudioPreview,
  resetAudioInput,
  fieldHasError,
}) => {
  const dispatch = useDispatch();
  const bookAudioUploadStyles = getStyles({ fieldHasError });

  const handleOnLoadedMetaData = (event) => {
    const audioDuration = event.target.duration;
    dispatch(storageActions.uploadFileAudioDuration({ fieldName: audioFieldName, audioDuration }));
    form.clearErrors(audioFieldName);
  };

  return (
    <Fade in={Boolean(showAudioPreview)} {...(showAudioPreview ? { timeout: 600 } : {})} unmountOnExit>
      <Box sx={bookAudioUploadStyles.playerWrapper}>
        <AudioPlayer
          autoPlay={false}
          showJumpControls={false}
          customVolumeControls={[]}
          customAdditionalControls={[]}
          src={audioSrc}
          onLoadedMetaData={handleOnLoadedMetaData}
          defaultDuration={
            <Box sx={bookAudioUploadStyles.playerDefaultDuration}>
              <LinearProgress color='secondary' />
            </Box>
          }
          defaultCurrentTime={
            <Box sx={bookAudioUploadStyles.playerDefaultCurrentTime}>
              <LinearProgress color='secondary' />
            </Box>
          }
          header={<Box sx={bookAudioUploadStyles.playerAudioName}>{audioName}</Box>}
        />
        {audioSrc ? (
          <IconButton
            variant='square'
            color='primary'
            sx={bookAudioUploadStyles.replaceAudioButton}
            title='Replace the audio book'
            onClick={resetAudioInput}
          >
            <ArrowsExchangeIcon />
          </IconButton>
        ) : null}
      </Box>
    </Fade>
  );
};

export default memo(BookAudioPlayer, componentPropsAreEqual);
