export const styles = {
  textFieldWrapper: { fontSize: 0 },
  textFieldInputWrapper: { position: 'relative' },
  textFieldLoadingIndicatorWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    cursor: 'not-allowed',
  },
  textFieldLoadingIndicator: {
    position: 'absolute',
    right: '16px',
    top: '50%',
    transform: 'translateY(-50%)',
    filter: 'grayscale(1)',
    opacity: 0.6,
    pointerEvents: 'none',
  },
};
