import { axios } from '../../../services/axios';

import { notificationsActions } from '.';
import { Notification } from '../../../models';
import { StatusCodes } from 'http-status-codes';
import humps from 'humps';

const {
  fetchNotificationsRequestLoading,
  fetchNotificationsRequestSuccess,
  fetchSingleNotificationRequestLoading,
  fetchSingleNotificationRequestSuccess,
  fetchSingleNotificationRequestFailure,
} = notificationsActions;

export const fetchNotifications = (params = {}) => {
  return (dispatch) => {
    dispatch(fetchNotificationsRequestLoading(true));
    const { type, created_at, title, ...restOfParams } = params;

    return axios
      .get(`/notifications`, {
        params: {
          ...humps.decamelizeKeys(restOfParams),
          ...(type && { type: type }),
          ...(created_at && { created_at: created_at }),
          ...(title && { title: title }),
        },
      })
      .then((res) => {
        const { data, ...paginatorInfo } = res.data;
        const notificationsData = data?.map((notification) => new Notification(notification));

        dispatch(fetchNotificationsRequestSuccess({ data: notificationsData, paginatorInfo }));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(fetchNotificationsRequestLoading(false)));
  };
};

export const fetchSingleNotification = (notificationId) => {
  return async (dispatch) => {
    dispatch(fetchSingleNotificationRequestLoading(true));

    return axios
      .get(`/notifications/${notificationId}`)
      .then(async (res) => {
        const notification = new Notification({ ...res?.data?.notification, analytics: res?.data?.analytics });

        dispatch(fetchSingleNotificationRequestSuccess(notification));
      })
      .catch((error) => {
        const statusCode = error?.response?.status;

        if (statusCode === StatusCodes.BAD_REQUEST) {
          dispatch(fetchSingleNotificationRequestFailure(statusCode));
        }
      })
      .finally(() => dispatch(fetchSingleNotificationRequestLoading(false)));
  };
};
