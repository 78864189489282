import { useTheme } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect, useRef, useState } from 'react';
import { LinearProgress, Fade, Box } from '@mui/material';
import { getItemStyles } from './BookOfTheDay.item.styles';
import { componentPropsAreEqual } from '../../utils';
import { Autocomplete } from '../../components';
import { bookOfTheDaySelector } from '../../redux/slices/book-of-the-day';
import { addBookOfTheDay } from '../../redux/slices/book-of-the-day/book-of-the-day.create';
import { replaceBookOfTheDay } from '../../redux/slices/book-of-the-day/book-of-the-day.update';

const BookOfTheDayItem = ({ initialBook = null, booksOptionsList, bookOfTheDayId, date }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const showSelectedBookPreview = selectedOption && !isOpen;
  const dispatch = useDispatch();
  const { bookOfTheDay } = useSelector(bookOfTheDaySelector);
  const bookIdOfSelectedOption = selectedOption?.value;
  const { isAddingBookOfTheDay } = bookOfTheDay.add[`${bookIdOfSelectedOption}_${date}`] || {};
  const theme = useTheme();
  const timer = useRef(null);

  const styles = getItemStyles({ isAddingBookOfTheDay, showSelectedBookPreview, theme, isOpen });

  const handleAutocompleteOnOpen = () => setIsOpen(true);
  const handleAutocompleteOnClose = () => setIsOpen(false);

  const handleAutocompleteOnChange = (event, value) => {
    setSelectedOption(value);

    timer.current = setTimeout(() => {
      const isAddMode = selectedOption === null;
      const { value: bookId, label: bookName } = value;

      if (isAddMode) {
        dispatch(addBookOfTheDay({ bookName, bookId, date }));
      } else if (bookOfTheDayId) {
        dispatch(replaceBookOfTheDay({ bookName, bookId, bookOfTheDayId, date }));
      }
    }, 0);
  };

  useEffect(() => {
    if (!selectedOption && initialBook && booksOptionsList) {
      const bookOption = booksOptionsList.find((option) => option.value === initialBook.id) || null;

      setSelectedOption(bookOption);
    }
  }, [initialBook, selectedOption, booksOptionsList]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <Box sx={styles.item.bookOfTheDayAutocompleteItemWrapper}>
      <Autocomplete
        id={`addBookAutocomplete_${date}`}
        disabled={isAddingBookOfTheDay}
        disableClearable
        listItemHeight={60}
        onOpen={handleAutocompleteOnOpen}
        onClose={handleAutocompleteOnClose}
        renderOption={(props, option) => [
          props,
          <Box key={option.label} sx={styles.item.bookOfTheDayOption}>
            <Box sx={styles.item.bookOfTheDayOptionInnerWrapper}>
              <Box
                component='img'
                width={30}
                height={45}
                src={option.cover}
                alt='Book Cover'
                loading='lazy'
                sx={styles.item.bookOfTheDayOptionCover}
              />
              <Box>
                <Box component='h5' sx={styles.item.bookOfTheDayOptionName}>
                  {option.label}
                </Box>
                <Box sx={styles.item.bookOfTheDayOptionAuthor}>{option?.author?.name}</Box>
              </Box>
            </Box>
          </Box>,
        ]}
        value={selectedOption}
        onChange={handleAutocompleteOnChange}
        options={booksOptionsList}
        popperSx={styles.item.autocompletePopper}
        sx={styles.item.autocompleteRoot}
        placeholder='+ Add Book'
      />

      {/*Adding book loading indicator */}
      <Fade in={Boolean(isAddingBookOfTheDay)} unmountOnExit>
        <Box display='flex'>
          <LinearProgress color='primary' sx={styles.item.addingBookOfTheDayLoadingIndicator} />
        </Box>
      </Fade>

      {/*Book item preview */}
      <Fade in={showSelectedBookPreview}>
        <Box sx={styles.item.bookOfTheDayPreviewWrapper}>
          <Box sx={styles.item.bookOfTheDayPreviewBookDetailsWrapper}>
            <Box
              component='img'
              width={40}
              height={60}
              src={selectedOption?.cover}
              alt='Book Cover'
              sx={styles.item.bookOfTheDayPreviewBookCover}
            />
            <Box component='h5' sx={styles.item.bookOfTheDayPreviewBookName}>
              {selectedOption?.name}
            </Box>
            <Box sx={styles.item.bookOfTheDayPreviewBookAuthorName}>{selectedOption?.author?.name}</Box>
          </Box>
        </Box>
      </Fade>
    </Box>
  );
};

export default memo(BookOfTheDayItem, componentPropsAreEqual);
