import { alpha, lighten } from '@mui/system';

const counterActiveStyles = { bgcolor: 'primary.main', color: '#fff' };

export const getStyles = ({ isAddingTopBook, isDeletingTopBook, showSelectedBookPreview, isOpen, theme } = {}) => ({
  section: {
    categoryNameSkeleton: {
      width: '80px',
      height: '7px',
      display: 'inline-block',
      verticalAlign: 'middle',
      ml: '10px',
    },
    addBookButton: {
      height: '60px',
      borderRadius: 0,
      '&:active': { transform: 'scale(1) !important' },
    },
    topBooksContentWrapper: {
      mb: '-1px',
      maxHeight: 'calc(100vh - 65px - 65px - 80px - 64px)',
      overflowX: 'hidden',
    },
  },
  item: {
    topBookAutocompleteItemWrapper: {
      position: 'relative',
      ...(!isAddingTopBook && { '&:hover': { '& .counter': counterActiveStyles } }),
      ...((isAddingTopBook || isDeletingTopBook) && { pointerEvents: 'none' }),
    },
    topBookOption: {
      m: '-10px',
      p: '10px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover .replaceBookButton': { bgcolor: 'primary.main', color: '#fff' },
    },
    topBookOptionInnerWrapper: { display: 'flex', alignItems: 'center' },
    topBookOptionCover: {
      width: 30,
      height: 45,
      borderRadius: '3px',
      border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
      margin: '0 14px 0 0',
      userSelect: 'none',
      position: 'relative',
      objectFit: 'cover',
      color: 'transparent',
      zIndex: 0,

      '&:before': {
        content: "'🚫'",
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        height: 'calc(100% + 2px)',
        width: 'calc(100% + 2px)',
        display: 'grid',
        placeItems: 'center',
        color: 'error.main',
        bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
        borderRadius: 'inherit',
        border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
        zIndex: 15,
        pointerEvents: 'none',
      },
    },
    topBookOptionName: {
      mb: '2px',
      fontSize: '14px',
      fontWeight: 700,
    },
    topBookOptionAuthor: { fontSize: '12px', color: 'secondary.400' },
    autocompleteRoot: {
      '& .MuiOutlinedInput-root': {
        borderRadius: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
          borderBottom: (theme) => `1px solid ${lighten(theme.palette.secondary[200], 0.41)}`,
        },
        '&:hover': {
          bgcolor: (theme) => lighten(theme.palette.secondary[100], 0.55),
        },
        '&.Mui-focused, &.Mui-error': { '& .MuiOutlinedInput-notchedOutline': { boxShadow: 'unset' } },
        '& .MuiAutocomplete-endAdornment': { right: '20px' },
        '& .MuiOutlinedInput-input': {
          cursor: 'pointer',
          height: '90px',
          transition: 'opacity 280ms ease',
          ...(showSelectedBookPreview && { opacity: 0, userSelect: 'none' }),
          p: '0 71px !important',
        },
      },
    },
    autocompletePopper: {
      px: '10px !important',
      '& .MuiAutocomplete-option[aria-selected=true] .chooseBookButton': {
        display: 'none',
      },
    },
    addingTopBookLoadingIndicator: {
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      borderRadius: '0',
      opacity: '.2',
      zIndex: '2',
    },
    topBookPreviewWrapper: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      top: '50%',
      left: '0',
      transform: 'translateY(-50%)',
      zIndex: 1,
      padding: '0 60px 0 20px',
      width: '100%',
      pointerEvents: 'none',
    },
    topBookCounterSkeleton: {
      width: 30,
      height: 30,
      borderRadius: '50%',
      display: 'inline-block',
      opacity: 0.35,
      mr: '21px',
      pointerEvents: 'none',
    },
    topBookCounter: {
      width: 30,
      height: 30,
      borderRadius: '50%',
      color: 'primary.main',
      bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
      display: 'grid',
      placeItems: 'center',
      fontSize: '14px',
      fontWeight: 700,
      userSelect: 'none',
      pointerEvents: 'none',
      mr: '21px',
      '& span': { mt: '-1px' },
      transition: 'all 120ms ease',
      ...(isOpen && counterActiveStyles),
    },
    topBookPreviewBookDetailsWrapper: { display: 'flex', alignItems: 'center' },
    topBookPreviewBookCoverSkeleton: {
      width: 40,
      height: 60,
      borderRadius: '3px',
      display: 'inline-block',
      verticalAlign: 'middle',
      mr: '14px',
      opacity: 0.35,
    },
    topBookPreviewBookCover: {
      width: 40,
      height: 60,
      borderRadius: '3px',
      border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
      margin: '0 14px 0 0',
      userSelect: 'none',
      position: 'relative',
      objectFit: 'cover',
      color: 'transparent',
      zIndex: 0,

      '&:before': {
        content: "'🚫'",
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        height: 'calc(100% + 2px)',
        width: 'calc(100% + 2px)',
        display: 'grid',
        placeItems: 'center',
        color: 'error.main',
        bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
        borderRadius: 'inherit',
        border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
        zIndex: 15,
        pointerEvents: 'none',
      },
    },
    topBookPreviewBookName: {
      mb: '7px',
      fontSize: '14px',
      fontWeight: 700,
      [theme?.breakpoints?.only('sx', 'sm', 'lg')]: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '140px',
      },
    },
    topBookPreviewBookNameSkeleton: {
      width: '80px',
      height: '7px',
      display: 'inline-block',
      opacity: 0.35,
      borderRadius: 7,
    },
    topBookPreviewBookAuthorName: { fontSize: '12px', color: 'secondary.400' },
    topBookPreviewBookAuthorNameSkeleton: {
      width: '50px',
      height: '7px',
      display: 'inline-block',
      opacity: 0.35,
      borderRadius: 7,
    },
    topBookActionsWrapper: { position: 'absolute', top: '50%', right: '62px', transform: 'translateY(-50%)' },
  },
});
