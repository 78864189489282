export const iframeStyles = {
  container: { width: '100%' },
  heading: { mb: '34px' },
  title: { color: 'primary.main' },
  innerWrapper: { position: 'relative' },
  skeleton: {
    width: '100%',
    minHeight: 'calc(100vh - 262px)',
    bgcolor: '#F7F8F9',
    borderRadius: '8px',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
    '&::before': {
      content: "'Loading...'",
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      zIndex: 1,
      fontSize: '18px',
    },
  },
  iframe: {
    all: 'unset',
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh - 262px)',
    borderRadius: '8px',
    zIndex: 2,
  },
};
