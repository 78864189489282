import { Section } from '../../components';
import Box from '@mui/material/Box';

export default function Home() {
  return (
    <Section>
      <Box sx={{ fontSize: '17px' }}>Welcome to SnackBook Admin Dashboard. 👋</Box>
    </Section>
  );
}
