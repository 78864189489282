export default function BooksIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path
        d='M24.01 3H11.146A5.173 5.173 0 006 8.2v15.6a5.173 5.173 0 005.146 5.2H24.01a2.587 2.587 0 002.573-2.6V5.6A2.587 2.587 0 0024.01 3zM8.573 8.2a2.587 2.587 0 012.573-2.6H24.01v13H11.146a4.991 4.991 0 00-2.573.728zm2.573 18.2a2.6 2.6 0 010-5.2H24.01v5.2zm2.573-15.6h5.146a1.3 1.3 0 000-2.6h-5.146a1.3 1.3 0 000 2.6z'
        fill='currentColor'
      />
    </svg>
  );
}
