import { axios } from '../../../services/axios';
import { booksActions } from '.';

const { getNoOfBookmarksLoading, getNoOfBookmarksSuccess } = booksActions;

export const getNoOfBookmarks = (bookId) => {
  return async (dispatch) => {
    dispatch(getNoOfBookmarksLoading(true));

    return axios
      .get(`/books/${bookId}/no-of-bookmarks`)
      .then((res) => {
        const noOfBookmarks = res.data?.no_of_bookmarks;

        if (noOfBookmarks || noOfBookmarks === 0) {
          dispatch(getNoOfBookmarksSuccess(noOfBookmarks));
        }
      })
      .finally(() => dispatch(getNoOfBookmarksLoading(false)));
  };
};
