import { alpha, lighten } from '@mui/system';

export function getButtonDesignTokens(theme) {
  return {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          '&:not(.MuiIconButton-root):active': { transform: 'scale(.96)' },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'large',
      },
      styleOverrides: {
        root: {
          '&:active': { transform: 'scale(.96)' },
          '&.Mui-disabled': {
            '&.MuiButton-contained': {
              '&:not(.MuiLoadingButton-root)': { color: alpha(theme.palette.secondary.main, 0.36) },
              backgroundColor: alpha(theme.palette.secondary[200], 0.8),
            },
            '&.MuiButton-outlined': {
              '&:not(.MuiLoadingButton-root)': { color: alpha(theme.palette.secondary.main, 0.36) },
              borderColor: alpha(theme.palette.secondary.main, 0.175),
            },
          },
        },
        sizeSmall: { borderRadius: 4, fontWeight: 600 },
        sizeMedium: { borderRadius: 6, fontWeight: 700 },
        sizeLarge: {
          borderRadius: 8,
          fontSize: '16px',
          padding: '9px 2rem',
          height: '48px',
          fontWeight: 700,
        },
      },
      variants: [
        {
          props: { variant: 'bare' },
          style: {
            '&:hover': { backgroundColor: 'unset' },
            padding: '0',
          },
        },
        {
          props: { color: 'secondary', variant: 'outlined' },
          style: {
            borderColor: theme.palette.secondary[200],
            '&:hover': {
              backgroundColor: theme.palette.secondary[100],
              borderColor: theme.palette.secondary[200],
            },
          },
        },
        {
          props: { color: 'primary', variant: 'outlined' },
          style: {
            borderColor: theme.palette.primary.main,
          },
        },
      ],
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: 'square' },
          style: {
            padding: 0,
            borderRadius: 4,
            width: 34,
            height: 34,
            '&:active': { transform: 'scale(.92)' },
          },
        },
        {
          props: { color: 'primary' },
          style: {
            color: theme.palette.primary.main,
            backgroundColor: lighten(theme.palette.primary.main, 0.89),
            '&:hover': {
              backgroundColor: lighten(theme.palette.primary.main, 0.84),
            },
            '&:active': {
              backgroundColor: lighten(theme.palette.primary.main, 0.79),
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            color: theme.palette.error.main,
            backgroundColor: lighten(theme.palette.error.main, 0.89),
            '&:hover': {
              backgroundColor: lighten(theme.palette.error.main, 0.84),
            },
            '&:active': {
              backgroundColor: lighten(theme.palette.error.main, 0.8),
            },
          },
        },
      ],
    },
  };
}
