import * as Yup from 'yup';
import { CategoryStatus } from '../../../../enums';
import { yupResolver } from '@hookform/resolvers/yup';
import { capitalizeWords } from '../../../../utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
  status: Yup.mixed().required('Category status is required'),
  cover: Yup.mixed()
    .test('isRequired', 'Category cover is required', (value) => {
      const isEmptyString = value === '';
      return !isEmptyString;
    })
    .test('coverNotUploadedYet', 'Cover not uploaded yet!', (value) => {
      const isObject = typeof value[0] === 'object';
      return !isObject;
    }),
  icon: Yup.mixed().test('iconNotUploadedYet', 'Icon not uploaded yet!', (value) => {
    const isObject = typeof value[0] === 'object';
    return !isObject;
  }),
  brandingColor: Yup.string(),
  promotionText: Yup.string(),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    name: '',
    status: { value: CategoryStatus.ACTIVE.toUpperCase(), label: capitalizeWords(CategoryStatus.ACTIVE) },
    brandingColor: '',
    cover: '',
    icon: '',
    promotionText: '',
  },
};
