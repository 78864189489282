import { Link, useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { clientsSelector } from '../../../../../redux/slices/clients';
import Box from '@mui/material/Box';
import { getStyles } from '../../ClientProfile.styles';
import { useTheme } from '@mui/system';
import { pathnames } from '../../../../../routes/pathnames';
import ListeningTimeProgressBar from '../ListeningTimeProgressBar';
import { fetchListenedBooksByClientId } from '../../../../../redux/slices/clients/clients.read';
import { handleBookStatusChip } from '../../../../../utils';

export default function useClientBookListTable() {
  const dispatch = useDispatch();
  const { clientListenedBooks, isFetchingClientListenedBooks } = useSelector(clientsSelector);
  const [booksTableRows, setBooksTableColumns] = useState(null);
  const { id: clientId } = useParams();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const booksTableColumns = [
    {
      id: 'bookName',
      label: 'Book Name',
      minWidth: 170,
    },
    {
      id: 'listeningTime',
      label: 'Listening Time',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
    },
  ];

  const createData = (bookName, listeningTime, status) => ({
    bookName,
    listeningTime,
    status,
  });

  // Fetch Categories Data
  useEffect(() => {
    dispatch(fetchListenedBooksByClientId(clientId));
  }, [dispatch, clientId]);

  useEffect(() => {
    if (clientListenedBooks) {
      const rows = clientListenedBooks.map((listenedBook) => {
        const bookName = (
          <Box display='flex' alignItems='center'>
            <Box
              component='img'
              width={40}
              height={60}
              src={listenedBook.book?.cover}
              alt='Book Cover'
              loading='lazy'
              sx={styles.categoryBookListTableBookCoverImage}
            />
            <Box>
              <Box mb='7px'>
                <Link to={`${pathnames.books}/${listenedBook.book?.id}`}>{listenedBook.book?.name || 'Book Name'}</Link>
              </Box>
              <Box sx={{ fontSize: '12px', color: 'secondary.400' }}>
                {listenedBook.book?.author?.name || 'Author Name'}
              </Box>
            </Box>
          </Box>
        );

        return createData(
          bookName,
          <ListeningTimeProgressBar ListeningTimeInPercentage={listenedBook.listeningTimeInPercentage} />,
          <Chip color={handleBookStatusChip(listenedBook.book?.status)} label={listenedBook.book?.status} />,
        );
      });

      setBooksTableColumns(rows);
    }
  }, [clientListenedBooks, styles]);

  return {
    booksTableColumns,
    booksTableRows,
    isFetchingClientListenedBooks,
  };
}
