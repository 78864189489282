import { useEffect, useRef, useState } from 'react';
import { FullCalendar } from '../../components';
import { Calendar } from '../../components/FullCalendar/FullCalenadr.calendar';
import { dropDownFormat } from '../../utils';
import { BookStatus } from '../../enums';
import { fetchBooks } from '../../redux/slices/books/books.read';
import { useDispatch, useSelector } from 'react-redux';
import { booksSelector } from '../../redux/slices/books';
import { bookOfTheDaySelector } from '../../redux/slices/book-of-the-day';
import { fetchBookOfTheDayList } from '../../redux/slices/book-of-the-day/book-of-the-day.read';
import BookOfTheDayItemWrapper from './BookOfTheDay.item.wrapper';

const BookOfTheDay = () => {
  const [yearAndMonth, setYearAndMonth] = useState([Calendar.thisYear, Calendar.thisMonth]);
  const { books } = useSelector(booksSelector);
  const { bookOfTheDay, isFetchingBookOfTheDayList } = useSelector(bookOfTheDaySelector);
  const dispatch = useDispatch();
  const booksOptionsList = dropDownFormat(books, { value: 'id', label: 'name' })?.filter(
    (option) => option.status.toUpperCase() === BookStatus.ACTIVE,
  );
  const timer = useRef(null);

  const handleYearAndMonthChange = (yearAndMonth) => {
    timer.current = setTimeout(() => setYearAndMonth(yearAndMonth), 0);
  };

  useEffect(() => {
    if (books === null) dispatch(fetchBooks());
  }, [books, dispatch]);

  useEffect(() => {
    const month = yearAndMonth[1];

    dispatch(fetchBookOfTheDayList(month));
  }, [dispatch, yearAndMonth]);

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  return (
    <FullCalendar
      yearAndMonth={yearAndMonth}
      onYearAndMonthChange={handleYearAndMonthChange}
      monthData={bookOfTheDay.list}
      renderDay={(calendarDayObject) => (
        <BookOfTheDayItemWrapper
          calendarDayObject={calendarDayObject}
          booksOptionsList={booksOptionsList}
          isFetchingBookOfTheDayList={isFetchingBookOfTheDayList}
        />
      )}
    />
  );
};

export default BookOfTheDay;
