export default function CategoriesIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path
        style={{ transform: 'translate(6px, 5px) scale(1.2)' }}
        d='M.451 6.348l8.1 4.68a.9.9 0 00.9 0l8.1-4.68a.9.9 0 000-1.557L9.451.12a.9.9 0 00-.9 0l-8.1 4.674a.9.9 0 000 1.557zm8.55-4.374l6.3 3.6-6.3 3.6-6.3-3.6zm7.65 6.453l-7.65 4.347-7.65-4.38a.9.9 0 10-.9 1.557l8.1 4.68a.9.9 0 00.9 0l8.1-4.68a.9.9 0 10-.9-1.557zm0 3.6l-7.65 4.347-7.65-4.38a.9.9 0 10-.9 1.557l8.1 4.68a.9.9 0 00.9 0l8.1-4.68a.9.9 0 10-.9-1.557z'
        fill='currentColor'
      />
    </svg>
  );
}
