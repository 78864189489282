export default function ArrowSingleBottom(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} {...props}>
      <path
        d='M4.296 6.044a1 1 0 011.41 0l3.59 3.54 3.54-3.54a1 1 0 111.406 1.42L10.006 11.7a1 1 0 01-1.42 0l-4.29-4.24a1 1 0 010-1.42z'
        fill='currentColor'
      />
    </svg>
  );
}
