import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchClients } from '../../redux/slices/clients/clients.read';
import { useCallback, useEffect, useState } from 'react';
import { clientsSelector } from '../../redux/slices/clients';
import { generateNewRelativePathWithSearchParams, parseSearchParams } from '../../utils';
import { pathnames } from '../../routes/pathnames';

export default function useClientsTable() {
  const getSearchParams = useCallback(parseSearchParams, []);
  const history = useHistory();

  const dispatch = useDispatch();
  const { clients, isFetchingClients } = useSelector(clientsSelector);
  const [clientsTableRows, setClientsTableRows] = useState(null);
  const [clientsTableRowsDraft, setClientsTableRowsDraft] = useState(null);

  const clientsTableColumns = [
    {
      id: 'clientName',
      label: 'Client Name',
      minWidth: 170,
    },
    {
      id: 'clientEmail',
      label: 'Email Address',
      minWidth: 100,
    },
    {
      id: 'clientPhone',
      label: 'Phone Number',
      minWidth: 100,
    },
    {
      id: 'clientCountry',
      label: 'Country',
      minWidth: 100,
    },
  ];

  const createData = (clientName, clientEmail, clientPhone, clientCountry) => ({
    clientName,
    clientEmail,
    clientPhone,
    clientCountry,
  });

  useEffect(() => {
    if (clients) {
      const rows = clients.data?.map((client) => {
        return createData(
          <Link to={`${pathnames.clients}/${client.id}`}>{client.name}</Link>,
          <a href={`mailto: ${client.email}`}>{client.email}</a>,
          client.phone,
          client.countryName,
        );
      });

      setClientsTableRows(rows);
      setClientsTableRowsDraft(rows);
    }
  }, [clients]);

  const searchCompareFunction = useCallback((row, searchInputValue) => {
    const clientName = row.clientName?.props?.children?.toLowerCase?.();
    const searchValue = searchInputValue?.toLowerCase()?.trim();
    return clientName.includes(Boolean(searchValue) ? searchValue : null);
  }, []);

  const fetchPageData = useCallback((params) => dispatch(fetchClients(params)), [dispatch]);

  const searchData = useCallback(
    (searchInputValue) => {
      dispatch(fetchClients({ ...getSearchParams(), page: 0, email_or_name: searchInputValue.trim() }));

      history.push(
        generateNewRelativePathWithSearchParams({
          ...getSearchParams(),
          page: 0,
          email_or_name: searchInputValue.trim(),
        }),
      );
    },
    [dispatch, history, getSearchParams],
  );

  return {
    fetchPageData,
    clientsTotalCount: clients?.paginatorInfo?.total,
    clientsTableColumns,
    clientsTableRows,
    isFetchingClients,
    clientsTableRowsDraft,
    setClientsTableRows,
    searchCompareFunction,
    searchData,
    clientsNameAndEmailSearchDefaultValue: getSearchParams().email_or_name,
  };
}
