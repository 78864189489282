export default function EditIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} {...props}>
      <path
        d='M2.7 14.406h3.816a.9.9 0 00.639-.261l6.228-6.239 2.556-2.5a.9.9 0 000-1.278L12.123.266a.9.9 0 00-1.278 0L8.307 2.813 2.061 9.048a.9.9 0 00-.261.639v3.819a.9.9 0 00.9.9zm8.784-12.232l2.547 2.546-1.278 1.278-2.547-2.547zM3.6 10.056l5.337-5.335 2.547 2.545-5.337 5.34H3.6zm13.5 6.145H.9a.9.9 0 100 1.8h16.2a.9.9 0 100-1.8z'
        fill='currentColor'
      />
    </svg>
  );
}
