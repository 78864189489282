import { alpha } from '@mui/system';

export function getMuiAutocompleteDesignTokens(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        popper: {
          padding: '10px 0',
        },
        paper: {
          boxShadow: `0 9px 28px ${alpha(theme.palette.secondary.main, 0.16)}`,
          border: `1px solid ${theme.palette.secondary[200]}`,
          padding: '0 5px',
          borderRadius: '8px',
          '& .MuiAutocomplete-listbox': {
            '& .MuiAutocomplete-option': {
              backgroundColor: '#fff',
              fontSize: '14px',
              borderRadius: '6px',
              padding: '0 10px',
              '&.Mui-focused': {
                backgroundColor: theme.palette.secondary[100],
              },
              '&[aria-selected="true"]': {
                backgroundColor: alpha(theme.palette.secondary[200], 0.6),
                '&:hover': {
                  backgroundColor: alpha(theme.palette.secondary[200], 0.6),
                },
                '&.Mui-focused': {
                  backgroundColor: alpha(theme.palette.secondary[200], 0.6),
                  '&:hover': {
                    backgroundColor: alpha(theme.palette.secondary[200], 0.85),
                  },
                  '&:active': {
                    backgroundColor: theme.palette.secondary[200],
                  },
                },
              },
              '&:hover': {
                backgroundColor: theme.palette.secondary[100],
              },
              '&:active': {
                backgroundColor: alpha(theme.palette.secondary[200], 0.8),
              },
            },
          },
          '& .MuiAutocomplete-popupIndicator': {
            '&.MuiSvgIcon-root': { path: { display: 'none' } },
          },
        },
        popupIndicator: {
          transition: 'all 120ms ease',
          color: theme.palette.secondary.main,
          svg: {
            backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%235B5675' d='M8.12 9.29 12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7a.9959.9959 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z'%3E%3C/path%3E%3C/svg%3E")`,
            path: { display: 'none' },
          },
          '&.Mui-disabled': {
            opacity: 0.6,
          },
        },
        clearIndicator: {
          svg: { transform: 'scale(.85)' },
        },
      },
    },
  };
}
