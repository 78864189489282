import { Box } from '@mui/system';
import { styles } from './EmptyList.styles';
import PropTypes from 'prop-types';
import ExclamationMarkIcon from '../../assets/images/icons/ExclamationMark';

const EmptyList = ({ title, overview, icon }) => {
  return (
    <Box sx={styles.wrapper}>
      <Box sx={styles.iconWrapper}>{icon ? icon : <ExclamationMarkIcon width={60} height={60} />}</Box>
      <Box component='h4' sx={styles.title}>
        {title}
      </Box>
      {overview ? (
        <Box component='p' sx={styles.overview}>
          {overview}
        </Box>
      ) : null}
    </Box>
  );
};

EmptyList.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node,
  overview: PropTypes.string,
};

export default EmptyList;
