import { axios } from '../../../services/axios';
import { booksActions } from '.';

const { getNoOfListeningLoading, getNoOfListeningSuccess } = booksActions;

export const getNoOfListening = (bookId) => {
  return async (dispatch) => {
    dispatch(getNoOfListeningLoading(true));

    return axios
      .get(`/books/${bookId}/no-of-listening`)
      .then((res) => {
        const noOfListening = res.data?.no_of_listening;

        if (noOfListening || noOfListening === 0) {
          dispatch(getNoOfListeningSuccess(noOfListening));
        }
      })
      .finally(() => dispatch(getNoOfListeningLoading(false)));
  };
};
