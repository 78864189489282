import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { LoadingIndicator, Section, SectionDataItem } from '../../../../components';
import { useTheme } from '@mui/system';
import { getStyles } from '../ClientProfile.styles';
import { clientsSelector } from '../../../../redux/slices/clients';

const ClientDetails = () => {
  const { client, isFetchingSingleClient, isFetchingClientTotalListenings } = useSelector(clientsSelector);
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <Section outerHeader title='Account Details' sx={{ height: '100%' }}>
      {isFetchingSingleClient || client === null ? (
        <LoadingIndicator />
      ) : (
        <Box>
          <SectionDataItem label='Country' value={client.countryName} />
          <SectionDataItem label='Phone Number' value={client.phone} />
          <SectionDataItem
            label='Account Type'
            value={
              <Box sx={styles.accountTypeWrapper}>
                {client.accountTypeIcon}
                {client.accountType}
              </Box>
            }
          />
          <SectionDataItem label='Email Address' value={<a href={`mailto: ${client.email}`}>{client.email}</a>} />
          <SectionDataItem
            label='Total Listening Time'
            value={client.totalListeningTime}
            isLoading={isFetchingClientTotalListenings}
          />
          <SectionDataItem label='Total Bookmarks' value={client.totalBookmarks} />
          <SectionDataItem label='App Version' value={client.appVersion || 'Unknown'} />
          <SectionDataItem label='Registered at' value={client.createdAt} />
          <SectionDataItem label='Last login at' value={client.lastLoginAt} />
        </Box>
      )}
    </Section>
  );
};

export default ClientDetails;
