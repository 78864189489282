import { Box } from '@mui/system';
import { Table, Section, ListCount, TableSearchbar } from '../../components';
import useCategoriesTable from './Categories.table';
import AddCategoryModal from './modals/AddCategoryModal/AddCategoryModal';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';
import { Stack } from '@mui/material';

const Categories = () => {
  const {
    categoriesTableColumns,
    categoriesTableRows,
    isFetchingCategories,
    categoriesTableRowsDraft,
    setCategoriesTableRows,
    searchCompareFunction,
  } = useCategoriesTable();

  return (
    <Section
      contentPadding={0}
      sectionHeader={
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <TableSearchbar
            rowsDraft={categoriesTableRowsDraft}
            setRows={setCategoriesTableRows}
            compareFunction={searchCompareFunction}
            placeholder='Search Categories..'
          />
          <Stack flexShrink={0} direction='row' spacing='20px' alignItems='center'>
            <ListCount count={categoriesTableRows?.length} sx={{ height: '48px' }} />
            <AddCategoryModal />
          </Stack>
        </Box>
      }
    >
      <Table columns={categoriesTableColumns} rows={categoriesTableRows} isLoading={isFetchingCategories} />
    </Section>
  );
};

export default memo(Categories, componentPropsAreEqual);
