import { styled } from '@mui/system';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { Box } from '@mui/material';
import React from 'react';
import { keyframes, css } from '@emotion/react';

const progressBarStripes = keyframes`
  0%   { background-position: 0; }
  100% { background-position: 40px }
`;

const BorderLinearProgress = styled(LinearProgress, {
  shouldForwardProp: (prop) => prop !== 'withStripe',
})(({ theme, withStripe, height = 9 }) => ({
  height,
  borderRadius: 20,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.secondary[200],
    ...(withStripe && {
      backgroundImage: `linear-gradient(45deg, ${theme.palette.secondary[100]} 25%, transparent 25%, transparent 50%, ${theme.palette.secondary[100]} 50%, ${theme.palette.secondary[100]} 75%, transparent 75%, transparent)`,
      backgroundSize: '10px 10px',
      transition: 'all 200ms ease',
      webkitAnimation: css`
        ${progressBarStripes} 3s linear infinite
      `,
      oAnimation: css`
        ${progressBarStripes} 3s linear infinite
      `,
      animation: css`
        ${progressBarStripes} 3s linear infinite
      `,
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 20,
    backgroundColor: theme.palette.primary.main,
  },
}));

export const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <BorderLinearProgress {...props} />
      </Box>
      <Box sx={{ minWidth: 35, fontSize: '12px', fontWeight: 700 }}>{Math.round(props.value)}%</Box>
    </Box>
  );
};
