import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import { autocompleteClasses } from '@mui/material/Autocomplete';

const AutocompletePopper = styled(Popper)({
  zIndex: '1500',
  [`& .${autocompleteClasses.listbox}`]: {
    maxHeight: '280px',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default AutocompletePopper;
