import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthors } from '../../redux/slices/authors/authors.read';
import { useCallback, useEffect, useState } from 'react';
import { AuthorStatus } from '../../enums';
import { authorsSelector } from '../../redux/slices/authors';
import { Link } from 'react-router-dom';
import { pathnames } from '../../routes/pathnames';

export default function useAuthorsTable() {
  const dispatch = useDispatch();
  const { authors, isFetchingAuthors } = useSelector(authorsSelector);
  const [authorsTableRows, setAuthorsTableRows] = useState(null);
  const [authorsTableRowsDraft, setAuthorsTableRowsDraft] = useState(null);

  const authorsTableColumns = [
    {
      id: 'authorName',
      label: 'Author Name',
      minWidth: 170,
    },
    {
      id: 'addedDate',
      label: 'Added Date',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
    },
  ];

  const createData = (authorName, addedDate, status, actions) => ({ authorName, addedDate, status, actions });

  const handleAuthorStatusChip = (authorStatus = '') => {
    switch (authorStatus.toUpperCase()) {
      case AuthorStatus.ACTIVE:
        return 'success';
      case AuthorStatus.INACTIVE:
        return 'error';
      default:
        return 'default';
    }
  };

  // Fetch Authors Data
  useEffect(() => {
    if (authors === null) dispatch(fetchAuthors());
  }, [dispatch, authors]);

  useEffect(() => {
    if (authors) {
      const rows = authors.map((author) => {
        return createData(
          <Link to={`${pathnames.authors}/${author.id}`}>{author.name}</Link>,
          author.createdAt,
          <Chip color={handleAuthorStatusChip(author.status)} label={author.status} />,
        );
      });

      setAuthorsTableRows(rows);
      setAuthorsTableRowsDraft(rows);
    }
  }, [authors]);

  const searchCompareFunction = useCallback((row, searchInputValue) => {
    const authorName = row.authorName?.props?.children?.toLowerCase?.();
    const searchValue = searchInputValue?.toLowerCase()?.trim();
    return authorName.includes(Boolean(searchValue) ? searchValue : null);
  }, []);

  return {
    authorsTableColumns,
    authorsTableRows,
    isFetchingAuthors,
    authorsTableRowsDraft,
    setAuthorsTableRows,
    searchCompareFunction,
  };
}
