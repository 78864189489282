import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authorsSelector } from '../../../redux/slices/authors';
import { categoriesSelector } from '../../../redux/slices/categories';
import { dropDownFormat, generateOptionsListFromEnumObject } from '../../../utils';
import Box from '@mui/material/Box';
import { Prompt } from 'react-router-dom';
import { Stack, Collapse } from '@mui/material';
import { Autocomplete, TextField, ShareLinkInput, DatePicker, RichTextEditor } from '../../../components';
import BookAudioUpload from '../../../pages/Books/BookAudioUpload/BookAudioUpload';
import BookCoverUpload from '../../../pages/Books/BookCoverUpload/BookCoverUpload';
import { BookStatus } from '../../../enums';
import { fetchAuthors } from '../../../redux/slices/authors/authors.read';
import { fetchCategories } from '../../../redux/slices/categories/categories.read';
import { booksSelector } from '../../../redux/slices/books';
import { getTomorrowDate } from '../../../utils';

const bookStatusOptions = generateOptionsListFromEnumObject(BookStatus);

const AddEditBookForm = ({ form, isViewPage, bookCoverLink, bookRecordLink }) => {
  const { formState, setValue } = form;
  const { authors } = useSelector(authorsSelector);
  const { categories } = useSelector(categoriesSelector);
  const { book } = useSelector(booksSelector);
  const authorsListOptions = dropDownFormat(authors, { value: 'id', label: 'name' });
  const categoriesListOptions = dropDownFormat(categories, { value: 'id', label: 'name' });
  const dispatch = useDispatch();
  const [showReleaseAtField, setShowReleaseAtField] = useState(false);

  useEffect(() => {
    if (!authors && !categories) {
      dispatch(fetchAuthors());
      dispatch(fetchCategories());
    }
  }, [authors, categories, dispatch]);

  useEffect(() => {
    if (book?.status.toUpperCase() === BookStatus.INACTIVE) setShowReleaseAtField(true);
  }, [book]);

  const handleBookStatusChange = (value) => {
    if (value?.value?.toUpperCase?.() === BookStatus.INACTIVE) {
      setValue('releaseAt', null);
      setShowReleaseAtField(true);
    } else {
      setShowReleaseAtField(false);
    }
  };

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr minmax(0, 255px)', columnGap: '40px' }}>
      <Prompt when={formState.isDirty} message='You have unsaved changes, are you sure you want to leave?' />
      <Box>
        <Stack spacing='25px'>
          <Box sx={{ display: 'grid', gridTemplateColumns: { sx: '1fr', lg: '1fr 1fr' }, gap: '25px' }}>
            <TextField form={form} name='name' id='addBookName' label='Book Name' placeholder='Ex: War and Peace' />
            <Autocomplete
              isCreatable
              form={form}
              name='category'
              id='addBookCategory'
              label='Category'
              placeholder='Select Category'
              options={categoriesListOptions}
            />
            <Autocomplete
              isCreatable
              form={form}
              name='author'
              id='addBookAuthor'
              label='Author'
              placeholder='Select Author'
              options={authorsListOptions}
            />
            <Autocomplete
              form={form}
              name='status'
              id='addBookStatus'
              label='Status'
              placeholder='Select Status'
              options={bookStatusOptions}
              onChange={handleBookStatusChange}
            />
            <Collapse in={showReleaseAtField}>
              <Box>
                <DatePicker
                  form={form}
                  name='releaseAt'
                  id='addBookReleaseAt'
                  label='Release At'
                  placeholder='Select Date'
                  minDate={getTomorrowDate(new Date())}
                />
              </Box>
            </Collapse>
          </Box>
          <RichTextEditor
            form={form}
            name='description'
            id='description'
            label='Description'
            placeholder='Write book description'
          />
          <BookAudioUpload form={form} {...(isViewPage && { initialBookRecordLink: bookRecordLink })} />
          {isViewPage ? <ShareLinkInput label='Share Link' linkText={book?.sharableLink} /> : null}
        </Stack>
      </Box>
      <Box>
        <BookCoverUpload form={form} {...(isViewPage && { initialBookCoverLink: bookCoverLink })} />
      </Box>
    </Box>
  );
};

export default AddEditBookForm;
