import { axios } from '../../../services/axios';
import { booksActions } from '.';
import { snackbar } from '../../../utils';
import { Book } from '../../../models';

const { updateBookRequestLoading, updateBookRequestSuccess } = booksActions;

export const updateBook = (bookId, updateBookPayload) => {
  return async (dispatch) => {
    dispatch(updateBookRequestLoading(true));

    return axios
      .patch(`/books/${bookId}`, updateBookPayload)
      .then((res) => {
        const updatedBook = new Book(res.data?.book);

        dispatch(updateBookRequestSuccess({ bookId, updatedBook })); // Update books list after new book has been updated
        snackbar.success('Book has been updated successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(updateBookRequestLoading(false)));
  };
};
