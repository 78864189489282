import { axios } from '../../../services/axios';
import { authActions } from '.';
const { loginRequestLoading, loginRequestSuccess } = authActions;

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch(loginRequestLoading(true));

    const requestPayload = { email, password };

    return axios
      .post('/auth/login', requestPayload)
      .then(async (res) => {
        const responseData = res.data;
        dispatch(loginRequestSuccess(responseData));
        axios.setHeaderToken(responseData.token);
      })
      .catch((error) => console.log({ error }))
      .finally(() => dispatch(loginRequestLoading(false)));
  };
};
