import dayjs from 'dayjs';
import { capitalizeWords } from '../utils';
import { Author, Category } from '.';

export class Book {
  constructor(data) {
    this.id = data.id || null;
    this.name = data.name || null;
    this.description = data.description || null;
    this.author = data.author ? new Author(data.author) : null;
    this.category = data.category ? new Category(data.category) : null;
    this.cover = data.cover || null;
    this.recordLink = data.record_link || null;
    this.duration = data.duration || null;
    this.sharableLink = data.sharable_link || null;
    this.status = data.status ? capitalizeWords(data.status) : null;
    this.createdAt = data.created_at ? dayjs(data.created_at).format('DD MMM YYYY') : null;
    this.releaseAt =
      data.release_at && dayjs(data.release_at).format('YYYY-MM-DD') !== '1970-01-01'
        ? dayjs(data.release_at).format('YYYY-MM-DD')
        : null;
  }
}
