import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function AndroidNotificationView({ isLoading, ...props }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={365.073} height={151.451} {...props}>
      <g data-name='Group 1179'>
        <path
          data-name='Path 682'
          d='M134.852 16.29a27.932 27.932 0 0 1 6.26-.286q43.949.03 87.892.01c3.174-.188 6.492 2.8 6.023 6.067-.741 2.6-3.15 5.03-6.008 4.9-29.467-.069-58.934-.01-88.4-.03a34.644 34.644 0 0 1-6.2-.193c-2.231-1.229-4.31-3.772-3.619-6.472a5.633 5.633 0 0 1 4.052-3.996Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 683'
          d='M279.999 18.257c1.945-.706 4.537-.459 5.8 1.363a4.789 4.789 0 0 1-.573 6.4 4.728 4.728 0 1 1-5.228-7.765Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 684'
          d='M21.165 59.636q161.414-.037 322.828 0 .037 27.628 0 55.261-161.414.037-322.828 0-.03-27.628 0-55.261'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 685'
          d='M36.266 73.779c.168-.943.933-1.219 1.787-1.175.094.306.281.918.37 1.224-.72-.009-1.441-.029-2.157-.049Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 686'
          d='M47.418 72.545c1.817-.217 1.649 2.123 1.318 3.293-.617.03-1.234.049-1.851.049-.212-1.095-.734-2.67.533-3.342Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 687'
          d='M50.089 85.662c.291-.538.41-1.466 1.219-1.387.839-.158 1.081.815 1.5 1.323-.907.039-1.811.054-2.719.064Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 688'
          d='M55.426 87.059c-.77-1.575.415-3.362 2.192-2.705a15.285 15.285 0 0 1 .1 3.49c-.841.119-2.159.439-2.292-.785Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 689'
          d='M72.225 84.561a5.371 5.371 0 0 1 1.762-.385c.37 1.18.775 2.651-.059 3.727a5.706 5.706 0 0 1-1.831-.059c-.128-1.076-.593-2.308.128-3.283Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 690'
          d='M82.948 87.795c.069-1.145-.731-3.3.938-3.525 1.595-.311 1.743 1.856 1.377 2.927-.222 1.101-1.54.785-2.315.598Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 691'
          d='M318.431 84.339a33.226 33.226 0 0 1 4.4 5.88 115.591 115.591 0 0 1-13.9.025 28.485 28.485 0 0 1 3.145-4.369 73.952 73.952 0 0 1 2.834 2.606c1.181-1.373 2.346-2.76 3.521-4.142Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 692'
          d='M30.633 86.906c.444-.839 1.575-.543 2.345-.741-.049.39-.148 1.16-.193 1.55-.784.317-2.438.505-2.152-.809Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 693'
          d='M67.206 86.354a12.918 12.918 0 0 1 1.856-.039c-.267.879-.795 2.1-1.95 1.6-.855.028-.722-1.536.094-1.561Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 694'
          d='M77.502 87.785c-.607-1.678 1.353-1.535 2.419-1.486-.203 1.186-1.17 2.216-2.419 1.486Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 716'
          d='M134.693.747c5.934.173 11.823-.627 17.752-.666 15.639-.128 31.284-.059 46.923-.074 5.939.079 11.883-.069 17.816.183 14.933 1.229 29.946.04 44.884 1.17 8.224.785 16.5 0 24.723.79a89.724 89.724 0 0 0 11.8.563c5.939-.168 11.818.908 17.757.938a101.121 101.121 0 0 1 12.559 1.086 52.122 52.122 0 0 1 13.067 5.129 46.6 46.6 0 0 1 21.909 30.291 72.053 72.053 0 0 1 1.185 8.086q-.007 35.255 0 70.505c-.879 0-1.757-.01-2.631-.01q.052-30.3.015-60.6c-.044-3.836.212-7.691-.311-11.507-.854-16.414-12.253-31.545-27.532-37.4a45.188 45.188 0 0 0-13.289-3.085c-6.265.064-12.48-.928-18.735-1.081-3.352-.133-6.7-.336-10.041-.568-5.4-.459-10.821-.109-16.217-.528-10.012-.74-20.058-.607-30.089-.81-11.828-.617-23.676-.765-35.524-.731q-36.536-.1-73.073.059c-5.628.3-11.27.2-16.893.592-6.047.227-12.1.173-18.147.321-6.926-.084-13.813.854-20.739.8-7.913-.069-15.783.963-23.7.992-4.122-.01-8.22.538-12.332.726-8.027.543-16.128 2.582-22.891 7.074A43.828 43.828 0 0 0 2.422 51.715c.044 20.077 0 40.15.025 60.227.03 2.276.025 4.552-.133 6.827q-1.14-.015-2.281 0c-.069-23.01-.01-46.02-.03-69.029-.148-5.174 1.15-10.258 2.6-15.18a45.966 45.966 0 0 1 34.04-29.898 91 91 0 0 1 11.162-.987c5.114.015 10.2-.622 15.3-.928a130.592 130.592 0 0 0 13.833-.469c8.052-.9 16.168-.207 24.234-.785 11.153-.88 22.345-.621 33.521-.746Z'
          fill='#d1d1d1'
        />
        <path
          data-name='Path 758'
          d='M17.575 56.052c3.984-.227 7.973-.074 11.957-.109q141.435-.007 282.871 0c11.888.079 23.785-.158 35.672.114.242 6.452.03 12.909.1 19.362-.03 14.519.049 29.037-.044 43.556-23.587-.168-47.185-.025-70.782-.069H114.442c-32.3-.04-64.6.074-96.892-.059-.143-6.576-.03-13.156-.064-19.732.059-14.351-.128-28.712.089-43.063m4.073 3.584q-.037 27.628 0 55.261 161.414.037 322.828 0 .03-27.628 0-55.261-161.414-.037-322.828 0Z'
          fill='#9e9e9e'
        />
        <text transform='translate(157.468 148.452)' fill='#8c87a6' fontSize={13} fontWeight={500}>
          <tspan x={0} y={0}>
            Android
          </tspan>
        </text>
        <switch>
          <foreignObject x='0' y='51' width='100%' height='100px'>
            <Box
              xmlns='http://www.w3.org/1999/xhtml'
              dir='auto'
              sx={{
                textAlign: 'initial',
                fontSize: '12px',
                margin: 0,
                padding: '10px 30px',
                overflow: 'hidden',
                height: '63px',
                '&::after': {
                  content: '""',
                  width: 'calc(100% - 41px)',
                  height: '30px',
                  position: 'absolute',
                  bottom: '37px',
                  left: '21px',
                  zIndex: '1',
                  background: 'linear-gradient(0, #f9f9f9, transparent)',
                  pointerEvents: 'none',
                },
              }}
            >
              <h4 style={{ fontWeight: 900 }}>
                {isLoading ? (
                  <LinearProgress
                    color='secondary'
                    sx={{ width: '90px', height: '12px', borderRadius: '4px', opacity: 0.3, mt: '5px' }}
                  />
                ) : (
                  props.title
                )}
              </h4>
              <p style={{ lineHeight: '1.1' }}>
                {isLoading ? (
                  <LinearProgress
                    color='secondary'
                    sx={{ width: '200px', height: '12px', borderRadius: '4px', opacity: 0.3, mt: '5px' }}
                  />
                ) : (
                  props.content
                )}
              </p>
            </Box>
          </foreignObject>
        </switch>
      </g>
    </svg>
  );
}
