import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  storage: { upload: {}, delete: {} },
};

const storageSlice = createSlice({
  name: 'storage',
  initialState,
  reducers: {
    uploadFileReset: (state, { payload }) => {
      const { fieldName } = payload;

      state.storage.upload[fieldName] = {
        loaded: '000.0KB',
        total: '000.0KB',
        percentage: 0,
        isUploading: false,
        isUploadedSuccessfully: false,
        uploadedFileLink: '',
      };
    },
    uploadFileStart: (state, { payload }) => {
      const { fieldName } = payload;

      state.storage.upload[fieldName] = {
        loaded: '000.0KB',
        total: '000.0KB',
        percentage: 0,
        isUploading: true,
        isUploadedSuccessfully: false,
        uploadedFileLink: '',
      };
    },
    uploadFileProgress: (state, { payload }) => {
      const { fieldName, loaded, total, percentage, isUploading, isUploadedSuccessfully } = payload;

      state.storage.upload[fieldName] = {
        ...state.storage.upload[fieldName],
        loaded,
        total,
        percentage,
        isUploading,
        isUploadedSuccessfully,
      };
    },
    uploadFileSuccess: (state, { payload }) => {
      const { fieldName, uploadedFileLink } = payload;

      state.storage.upload[fieldName] = {
        ...state.storage.upload[fieldName],
        uploadedFileLink,
      };
    },
    uploadFileAudioDuration: (state, { payload }) => {
      const { fieldName, audioDuration } = payload;

      state.storage.upload[fieldName] = {
        ...state.storage.upload[fieldName],
        audioDuration,
      };
    },
    uploadFileAudioDurationReset: (state, { payload }) => {
      const { fieldName } = payload;

      state.storage.upload[fieldName] = {
        ...state.storage.upload[fieldName],
        audioDuration: 0,
      };
    },
    deleteFileLoading: (state, { payload }) => {
      const { fileLink, isDeletingFile = false } = payload;
      state.storage.delete[fileLink] = { ...state.storage.delete[fileLink], isDeletingFile: isDeletingFile };
    },
  },
});

export const storageActions = storageSlice.actions;
export const storageSelector = (state) => state.storage;
export default storageSlice.reducer;
