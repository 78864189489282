import { Box } from '@mui/material';
import { Section } from '../../../../components';
import NotificationAnalyticsItem from './NotificationAnalytics.item';
import CircleSingleUserIcon from '../../../../assets/images/icons/CircleSingleUser';
import CircleUserSeenIcon from '../../../../assets/images/icons/CircleUserSeen';
import CircleBookReaderIcon from '../../../../assets/images/icons/CircleBookReader';
import { styles } from './NotificationAnalytics.styles';

const NotificationAnalytics = ({ analytics, isLoading }) => {
  return (
    <Section title='Notification Analytics'>
      <Box sx={styles.itemsWrapper}>
        <NotificationAnalyticsItem
          title='Total Users Received Notification'
          count={analytics?.seen_count}
          icon={<CircleSingleUserIcon />}
          isLoading={isLoading}
        />
        <NotificationAnalyticsItem
          title='Total Users Seen The Notification'
          count={analytics?.read_count}
          icon={<CircleUserSeenIcon />}
          isLoading={isLoading}
        />
        <NotificationAnalyticsItem
          title='Total Users Read The Notification'
          count={analytics?.received_count}
          icon={<CircleBookReaderIcon />}
          isLoading={isLoading}
        />
      </Box>
    </Section>
  );
};

export default NotificationAnalytics;
