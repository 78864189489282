import { Section } from '../../components';
import Box from '@mui/material/Box';
import { styles } from './UnprocessableEntity.styles';

export const UnprocessableEntity = ({ message, linkComponent }) => {
  return (
    <Section sx={styles.unprocessableEntitySection}>
      <Box sx={styles.exclamationMark}>❗</Box>
      <Box component='h3'>{message}</Box>
      <br />
      {linkComponent ? linkComponent : null}
    </Section>
  );
};
