export default function ColorBrushIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='48px' viewBox='0 0 752 752' fill='currentColor' {...props}>
      <path
        d='m383.79 430.57-12.078 14.898-53.895-53.613 15.055-12.203zm18.746-23.117 164.07-202.34c.695-.86.563-3.422-.266-4.25l-3.73-3.73c-.8-.797-3.418-.938-4.25-.266l-202.37 164.05zm-10.762 60.266 197.82-243.97c10.242-12.629 9.223-32.273-2.324-43.816l-3.735-3.734c-11.469-11.47-31.164-12.586-43.816-2.325l-248.03 201.12c-50.285-2.53-111.37 41.727-109.3 117.47.383 13.938.235 19.281-.464 23.312-.106.598-.23 1.22-.383 1.918-.25 1.125-1.88 7.688-2.457 10.207-.489 2.122-1.992 5.11-4.371 8.493-3.246 4.629-7.465 9.156-11.402 12.766-.461.421-.813.734-.871.78-15.137 14.68-8.973 35.368 11.008 38.802l-2.579-.688c4.34 1.59 14.926 3.106 36.875 4.399 86.25 5.074 158.08-30.945 182.14-117.91zM309.157 541c22.539-14.516 39.77-36.359 49.734-66.238-10.977-10.98-70.125-70.102-70.125-70.102s-26.852 4.64-43.035 15.684c-22.148 15.109-34.652 38.266-33.746 71.309.035 1.344.066 2.621.094 3.84 15.129-4.418 36.785-11.422 36.785-11.422l6.004 31.719s30.234 2.887 49.672-11.035c12.977-9.297 18.98 13.266 4.617 36.246z'
        fillRule='evenodd'
      />
    </svg>
  );
}
