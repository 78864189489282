import { memo } from 'react';
import { Box } from '@mui/material';
import { componentPropsAreEqual } from '../../utils';
import { Calendar } from './FullCalenadr.calendar';
import { getStyles } from './FullCalendar.styles';

const DaysOfWeek = () => {
  const styles = getStyles();

  return (
    <Box sx={styles.daysOfWeek}>
      {Calendar.daysOfWeek.map((day) => (
        <Box sx={styles.daysOfWeekItem} key={day}>
          {day}
        </Box>
      ))}
    </Box>
  );
};

export default memo(DaysOfWeek, componentPropsAreEqual);
