import { useFormController } from '../hooks';
import { memo, useEffect } from 'react';
import { componentPropsAreEqual } from '../utils';

export const FormController = memo(({ form, name, setFormControllerValues }) => {
  const formControllerValues = useFormController(form, name);

  useEffect(() => {
    setFormControllerValues(formControllerValues);
  }, [formControllerValues, setFormControllerValues]);

  return null;
}, componentPropsAreEqual);

FormController.displayName = 'FormController';
