import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import AppBar from './components/AppBar/AppBar';
import Drawer from './components/Drawer/Drawer';
import PageContent from './components/PageContent/PageContent';
import useMediaQuery from '@mui/material/useMediaQuery';

const MainLayout = ({ label, children }) => {
  const [isDrawerOpened, setIsDrawerOpened] = useState(true);
  const isViewportWidthLessThan990px = useMediaQuery('(max-width:990px)');

  const toggleDrawer = () => setIsDrawerOpened((prevState) => !prevState);

  useEffect(() => {
    if (isViewportWidthLessThan990px) setIsDrawerOpened(false);
    else setIsDrawerOpened(true);
  }, [isViewportWidthLessThan990px]);

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh', overflowX: 'hidden' }}>
      <AppBar isDrawerOpened={isDrawerOpened} label={label} />
      <Drawer isDrawerOpened={isDrawerOpened} setIsDrawerOpened={setIsDrawerOpened} toggleDrawer={toggleDrawer} />
      <PageContent>{children}</PageContent>
    </Box>
  );
};

MainLayout.propTypes = {
  label: PropTypes.string,
  children: PropTypes.any,
};

export default MainLayout;
