import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { authPersistConfig } from './persistConfig';
import authReducer from './slices/auth';
import authorsReducer from './slices/authors';
import categoriesReducer from './slices/categories';
import booksReducer from './slices/books';
import storageReducer from './slices/storage';
import bookOfTheDayReducer from './slices/book-of-the-day';
import clientsReducer from './slices/clients';
import adminsReducer from './slices/admins';
import notificationsReducer from './slices/notifications';

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  authors: authorsReducer,
  categories: categoriesReducer,
  books: booksReducer,
  storage: storageReducer,
  bookOfTheDay: bookOfTheDayReducer,
  clients: clientsReducer,
  admins: adminsReducer,
  notifications: notificationsReducer,
});

export default rootReducer;
