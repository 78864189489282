import { useDispatch, useSelector } from 'react-redux';
import { notificationsActions, notificationsSelector } from '../../../redux/slices/notifications';
import { useEffect } from 'react';
import { UnprocessableEntity } from '../../../components';
import { Link, useParams } from 'react-router-dom';
import { fetchSingleNotification } from '../../../redux/slices/notifications/notifications.read';
import { Box, Stack } from '@mui/material';
import NotificationAnalytics from './NotificationAnalytics/NotificationAnalytics';
import NotificationDetails from './NotificationDetails/NotificationDetails';
import { styles } from './NotificationView.styles';

const NotificationView = () => {
  const { notification, notificationIdIsNotValid, isFetchingSingleNotification } = useSelector(notificationsSelector);
  const dispatch = useDispatch();
  const { id: notificationId } = useParams();

  useEffect(() => {
    dispatch(fetchSingleNotification(notificationId));

    return () => {
      dispatch(notificationsActions.resetSingleNotification());
      dispatch(notificationsActions.resetNotificationIdIsNotValidToBeFalse());
    };
  }, [dispatch, notificationId]);

  if (notificationIdIsNotValid) {
    return (
      <UnprocessableEntity
        message='Oops! The notification you’re looking for does not exist.'
        linkComponent={
          <Link to='/notifications'>
            <span>←</span> Back to Notifications
          </Link>
        }
      />
    );
  }

  return (
    <Box sx={styles.container}>
      <Stack spacing='30px'>
        <NotificationAnalytics analytics={notification?.analytics} isLoading={isFetchingSingleNotification} />
        <NotificationDetails />
      </Stack>
    </Box>
  );
};

export default NotificationView;
