import { axios } from '../../../services/axios';

import { notificationsActions } from '.';
import { Notification } from '../../../models';
import { snackbar } from '../../../utils';

const { createNotificationRequestLoading, createNotificationRequestSuccess } = notificationsActions;

export const createNotification = ({
  createNotificationRequestPayload,
  showSnackbar = false,
  updateNotificationList = false,
}) => {
  return (dispatch) => {
    dispatch(createNotificationRequestLoading(true));

    return axios
      .post(`/notifications`, createNotificationRequestPayload)
      .then((res) => {
        const notification = new Notification(res.data?.notification);

        dispatch(createNotificationRequestSuccess({ notification, updateNotificationList })); // Update notifications list after new notification has been created
        showSnackbar && snackbar.success('Notification has been created and saved successfully! 🎉');

        return res;
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(createNotificationRequestLoading(false)));
  };
};
