import { memo, useEffect } from 'react';
import { componentPropsAreEqual } from '../../../../utils';
import { ListCount, Section, Table } from '../../../../components';
import useCategoryBookListTable from './CategoryBookList.table';
import { booksActions } from '../../../../redux/slices/books';
import { useDispatch } from 'react-redux';

const CategoryBookList = () => {
  const { booksTableColumns, booksTableRows, isFetchingBooks } = useCategoryBookListTable();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(booksActions.resetBooks());
    };
  }, [dispatch]);

  return (
    <Section
      title='Category Books'
      sx={{ height: '100%' }}
      contentPadding={0}
      toolbar={<ListCount count={booksTableRows?.length} />}
    >
      <Table
        columns={booksTableColumns}
        rows={booksTableRows}
        isLoading={isFetchingBooks}
        containerSx={{
          maxHeight: {
            xs: '600px',
            lg: 'calc(100vh - 380.25px - 80px - 64px - 32px)',
            xl: 'calc(100vh - 275.13px - 80px - 64px - 32px)',
          },
        }}
      />
    </Section>
  );
};

export default memo(CategoryBookList, componentPropsAreEqual);
