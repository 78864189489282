import { styles } from './NoutFound.styles';
import NotFoundImage from '../../assets/images/404image.png';
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

const NotFound = () => {
  const history = useHistory();

  function backToDashboard() {
    history.push('/');
  }

  return (
    <Box sx={styles.notFoundGridWrapper}>
      <Box
        sx={styles.heroImage}
        width={550}
        height={351}
        component='img'
        src={NotFoundImage}
        alt='Snackbook not found page'
      />
      <Typography variant='h6' component='div' sx={styles.heroHeading}>
        404
      </Typography>
      <Typography paragraph sx={styles.notFoundTitle}>
        <strong>Sorry, Page Not Found</strong>
      </Typography>
      <Typography paragraph sx={styles.notFoundDescription}>
        Oopps!! The Page You Were Looking For Doesn’t Exist.
      </Typography>
      <Button variant='contained' color='primary' onClick={backToDashboard}>
        Back to dashboard
      </Button>
    </Box>
  );
};
export default NotFound;
