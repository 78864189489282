export default function FilterIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' data-name='Icons/Multimedia/sliders-v' width={18} height={18} {...props}>
      <path
        d='M4.5 3.6h-.9V.9a.9.9 0 0 0-1.8 0v2.7H.9a.9.9 0 0 0 0 1.8h3.6a.9.9 0 0 0 0-1.8ZM2.7 7.2a.9.9 0 0 0-.9.9v9a.9.9 0 1 0 1.8 0v-9a.9.9 0 0 0-.9-.9ZM9 14.4a.9.9 0 0 0-.9.9v1.8a.9.9 0 1 0 1.8 0v-1.8a.9.9 0 0 0-.9-.9Zm8.1-7.2h-.9V.9a.9.9 0 1 0-1.8 0v6.3h-.9a.9.9 0 0 0 0 1.8h3.6a.9.9 0 1 0 0-1.8Zm-1.8 3.6a.9.9 0 0 0-.9.9v5.4a.9.9 0 1 0 1.8 0v-5.4a.9.9 0 0 0-.9-.9Zm-4.5 0h-.9V.9a.9.9 0 1 0-1.8 0v9.9h-.9a.9.9 0 0 0 0 1.8h3.6a.9.9 0 0 0 0-1.8Z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </svg>
  );
}
