import dayjs from 'dayjs';
import { axios } from '../../../services/axios';
import { bookOfTheDayActions } from '.';
import { snackbar } from '../../../utils';
const { addBookOfTheDayRequestLoading } = bookOfTheDayActions;

export const replaceBookOfTheDay = ({ bookName, bookId, bookOfTheDayId, date }) => {
  return async (dispatch) => {
    dispatch(addBookOfTheDayRequestLoading({ bookId, date, isAddingBookOfTheDay: true }));

    const addBookOfTheDayPayload = { book_id: bookId };
    const formattedDate = dayjs(date).format('dddd, DD MMM YYYY');

    return axios
      .patch(`/book-of-the-day/${bookOfTheDayId}`, addBookOfTheDayPayload)
      .then(() => snackbar.success(`${bookName} has been added successfully to ${formattedDate}.`))
      .catch((error) => console.log(error))
      .finally(() => dispatch(addBookOfTheDayRequestLoading({ bookId, date, isAddingBookOfTheDay: false })));
  };
};
