import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { authorsSelector } from '../redux/slices/authors';

export default function useAddImmediateAuthor(form) {
  const { authors } = useSelector(authorsSelector);
  const [authorInitialInputValue, setAuthorInitialInputValue] = useState('');
  const author = form.watch('author');

  const addAuthorModalInitialValues = { name: authorInitialInputValue };

  const resetAuthorInitialInputValue = useCallback(() => setAuthorInitialInputValue(''), []);

  // Set author value with latest added author from add book modal
  useEffect(() => {
    if (authors && authorInitialInputValue) {
      const latestAddedAuthor = authors?.[0];

      if (latestAddedAuthor?.name === authorInitialInputValue) {
        form.setValue('author', { label: latestAddedAuthor.name, value: latestAddedAuthor.id });
        resetAuthorInitialInputValue();
      }
    }
  }, [authors, authorInitialInputValue, form, resetAuthorInitialInputValue]);

  useEffect(() => {
    if (author) setAuthorInitialInputValue(author.inputValue);
  }, [author]);

  return { authorInitialInputValue, addAuthorModalInitialValues, resetAuthorInitialInputValue };
}
