import { axios } from '../../../services/axios';
import { categoriesActions } from '.';
import { snackbar } from '../../../utils';
import { Category } from '../../../models';

const { createCategoryRequestLoading, createCategoryRequestSuccess } = categoriesActions;

export const createCategory = (createCategoryPayload) => {
  return async (dispatch) => {
    dispatch(createCategoryRequestLoading(true));

    return axios
      .post('/categories', createCategoryPayload)
      .then((res) => {
        const category = new Category(res.data?.category);
        if (category) {
          dispatch(createCategoryRequestSuccess({ category })); // Update categories list after new category has been created
          snackbar.success('Category has been added successfully! 🎉');
        }
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(createCategoryRequestLoading(false)));
  };
};
