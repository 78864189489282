export default function AppleIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={20} {...props}>
      <path
        data-name='\uF8FF Continue with Appl'
        d='M11.606 4.824c-1.5 0-2.694.921-3.474.921-.818 0-1.9-.87-3.2-.87-2.442 0-4.935 2.073-4.935 5.95a12.444 12.444 0 0 0 2.055 6.629C3.034 18.849 3.89 20 5.111 20s1.762-.832 3.273-.832 1.876.806 3.223.806c1.322 0 2.216-1.241 3.046-2.47A10.43 10.43 0 0 0 16 14.664a4.393 4.393 0 0 1-2.623-4.043 4.5 4.5 0 0 1 2.115-3.8 4.579 4.579 0 0 0-3.886-1.997Zm-.705-1.639a4.574 4.574 0 0 0 1.045-2.79 1.927 1.927 0 0 0-.038-.4 4.344 4.344 0 0 0-2.9 1.51 4.447 4.447 0 0 0-1.083 2.713 1.784 1.784 0 0 0 .038.358 1.424 1.424 0 0 0 .264.026 3.8 3.8 0 0 0 2.674-1.417Z'
        fill='#222b45'
      />
    </svg>
  );
}
