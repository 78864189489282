import { useEffect, useState } from 'react';
import AppNavItem from '../AppNavItem/AppNavItem';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { styles } from './NestedNav.styles';

const NestedNav = (props) => {
  const [isNestedNavOpened, setIsNestedNavOpened] = useState(false);

  const toggleNestedNav = () => {
    if (!props.isDrawerOpened) props.setIsDrawerOpened(true);
    setIsNestedNavOpened((prevState) => !prevState);
  };

  useEffect(() => {
    if (!props.isDrawerOpened) setIsNestedNavOpened(false);
  }, [props.isDrawerOpened]);

  return (
    <AppNavItem {...props} hasChildren isNestedNavOpened={isNestedNavOpened} onClick={toggleNestedNav}>
      <Collapse in={isNestedNavOpened} timeout='auto' unmountOnExit sx={styles.nestedNavWrapper}>
        <List disablePadding sx={styles.nestedNavList}>
          {props.children.map((navItem) => (
            <AppNavItem key={navItem.title} {...navItem} />
          ))}
        </List>
      </Collapse>
    </AppNavItem>
  );
};

export default NestedNav;
