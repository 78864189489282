export default function BookmarkIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16.797} height={24.001} {...props}>
      <path
        d='M13.2 0H3.6A3.6 3.6 0 000 3.6v19.2a1.2 1.2 0 001.8 1.04l6.6-3.815L15 23.84a1.2 1.2 0 001.8-1.04V3.6A3.6 3.6 0 0013.2 0zm1.2 20.721L9 17.6a1.2 1.2 0 00-1.2 0l-5.4 3.12V3.6a1.2 1.2 0 011.2-1.2h9.6a1.2 1.2 0 011.2 1.2z'
        fill='currentColor'
      />
    </svg>
  );
}
