const sectionHorizontalPadding = 25;

export const getStyles = ({ contentPadding, headerPadding, footerPadding, isOuterHeader } = {}) => ({
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    bgcolor: '#fff',
    border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    borderRadius: '8px',
    overflow: 'hidden',
  },
  sectionContent: {
    ...(contentPadding === 0 ? {} : { p: `20px ${sectionHorizontalPadding}px` }),
    flexGrow: 1,
    '& hr': {
      height: '1px',
      border: 'initial',
      bgcolor: 'secondary.200',
      ml: `-${sectionHorizontalPadding}px`,
      width: `calc(100% + ${sectionHorizontalPadding * 2}px)`,
      my: '28px',
    },
  },
  sectionHeader: {
    ...(!isOuterHeader && { borderBottom: (theme) => `1px solid ${theme.palette.secondary[200]}` }),
    ...(headerPadding === 0 || isOuterHeader ? {} : { p: '20px 25px' }),
    ...(isOuterHeader && { mb: '25px', h4: { fontSize: '18px' } }),
  },
  sectionHeaderWithTitleAndToolbar: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  sectionFooter: {
    display: 'flex',
    borderTop: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    ...(footerPadding === 0 ? {} : { p: '20px 25px' }),
  },
  sectionDataItem: {
    padding: '15px 0',
    display: 'flex',
    alignItems: 'center',
  },
  sectionDataItemTitle: {
    color: 'secondary.400',
    fontSize: '15px',
    width: '200px',
    maxWidth: '200px',
    pr: '30px',
  },
  sectionDataItemContent: {
    fontSize: '14px',
    '& a': {
      fontWeight: '500',
    },
  },
  sectionH4: { fontWeight: 700, mb: '15px', fontSize: '17px' },
});
