import { useState, useEffect } from 'react';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import PropTypes from 'prop-types';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichTextEditor.styles.css';
import { alpha, Box } from '@mui/system';
import { FormLabel } from '@mui/material';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import { FormController } from '..';

export const RichTextEditor = ({ form = {}, name, placeholder, label, id }) => {
  const [{ value = '', onChange }, setFormControllerValues] = useState({});

  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isFocused, setIsFocused] = useState(false);
  const [updated, setUpdated] = useState(false);

  // Set initial value
  useEffect(() => {
    if (!updated) {
      const rawData = markdownToDraft(value);
      const contentState = convertFromRaw(rawData);
      const initialEditorState = EditorState.createWithContent(contentState);
      setEditorState(initialEditorState);
    }
  }, [value, updated]);

  const handleEditorChange = (state) => {
    setUpdated(true);
    setEditorState(state);

    const content = editorState.getCurrentContent();
    const rawObject = convertToRaw(content);
    const markdownString = draftToMarkdown(rawObject);
    return onChange(markdownString);
  };

  return (
    <div>
      {form && name ? (
        <FormController form={form} name={name} setFormControllerValues={setFormControllerValues} />
      ) : null}
      {label ? <FormLabel {...(id ? { htmlFor: id } : {})}>{label}</FormLabel> : null}
      <Box
        sx={{
          transition: 'all 100ms ease',
          border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
          borderRadius: '8px',
          ...(isFocused && {
            borderColor: (theme) => theme.palette.primary.main,
            boxShadow: (theme) =>
              `0 0 0 1px ${theme.palette.primary.main}, 0 0 0 5px ${alpha(theme.palette.primary.main, 0.2)} `,
          }),
          ...(!isFocused && {
            '&:hover': {
              borderColor: (theme) => theme.palette.secondary[300],
            },
          }),
        }}
      >
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          wrapperClassName='wrapper'
          editorClassName='editor'
          toolbarClassName='toolbar'
          placeholder={placeholder}
          toolbar={{
            options: ['blockType', 'inline', 'textAlign', 'list', 'remove', 'emoji'],
            inline: { options: ['bold', 'italic', 'underline'] },
            blockType: { options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'Blockquote'] },
            list: { options: ['unordered', 'ordered'] },
            textAlign: { options: ['left', 'right'] },
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          spellCheck
        />
      </Box>
    </div>
  );
};

RichTextEditor.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  id: PropTypes.string.isRequired,
};
