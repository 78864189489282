import { capitalizeWords } from '../utils';

export class User {
  constructor(obj) {
    this.id = obj?.id || null;
    this.name = obj?.name ? capitalizeWords(obj?.name) : null;
    this.email = obj?.email || null;
    this.emailVerified = obj?.email_verified || null;
    this.role = obj?.role || null;
    this.lastLoginAt = obj?.last_login_at || null;
  }
}
