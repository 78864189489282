import moment from 'moment';
import GoogleIcon from '../assets/images/icons/Google';
import AppleIcon from '../assets/images/icons/Apple';

const paymentTypeMeta = {
  GOOGLE_IN_APP: {
    name: 'Google',
    icon: <GoogleIcon />,
  },
  APPLE_IN_APP: {
    name: 'Apple',
    icon: <AppleIcon />,
  },
};

export class Payment {
  constructor(data) {
    this.id = data.id || null;
    this.transactionId = data.transaction_id || null;
    this.transactionDate = data.transaction_date ? moment(data.transaction_date).format('DD MMM YYYY') : null;
    this._paymentType = data.payment_type || null;
    this.productName = data.product_name || null;
    this.productStoreId = data.product_store_id || null;
  }

  get paymentType() {
    return paymentTypeMeta[this._paymentType]?.name;
  }

  get paymentTypeIcon() {
    return paymentTypeMeta[this._paymentType]?.icon;
  }
}
