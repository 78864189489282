import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Stack } from '@mui/material';
import { componentPropsAreEqual } from '../../utils';
import { Autocomplete } from '..';
import { ChevronLeftRounded, ChevronRightRounded } from '@mui/icons-material';
import { getStyles } from './FullCalendar.styles';

const Navigation = ({ calendar = {}, onYearAndMonthChange }) => {
  const styles = getStyles();

  const handleMonthNavBackButtonClick = () => {
    onYearAndMonthChange(calendar.prevYearAndMonth);
  };

  const handleMonthNavForwardButtonClick = () => {
    onYearAndMonthChange(calendar.nextYearAndMonth);
  };

  const handleMonthSelect = useCallback(
    (event, value) => {
      let year = calendar.year;
      let month = parseInt(value.value, 10);

      onYearAndMonthChange([year, month]);
    },
    [calendar, onYearAndMonthChange],
  );

  const handleYearSelect = useCallback(
    (event, value) => {
      let month = calendar.month;
      let year = parseInt(value.value, 10);

      onYearAndMonthChange([year, month]);
    },
    [calendar, onYearAndMonthChange],
  );

  return (
    <Box sx={styles.navigation.wrapper}>
      <Box mr='20px'>
        <IconButton
          onClick={handleMonthNavBackButtonClick}
          sx={{ color: 'secondary.main' }}
          disableRipple={false}
          centerRipple
        >
          <ChevronLeftRounded />
        </IconButton>
        <IconButton
          onClick={handleMonthNavForwardButtonClick}
          sx={{ color: 'secondary.main' }}
          disableRipple={false}
          centerRipple
        >
          <ChevronRightRounded />
        </IconButton>
      </Box>
      <Box component='h2' sx={styles.navigation.yearMonthTitle}>
        {calendar.formattedMonth} {calendar.year}
      </Box>
      <Stack direction='row' spacing='12px' ml='auto'>
        <Autocomplete
          value={calendar.monthDropdownOptions.find((option) => option.value === String(calendar.month))}
          name='month'
          placeholder='Month'
          id='calendarNavigationMonthDropdownList'
          options={calendar.monthDropdownOptions}
          onChange={handleMonthSelect}
          sx={{ minWidth: '130px' }}
          disableClearable
        />
        <Autocomplete
          value={calendar.yearDropdownOptions.find((option) => option.value === String(calendar.year))}
          name='year'
          placeholder='Year'
          id='calendarNavigationYearDropdownList'
          options={calendar.yearDropdownOptions}
          onChange={handleYearSelect}
          sx={{ minWidth: '100px' }}
          disableClearable
        />
      </Stack>
    </Box>
  );
};

Navigation.propTypes = {
  calendar: PropTypes.object.isRequired,
  onYearAndMonthChange: PropTypes.func.isRequired,
};

export default memo(Navigation, componentPropsAreEqual);
