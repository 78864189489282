export default function DashboardIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path
        d='M16 8.8a1.2 1.2 0 00-1.2 1.2v12a1.2 1.2 0 102.4 0V10A1.2 1.2 0 0016 8.8zM10 16a1.2 1.2 0 00-1.2 1.2V22a1.2 1.2 0 102.4 0v-4.8A1.2 1.2 0 0010 16zm12-2.4a1.2 1.2 0 00-1.2 1.2V22a1.2 1.2 0 102.4 0v-7.2a1.2 1.2 0 00-1.2-1.2zM24.4 4H7.6A3.6 3.6 0 004 7.6v16.8A3.6 3.6 0 007.6 28h16.8a3.6 3.6 0 003.6-3.6V7.6A3.6 3.6 0 0024.4 4zm1.2 20.4a1.2 1.2 0 01-1.2 1.2H7.6a1.2 1.2 0 01-1.2-1.2V7.6a1.2 1.2 0 011.2-1.2h16.8a1.2 1.2 0 011.2 1.2z'
        fill='currentColor'
      />
    </svg>
  );
}
