import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { Box } from '@mui/material';
import { Section } from '../../../../components';
import MultimediaMusicIcon from '../../../../assets/images/icons/MultimediaMusic';
import BookmarkIcon from '../../../../assets/images/icons/Bookmark';
import BookAnalyticsItem from './BookAnalytics.item';
import { styles } from './BookAnalytics.styles';
import { getNoOfListening } from '../../../../redux/slices/books/books.no-of-listening';
import { getNoOfBookmarks } from '../../../../redux/slices/books/books.no-of-bookmarks';
import { booksActions, booksSelector } from '../../../../redux/slices/books';
import LinearProgress from '@mui/material/LinearProgress';

const BookAnalytics = () => {
  const {
    noOfListening = 0,
    isFetchingNoOfListening,
    noOfBookmarks = 0,
    isFetchingNoOfBookmarks,
  } = useSelector(booksSelector);
  const dispatch = useDispatch();
  const { id: bookId } = useParams();

  useEffect(() => {
    dispatch(getNoOfListening(bookId));
    dispatch(getNoOfBookmarks(bookId));

    return () => {
      dispatch(booksActions.getNoOfListeningReset());
      dispatch(booksActions.getNoOfBookmarksReset());
    };
  }, [dispatch, bookId]);

  return (
    <Section title='Book Analytics'>
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridGap: '25px' }}>
        <BookAnalyticsItem
          title='Number of Listening'
          data={
            <Box sx={styles.item.dataSkeletonWrapper}>
              {isFetchingNoOfListening ? (
                <>
                  <LinearProgress color='secondary' sx={styles.item.dataSkeleton} />
                  <Box sx={styles.item.dataSkeletonPlaceholder}>0</Box>
                </>
              ) : (
                noOfListening
              )}{' '}
              <span>/{noOfListening === 1 ? 'Client' : 'Clients'}</span>
            </Box>
          }
          icon={
            <Box sx={styles.numberOfListeningIcon}>
              <MultimediaMusicIcon />
            </Box>
          }
        />
        <BookAnalyticsItem
          title='Bookmarks'
          data={
            <Box sx={styles.item.dataSkeletonWrapper}>
              {isFetchingNoOfBookmarks ? (
                <>
                  <LinearProgress color='secondary' sx={styles.item.dataSkeleton} />
                  <Box sx={styles.item.dataSkeletonPlaceholder}>0</Box>
                </>
              ) : (
                noOfBookmarks
              )}
            </Box>
          }
          icon={
            <Box sx={styles.bookmarksIcon}>
              <BookmarkIcon />
            </Box>
          }
        />
      </Box>
    </Section>
  );
};

export default BookAnalytics;
