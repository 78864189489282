import { axios } from '../../../services/axios';
import { authorsActions } from '.';
import { snackbar } from '../../../utils';
import { Author } from '../../../models';

const { updateAuthorRequestLoading, updateAuthorRequestSuccess } = authorsActions;

export const updateAuthor = (authorId, updateAuthorPayload) => {
  return async (dispatch) => {
    dispatch(updateAuthorRequestLoading(true));

    return axios
      .patch(`/authors/${authorId}`, updateAuthorPayload)
      .then((res) => {
        const updatedAuthor = new Author(res.data?.author);

        dispatch(updateAuthorRequestSuccess({ authorId, updatedAuthor })); // Update authors list after new author has been updated
        snackbar.success('Author has been updated successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(updateAuthorRequestLoading(false)));
  };
};
