import { useCallback, useEffect, useState } from 'react';
import { FileUpload, FileUploadButton, LinearProgressWithLabel, ValidationMessage } from '../../../components';
import PaperClipIcon from '../../../assets/images/icons/PaperClip';
import { Box } from '@mui/material';
import Fade from '@mui/material/Fade';
import { getStyles } from './BookAudioUpload.styles';
import BookAudioPlayer from './BookAudioUpload.player';
import { useDispatch, useSelector } from 'react-redux';
import { storageActions, storageSelector } from '../../../redux/slices/storage';
import { deleteFile } from '../../../redux/slices/storage/storage.delete';
import * as React from 'react';

const BookAudioUpload = ({ form, initialBookRecordLink }) => {
  const fieldName = 'bookAudio';
  const { watch, formState } = form;
  const [showAudioPreview, setShowAudioPreview] = useState(false);
  const dispatch = useDispatch();
  const { storage } = useSelector(storageSelector);
  const {
    loaded,
    total,
    percentage,
    isUploadedSuccessfully,
    uploadedFileLink: bookAudioLink,
  } = storage.upload[fieldName] || {};
  const bookAudio = watch(fieldName);
  const bookAudioName = bookAudio?.[0]?.name || 'Book Audio File';
  const fieldError = formState.errors[fieldName];
  const fieldHasError = Boolean(fieldError);
  const fieldErrorMessage = fieldError?.message;

  const getBookAudioUrl = useCallback(() => {
    const bookAudioFile = bookAudio?.[0];
    if (bookAudioLink) return bookAudioLink;
    if (bookAudioFile && typeof bookAudioFile === 'object') return URL.createObjectURL(bookAudioFile);
  }, [bookAudio, bookAudioLink]);

  const showUploadProgressBox = getBookAudioUrl() && !showAudioPreview;
  const bookAudioUploadStyles = getStyles({ fieldHasError });

  useEffect(() => {
    if (isUploadedSuccessfully) {
      const timer = setTimeout(() => setShowAudioPreview(true), 1200);
      return () => clearTimeout(timer);
    }
  }, [isUploadedSuccessfully]);

  useEffect(() => {
    if (initialBookRecordLink) setShowAudioPreview(true);
  }, [initialBookRecordLink]);

  const resetBookAudioInput = useCallback(() => {
    form.setValue(fieldName, '');
    setShowAudioPreview(false);
    dispatch(storageActions.uploadFileReset({ fieldName }));
    dispatch(storageActions.uploadFileAudioDurationReset({ fieldName }));
  }, [dispatch, form]);

  const handleAudioDeletion = () => dispatch(deleteFile(initialBookRecordLink || getBookAudioUrl()));

  return (
    <Box sx={bookAudioUploadStyles.outerWrapper}>
      <FileUpload
        form={form}
        name={fieldName}
        id='bookAudio'
        accept='audio/*'
        {...(initialBookRecordLink && { handleFileDelete: handleAudioDeletion })}
      >
        <FileUploadButton sx={bookAudioUploadStyles.fileUploadButton} error={fieldHasError}>
          <PaperClipIcon />
          Select an audio book, or &nbsp;
          <span>Browse</span>
        </FileUploadButton>
      </FileUpload>
      <BookAudioPlayer
        form={form}
        resetAudioInput={resetBookAudioInput}
        audioSrc={getBookAudioUrl() || initialBookRecordLink}
        audioName={bookAudioName}
        audioFieldName={fieldName}
        showAudioPreview={showAudioPreview}
        fieldHasError={fieldHasError}
      />
      <Fade in={showUploadProgressBox} {...(showUploadProgressBox ? { timeout: 800 } : {})} unmountOnExit>
        <Box sx={bookAudioUploadStyles.uploadStatusOuterWrapper}>
          <Box sx={bookAudioUploadStyles.uploadStatusContentWrapper}>
            <Box sx={bookAudioUploadStyles.uploadStatusAudioName}>{bookAudioName}</Box>
            <Box component='span' sx={bookAudioUploadStyles.progressDetails}>
              {isUploadedSuccessfully ? '✓ Audio uploaded successfully! 🎉' : `${loaded} of ${total} Uploaded`}
            </Box>
          </Box>
          <LinearProgressWithLabel withStripe variant='determinate' value={percentage} />
        </Box>
      </Fade>
      <ValidationMessage message={fieldErrorMessage} />
    </Box>
  );
};

export default BookAudioUpload;
