import { ListItemText, ListItemButton, ListItemIcon } from '@mui/material';
import Box from '@mui/material/Box';
import ArrowSingleTop from '../../../../../assets/images/icons/ArrowSingleTop';
import { NavLink } from 'react-router-dom';
import { getStyles } from './AppNavItem.styles';

const AppNavItem = ({
  pathname,
  title,
  icon,
  hasChildren,
  isNestedNavOpened,
  children,
  isDrawerOpened,
  setIsDrawerOpened,
  ...props
}) => {
  const styles = getStyles(isNestedNavOpened);

  const handleNavItemOnClick = () => {
    props.onClick?.();
    if (!isDrawerOpened) setIsDrawerOpened?.(true);
  };

  const listItem = (
    <ListItemButton key={title} onClick={handleNavItemOnClick} {...props} sx={styles.listItemRoot}>
      {icon ? <ListItemIcon sx={styles.listItemIcon}>{icon}</ListItemIcon> : null}
      <ListItemText sx={styles.listItemText} primary={title} />
      {hasChildren ? (
        <Box sx={styles.expandIconWrapper}>
          <ArrowSingleTop />
        </Box>
      ) : null}
    </ListItemButton>
  );

  return (
    <>
      <Box sx={styles.listItemWrapper}>
        {hasChildren ? (
          listItem
        ) : (
          <NavLink exact to={pathname}>
            {listItem}
          </NavLink>
        )}
      </Box>
      {children}
    </>
  );
};

export default AppNavItem;
