import { useParams } from 'react-router-dom';
import { memo, useCallback, useEffect } from 'react';
import { Autocomplete, Section, TextField } from '../../../../components';
import { LoadingButton } from '@mui/lab';
import Box from '@mui/material/Box';
import { componentPropsAreEqual, generateOptionsListFromEnumObject } from '../../../../utils';
import { useForm } from 'react-hook-form';
import { formOptions } from './AuthorDetails.form';
import { AuthorStatus } from '../../../../enums';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleAuthor } from '../../../../redux/slices/authors/authors.read';
import { authorsActions, authorsSelector } from '../../../../redux/slices/authors';
import { updateAuthor } from '../../../../redux/slices/authors/authors.update';
import { Helmet } from 'react-helmet';

const authorStatusOptions = generateOptionsListFromEnumObject(AuthorStatus);

const AuthorDetails = () => {
  const form = useForm(formOptions);
  const { formState, reset, handleSubmit } = form;
  const dispatch = useDispatch();
  const { author, isFetchingSingleAuthor, isUpdatingAuthor } = useSelector(authorsSelector);
  const { id: authorId } = useParams();

  const setAuthorFormInitialValues = useCallback(
    ({ name, status }) => {
      const authorFormDefaultValues = {
        name: name,
        status: { value: status.toUpperCase(), label: status },
      };

      reset(authorFormDefaultValues);
    },
    [reset],
  );

  useEffect(() => {
    dispatch(fetchSingleAuthor(authorId));
  }, [authorId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(authorsActions.resetSingleAuthor());
      reset();
    };
  }, [dispatch, reset]);

  // Set form initial values
  useEffect(() => {
    if (author) {
      setAuthorFormInitialValues({ name: author.name, status: author.status });
    }
  }, [author, setAuthorFormInitialValues]);

  const submitAuthorDetailsForm = (data) => {
    const { name, status } = data;
    const requestPayload = { name, status: status.value.toUpperCase() };

    dispatch(updateAuthor(authorId, requestPayload)).then(() => {
      setAuthorFormInitialValues({ name, status: status.label });
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${author?.name || 'Author'} Profile | SnackBook`}</title>
      </Helmet>
      <Section
        component='form'
        title='Author Details'
        sectionFooter={
          <LoadingButton
            type='submit'
            variant='contained'
            loading={isUpdatingAuthor}
            disabled={!formState.isDirty}
            sx={{ ml: 'auto' }}
          >
            Save
          </LoadingButton>
        }
        onSubmit={handleSubmit(submitAuthorDetailsForm)}
      >
        <Box sx={{ display: 'grid', gridTemplateColumns: { xl: '1fr 1fr' }, gap: '26px' }}>
          <TextField
            form={form}
            name='name'
            id='authorName'
            label='Author Name'
            placeholder='Ex: Leo Tolstoy'
            loading={isFetchingSingleAuthor}
          />
          <Autocomplete
            form={form}
            name='status'
            id='authorStatus'
            label='Status'
            placeholder='Select Status'
            options={authorStatusOptions}
            loading={isFetchingSingleAuthor}
          />
        </Box>
      </Section>
    </>
  );
};

export default memo(AuthorDetails, componentPropsAreEqual);
