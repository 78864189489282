import { lighten } from '@mui/system';

export const styles = {
  container: { display: 'flex' },
  tabsWrapper: {
    borderRight: 1,
    borderColor: 'secondary.200',
    minWidth: 'fit-content',
    mr: '20px',
    '& .MuiTabs-indicator': {
      borderRadius: '10px',
      width: '3px',
    },
  },
  tab: {
    '&.MuiTab-root': {
      alignItems: 'flex-start',
      fontSize: 14,
      bgcolor: 'secondary.100',
      borderRadius: '6px',
      mb: '10px',
      mr: '20px',
      color: 'secondary.main',
      '&:hover': {
        bgcolor: (theme) => lighten(theme.palette.secondary[300], 0.8),
      },
      '&:active': {
        bgcolor: 'secondary.200',
      },
      '&.Mui-selected': {
        bgcolor: 'primary.main',
        color: '#fff',
      },
    },
  },
};
