import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import { getBrandingTheme } from '../ui/theme/brandingTheme';

export default function MUI({ children }) {
  return (
    <ThemeProvider theme={getBrandingTheme()}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
