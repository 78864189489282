import { alpha, lighten } from '@mui/system';

export const getStyles = (theme) => ({
  clientProfileGridContainer: {
    width: '100%',
    mx: 'auto',
    maxWidth: '1400px',
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', lg: '1fr 1.75fr' },
    gap: '30px',
    height: { lg: '100%' },
    alignItems: 'start',
  },
  clientProfileSidebarContentWrapper: { maxHeight: 'calc(100vh - 80px - 64px - 60px - 3px)', overflowX: 'hidden' },
  clientRecentlyListenedBooksWrapper: {
    [theme.breakpoints.up('lg')]: { gridRow: '1 / -1', height: '100%' },
  },
  resetPasswordButton: {
    height: '60px',
    borderRadius: 0,
    '&:active': { transform: 'scale(1) !important' },
    fontSize: '14px',
  },
  clientNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '15px',
    fontWeight: '700',
    p: '30px 20px',
  },
  bookIconWrapper: {
    width: 70,
    height: 70,
    color: 'primary.main',
    bgcolor: (theme) => alpha(theme.palette.primary.main, 0.1),
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
    '& svg': {
      transform: 'scale(.85)',
    },
    mr: '20px',
  },
  categoryBookListTableBookCoverImage: {
    width: 40,
    height: 60,
    borderRadius: '3px',
    border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    margin: '-5px 14px -5px 0',
    userSelect: 'none',
    position: 'relative',
    objectFit: 'cover',
    color: 'transparent',
    zIndex: 0,

    '&:before': {
      content: "'🚫'",
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      height: 'calc(100% + 2px)',
      width: 'calc(100% + 2px)',
      display: 'grid',
      placeItems: 'center',
      color: 'error.main',
      bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
      borderRadius: 'inherit',
      border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
      zIndex: 15,
      pointerEvents: 'none',
    },
  },
  accountTypeWrapper: { display: 'flex', alignItems: 'center', '& > svg:first-of-type': { mr: '8px' } },
  paymentTypeWrapper: { display: 'flex', alignItems: 'center', '& > svg:first-of-type': { mr: '8px' } },
  clientProfileSidebarNavButtonRoot: {
    '& > a': {
      all: 'unset',
      display: 'flex',
      alignItems: 'center',
      p: '19px 23px',
      cursor: 'pointer',
      borderTop: `1px solid ${theme.palette.secondary[200]}`,
      fontSize: '15px',
      fontWeight: 500,
      transition: 'background-color 120ms ease, color 120ms ease',

      '& > svg': {
        mr: '10px',
      },

      '&:hover, &[aria-current="page"]': {
        opacity: 1,
        color: 'primary.main',
        bgcolor: theme.palette.primary[100],
      },

      '&[aria-current="page"]': {
        bgcolor: theme.palette.primary[150],
        fontWeight: 600,
      },

      '&:active': { bgcolor: theme.palette.primary[200] },
    },
  },
});
