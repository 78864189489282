import { createTransform } from 'redux-persist';
import { deserialize, serialize } from 'class-transformer';
import { User } from '../models';

export const userTransformer = createTransform(
  (inboundState) => {
    return serialize(inboundState);
  },
  (outboundState) => {
    return deserialize(User, outboundState);
  },
  { whitelist: ['user'] },
);
