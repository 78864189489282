import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { formOptions } from './AddEditBookForm.form';
import { useDispatch, useSelector } from 'react-redux';
import { storageActions, storageSelector } from '../../../redux/slices/storage';
import { booksSelector } from '../../../redux/slices/books';
import { useCallback, useEffect } from 'react';
import { preventLeavePageOn } from '../../../utils';
import { createBook } from '../../../redux/slices/books/books.create';
import { updateBook } from '../../../redux/slices/books/books.update';
import { BookStatus } from '../../../enums';

const useAddEditBookForm = () => {
  const form = useForm(formOptions);
  const { formState, setError } = form;
  const dispatch = useDispatch();
  const { storage } = useSelector(storageSelector);
  const { isCreatingBook, isUpdatingBook } = useSelector(booksSelector);
  const { uploadedFileLink: bookCoverLink } = storage.upload['bookCover'] || {};
  const { uploadedFileLink: bookAudioLink, audioDuration: bookAudioDuration } = storage.upload['bookAudio'] || {};
  const { id: bookId } = useParams();

  const resetFormState = useCallback(() => {
    dispatch(storageActions.uploadFileReset({ fieldName: 'bookCover' }));
    dispatch(storageActions.uploadFileReset({ fieldName: 'bookAudio' }));
    dispatch(storageActions.uploadFileAudioDurationReset({ fieldName: 'bookAudio' }));
  }, [dispatch]);

  const setEditBookFormInitialValues = useCallback(
    ({ name, status, category, author, description, bookCover, bookAudio, releaseAt }) => {
      const editBookFormInitialValues = {
        name,
        status: { value: status.value || status.toUpperCase?.(), label: status.label || status },
        category: { value: category.id || category.value, label: category.label || category.name },
        author: { value: author.id || author.value, label: author.label || author.name },
        description,
        bookCover,
        bookAudio,
        releaseAt,
      };

      form.reset(editBookFormInitialValues);
    },
    [form],
  );

  const getRequestPayload = useCallback(
    (data) => {
      const { name, status, category, author, description, bookCover, bookAudio, releaseAt } = data;
      const bookStatusValue = status.value.toUpperCase();

      if (!bookCover || typeof bookCover !== 'string') {
        setError('bookCover', {
          type: 'bookCoverLinkMissed',
          message: 'Book cover link is required!',
        });
      }

      if (!bookAudioDuration || !bookAudio || typeof bookAudio !== 'string') {
        setError('bookAudio', {
          type: 'bookAudioLinkMissed',
          message: 'Please wait while the book is uploading or data is being ready.',
        });

        return;
      }

      const requestPayload = {
        name,
        status: status.value.toUpperCase(),
        category_id: category.value,
        author_id: author.value,
        description,
        cover: bookCover,
        record_link: bookAudio,
        duration: Math.floor(bookAudioDuration),
        ...(bookStatusValue === BookStatus.INACTIVE && releaseAt && { release_at: releaseAt }),
      };

      return requestPayload;
    },
    [bookAudioDuration, setError],
  );

  const submitAddBookForm = useCallback(
    (data, closeModal) => {
      const requestPayload = getRequestPayload(data);

      if (requestPayload) dispatch(createBook(requestPayload)).then(() => closeModal());
    },
    [dispatch, getRequestPayload],
  );

  const submitEditBookForm = useCallback(
    (data) => {
      const requestPayload = getRequestPayload(data);

      if (requestPayload) {
        const { name, status, category, author, description, bookCover, bookAudio, releaseAt } = data;

        dispatch(updateBook(bookId, requestPayload)).then(() => {
          setEditBookFormInitialValues({
            name,
            status,
            category,
            author,
            description,
            bookCover,
            bookAudio,
            releaseAt,
          });
        });
      }
    },
    [getRequestPayload, setEditBookFormInitialValues, bookId, dispatch],
  );

  // Prevent page reload if form data was changed
  useEffect(() => preventLeavePageOn(formState.isDirty), [formState.isDirty]);

  useEffect(() => {
    return () => resetFormState();
  }, [resetFormState]);

  return {
    form,
    isCreatingBook,
    isUpdatingBook,
    resetAddBookFormState: resetFormState,
    bookCoverLink,
    bookAudioLink,
    bookAudioDuration,
    submitAddBookForm,
    submitEditBookForm,
    setEditBookFormInitialValues,
  };
};

export default useAddEditBookForm;
