import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { setCheckboxesFormDefaultValues, setFiltersFormDefaultValues } from './FiltersPopup.helpers';
import {
  generateNewRelativePathWithSearchParams,
  parseSearchParams,
  extractParamValueFromFormField,
} from '../../utils';

export const useFilters = ({ filtersConfig, popupState, onSubmit, onClear }) => {
  const history = useHistory();
  const defaultValues = useMemo(
    () => filtersConfig.reduce((obj, { name, defaultValue }) => ({ ...obj, [name]: defaultValue }), {}),
    [filtersConfig],
  );
  const checkboxesDefaultValues = useMemo(
    () => filtersConfig.reduce((obj, { name }) => ({ ...obj, [name]: false }), {}),
    [filtersConfig],
  );
  const form = useForm({ defaultValues });
  const checkboxesForm = useForm({ defaultValues: checkboxesDefaultValues });
  const [activeFilters, setActiveFilters] = useState({});

  useEffect(() => {
    const currentActiveFilters = {};
    const searchParams = parseSearchParams();

    Object.entries(searchParams).forEach(([key, value]) => {
      const formFields = form.getValues();
      const isFilterExist = formFields[key] !== undefined;

      if (isFilterExist) currentActiveFilters[key] = value;
    });

    setActiveFilters(currentActiveFilters);

    const activeFiltersNotEmpty = !!Object.keys(currentActiveFilters).length;

    if (activeFiltersNotEmpty) {
      setCheckboxesFormDefaultValues(currentActiveFilters, checkboxesForm);
      setFiltersFormDefaultValues(filtersConfig, currentActiveFilters, form);
    }
  }, [popupState.isOpen, form, checkboxesForm, filtersConfig]);

  const submitFilters = (data) => {
    const doesFieldValueNotEmpty = (value) => value !== undefined && value !== null && value !== '';

    Object.entries(data).forEach(([key, value]) => {
      const fieldVariant = filtersConfig.find((f) => f.name === key)?.fieldVariant;
      const paramValue = extractParamValueFromFormField({
        fieldVariant,
        fieldName: key,
        fieldValue: value,
      });

      history.push(
        generateNewRelativePathWithSearchParams({
          ...parseSearchParams(),
          [key]: paramValue,
        }),
      );

      if (doesFieldValueNotEmpty(value)) {
        activeFilters[key] = paramValue;
      } else {
        delete activeFilters[key];
        form.reset({ ...data, [key]: defaultValues[key] });
        checkboxesForm.reset({ ...checkboxesForm.getValues(), [key]: false });
      }
    });

    setActiveFilters(activeFilters);
    onSubmit();
    popupState.close();
  };

  const clearFilters = () => {
    const activeFiltersCount = Object.keys(activeFilters).length;

    Object.keys(parseSearchParams()).forEach((key) => {
      const isSearchParamRelatedToTheFiltersForm = Boolean(form.getValues()[key]);
      if (isSearchParamRelatedToTheFiltersForm) {
        history.push(generateNewRelativePathWithSearchParams({ ...parseSearchParams(), [key]: null }));
      }
    });

    if (activeFiltersCount) onClear();
    form.reset(defaultValues);
    checkboxesForm.reset(checkboxesDefaultValues);
    setActiveFilters({});
    popupState.close();
  };

  return {
    form,
    checkboxesForm,
    clearFilters,
    submitFilters,
    defaultValues,
    activeFiltersCount: Object.keys(activeFilters).length,
  };
};
