export default function ImageUploadIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path
        d='M25.9 18.282a1.524 1.524 0 00-1.523 1.524v.579L22.12 18.13a4.249 4.249 0 00-5.987 0l-1.067 1.065-3.778-3.78a4.34 4.34 0 00-5.987 0l-2.254 2.258V9.137A1.524 1.524 0 014.57 7.613h10.664a1.524 1.524 0 000-3.048H4.57A4.571 4.571 0 000 9.137v18.29a4.571 4.571 0 004.57 4.568h18.281a4.571 4.571 0 004.57-4.573v-7.615a1.524 1.524 0 00-1.521-1.525zM4.57 28.952a1.524 1.524 0 01-1.523-1.524v-5.442l4.418-4.42a1.2 1.2 0 011.66 0l4.829 4.829 6.546 6.557zm19.8-1.524a1.357 1.357 0 01-.274.808l-6.87-6.9 1.074-1.072a1.173 1.173 0 011.676 0l4.4 4.435zm7.179-22.421L26.979.434a1.574 1.574 0 00-2.163 0l-4.57 4.573a1.53 1.53 0 102.163 2.164l1.965-1.981v8.52a1.524 1.524 0 103.047 0V5.19l1.965 1.981a1.53 1.53 0 102.163-2.164z'
        fill='currentColor'
      />
    </svg>
  );
}
