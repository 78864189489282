import { generateOptionsListFromEnumObject } from '../../../../utils';
import { CategoryStatus } from '../../../../enums';
import { TextField, Autocomplete } from '../../../../components';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/material';
import CategoryIconUpload from './CategoryIconUpload/CategoryIconUpload';
import CategoryCoverUpload from './CategoryCoverUpload/CategoryCoverUpload';
import ColorPicker from '../../../../components/ColorPicker/ColorPicker';

const categoryStatusOptions = generateOptionsListFromEnumObject(CategoryStatus);

const AddEditCategoryForm = ({ form }) => {
  return (
    <Stack spacing='26px'>
      <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr' }}>
        <TextField form={form} name='name' id='categoryName' label='Name' placeholder='Ex: Literature 📚' />
        <Autocomplete
          form={form}
          name='status'
          id='categoryStatus'
          label='Status'
          placeholder='Select Status'
          options={categoryStatusOptions}
        />
      </Box>
      <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr' }}>
        <TextField
          form={form}
          name='promotionText'
          id='promotionText'
          label='Promotion Text'
          placeholder='Enter a promotion text'
        />
        <ColorPicker label='Branding Color' form={form} name='brandingColor' />
      </Box>
      <Box sx={{ display: 'grid', gap: '20px', gridTemplateColumns: '1fr 1fr', maxWidth: 430 }}>
        <CategoryIconUpload form={form} text='Upload Icon' label='Icon' />
        <CategoryCoverUpload form={form} text='Upload Cover' label='Cover' />
      </Box>
    </Stack>
  );
};

export default AddEditCategoryForm;
