import { axios } from '../../../services/axios';

import { clientsActions } from '.';
import { Client, ListenedBook, Subscription } from '../../../models';
import { StatusCodes } from 'http-status-codes';
import humps from 'humps';

const {
  fetchClientsRequestLoading,
  fetchClientsRequestSuccess,
  fetchListenedBooksByClientIdRequestLoading,
  fetchListenedBooksByClientIdRequestSuccess,
  fetchSingleClientRequestLoading,
  fetchSingleClientRequestSuccess,
  fetchSingleClientRequestFailure,
  fetchTotalListeningsByClientIdRequestLoading,
  fetchSubscriptionRequestLoading,
  fetchSubscriptionRequestSuccess,
  fetchClientPreviousSubscriptionsRequestLoading,
  fetchClientPreviousSubscriptionsRequestSuccess,
} = clientsActions;

export const fetchClients = (params = {}) => {
  return async (dispatch) => {
    dispatch(fetchClientsRequestLoading(true));
    const { email_or_name, ...restOfParams } = params;

    return axios
      .get(`/clients`, {
        params: { ...restOfParams, ...(email_or_name && { email_or_name: email_or_name }) },
      })
      .then((res) => {
        const { data, ...paginatorInfo } = res.data;
        const clientsData = data?.map((client) => new Client(client));

        dispatch(fetchClientsRequestSuccess({ data: clientsData, paginatorInfo }));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(fetchClientsRequestLoading(false)));
  };
};

export const fetchSingleClient = (clientId) => {
  return async (dispatch) => {
    dispatch(fetchSingleClientRequestLoading(true));

    return axios
      .get(`/clients/${clientId}/profile`)
      .then(async (res) => {
        const client = new Client(res.data?.user);

        dispatch(fetchSingleClientRequestSuccess(client));
        dispatch(fetchTotalListeningsByClientId(res.data?.user));
      })
      .catch((error) => {
        const statusCode = error?.response?.status;

        if (statusCode === StatusCodes.BAD_REQUEST) {
          dispatch(fetchSingleClientRequestFailure(statusCode));
        }
      })
      .finally(() => dispatch(fetchSingleClientRequestLoading(false)));
  };
};

export const fetchListenedBooksByClientId = (clientId) => {
  return async (dispatch) => {
    dispatch(fetchListenedBooksByClientIdRequestLoading(true));

    return axios
      .get('/clients/listenings', { params: { client_id: clientId } })
      .then(async (res) => {
        const clientListenedBooks = res.data?.listening_history?.map((listenedBook) => new ListenedBook(listenedBook));

        dispatch(fetchListenedBooksByClientIdRequestSuccess(clientListenedBooks));
      })
      .finally(() => dispatch(fetchListenedBooksByClientIdRequestLoading(false)));
  };
};

export const fetchTotalListeningsByClientId = (client) => {
  return async (dispatch) => {
    dispatch(fetchTotalListeningsByClientIdRequestLoading(true));

    return axios
      .get(`/clients/${client.id}/totalListenings`)
      .then(async (res) => {
        const updatedClient = new Client({ ...client, total_listening_time: res.data?.total_listening_time });

        dispatch(fetchSingleClientRequestSuccess(updatedClient));
      })
      .finally(() => dispatch(fetchTotalListeningsByClientIdRequestLoading(false)));
  };
};

export const fetchClientActiveSubscription = (clientId) => {
  return async (dispatch) => {
    dispatch(fetchSubscriptionRequestLoading(true));

    return axios
      .get(`/clients/${clientId}/active-subscription`)
      .then(async (res) => {
        const activeSubscription = res.data?.active_subscription
          ? new Subscription(res.data?.active_subscription)
          : null;

        dispatch(fetchSubscriptionRequestSuccess(activeSubscription));
      })
      .finally(() => dispatch(fetchSubscriptionRequestLoading(false)));
  };
};

export const fetchClientPreviousSubscriptions = (clientId, params) => {
  return async (dispatch) => {
    dispatch(fetchClientPreviousSubscriptionsRequestLoading(true));

    return axios
      .get(`/clients/${clientId}/subscriptions`, { ...(params && { params: humps.decamelizeKeys(params) }) })
      .then(async (res) => {
        const { data, ...paginatorInfo } = res.data;
        const previousSubscriptions = data?.map((subscription) => new Subscription(subscription));

        dispatch(fetchClientPreviousSubscriptionsRequestSuccess({ data: previousSubscriptions, paginatorInfo }));
      })
      .finally(() => dispatch(fetchClientPreviousSubscriptionsRequestLoading(false)));
  };
};
