import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  password: Yup.string().min(8, 'Password should be at least 8 characters!'),
  confirmPassword: Yup.string()
    .min(8, 'Password should be at least 8 characters!')
    .oneOf([Yup.ref('password')], 'Passwords must match!'),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    password: '',
    confirmPassword: '',
  },
};
