import { Box } from '@mui/system';
import { Table, Section, TableSearchbar, ListCount } from '../../components';
import useAdminsTable from './Admins.table';
import AddAdminModal from './modals/AddAdminModal/AddAdminModal';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';
import { Stack } from '@mui/material';

const Admins = () => {
  const {
    adminsTableColumns,
    adminsTableRows,
    isFetchingAdmins,
    adminsTableRowsDraft,
    setAdminsTableRows,
    searchCompareFunction,
  } = useAdminsTable();

  return (
    <Section
      contentPadding={0}
      sectionHeader={
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <TableSearchbar
            rowsDraft={adminsTableRowsDraft}
            setRows={setAdminsTableRows}
            compareFunction={searchCompareFunction}
            placeholder='Search Admins..'
          />

          <Stack flexShrink={0} direction='row' spacing='20px' alignItems='center'>
            <ListCount count={adminsTableRows?.length} sx={{ height: '48px' }} />
            <AddAdminModal />
          </Stack>
        </Box>
      }
    >
      <Table columns={adminsTableColumns} rows={adminsTableRows} isLoading={isFetchingAdmins} />
    </Section>
  );
};

export default memo(Admins, componentPropsAreEqual);
