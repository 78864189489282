import { createTheme } from '@mui/material/styles';
import { getThemeBaseTokens } from './base';
import { getPaletteTokens } from './palette';
import { getTypographyTokens } from './typography';
import { getComponentsTokens } from './components/components';

// BASE
let brandingTheme = createTheme(getThemeBaseTokens());

// PALETTE
brandingTheme = createTheme(brandingTheme, getPaletteTokens(brandingTheme));

// TYPOGRAPHY
brandingTheme = createTheme(brandingTheme, getTypographyTokens(brandingTheme));

// COMPONENTS
brandingTheme = createTheme(brandingTheme, getComponentsTokens(brandingTheme));

function getBrandingTheme(direction = 'ltr') {
  brandingTheme = createTheme(brandingTheme, { direction });

  return brandingTheme;
}

export { getBrandingTheme };
