import { Box } from '@mui/system';
import { getStyles } from './Section.styles';

export const Section = ({
  sectionHeader,
  sectionFooter,
  title,
  toolbar,
  component = 'section',
  contentPadding,
  headerPadding,
  footerPadding,
  outerHeader,
  onSubmit,
  children,
  sx,
}) => {
  const styles = getStyles({ contentPadding, headerPadding, footerPadding });

  const HeaderWithTitleAndToolbar = ({ isOuterHeader }) => {
    const styles = getStyles({ contentPadding, headerPadding, footerPadding, isOuterHeader });

    return (
      <Box component='header' sx={{ ...styles.sectionHeader, ...styles.sectionHeaderWithTitleAndToolbar }}>
        <h4>{title}</h4>
        <Box>{toolbar}</Box>
      </Box>
    );
  };

  return (
    <div>
      {(title || toolbar) && outerHeader ? <HeaderWithTitleAndToolbar isOuterHeader /> : null}

      <Box
        component={component}
        sx={{ ...styles.sectionWrapper, ...sx }}
        {...(component === 'form' && onSubmit && { onSubmit })}
      >
        {sectionHeader ? (
          <Box component='header' sx={styles.sectionHeader}>
            {sectionHeader}
          </Box>
        ) : null}
        {(title || toolbar) && !outerHeader ? <HeaderWithTitleAndToolbar /> : null}
        <Box sx={styles.sectionContent}>{children}</Box>
        {sectionFooter ? (
          <Box component='footer' sx={styles.sectionFooter}>
            {sectionFooter}
          </Box>
        ) : null}
      </Box>
    </div>
  );
};
