import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../redux/slices/categories';

export default function useAddImmediateCategory(form) {
  const { categories } = useSelector(categoriesSelector);
  const [categoryInitialInputValue, setCategoryInitialInputValue] = useState('');
  const category = form.watch('category');

  const addCategoryModalInitialValues = { name: categoryInitialInputValue };

  const resetCategoryInitialInputValue = useCallback(() => setCategoryInitialInputValue(''), []);

  // Set category value with latest added category from add book modal
  useEffect(() => {
    if (categories && categoryInitialInputValue) {
      const latestAddedCategory = categories?.[0];

      if (latestAddedCategory?.name === categoryInitialInputValue) {
        form.setValue('category', { label: latestAddedCategory.name, value: latestAddedCategory.id });
        resetCategoryInitialInputValue();
      }
    }
  }, [categories, form, resetCategoryInitialInputValue, categoryInitialInputValue]);

  useEffect(() => {
    if (category) setCategoryInitialInputValue(category.inputValue);
  }, [category]);

  return { categoryInitialInputValue, addCategoryModalInitialValues, resetCategoryInitialInputValue };
}
