import { Box } from '@mui/system';
import { styles } from './TableSearchbar.styles';
import SearchIcon from '../../assets/images/icons/Search';
import { memo, useEffect, useState } from 'react';
import { componentPropsAreEqual } from '../../utils';

export const TableSearchbar = memo(
  ({ rowsDraft, setRows, compareFunction, placeholder, async, searchData, searchDefaultValue }) => {
    const [searchInputValue, setSearchInputValue] = useState(null);

    useEffect(() => {
      if (!async) {
        const isSearchInputValueEmpty = searchInputValue === '';

        const timer = setTimeout(() => {
          if (isSearchInputValueEmpty) {
            setRows(rowsDraft);
            return;
          }

          if (searchInputValue?.trim()) {
            const filteredRows = rowsDraft?.filter((row) => compareFunction(row, searchInputValue));

            setRows(filteredRows);
          }
        }, 700);

        return () => clearTimeout(timer);
      }
    }, [searchInputValue, rowsDraft, setRows, compareFunction, async]);

    useEffect(() => {
      if (searchInputValue !== null && async) {
        const timer = setTimeout(() => searchData(searchInputValue), 700);

        return () => clearTimeout(timer);
      }
    }, [searchInputValue, async, searchData]);

    return (
      <Box sx={styles.searchbarWrapper}>
        <SearchIcon />
        <input
          type='text'
          placeholder={placeholder || 'Temporary placeholder'}
          value={searchInputValue || searchDefaultValue || ''}
          onChange={(e) => setSearchInputValue(e.target.value)}
        />
      </Box>
    );
  },
  componentPropsAreEqual,
);

TableSearchbar.displayName = 'TableSearchbar';
