import Button from '@mui/material/Button';
import AddIcon from '../../../../assets/images/icons/Add';
import { useModal, useAddImmediateCategory, useAddImmediateAuthor } from '../../../../hooks';
import { Modal } from '../../../../components';
import { memo, useEffect } from 'react';
import { componentPropsAreEqual } from '../../../../utils';
import useAddEditBookForm from '../../AddEditBookForm/useAddEditBookForm.js';
import AddEditBookForm from '../../AddEditBookForm/AddEditBookForm';
import AddCategoryModal from '../../../../pages/Categories/modals/AddCategoryModal/AddCategoryModal';
import AddAuthorModal from '../../../../pages/Authors/modals/AddAuthorModal/AddAuthorModal';

const AddBookModal = () => {
  const { modalIsOpen, openModal, closeModal, closeModalWithConfirm } = useModal();
  const { form, isCreatingBook, resetAddBookFormState, submitAddBookForm } = useAddEditBookForm();
  const { categoryInitialInputValue, addCategoryModalInitialValues, resetCategoryInitialInputValue } =
    useAddImmediateCategory(form);
  const { addAuthorModalInitialValues, authorInitialInputValue, resetAuthorInitialInputValue } =
    useAddImmediateAuthor(form);

  // Reset upload state on modal close
  useEffect(() => {
    if (modalIsOpen) return () => resetAddBookFormState();
  }, [resetAddBookFormState, modalIsOpen]);

  return (
    <>
      <Button variant='contained' startIcon={<AddIcon />} onClick={openModal}>
        Add New Book
      </Button>
      <Modal
        title='Add New Book'
        open={modalIsOpen}
        onSubmit={form.handleSubmit((data) => submitAddBookForm(data, closeModal))}
        submitButtonText='Add book'
        submitButtonIsLoading={isCreatingBook}
        closeModal={form.formState.isDirty ? closeModalWithConfirm : closeModal}
        maxWidth={900}
      >
        <AddEditBookForm form={form} />
      </Modal>
      <AddCategoryModal
        initialValues={addCategoryModalInitialValues}
        categoryInitialInputValue={categoryInitialInputValue}
        resetCategoryInitialInputValue={resetCategoryInitialInputValue}
        hideTrigger
      />
      <AddAuthorModal
        initialValues={addAuthorModalInitialValues}
        authorInitialInputValue={authorInitialInputValue}
        resetAuthorInitialInputValue={resetAuthorInitialInputValue}
        hideTrigger
      />
    </>
  );
};

export default memo(AddBookModal, componentPropsAreEqual);
