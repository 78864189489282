import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { LoadingIndicator } from '../../components';
import { styles } from './Table.styles';
import Box from '@mui/material/Box';
import TableNoResultFound from '../../components/TableNoResultFound/TableNoResultFound';
import Paginator from './Table.paginator';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';

export const Table = memo(
  ({ columns = [], rows = null, isLoading, containerSx, fetchPageData, dataTotalCount, hasPaginator }) => {
    const tableColumns = columns.filter((column) => column.id !== 'id');

    return (
      <TableContainer sx={{ ...styles.tableContainer, ...containerSx }}>
        <MuiTable stickyHeader>
          <TableHead>
            <TableRow>
              {tableColumns.map((column) => (
                <TableCell key={column.id} align={column.align} sx={{ minWidth: column.minWidth }}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading || rows?.length === 0 ? null : (
            <TableBody>
              {rows?.map((row, i) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={i}>
                    {tableColumns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={`${column.id}_column${i}`} align={column.align}>
                          {column.format && typeof value === 'number' ? column.format(value) : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </MuiTable>
        {isLoading ? (
          <Box sx={{ display: 'flex', padding: '70px 0' }}>
            <LoadingIndicator />
          </Box>
        ) : null}
        {rows?.length === 0 && !isLoading ? <TableNoResultFound /> : null}
        {hasPaginator ? <Paginator fetchPageData={fetchPageData} dataTotalCount={dataTotalCount} /> : null}
      </TableContainer>
    );
  },
  componentPropsAreEqual,
);

Table.displayName = 'Table';
