import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { NotificationStatus } from '../../../enums';

export const initialState = {
  isFetchingNotifications: false,
  isFetchingSingleNotification: true,
  isCreatingNotification: false,
  isSendingNotification: false,
  isUpdatingNotification: false,
  notifications: null,
  notification: null,
  notificationIdIsNotValid: false,
};

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    fetchNotificationsRequestLoading: (state, { payload }) => {
      state.isFetchingNotifications = payload;
    },
    fetchNotificationsRequestSuccess: (state, { payload }) => {
      const { data, paginatorInfo } = payload;
      state.notifications = {
        data,
        paginatorInfo,
      };
    },
    fetchSingleNotificationRequestLoading: (state, { payload }) => {
      state.isFetchingSingleNotification = payload;
    },
    fetchSingleNotificationRequestSuccess: (state, { payload }) => {
      state.notification = payload;
    },
    fetchSingleNotificationRequestFailure: (state, { payload }) => {
      const statusCode = payload;

      if (statusCode === StatusCodes.BAD_REQUEST) state.notificationIdIsNotValid = true;
    },
    resetSingleNotification: (state) => {
      state.notification = null;
    },
    resetNotificationIdIsNotValidToBeFalse: (state) => {
      state.notificationIdIsNotValid = false;
    },
    createNotificationRequestLoading: (state, { payload }) => {
      state.isCreatingNotification = payload;
    },
    createNotificationRequestSuccess: (state, { payload }) => {
      const { notification, updateNotificationList } = payload;

      if (updateNotificationList) state.notifications?.data.unshift(notification);
    },
    sendNotificationRequestLoading: (state, { payload }) => {
      state.isSendingNotification = payload;
    },
    sendNotificationRequestSuccess: (state, { payload }) => {
      const { sentNotification } = payload;

      if (state.notification) state.notification.status = NotificationStatus.SENT;
      if (state.notifications?.data) state.notifications?.data.unshift(sentNotification);
    },
    updateNotificationRequestLoading: (state, { payload }) => {
      state.isUpdatingNotification = payload;
    },
    updateNotificationRequestSuccess: (state, { payload }) => {
      const { updatedNotification } = payload;
      state.notification = updatedNotification;
    },
  },
});

export const notificationsActions = notificationsSlice.actions;
export const notificationsSelector = (state) => state.notifications;
export default notificationsSlice.reducer;
