import { alpha, lighten } from '@mui/system';

export const getStyles = (theme) => ({
  categoryProfileGridContainer: {
    display: 'grid',
    gridTemplateColumns: { xs: '1fr', lg: '1fr 1fr' },
    gap: '30px',
    height: { lg: '100%' },
    alignItems: 'start',
    gridTemplateRows: 'min-content 1fr',
  },
  categoryTopBooksWrapper: {
    [theme.breakpoints.up('lg')]: { gridRow: '1 / -1', height: '100%', gridColumn: '2' },
  },
  categoryBookListTableBookCoverImage: {
    width: 40,
    height: 60,
    borderRadius: '3px',
    border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    margin: '-5px 14px -5px 0',
    userSelect: 'none',
    position: 'relative',
    objectFit: 'cover',
    color: 'transparent',
    zIndex: 0,

    '&:before': {
      content: "'🚫'",
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      height: 'calc(100% + 2px)',
      width: 'calc(100% + 2px)',
      display: 'grid',
      placeItems: 'center',
      color: 'error.main',
      bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
      borderRadius: 'inherit',
      border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
      zIndex: 15,
      pointerEvents: 'none',
    },
  },
});
