import { storageActions } from '.';
import { axios } from '../../../services/axios';

export const deleteFile = (fileLink) => {
  return async (dispatch) => {
    dispatch(storageActions.deleteFileLoading({ fileLink, isDeletingFile: true }));

    return axios.delete(`storage/object?object_link=${fileLink}`).then(() => {
      dispatch(storageActions.deleteFileLoading({ fileLink, isDeletingFile: false }));
    });
  };
};
