import { createSlice } from '@reduxjs/toolkit';
import { User } from '../../../models';

export const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
  isSendingLoginRequest: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequestLoading: (state, { payload }) => {
      state.isSendingLoginRequest = payload;
    },
    loginRequestSuccess: (state, { payload }) => {
      state.isLoggedIn = true;
      state.user = new User(payload.user);
      state.token = payload.token;
    },
    logoutRequestSuccess: (state) => {
      state.isLoggedIn = false;
      state.user = null;
      state.token = null;
    },
    refreshToken: (state, { payload }) => {
      state.token = payload;
    },
  },
});

export const authActions = authSlice.actions;
export const authSelector = (state) => state.auth;
export default authSlice.reducer;
