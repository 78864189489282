export default function ReloadIcon(props) {
  return (
    <svg width={18} height={20} fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='m8.033.549 3.244 3.204a.914.914 0 0 1 .282.665c0 .242-.1.484-.282.665L8.033 8.267a.907.907 0 0 1-1.31-.02.907.907 0 0 1 .02-1.31l1.814-1.794C5.19 5.365 2.53 8.126 2.53 11.492c0 3.526 2.902 6.368 6.47 6.368 3.566 0 6.468-2.862 6.468-6.368a6.16 6.16 0 0 0-1.128-3.588c-.303-.423-.182-.987.221-1.29.424-.302.988-.18 1.29.222a8.03 8.03 0 0 1 1.471 4.656c0 4.534-3.728 8.202-8.323 8.202-4.595 0-8.323-3.668-8.323-8.202 0-4.273 3.305-7.779 7.537-8.182l-1.47-1.45A.943.943 0 0 1 6.722.55c.362-.343.947-.364 1.31 0V.548Z'
        fill='currentColor'
      />
    </svg>
  );
}
