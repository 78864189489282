import { alpha } from '@mui/system';

export const getStyles = ({ theme }) => ({
  userSettingTab: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    padding: '7px 13px',
    borderRadius: '8px',
    cursor: 'pointer',
    userSelect: 'none',
    transition: 'background-color 120ms ease',
    '&:hover': { backgroundColor: 'secondary.100' },
    '&:active': { transform: 'scale(.96)', backgroundColor: alpha(theme.palette.secondary[200], 0.55) },
  },
  userSettingTabIcon: {
    width: '40px',
    height: '40px',
    backgroundColor: 'secondary.200',
    display: 'grid',
    placeItems: 'center',
    borderRadius: '50%',
    fontSize: '13px',
    fontWeight: '600',
    marginRight: '12px',
    flexShrink: 0,
  },
  userSettingTabName: {
    fontWeight: '700',
    marginBottom: '0',
    display: 'flex',
    alignItems: 'center',

    svg: {
      ml: '6px',
    },
  },
  userSettingTabDescription: {
    margin: '0',
    color: 'secondary.300',
    fontWeight: '700',
    fontSize: '13px',
  },
  userSettingTabMenuList: {
    minWidth: 230,
  },
});
