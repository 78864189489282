import { Box } from '@mui/material';
import { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { uploadFile } from '../../redux/slices/storage/storage.upload';
import { useDispatch, useSelector } from 'react-redux';
import { useController } from 'react-hook-form';
import { storageSelector } from '../../redux/slices/storage';
import { componentPropsAreEqual } from '../../utils';

export const FileUpload = memo(
  ({ id, form, name = '', accept = '', labelProps, inputProps, handleFileDelete, children }) => {
    const dispatch = useDispatch();
    const {
      field: { onChange },
    } = useController({ control: form.control, name });
    const { storage } = useSelector(storageSelector);
    const { isUploadedSuccessfully, uploadedFileLink } = storage.upload[name] || {};

    // Reset form on unmounting
    useEffect(() => () => form.reset(), [form]);

    useEffect(() => {
      if (isUploadedSuccessfully && uploadedFileLink) {
        form.setValue(name, uploadedFileLink);
        form.clearErrors(name);
      }
    }, [isUploadedSuccessfully, uploadedFileLink, form, name]);

    return (
      <Box component='label' htmlFor={id} {...labelProps}>
        <Box
          component='input'
          id={id}
          type='file'
          accept={accept}
          {...inputProps}
          onChange={(e) => {
            onChange(e.target.files);

            const file = e.target.files?.[0];

            if (file) {
              if (handleFileDelete) handleFileDelete().then(() => dispatch(uploadFile(file, name)));
              else dispatch(uploadFile(file, name));
            }
          }}
          sx={{ display: 'none' }}
        />
        {children}
      </Box>
    );
  },
  componentPropsAreEqual,
);

FileUpload.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  form: PropTypes.object.isRequired,
  accept: PropTypes.string.isRequired,
  onUploadProgress: PropTypes.func,
  children: PropTypes.node.isRequired,
};

FileUpload.displayName = 'FileUpload';
