import tinycolor from 'tinycolor2';
import { darken, lighten } from '@mui/system';

const primary = {
  100: '#F7FAFC',
  150: '#f0f5f9',
  200: '#e4eef6',
  250: '#d2e5f4',
  500: '#0090f0',
};
const secondary = {
  100: '#F8F7FC',
  200: '#E1DFEC',
  300: '#BCB8D0',
  400: '#8C87A6',
  500: '#5B5675',
};
const warning = {
  500: '#FFAB00',
  600: '#e69900',
};
const white = '#FFFFFF';
const success = '#15AB74';
const error = '#FE3E3E';

const lightenRate = 7.5;
const darkenRate = 15;

export function getPaletteTokens() {
  return {
    palette: {
      primary: {
        main: primary[500],
        light: lighten(primary[500], 1),
        dark: darken(primary[500], 0.12),
        ...primary,
      },
      secondary: {
        main: secondary[500],
        light: secondary[400],
        dark: darken(secondary[500], 0.12),
        ...secondary,
      },
      success: {
        main: success,
        light: tinycolor(success).lighten(lightenRate).toHexString(),
        dark: tinycolor(success).darken(darkenRate).toHexString(),
      },
      warning: {
        main: warning[500],
        ...warning,
      },
      error: {
        main: error,
        light: tinycolor(error).lighten(lightenRate).toHexString(),
        dark: tinycolor(error).darken(darkenRate).toHexString(),
      },
      white: {
        main: white,
      },
    },
  };
}
