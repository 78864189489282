import Box from '@mui/material/Box';
import MuiCircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

export const CircularProgress = ({ size, sx, ...props }) => {
  return (
    <Box sx={{ position: 'relative', width: size, height: size, m: 'auto', ...sx }}>
      <MuiCircularProgress
        variant='indeterminate'
        disableShrink
        sx={{
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: { strokeLinecap: 'round' },
        }}
        size={size}
        thickness={4}
        {...props}
      />
    </Box>
  );
};
