import { useParams } from 'react-router-dom';
import { memo, useCallback, useEffect } from 'react';
import { LoadingIndicator, Section, ShareLinkInput } from '../../../../components';
import { LoadingButton } from '@mui/lab';
import { componentPropsAreEqual } from '../../../../utils';
import { useForm } from 'react-hook-form';
import { formOptions } from '../../forms/AddEditCategoryForm/AddEditCategoryForm.form';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSingleCategory } from '../../../../redux/slices/categories/categories.read';
import { categoriesActions, categoriesSelector } from '../../../../redux/slices/categories';
import { updateCategory } from '../../../../redux/slices/categories/categories.update';
import { Helmet } from 'react-helmet';
import Stack from '@mui/material/Stack';
import AddEditCategoryForm from '../../forms/AddEditCategoryForm/AddEditCategoryForm';

const CategoryDetails = () => {
  const form = useForm(formOptions);
  const { formState, reset, handleSubmit } = form;
  const dispatch = useDispatch();
  const { category, isUpdatingCategory, isFetchingSingleCategory } = useSelector(categoriesSelector);
  const { id: categoryId } = useParams();

  const setCategoryFormInitialValues = useCallback(
    (data) => {
      const { status, name, brandingColor, cover, icon, promotionText } = data;
      const categoryFormDefaultValues = {
        status: { value: status.toUpperCase(), label: status },
        name,
        brandingColor: brandingColor || '',
        cover: cover || '',
        icon: icon || '',
        promotionText: promotionText === null ? '' : promotionText,
      };

      reset(categoryFormDefaultValues);
    },
    [reset],
  );

  useEffect(() => {
    dispatch(fetchSingleCategory(categoryId));
  }, [categoryId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(categoriesActions.resetSingleCategory());
      reset();
    };
  }, [dispatch, reset]);

  // Set form initial values
  useEffect(() => {
    if (category) setCategoryFormInitialValues(category);
  }, [category, setCategoryFormInitialValues]);

  const submitCategoryDetailsForm = (data) => {
    const { name, status, brandingColor, icon, cover, promotionText } = data;
    const requestPayload = {
      name,
      status: status.value.toUpperCase(),
      cover,
      ...(icon && { icon }),
      ...(brandingColor && { branding_color: brandingColor }),
      ...(promotionText !== undefined && { promotion_text: promotionText === '' ? null : promotionText }),
    };

    dispatch(updateCategory(categoryId, requestPayload)).then(() => {
      setCategoryFormInitialValues({ ...data, status: status.label });
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${category?.name || 'Category'} Profile | SnackBook`}</title>
      </Helmet>
      <Section
        component='form'
        title='Category Details'
        sectionFooter={
          <LoadingButton
            type='submit'
            variant='contained'
            loading={isUpdatingCategory}
            disabled={!formState.isDirty}
            sx={{ ml: 'auto' }}
          >
            Save
          </LoadingButton>
        }
        onSubmit={handleSubmit(submitCategoryDetailsForm)}
      >
        {isFetchingSingleCategory ? (
          <LoadingIndicator />
        ) : (
          <Stack spacing='26px'>
            <AddEditCategoryForm form={form} />
            <ShareLinkInput label='Share Link' linkText={category?.sharableLink} />
          </Stack>
        )}
      </Section>
    </>
  );
};

export default memo(CategoryDetails, componentPropsAreEqual);
