export const setCheckboxesFormDefaultValues = (activeFilters, checkboxesForm) => {
  const fieldsDefaultValues = Object.keys(activeFilters).reduce((obj, key) => ({ ...obj, [key]: true }), {});

  checkboxesForm.reset({ ...checkboxesForm.getValues(), ...fieldsDefaultValues }, { keepDefaultValues: true });
};

export const setFiltersFormDefaultValues = (filtersConfig, activeFilters, form) => {
  const fieldsDefaultValues = Object.entries(activeFilters).reduce((obj, [key, value]) => {
    const fieldValueExtractor = filtersConfig.find((filter) => filter.name === key)?.fieldValueExtractor;

    return {
      ...obj,
      [key]: fieldValueExtractor ? fieldValueExtractor(value) : value,
    };
  }, {});

  form.reset({ ...form.getValues(), ...fieldsDefaultValues });
};
