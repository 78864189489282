import { darken, lighten } from '@mui/system';

export const getStyles = ({ selectedColor: selectColorValue }) => {
  const selectedColor = selectColorValue || '';

  return {
    previewButton: {
      width: '48px',
      height: '48px',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'secondary.400',
      transition: 'background-color 180ms ease, box-shadow 180ms ease, color 180ms ease',
      cursor: 'pointer',
      boxShadow: selectedColor
        ? `0 0 0 3px ${lighten(selectedColor, 0.8)}, rgb(0 0 0 / 25%) 0px 0px 4px inset`
        : (theme) => `0 0 0 1px ${theme.palette.secondary[200]}`,
      bgcolor: selectedColor ? selectedColor : '#fff',

      '&:hover': {
        bgcolor: selectedColor ? darken(selectedColor, 0.175) : 'secondary.100',
        boxShadow: selectedColor
          ? `0 0 0 3px ${lighten(selectedColor, 0.8)}`
          : (theme) => `0 0 0 1px ${theme.palette.secondary[300]}`,
        color: 'secondary.main',
      },
      '&:active': {
        bgcolor: selectedColor ? darken(selectedColor, 0.35) : 'secondary.200',
        transform: 'scale(.96)',
      },
    },
    editIcon: {
      display: 'flex',
      padding: '3px',
      background: 'hsla(0, 0%, 0%, .15)',
      borderRadius: '6px',
      color: 'hsla(0, 0%, 100%, .9)',
      '& svg': {
        transform: 'scale(.85)',
      },
    },
  };
};
