import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { BookStatus } from '../../../enums';
import { capitalizeWords } from '../../../utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('*Required!'),
  category: Yup.mixed().required('*Required!'),
  author: Yup.mixed().required('*Required!'),
  status: Yup.mixed().required('*Required!'),
  description: Yup.string().required('*Required!'),
  bookAudio: Yup.string().required('*Required!'),
  bookCover: Yup.string().required('*Required!'),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    name: '',
    category: null,
    author: null,
    status: { value: BookStatus.ACTIVE.toUpperCase(), label: capitalizeWords(BookStatus.ACTIVE) },
    description: '',
    bookCover: '',
    bookAudio: '',
    releaseAt: null,
  },
};
