import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { clientsSelector } from '../../../../../redux/slices/clients';
import { getStyles } from '../../ClientProfile.styles';
import { useTheme } from '@mui/system';
import { fetchClientPreviousSubscriptions } from '../../../../../redux/slices/clients/clients.read';

export default function useClientPreviousSubscriptionsTable({ clientId }) {
  const dispatch = useDispatch();
  const { clientPreviousSubscriptions, isFetchingClientPreviousSubscriptions } = useSelector(clientsSelector);
  const [previousSubscriptionsTableRows, setPreviousSubscriptionsTableRows] = useState(null);
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const previousSubscriptionsTableColumns = [
    {
      id: 'paymentType',
      label: 'Payment Type',
    },
    {
      id: 'subscriptionType',
      label: 'Subscription Type',
    },
    {
      id: 'subscriptionDate',
      label: 'Subscription Date',
    },
  ];

  const createData = (paymentType, subscriptionType, subscriptionDate) => ({
    paymentType,
    subscriptionType,
    subscriptionDate,
  });

  useEffect(() => {
    if (clientPreviousSubscriptions?.data) {
      const rows = clientPreviousSubscriptions.data.map(({ payment: { paymentType, productName } = {}, startDate }) => {
        return createData(paymentType, productName, startDate);
      });

      setPreviousSubscriptionsTableRows(rows);
    }
  }, [clientPreviousSubscriptions, styles]);

  const fetchPageData = useCallback(
    (params) => dispatch(fetchClientPreviousSubscriptions(clientId, params)),
    [dispatch],
  );

  return {
    previousSubscriptionsTotalCount: clientPreviousSubscriptions?.paginatorInfo?.total,
    previousSubscriptionsTableColumns,
    previousSubscriptionsTableRows,
    isFetchingClientPreviousSubscriptions,
    fetchPageData,
  };
}
