import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export default function AppleNotificationView({ isLoading, ...props }) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={363.474} height={147.248} {...props}>
      <g data-name='Group 1178'>
        <text
          transform='translate(163.635 144.248)'
          fill='#8c87a6'
          fontSize={13}
          fontFamily='EudoxusSans-Medium, Eudoxus Sans'
          fontWeight={500}
        >
          <tspan x={0} y={0}>
            {'Apple'}
          </tspan>
        </text>
        <path
          data-name='Path 699'
          d='M159.211 18.262a5.916 5.916 0 0 1 2.276-.331c13.675.054 27.349.015 41.024.015a2.956 2.956 0 0 1 3.273 3.071 3.328 3.328 0 0 1-3.268 2.221c-13.507.044-27.023-.034-40.53.039-1.372.02-2.977-.089-3.786-1.377-1.103-1.151-.135-2.938 1.011-3.638Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 700'
          d='M224.853 18.192c1.412-.81 3.283.429 3.475 1.965a2.648 2.648 0 0 1-3.09 3.056 3.611 3.611 0 0 1-1.975-2.261c-.518-1.18.736-2.158 1.59-2.76Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 703'
          d='M40.987 70.259a3.543 3.543 0 0 1 1.8-1.264c.232.439.459.889.686 1.343-.832-.02-1.662-.04-2.486-.079Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 704'
          d='M52.064 69.02c1.812-.242 2.3 1.861 1.585 3.184a5.646 5.646 0 0 1-1.693.365 3.925 3.925 0 0 1 .108-3.549Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 705'
          d='M54.904 81.979a2.139 2.139 0 0 1 2.305-1.155c.069.365.2 1.091.262 1.456a23.5 23.5 0 0 1-2.567-.301Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 706'
          d='M60.413 83.672c-.434-1.056-.227-3.159 1.328-2.873 1.7.2.844 2.4.953 3.55-.797.123-2.143.522-2.281-.677Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 707'
          d='M77.143 80.868c.74.079 1.945-.326 2.143.721.133 1.1.336 2.9-1.19 3.051-1.728-.328-1.378-2.543-.953-3.772Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 708'
          d='M87.881 81.396c.434-.671 1.313-.538 1.989-.676.4 1.022.874 2.241.237 3.283a1.314 1.314 0 0 1-2.078.138 5.334 5.334 0 0 1-.148-2.745Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 710'
          d='M35.719 84.304c-.721-1.59 1.377-1.595 2.389-1.461-.187 1.175-1.164 2.184-2.389 1.461Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 711'
          d='M71.713 82.931c.7-.074 1.4-.143 2.1-.2-.044.4-.138 1.219-.188 1.629-.676.03-1.353.059-2.029.084.033-.382.092-1.137.117-1.513Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 712'
          d='M82.585 82.936a94.711 94.711 0 0 1 2.083-.212c-.044.41-.128 1.224-.173 1.634-.681.035-1.358.059-2.039.074.029-.375.093-1.125.129-1.496Z'
          fill='#f9f9f9'
        />
        <path
          data-name='Path 718'
          d='M26.438 7.958C33.769 3.327 42.329.928 50.894.03Q180-.029 309.111.01c1.481-.005 2.962-.044 4.443.084 8.106 1.076 16.212 3.288 23.173 7.711a53.542 53.542 0 0 1 17.94 17.081 55.942 55.942 0 0 1 8.787 26.322q.007 26.658 0 53.321c-.049 3.589.128 7.183-.1 10.767-.75-.02-1.5-.03-2.246-.03.059-17.9.01-35.791.025-53.686-.064-4.122.153-8.249-.148-12.361a45.782 45.782 0 0 0-3.925-14.326 53.13 53.13 0 0 0-25.365-27.009 68.514 68.514 0 0 0-21.583-5.248q-128.358.015-256.726 0A68.487 68.487 0 0 0 31.131 8.17 57.956 57.956 0 0 0 16.935 18.7C8.518 27.788 2.569 39.656 2.352 52.21c-.044 20.971.1 41.942-.069 62.913q-1.066.1-2.133.222c-.3-7.711-.044-15.437-.123-23.158q-.007-19 0-38.012c-.222-6.077 1.264-12.055 3.036-17.821a52.041 52.041 0 0 1 10.915-18.137c3.431-4.201 7.899-7.386 12.46-10.259Z'
          fill='#d1d1d1'
        />
        <path
          data-name='Path 719'
          d='M158.805 17.002a7.679 7.679 0 0 1 1.683-.183c14.331.054 28.662.015 42.993.02 2.824-.109 4.749 3.954 2.75 6.042-.953 1.353-2.769 1.284-4.236 1.353q-20.253-.022-40.5 0c-1.585-.039-3.554-.069-4.443-1.634-1.451-1.871-.183-4.645 1.753-5.6m.4 1.259c-1.145.7-2.118 2.488-1.012 3.638.81 1.288 2.414 1.4 3.786 1.377 13.507-.074 27.023 0 40.53-.039a3.328 3.328 0 0 0 3.268-2.221 2.955 2.955 0 0 0-3.273-3.071c-13.675 0-27.349.039-41.024-.015a5.915 5.915 0 0 0-2.27.333Z'
          fill='#d1d1d1'
        />
        <path
          data-name='Path 720'
          d='M224.91 16.918c2.167-.469 4.843 1.3 4.6 3.693.114 1.8-1.451 3.214-3.066 3.678-1.451.439-2.888-.533-3.732-1.639-1.081-2.108-.262-5.09 2.2-5.731m-.059 1.274c-.854.6-2.108 1.58-1.59 2.76a3.612 3.612 0 0 0 1.975 2.261 2.648 2.648 0 0 0 3.09-3.056c-.192-1.537-2.063-2.776-3.475-1.966Z'
          fill='#d1d1d1'
        />
        <path
          data-name='Path 767'
          d='M49.748 21.376c4.428-.864 8.95-.657 13.438-.691q11.618.015 23.232 0a4.589 4.589 0 0 1 4.127 1.669c1.521 1.876.829 4.428 1.2 6.63.839 5.243 3.194 10.54 7.593 13.734a17.892 17.892 0 0 0 10.752 3.367q70.836.007 141.668 0c2.533-.059 5.154.1 7.583-.76 6.749-2.809 11.991-9.39 12.391-16.795.044-2.389.138-5.021 1.738-6.951 1.165-1.14 2.967-.8 4.453-.908 10.2.1 20.413-.123 30.617.114 6.279.612 12.821 1.427 18.34 4.739 2.478 1.762 5.287 3.13 7.385 5.386a31.286 31.286 0 0 1 8.328 16.785c1.338 6.689.736 13.531 1.037 20.3.138 6.586-.242 13.161.03 19.747-.257 6.087.173 12.179-.123 18.266-.1 3.105.262 6.215-.01 9.32-18.409.217-36.823.049-55.231.1H55.287c-11.853-.04-23.716.089-35.569-.1-.163-18.74-.03-37.484-.064-56.224.015-9.286 2.073-18.907 7.706-26.47 5.45-6.714 13.956-10.273 22.388-11.266m-17.486 35a7.872 7.872 0 0 0-6.082 7.637q-.044 19.786 0 39.567a8.017 8.017 0 0 0 8.333 7.854q147.152.015 294.294 0a8.11 8.11 0 0 0 7.286-3.989 10.725 10.725 0 0 0 1.071-5.884c-.015-12.515.025-25.034-.02-37.553a7.928 7.928 0 0 0-5.7-7.538c-2-.509-4.092-.267-6.131-.321h-290.3a12.937 12.937 0 0 0-2.751.236Z'
          fill='#9e9e9e'
        />
        <g data-name='Group 1175'>
          <path
            data-name='Path 701'
            d='M32.345 56.377a12.941 12.941 0 0 1 2.765-.232q145.145.015 290.3 0c2.039.054 4.127-.188 6.131.321a7.928 7.928 0 0 1 5.7 7.538c.044 12.519 0 25.039.02 37.553a10.724 10.724 0 0 1-1.071 5.884 8.11 8.11 0 0 1-7.287 3.989q-147.145.015-294.294 0a8.017 8.017 0 0 1-8.333-7.854q-.059-19.786 0-39.567a7.872 7.872 0 0 1 6.082-7.637'
            fill='#f9f9f9'
          />
        </g>
        <switch>
          <foreignObject x='0' y='48' width='100%' height='100px'>
            <Box
              xmlns='http://www.w3.org/1999/xhtml'
              dir='auto'
              sx={{
                fontSize: '12px',
                margin: 0,
                padding: '10px 36px',
                overflow: 'hidden',
                height: '63px',
                textAlign: 'initial',
                '&::after': {
                  content: '""',
                  width: 'calc(100% - 52px)',
                  height: '30px',
                  position: 'absolute',
                  bottom: '37px',
                  left: '26px',
                  zIndex: '1',
                  background: 'linear-gradient(0, #f9f9f9, transparent)',
                  pointerEvents: 'none',
                  borderRadius: '0 0 10px 10px',
                },
              }}
            >
              <h4 style={{ fontWeight: 900 }}>
                {isLoading ? (
                  <LinearProgress
                    color='secondary'
                    sx={{ width: '90px', height: '12px', borderRadius: '4px', opacity: 0.3, mt: '5px' }}
                  />
                ) : (
                  props.title
                )}
              </h4>
              <p style={{ lineHeight: '1.1' }}>
                {isLoading ? (
                  <LinearProgress
                    color='secondary'
                    sx={{ width: '200px', height: '12px', borderRadius: '4px', opacity: 0.3, mt: '5px' }}
                  />
                ) : (
                  props.content
                )}
              </p>
            </Box>
          </foreignObject>
        </switch>
      </g>
    </svg>
  );
}
