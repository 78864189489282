export default function MultimediaMusicIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
      <path
        d='M23.58.289a1.2 1.2 0 00-.96-.276l-15.6 2.4A1.2 1.2 0 006 3.601V16.02a4.14 4.14 0 00-1.8-.42 4.2 4.2 0 104.2 4.2v-9.167l13.2-2.032v5.016a4.14 4.14 0 00-1.8-.42 4.2 4.2 0 104.2 4.204v-16.2a1.2 1.2 0 00-.42-.912zM4.2 21.601a1.8 1.8 0 111.8-1.8 1.8 1.8 0 01-1.8 1.8zm15.6-2.4a1.8 1.8 0 111.8-1.8 1.8 1.8 0 01-1.8 1.8zm1.8-13.032L8.4 8.201v-3.6l13.2-2z'
        fill='currentColor'
      />
    </svg>
  );
}
