import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

export const initialState = {
  isFetchingCategories: false,
  isFetchingSingleCategory: false,
  isCreatingCategory: false,
  isUpdatingCategory: false,
  categories: null,
  category: null,
  categoryTopBooks: { list: [], add: {}, delete: {} },
  isFetchingCategoryTopBooks: false,
  categoryIdIsNotValid: false,
};

const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    fetchCategoriesRequestLoading: (state, { payload }) => {
      state.isFetchingCategories = payload;
    },
    fetchSingleCategoryRequestLoading: (state, { payload }) => {
      state.isFetchingSingleCategory = payload;
    },
    createCategoryRequestLoading: (state, { payload }) => {
      state.isCreatingCategory = payload;
    },
    updateCategoryRequestLoading: (state, { payload }) => {
      state.isUpdatingCategory = payload;
    },
    fetchCategoryTopBooksRequestLoading: (state, { payload }) => {
      state.isFetchingCategoryTopBooks = payload;
    },
    addTopBookRequestLoading: (state, { payload }) => {
      const { bookId, bookIndex, isAddingTopBook = false } = payload;
      state.categoryTopBooks.add[`${bookId}_${bookIndex}`] = {
        ...state.categoryTopBooks.add[`${bookId}_${bookIndex}`],
        isAddingTopBook: isAddingTopBook,
      };
    },
    createCategoryRequestSuccess: (state, { payload }) => {
      const { category } = payload;

      state.categories.unshift(category);
    },
    addTopBookRequestSuccess: (state, { payload }) => {
      const { topBook } = payload;
      state.categoryTopBooks.list.push(topBook);
    },
    replaceTopBookRequestSuccess: (state, { payload }) => {
      const { bookIndex, topBook: topBookAfterReplace } = payload;
      const listItemIndex = bookIndex - 1;

      state.categoryTopBooks.list[listItemIndex] = topBookAfterReplace;
    },
    deleteTopBookRequestLoading: (state, { payload }) => {
      const { categoryTopBookId, bookIndex, isDeletingTopBook = false } = payload;
      state.categoryTopBooks.delete[`${categoryTopBookId}_${bookIndex}`] = {
        ...state.categoryTopBooks.delete[`${categoryTopBookId}_${bookIndex}`],
        isDeletingTopBook: isDeletingTopBook,
      };
    },
    deleteTopBookRequestSuccess: (state, { payload }) => {
      const { categoryTopBookId } = payload;
      const deletedCategoryTopBookId = categoryTopBookId;

      const filteredTopBooks = state.categoryTopBooks.list.filter((book) => {
        return book.id !== deletedCategoryTopBookId;
      });

      state.categoryTopBooks.list = filteredTopBooks;
    },
    fetchCategoriesRequestSuccess: (state, { payload }) => {
      state.categories = payload;
    },
    fetchSingleCategoryRequestSuccess: (state, { payload }) => {
      state.category = payload;
    },
    fetchSingleCategoryRequestFailure: (state, { payload }) => {
      const statusCode = payload;

      if (statusCode === StatusCodes.BAD_REQUEST) state.categoryIdIsNotValid = true;
    },
    resetCategoryIdIsNotValidToBeFalse: (state) => {
      state.categoryIdIsNotValid = false;
    },
    fetchCategoryTopBooksRequestSuccess: (state, { payload }) => {
      state.categoryTopBooks.list = payload;
    },
    resetSingleCategory: (state) => {
      state.category = null;
    },
    updateCategoryRequestSuccess: (state, { payload }) => {
      const { categoryId, updatedCategory } = payload;
      state.category = updatedCategory;
      state.categories = state.categories?.map((category) => {
        if (category.id === categoryId) return updatedCategory;
        else return category;
      });
    },
  },
});

export const categoriesActions = categoriesSlice.actions;
export const categoriesSelector = (state) => state.categories;
export default categoriesSlice.reducer;
