export const styles = {
  unprocessableEntitySection: {
    height: '100%',
    width: '100%',
    display: 'grid',
    placeItems: 'center',
    placeContent: 'center',
    textAlign: 'center',
  },
  exclamationMark: { fontSize: '60px', mb: '30px' },
};
