import range from 'lodash.range';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isToday from 'dayjs/plugin/isToday';

dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.extend(isToday);

export class Calendar {
  constructor(data) {
    this.monthData = data.monthData || null;
    this.year = data.year || null;
    this.month = data.month || null;
    this.formattedMonth = data.month
      ? dayjs()
          .month(data.month - 1)
          .format('MMMM')
      : null;
  }

  static get daysOfWeek() {
    return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((day) =>
      day.substring(0, 3),
    );
  }

  static get thisMonth() {
    return new Date().getMonth() + 1;
  }

  static get thisYear() {
    return new Date().getFullYear();
  }

  get yearDropdownOptions() {
    let minYear = this.year - 2;
    let maxYear = this.year + 5;

    return this.year && range(minYear, maxYear + 1).map((y) => ({ label: String(y), value: String(y) }));
  }

  get monthDropdownOptions() {
    return range(1, 13).map((m) => ({
      value: String(m),
      label: dayjs()
        .month(m - 1)
        .format('MMMM'),
    }));
  }

  createDaysForCurrentMonth() {
    return [...Array(this.getNumberOfDaysInMonth(this.year, this.month))].map((_, index) => {
      const dateIsoString = dayjs(`${this.year}-${this.month}-${index + 1}`).format('YYYY-MM-DD');
      const dayData = this.monthData?.find?.((item) => item.date === dateIsoString) || null;

      return {
        dateIsoString,
        dayOfMonth: index + 1,
        isCurrentMonth: true,
        dayData,
        isPastDay: this.isPastDay(dateIsoString),
      };
    });
  }

  createDaysForPreviousMonth(currentMonthDays) {
    const firstDayOfTheMonthWeekday = this.getWeekday(currentMonthDays[0].dateIsoString);
    const previousMonth = dayjs(`${this.year}-${this.month}-01`).subtract(1, 'month');

    const visibleNumberOfDaysFromPreviousMonth = firstDayOfTheMonthWeekday;

    const previousMonthLastMondayDayOfMonth = dayjs(currentMonthDays[0].dateIsoString)
      .subtract(visibleNumberOfDaysFromPreviousMonth, 'day')
      .date();

    return [...Array(visibleNumberOfDaysFromPreviousMonth)].map((_, index) => {
      const dateIsoString = dayjs(
        `${previousMonth.year()}-${previousMonth.month() + 1}-${previousMonthLastMondayDayOfMonth + index}`,
      ).format('YYYY-MM-DD');

      return {
        dateIsoString,
        dayOfMonth: previousMonthLastMondayDayOfMonth + index,
        isCurrentMonth: false,
        isPreviousMonth: true,
        isPastDay: this.isPastDay(dateIsoString),
      };
    });
  }

  createDaysForNextMonth(currentMonthDays) {
    const lastDayOfTheMonthWeekday = this.getWeekday(`${this.year}-${this.month}-${currentMonthDays.length}`);
    const nextMonth = dayjs(`${this.year}-${this.month}-01`).add(1, 'month');
    const visibleNumberOfDaysFromNextMonth = 6 - lastDayOfTheMonthWeekday;

    return [...Array(visibleNumberOfDaysFromNextMonth)].map((day, index) => {
      const dateIsoString = dayjs(`${nextMonth.year()}-${nextMonth.month() + 1}-${index + 1}`).format('YYYY-MM-DD');

      return {
        dateIsoString,
        dayOfMonth: index + 1,
        isCurrentMonth: false,
        isNextMonth: true,
        isPastDay: this.isPastDay(dateIsoString),
      };
    });
  }

  get gridDayObjects() {
    let currentMonthDays = this.createDaysForCurrentMonth();
    let previousMonthDays = this.createDaysForPreviousMonth(currentMonthDays);
    let nextMonthDays = this.createDaysForNextMonth(currentMonthDays);

    return [...previousMonthDays, ...currentMonthDays, ...nextMonthDays];
  }

  getNumberOfDaysInMonth() {
    return dayjs(`${this.year}-${this.month}-01`).daysInMonth();
  }

  getWeekday(dateIsoString) {
    // sunday === 0, saturday === 6
    return dayjs(dateIsoString).weekday();
  }

  isPastDay(dateIsoString) {
    return dayjs(dateIsoString).isBefore(new Date()) && !dayjs(dateIsoString).isToday();
  }

  get prevYearAndMonth() {
    let year = this.year;
    let prevMonth = this.month - 1;

    if (prevMonth === 0) {
      const prevYear = this.year - 1;

      prevMonth = 12;
      year = prevYear;
    }

    return [year, prevMonth];
  }

  get nextYearAndMonth() {
    let year = this.year;
    let nextMonth = this.month + 1;

    if (nextMonth === 13) {
      const nextYear = this.year + 1;

      nextMonth = 1;
      year = nextYear;
    }

    return [year, nextMonth];
  }
}
