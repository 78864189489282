import { activeNavItemStyles } from '../AppNavItem/AppNavItem.styles';

export const openedDrawerWidth = 250;
const closedDrawerWidth = 72;

export const getStyles = ({ theme, isDrawerOpened }) => ({
  appBarRoot: {
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${closedDrawerWidth}px)`,
    ...(isDrawerOpened && {
      marginLeft: openedDrawerWidth,
      width: `calc(100% - ${openedDrawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    position: 'fixed',
    boxShadow: 'initial',
    bgcolor: '#fff',
    borderBottom: (theme) => `1px solid ${theme.palette.secondary['200']}`,
    color: 'inherit',
  },
  toolbarRoot: {
    minHeight: { xs: '80px' },
    paddingLeft: { xs: '40px' },
    paddingRight: { xs: '40px' },
  },
  labelNavWrapper: { ml: '80px', display: 'flex', flexWrap: 'nowrap' },
  navItemWrapper: {
    display: 'inline-flex',
    '& + *': { ml: '12px' },
    '& a[aria-current="page"] .MuiButton-root': activeNavItemStyles,
  },
  navItemButtonRoot: {
    px: '20px',
    color: 'secondary.300',
    '&:hover': activeNavItemStyles,
    '& .MuiButton-startIcon': { transform: 'scale(.83)' },
  },
  labelHeading: { mt: '-2px', flexShrink: 0 },
});
