import PropTypes from 'prop-types';

const FullPageLayout = ({ children }) => {
  return (
    <div>
      <main>{children}</main>
    </div>
  );
};

FullPageLayout.displayName = 'FullPageLayout';

FullPageLayout.propTypes = {
  children: PropTypes.any,
};

FullPageLayout.defaultProps = {};

export default FullPageLayout;
