import { Suspense } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import RestrictedRoute from './RestrictedRoute';
import { LoadingIndicator } from '../components';
import AppRoute from './AppRoute';
import FullPageLayout from '../ui/layouts/FullPageLayout';
import { NAV_ITEM_TITLES, LABEL_TITLES } from '../nav-config';
import {
  Home,
  NotFound,
  Login,
  Authors,
  Categories,
  Books,
  CategoryProfile,
  BookProfile,
  AuthorProfile,
  BookOfTheDay,
  Clients,
  ClientProfile,
  Admins,
  Notifications,
  NotificationView,
  Reports,
} from '../pages';
import { pathnames } from './pathnames';

export default function Routes() {
  return (
    <Router>
      <Suspense fallback={<LoadingIndicator />}>
        <Switch>
          {/* Home */}
          <RestrictedRoute
            exact
            path={pathnames.home}
            component={Home}
            title='Home'
            label={NAV_ITEM_TITLES.DASHBOARD}
          />

          {/* Books page */}
          <RestrictedRoute exact path={pathnames.books} component={Books} title='Books' label={LABEL_TITLES.LIBRARY} />

          {/* Book profile page */}
          <RestrictedRoute
            exact
            path={pathnames.bookProfile}
            component={BookProfile}
            title='Book Profile'
            label={LABEL_TITLES.LIBRARY}
          />

          {/* Categories page */}
          <RestrictedRoute
            exact
            path={pathnames.categories}
            component={Categories}
            title='Categories'
            label={LABEL_TITLES.LIBRARY}
          />

          {/* Category profile page */}
          <RestrictedRoute
            exact
            path={pathnames.categoryProfile}
            component={CategoryProfile}
            title='Category Profile'
            label={LABEL_TITLES.LIBRARY}
          />

          {/* Authors page */}
          <RestrictedRoute
            exact
            path={pathnames.authors}
            component={Authors}
            title='Authors'
            label={NAV_ITEM_TITLES.AUTHORS}
          />

          {/* Author profile page */}
          <RestrictedRoute
            exact
            path={pathnames.authorProfile}
            component={AuthorProfile}
            title='Author Profile'
            label={LABEL_TITLES.LIBRARY}
          />

          {/* Book of the day page */}
          <RestrictedRoute
            exact
            path={pathnames.bookOfTheDay}
            component={BookOfTheDay}
            title='Book of The Day'
            label={NAV_ITEM_TITLES.BOOK_OF_THE_DAY}
          />

          {/* Clients page */}
          <RestrictedRoute
            exact
            path={pathnames.clients}
            component={Clients}
            title='Clients'
            label={NAV_ITEM_TITLES.USERS}
          />

          {/* Admins page */}
          <RestrictedRoute
            exact
            path={pathnames.admins}
            component={Admins}
            title='Admins'
            label={NAV_ITEM_TITLES.USERS}
          />

          {/* Client profile page */}
          <RestrictedRoute
            path={pathnames.clientProfile}
            component={ClientProfile}
            title='Client Profile'
            label={LABEL_TITLES.USERS}
          />

          {/* Notification List page */}
          <RestrictedRoute
            exact
            path={pathnames.notifications}
            component={Notifications}
            title='Notification'
            label={NAV_ITEM_TITLES.NOTIFICATIONS}
          />

          {/* Notification view page */}
          <RestrictedRoute
            exact
            path={pathnames.notificationView}
            component={NotificationView}
            title='Notification View'
            label={NAV_ITEM_TITLES.NOTIFICATIONS}
          />

          {/* Reports page */}
          <RestrictedRoute
            exact
            path={pathnames.reports}
            component={Reports}
            title='Reports'
            label={NAV_ITEM_TITLES.REPORTS}
          />

          {/* Login page */}
          <AppRoute
            path={pathnames.login}
            exact
            layout={FullPageLayout}
            component={Login}
            title='Login to SnackBook Admin Dashboard'
          />

          {/*Not Found page */}
          <AppRoute layout={FullPageLayout} component={NotFound} title='404 Not Found' />
        </Switch>
      </Suspense>
    </Router>
  );
}
