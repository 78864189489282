export default function AuthorsIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path
        d='M25.894 16.006a1.1 1.1 0 00-1.1 1.1v6.6a1.1 1.1 0 01-1.1 1.1H8.3a1.1 1.1 0 01-1.1-1.1v-15.4a1.1 1.1 0 011.1-1.1h6.6a1.1 1.1 0 000-2.2H8.3a3.3 3.3 0 00-3.3 3.3v15.4a3.3 3.3 0 003.3 3.3h15.4a3.3 3.3 0 003.3-3.3v-6.6a1.1 1.1 0 00-1.106-1.1zm-16.5.836v4.664a1.1 1.1 0 001.1 1.1h4.663a1.1 1.1 0 00.781-.319l7.61-7.621 3.127-3.06a1.1 1.1 0 000-1.562l-4.663-4.719a1.1 1.1 0 00-1.562 0l-3.1 3.112-7.632 7.621a1.1 1.1 0 00-.318.781zm11.837-9.185l3.112 3.112-1.561 1.561-3.112-3.112zM11.6 17.29l6.521-6.521 3.112 3.112-6.523 6.525H11.6z'
        fill='currentColor'
      />
    </svg>
  );
}
