import { alpha } from '@mui/system';

const chipHeight = '24px';

export function getChipDesignTokens(theme) {
  return {
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: chipHeight,
          fontSize: '13px',
          padding: '3px 9px',
          height: chipHeight,
          textTransform: 'capitalize',
          minWidth: '82px',
          color: theme.palette.secondary.main,
          backgroundColor: alpha(theme.palette.secondary[200], 0.75),
          '& .MuiChip-label': { padding: 0 },
          '&:before': {
            content: '""',
            width: '8px',
            height: '8px',
            borderRadius: '50%',
            background: 'currentColor',
            marginRight: '7px',
          },
        },
        colorError: {
          backgroundColor: alpha(theme.palette.error.main, 0.2),
          color: theme.palette.error.main,
        },
        colorSuccess: {
          backgroundColor: alpha(theme.palette.success.main, 0.2),
          color: theme.palette.success.main,
        },
      },
    },
  };
}
