import MuiPopper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { getStyles } from './Popper.styles';
import { useTheme } from '@mui/system';

export const Popper = ({ children, closePopper, ...props }) => {
  const theme = useTheme();
  const styles = getStyles(theme);

  return (
    <MuiPopper placement='bottom-end' {...props} transition style={{ zIndex: 1300 }}>
      {({ TransitionProps }) => (
        <ClickAwayListener onClickAway={closePopper}>
          <Grow {...TransitionProps}>
            <Paper sx={styles.popperPaper} elevation={0}>
              {children}
            </Paper>
          </Grow>
        </ClickAwayListener>
      )}
    </MuiPopper>
  );
};
