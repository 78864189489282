import { lighten } from '@mui/system';

export const styles = {
  nestedNavWrapper: {
    mt: '-8px',
    mb: '8px',
    '&:not(:last-of-type)': { '& .MuiList-root': { mb: '12px' } },
  },
  nestedNavList: {
    bgcolor: (theme) => lighten(theme.palette.primary.main, 0.985),
    border: (theme) => `1px solid ${lighten(theme.palette.secondary[200], 0.5)}`,
    padding: '7px 5px',
    borderRadius: '8px',
    '& > *': {
      mb: 0,
      '& + *': { mt: '8px' },
    },
  },
};
