export default function CircleBookReaderIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={56.037} height={56.037} {...props}>
      <g data-name='Group 1172'>
        <g data-name='Group 1171'>
          <circle data-name='Ellipse 15' cx={28.019} cy={28.019} r={28.019} fill='rgba(21,171,116,0.2)' />
        </g>
        <g data-name='Group 1168'>
          <path
            data-name='Icon awesome-book-reader'
            d='M26.757 26.579c-3.339-2.045-8.748-2.606-11.471-2.764a1.557 1.557 0 0 0-1.677 1.528v12.54a1.548 1.548 0 0 0 1.491 1.523c2.458.129 7.429.6 10.866 2.332a.784.784 0 0 0 1.153-.668V27.224a.749.749 0 0 0-.361-.645Zm13.994-2.764c-2.721.154-8.131.717-11.47 2.761a.763.763 0 0 0-.361.655v13.837a.786.786 0 0 0 1.156.67c3.436-1.729 8.4-2.2 10.862-2.33a1.549 1.549 0 0 0 1.491-1.523V25.343a1.558 1.558 0 0 0-1.678-1.528Z'
            fill='#15ab74'
          />
          <path
            data-name='Path 672'
            d='M32.821 19.009a4.8 4.8 0 1 1-4.8-4.8 4.8 4.8 0 0 1 4.8 4.8Z'
            fill='none'
            stroke='#15ab74'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth={3}
          />
        </g>
      </g>
    </svg>
  );
}
