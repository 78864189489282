import { axios } from '../../../services/axios';
import { authorsActions } from '.';
import { snackbar } from '../../../utils';
import { Author } from '../../../models';

const { createAuthorRequestLoading, createAuthorRequestSuccess } = authorsActions;

export const createAuthor = (createAuthorPayload) => {
  return async (dispatch) => {
    dispatch(createAuthorRequestLoading(true));

    return axios
      .post('/authors', createAuthorPayload)
      .then((res) => {
        const author = new Author(res.data?.author);

        dispatch(createAuthorRequestSuccess({ author })); // Update authors list after new author has been created
        snackbar.success('Author has been added successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(createAuthorRequestLoading(false)));
  };
};
