export default function LinkAltIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={19.866} height={19.866} {...props}>
      <path
        d='M9.981 13.385l-3.88 3.88a2.52 2.52 0 01-3.5 0 2.47 2.47 0 010-3.5l3.88-3.88a1.004 1.004 0 00-1.42-1.42l-3.88 3.89a4.48 4.48 0 006.33 6.33l3.89-3.88a1.004 1.004 0 00-1.42-1.42zm8.58-12.08a4.49 4.49 0 00-6.33 0l-3.89 3.88a1.004 1.004 0 101.42 1.42l3.88-3.88a2.52 2.52 0 013.5 0 2.47 2.47 0 010 3.5l-3.88 3.88a1.004 1.004 0 101.42 1.42l3.88-3.89a4.49 4.49 0 000-6.33zm-11.86 11.86a1.014 1.014 0 001.42 0l4.92-4.92a1.004 1.004 0 10-1.42-1.42l-4.92 4.92a1 1 0 000 1.42z'
        fill='currentColor'
      />
    </svg>
  );
}
