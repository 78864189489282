import { axios } from '../../../services/axios';

import { booksActions } from '.';
import { Book } from '../../../models';
import { StatusCodes } from 'http-status-codes';

export const fetchBooks = () => {
  return async (dispatch) => {
    dispatch(booksActions.fetchBooksRequestLoading(true));

    return axios
      .get('/books')
      .then((res) => {
        const books = res.data?.books?.map((book) => new Book(book));
        if (books) dispatch(booksActions.fetchBooksRequestSuccess(books));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(booksActions.fetchBooksRequestLoading(false)));
  };
};
export const fetchSingleBook = (bookId) => {
  return async (dispatch) => {
    dispatch(booksActions.fetchSingleBookRequestLoading(true));

    return axios
      .get(`/books/${bookId}`)
      .then(async (res) => {
        const book = new Book(res.data?.book);

        if (book) dispatch(booksActions.fetchSingleBookRequestSuccess(book));
      })
      .catch((error) => {
        const statusCode = error?.response?.status;

        if (statusCode === StatusCodes.BAD_REQUEST) {
          dispatch(booksActions.fetchSingleBookRequestFailure(statusCode));
        }
      })
      .finally(() => dispatch(booksActions.fetchSingleBookRequestLoading(false)));
  };
};

export const fetchBooksByCategoryId = (categoryId) => {
  return async (dispatch) => {
    dispatch(booksActions.fetchBooksRequestLoading(true));

    return axios
      .get(`/books?category_id=${categoryId}`)
      .then((res) => {
        const books = res.data?.books?.map((book) => new Book(book));
        if (books) dispatch(booksActions.fetchBooksRequestSuccess(books));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(booksActions.fetchBooksRequestLoading(false)));
  };
};

export const fetchBooksByAuthorId = (authorId) => {
  return async (dispatch) => {
    dispatch(booksActions.fetchBooksRequestLoading(true));

    return axios
      .get(`/books?author_id=${authorId}`)
      .then((res) => {
        const books = res.data?.books?.map((book) => new Book(book));
        if (books) dispatch(booksActions.fetchBooksRequestSuccess(books));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(booksActions.fetchBooksRequestLoading(false)));
  };
};
