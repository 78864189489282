import { styled } from '@mui/material/styles';

const checkboxSize = 22;

export const Icon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: checkboxSize,
  height: checkboxSize,
  boxShadow: `inset 0 0 0 2px ${theme.palette.secondary[300]}`,
  backgroundColor: '#fff',
  backgroundImage: 'red',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.secondary[100],
  },
  'input:disabled ~ &': {
    pointerEvents: 'none',
  },
}));

export const CheckedIcon = styled(Icon)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow: `inset 0 0 0 2px ${theme.palette.primary.main}`,
  '&:before': {
    display: 'block',
    width: checkboxSize,
    height: checkboxSize,
    backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='h-5 w-5' viewBox='0 0 20 20' fill='currentColor'%3E%3Cpath fill-rule='evenodd' fill='%23fff' d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z' clip-rule='evenodd' /%3E%3C/svg%3E")`,
    content: '""',
    backgroundPosition: 'center',
    backgroundSize: '88%',
    backgroundRepeat: 'no-repeat',
  },
  'input:hover ~ &': { backgroundColor: theme.palette.primary.main },
}));
