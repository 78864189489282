import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isFetchingAdmins: false,
  isResettingAdminPassword: false,
  isCreatingAdmin: false,
  admins: null,
};

const adminsSlice = createSlice({
  name: 'admins',
  initialState,
  reducers: {
    fetchAdminsRequestLoading: (state, { payload }) => {
      state.isFetchingAdmins = payload;
    },
    createAdminRequestLoading: (state, { payload }) => {
      state.isCreatingAdmin = payload;
    },
    fetchAdminsRequestSuccess: (state, { payload }) => {
      state.admins = payload;
    },
    createAdminRequestSuccess: (state, { payload }) => {
      const { admin } = payload;

      state.admins.unshift(admin);
    },
    resetAdminPasswordRequestLoading: (state, { payload }) => {
      state.isResettingAdminPassword = payload;
    },
  },
});

export const adminsActions = adminsSlice.actions;
export const adminsSelector = (state) => state.admins;
export default adminsSlice.reducer;
