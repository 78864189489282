import { alpha, lighten } from '@mui/system';

export const getStyles = ({ isUploadedSuccessfully, percentage }) => ({
  contentWrapper: {
    width: '100%',
    height: '205px',
  },
  uploadStatusWrapper: {
    display: 'grid',
    height: '100%',
    position: 'absolute',
    width: '100%',
    borderRadius: 'inherit',
  },
  previewImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'transform 300ms ease',
    border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    borderRadius: 'inherit',
    bgcolor: 'primary.100',

    '&:before': {
      content: "'Image Not Found!'",
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      height: 'calc(100% + 2px)',
      width: 'calc(100% + 2px)',
      display: 'grid',
      placeItems: 'center',
      color: 'error.main',
      bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
      borderRadius: 'inherit',
      border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
      zIndex: 15,
      pointerEvents: 'none',
    },
  },
  progressBar: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    transform: `translateX(${Number(isUploadedSuccessfully ? 0 : percentage)}%)`,
    transition: 'transform 200ms ease',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.6),
    opacity: isUploadedSuccessfully ? 0 : 1,
  },
  progressDetails: {
    backgroundColor: 'hsl(0, 0%, 100%, .6)',
    backdropFilter: 'blur(8px)',
    color: 'secondary.main',
    position: 'absolute',
    bottom: '7px',
    left: '7px',
    width: 'calc(100% - 14px)',
    height: '40px',
    fontWeight: '600',
    display: 'grid',
    placeItems: 'center',
    fontSize: '10px',
    paddingTop: '8px',
    borderRadius: '6px',
    zIndex: 1,
  },
  progressPercentage: {
    color: '#fff',
    backgroundColor: (theme) => theme.palette.primary.main,
    position: 'absolute',
    top: '0',
    transform: 'translateY(-50%)',
    borderRadius: '22px',
    display: 'grid',
    placeItems: 'center',
    fontSize: '10px',
    padding: '4px 10px',
    boxShadow: '0 3px 5px rgba(0, 0, 0, .2)',
  },
  fileUploadButton: {
    flexDirection: 'column',
  },
  deleteFileButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 11,
  },
  replaceButton: {
    position: 'absolute',
    left: 10,
    top: 10,
    pointerEvents: 'none',
  },
});
