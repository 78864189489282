export default function PaperClipIcon(props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      data-name='Icons/User-Interface/paperclip'
      width={21}
      height={24}
      {...props}
    >
      <path
        d='M18.668 12.702l-7.555 7.562a5.194 5.194 0 01-7.335-7.33l9.779-9.773a3.143 3.143 0 014.327 0 3.077 3.077 0 010 4.325l-8.433 8.417a.934.934 0 11-1.369-1.27l6.271-6.255a1.227 1.227 0 00-1.736-1.735l-6.271 6.279a3.346 3.346 0 000 4.752 3.449 3.449 0 004.755 0l8.423-8.429a5.5 5.5 0 00-7.775-7.767l-9.78 9.771a7.638 7.638 0 0010.868 10.713l7.567-7.55a1.227 1.227 0 00-1.736-1.734z'
        fill='currentColor'
      />
    </svg>
  );
}
