export const styles = {
  tableNoResultWrapper: {
    display: 'grid',
    placeContent: 'center',
    placeItems: 'center',
    padding: '70px 0 ',
    m: 'auto',

    '& img': {
      width: '100%',
      maxWidth: '260px',
      textAlign: 'center',
      userSelect: 'none',
    },

    '& p': {
      margin: '25px 0 0',
      fontWeight: '700',
    },
  },
};
