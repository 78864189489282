import { FormHelperText, Collapse } from '@mui/material';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';

export const ValidationMessage = memo(({ message }) => {
  return (
    <Collapse in={Boolean(message)} unmountOnExit>
      <FormHelperText error> {message}</FormHelperText>
    </Collapse>
  );
}, componentPropsAreEqual);

ValidationMessage.displayName = 'ValidationMessage';
