import { alpha, lighten } from '@mui/system';

const truncateBookNameAndAuthorNameStyles = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' };

export const getItemStyles = ({
  isAddingBookOfTheDay,
  showSelectedBookPreview,
  calendarDayObject = {},
  isFetchingBookOfTheDayList,
  theme,
} = {}) => ({
  item: {
    bookOfTheDayAutocompleteItemWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      ...(isAddingBookOfTheDay && { pointerEvents: 'none' }),
    },
    bookOfTheDayOption: {
      m: '-10px',
      p: '10px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      '&:hover .replaceBookButton': { bgcolor: 'primary.main', color: '#fff' },
    },
    bookOfTheDayOptionInnerWrapper: { display: 'flex', alignItems: 'center' },
    bookOfTheDayOptionCover: {
      width: 30,
      height: 45,
      borderRadius: '3px',
      border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
      margin: '0 14px 0 0',
      userSelect: 'none',
      position: 'relative',
      objectFit: 'cover',
      color: 'transparent',
      zIndex: 0,

      '&:before': {
        content: "'🚫'",
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        height: 'calc(100% + 2px)',
        width: 'calc(100% + 2px)',
        display: 'grid',
        placeItems: 'center',
        color: 'error.main',
        bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
        borderRadius: 'inherit',
        border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
        zIndex: 15,
        pointerEvents: 'none',
      },
    },
    bookOfTheDayOptionName: {
      mb: '2px',
      fontSize: '14px',
      fontWeight: 700,
    },
    bookOfTheDayOptionAuthor: { fontSize: '12px', color: 'secondary.400' },
    autocompleteRoot: {
      '& .MuiOutlinedInput-root': {
        bgcolor: 'transparent',
        borderRadius: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          border: 0,
          borderBottom: (theme) => `1px solid ${lighten(theme.palette.secondary[200], 0.41)}`,
        },

        '&.Mui-focused, &.Mui-error': { '& .MuiOutlinedInput-notchedOutline': { boxShadow: 'unset' } },
        '& .MuiAutocomplete-endAdornment': {
          right: { xs: '5px', lg: '15px' },
          [theme?.breakpoints?.down('lg')]: { top: '5px' },
          '& svg': { opacity: '.75' },
        },
        '& .MuiOutlinedInput-input': {
          cursor: 'pointer',
          height: '170px',
          ...(showSelectedBookPreview && { opacity: 0, userSelect: 'none' }),
          p: '0 40px 0 20px !important',
          textAlign: 'center',
        },
      },
    },
    autocompletePopper: {
      width: '400px !important',
      pr: '10px !important',
      pl: '7px !important',
      '& .MuiAutocomplete-option[aria-selected=true] .chooseBookButton': {
        display: 'none',
      },
    },
    addingBookOfTheDayLoadingIndicator: {
      position: 'absolute',
      top: '0',
      width: '100%',
      height: '100%',
      borderRadius: '0',
      opacity: '.2',
      zIndex: '2',
    },
    bookOfTheDayPreviewWrapper: {
      display: 'flex',
      position: 'absolute',
      alignItems: 'center',
      top: '50%',
      left: '0',
      transform: 'translateY(-50%)',
      zIndex: 1,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
    },
    bookOfTheDayPreviewBookDetailsWrapper: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      width: '100%',
    },
    bookOfTheDayPreviewBookDetailsWrapperSkeleton: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    bookOfTheDayPreviewBookCoverSkeleton: {
      width: 40,
      height: 60,
      borderRadius: '3px',
      display: 'inline-block',
      verticalAlign: 'middle',
      mb: '19px',
      opacity: 0.35,
    },
    bookOfTheDayPreviewBookCover: {
      width: 40,
      height: 60,
      borderRadius: '3px',
      border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
      margin: '0 0 13px 0',
      userSelect: 'none',
      position: 'relative',
      objectFit: 'cover',
      color: 'transparent',
      zIndex: 0,
      '&:before': {
        content: "'🚫'",
        position: 'absolute',
        top: '-1px',
        left: '-1px',
        height: 'calc(100% + 2px)',
        width: 'calc(100% + 2px)',
        display: 'grid',
        placeItems: 'center',
        color: 'error.main',
        bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
        borderRadius: 'inherit',
        border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
        zIndex: 15,
        pointerEvents: 'none',
      },
    },
    bookOfTheDayPreviewBookName: {
      mb: '4px',
      fontSize: '14px',
      fontWeight: 700,
      width: '82%',
      textAlign: 'center',
      ...truncateBookNameAndAuthorNameStyles,
      [theme?.breakpoints?.only('sx', 'sm', 'lg')]: {
        overflowX: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '140px',
      },
    },
    bookOfTheDayPreviewBookNameSkeleton: {
      width: '80px',
      height: '7px',
      display: 'inline-block',
      opacity: 0.35,
      borderRadius: 7,
      mb: '15px',
    },
    bookOfTheDayPreviewBookAuthorName: {
      fontSize: '12px',
      color: 'secondary.400',
      mb: '-20px',
      textAlign: 'center',
      width: '82%',
      ...truncateBookNameAndAuthorNameStyles,
    },
    bookOfTheDayPreviewBookAuthorNameSkeleton: {
      width: '50px',
      height: '7px',
      display: 'inline-block',
      opacity: 0.35,
      borderRadius: 7,
    },
    bookOfTheDayDayItemWrapper: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '&:hover .dayHeaderDayNumber': {
        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.15),
        color: 'primary.main',
      },
      ...(calendarDayObject.isPastDay &&
        calendarDayObject.isCurrentMonth &&
        !isFetchingBookOfTheDayList && {
          pointerEvents: 'none',
          '& img': { filter: 'grayscale(1)' },
          '&:before': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            backgroundColor: '#ffffff8f',
            zIndex: 20,
            backgroundSize: '50px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' height='300px' width='300px' fill='%235b56754f' version='1.1' x='0px' y='0px' viewBox='0 0 100 100' enable-background='new 0 0 100 100' xml:space='preserve' style='&%2310;'%3E%3Cg%3E%3Cpath d='M50.71,5.175c-24.814,0-45.005,20.181-45.005,44.995c0,24.814,20.191,45.005,45.005,45.005 c24.814,0,44.995-20.191,44.995-45.005C95.705,25.356,75.524,5.175,50.71,5.175z M50.71,13.523c8.306,0,15.976,2.776,22.132,7.461 L21.514,72.312c-4.685-6.157-7.461-13.826-7.461-22.143C14.053,29.958,30.498,13.523,50.71,13.523z M50.71,86.827 c-8.901,0-17.061-3.183-23.416-8.483l51.59-51.59c5.29,6.344,8.473,14.515,8.473,23.416C87.357,70.382,70.912,86.827,50.71,86.827z '/%3E%3C/g%3E%3C/svg%3E\")",
          },
          '&:after': {
            content: '""',
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: 21,
            backgroundImage:
              "url(\"data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.09' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E\")",
          },
        }),
    },
    bookOfTheDayDayItemOfCurrentMonthWrapper: {
      cursor: 'pointer',
      '&:hover': {
        bgcolor: 'primary.100',
      },
    },
    bookOfTheDayDayItemOfPrevAndNextMonthWrapper: {
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.09' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E\")",
    },
  },
});
