const systemFont = [
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
];

export function getTypographyBaseTokens() {
  return {
    typography: {
      fontFamily: ['Eudoxus Sans', 'Noto Sans Arabic', ...systemFont].join(','),
    },
  };
}

export function getTypographyTokens(theme) {
  return {
    typography: {
      h1: { fontWeight: 700 },
      h2: { fontWeight: 700 },
      h3: { fontWeight: 700 },
      h4: { fontWeight: 700 },
      h5: { fontWeight: 700 },
      h6: { fontWeight: 700, fontSize: 21 },
      button: {
        textTransform: 'initial',
        fontWeight: 500,
      },
      body1: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
      },
      body2: {
        color: theme.palette.secondary.main,
        fontWeight: 500,
      },
    },
  };
}
