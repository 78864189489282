import { alpha } from '@mui/system';

export const getStyles = ({ theme, disabled }) => ({
  datePickerTextField: {
    ...(disabled && { opacity: 0.75, userSelect: 'none' }),
    '& .MuiIconButton-root': { mr: 0 },
    'input::placeholder': { textTransform: 'uppercase' },
    '& .MuiInputAdornment-root': {
      transform: 'scale(.8)',
      svg: { color: (theme) => alpha(theme.palette.secondary[500], disabled ? 0.5 : 0.8) },
    },
  },
  datePickerPopperStyle: {
    boxShadow: `0 9px 28px ${alpha(theme.palette.secondary.main, 0.16)}`,
    border: `1px solid ${theme.palette.secondary[200]}`,
    borderRadius: '8px',
    margin: '10px 0',
  },
});
