export const pathnames = {
  home: '/',
  login: '/login',
  authors: '/library/authors',
  authorProfile: '/library/authors/:id',
  categories: '/library/categories',
  categoryProfile: '/library/categories/:id',
  books: '/library/books',
  bookProfile: '/library/books/:id',
  bookOfTheDay: '/book-of-the-day',
  clients: '/users/clients',
  clientProfile: '/users/clients/:id',
  admins: '/users/admins',
  notifications: '/notifications',
  notificationView: '/notifications/:id',
  reports: '/reports',
};
