import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { componentPropsAreEqual } from '../../utils';
import { getStyles } from './FullCalendar.styles';

export const FullCalendarDayContent = memo(({ children }) => {
  const styles = getStyles();

  return <Box sx={styles.dayContent}>{children}</Box>;
}, componentPropsAreEqual);

FullCalendarDayContent.propTypes = {
  children: PropTypes.node.isRequired,
};

FullCalendarDayContent.displayName = 'FullCalendarDayContent';
