export const styles = {
  wrapper: { padding: '52px 0', textAlign: 'center' },
  iconWrapper: {
    width: 90,
    height: 90,
    bgcolor: 'secondary.100',
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
    mb: '20px',
    mx: 'auto',
    '& svg': { color: 'secondary.400' },
  },
  title: { mb: '10px' },
  overview: { fontSize: '14px', color: 'secondary.400', mb: 0 },
};
