import { Box } from '@mui/material';
import { getStyles } from './ClientProfile.styles';
import { useTheme } from '@mui/system';
import ClientProfileSidebar from './components/ClientProfileSidebar';
import ClientBookList from './components/ClientBookList/ClientBookList';
import { UnprocessableEntity } from '../../../components';
import { Link, Route, Switch, useParams, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clientsSelector } from '../../../redux/slices/clients';
import { useEffect } from 'react';
import { clientsActions } from '../../../redux/slices/clients';
import ClientDetails from './components/ClientDetails';
import ActiveSubscription from './components/ActiveSubscription';
import ClientPreviousSubscriptions from './components/ClientPreviousSubscriptions/ClientPreviousSubscriptions';

const ClientProfile = () => {
  const { clientIdIsNotValid } = useSelector(clientsSelector);
  const theme = useTheme();
  const styles = getStyles(theme);
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const { id: clientId } = useParams();

  useEffect(() => {
    return () => dispatch(clientsActions.resetClientIdIsNotValidToBeFalse());
  }, [dispatch]);

  if (clientIdIsNotValid) {
    return (
      <UnprocessableEntity
        message='Oops! The client you’re looking for does not exist.'
        linkComponent={
          <Link to='/users/clients'>
            <span>←</span> Back to Clients
          </Link>
        }
      />
    );
  }

  return (
    <Box sx={styles.clientProfileGridContainer}>
      <Box sx={styles.clientRecentlyListenedBooksWrapper}>
        <ClientProfileSidebar />
      </Box>
      <Box>
        <Switch>
          <Route exact path={match.url}>
            <ClientDetails />
          </Route>
          <Route path={`${match.url}/active-subscription`}>
            <ActiveSubscription clientId={clientId} />
          </Route>
          <Route path={`${match.url}/previous-subscription`}>
            <ClientPreviousSubscriptions clientId={clientId} />
          </Route>
          <Route path={`${match.url}/recently-Listened-books`}>
            <ClientBookList />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default ClientProfile;
