export function getMuiCollapseDesignTokens() {
  return {
    MuiCollapse: {
      styleOverrides: {
        hidden: {
          position: 'absolute',
        },
      },
    },
  };
}
