import { capitalizeWords } from '../../../../utils';
import { NotificationTypes } from '../../../../enums';
import { FIELD_DEFAULT_VALUES, FIELD_VARIANTS } from '../../../../constants';
import { Autocomplete, DatePicker } from '../../../../components';

const typeFieldValueExtractor = (value) => ({
  value: value.toUpperCase(),
  label: capitalizeWords(value.split('_').join(' ')),
});

const notificationTypeOptionsList = Object.keys(NotificationTypes).map((enumKey) => typeFieldValueExtractor(enumKey));

export const filtersConfig = [
  {
    label: 'Type',
    name: 'type',
    defaultValue: FIELD_DEFAULT_VALUES.AUTOCOMPLETE_SINGLE,
    fieldVariant: FIELD_VARIANTS.AUTOCOMPLETE_SINGLE,
    fieldValueExtractor: typeFieldValueExtractor,
    field: <Autocomplete placeholder='Select Type' options={notificationTypeOptionsList} />,
  },
  {
    label: 'Date Created',
    name: 'createdAt',
    defaultValue: FIELD_DEFAULT_VALUES.DATE_PICKER,
    fieldVariant: FIELD_VARIANTS.DATE_PICKER,
    field: <DatePicker placeholder='Select Date' />,
  },
];
