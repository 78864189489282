import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { AuthorStatus } from '../../../../enums';
import { capitalizeWords } from '../../../../utils';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Author name is required'),
  status: Yup.mixed().required('Author status is required'),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    name: '',
    status: { value: AuthorStatus.ACTIVE.toUpperCase(), label: capitalizeWords(AuthorStatus.ACTIVE) },
  },
};
