import { Book } from './book';

export class ListenedBook {
  constructor(data) {
    this.book = new Book(data.book) || null;
    this.bookId = data.book_id || null;
    this.isCompletedOnce = data.is_completed_once === undefined ? null : data.is_completed_once;
    this.listenedTill = data.listened_till === undefined ? null : data.listened_till;
    this.listeningTimeInPercentage = Math.floor((data.listened_till / data.book.duration) * 100) || 0;
  }
}
