import { alpha } from '@mui/material';

export const styles = {
  item: {
    outerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
      borderRadius: '8px',
      p: '25px',
    },
    detailsWrapper: {
      '& p': { mb: '16px', color: 'secondary.400', fontSize: '14px' },
      '& h2': {
        mb: 0,
        fontSize: '32px',
        '& span': { color: 'secondary.400', fontSize: '14px', fontWeight: '500' },
      },
    },
    dataSkeletonWrapper: { display: 'flex', alignItems: 'center', '& span': { ml: '7px' } },
    dataSkeleton: { width: '70px', height: '20px', borderRadius: '4px', opacity: 0.5 },
    dataSkeletonPlaceholder: { opacity: 0, pointerEvents: 'none', width: 0 },
  },
  numberOfListeningIcon: {
    width: 70,
    height: 70,
    color: 'success.main',
    bgcolor: (theme) => alpha(theme.palette.success.main, 0.2),
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
  },
  bookmarksIcon: {
    width: 70,
    height: 70,
    color: 'warning.main',
    bgcolor: (theme) => alpha(theme.palette.warning.main, 0.2),
    borderRadius: '50%',
    display: 'grid',
    placeItems: 'center',
  },
};
