import { capitalizeWords } from '../utils';
import moment from 'moment';

export class Category {
  constructor(data) {
    this.id = data.id || null;
    this.name = data.name || null;
    this.sharableLink = data.sharable_link || null;
    this.status = data.status ? capitalizeWords(data.status) : null;
    this.createdAt = data.created_at ? moment(data.created_at).format('DD MMM YYYY') : null;
    this.brandingColor = data.branding_color ? data.branding_color : null;
    this.cover = data.cover ? data.cover : null;
    this.icon = data.icon ? data.icon : null;
    this.promotionText = data.promotion_text !== undefined ? data.promotion_text : null;
  }
}
