import Box from '@mui/material/Box';
import { getStyles } from './Drawer.styles';
import DrawerNav from '../DrawerNav/DrawerNav';
import DrawerHeader from '../DrawerHeader/DrawerHeader';
import MuiDrawer from '@mui/material/Drawer';
import { useTheme } from '@mui/styles';

const Drawer = ({ isDrawerOpened, setIsDrawerOpened, toggleDrawer }) => {
  const theme = useTheme();
  const styles = getStyles({ theme, isDrawerOpened });

  return (
    <MuiDrawer variant='permanent' sx={styles.drawerRoot}>
      <DrawerHeader isDrawerOpened={isDrawerOpened} toggleDrawer={toggleDrawer} />
      <Box sx={styles.drawerNavWrapper}>
        <DrawerNav isDrawerOpened={isDrawerOpened} setIsDrawerOpened={setIsDrawerOpened} />
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
