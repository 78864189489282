import Box from '@mui/material/Box';
import { Stack } from '@mui/material';
import Chip from '@mui/material/Chip';
import { getTomorrowDate } from '../../../../utils';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DatePicker, TextField } from '../../../../components';
import { styles } from './CreateUpdateNotificationForm.styles';
import { handleNotificationStatusChip } from '../../Notifications.table';
import AppleNotificationView from '../../NotificationView/NotificationDetails/AppleNotificationView';
import AndroidNotificationView from '../../NotificationView/NotificationDetails/AndroidNotificationView';
export { createUpdateNotificationFormOptions } from './CreateUpdateNotificationForm.form';

export const CreateUpdateNotificationForm = ({ form, notification, isDraft }) => {
  const { title, content } = form.watch();
  const isViewportWidthLessThan1270px = useMediaQuery('(max-width:1270px)');

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '40px' }}>
      {isDraft ? (
        <Stack
          spacing={isViewportWidthLessThan1270px ? '24px' : '120px'}
          direction={isViewportWidthLessThan1270px ? 'column' : 'row'}
          alignItems={isViewportWidthLessThan1270px ? 'flex-start' : 'center'}
        >
          <Box sx={styles.contentItemWrapper}>
            <Box sx={styles.contentItemTitleInDraftMode}>Type</Box>
            <Box sx={styles.contentItemTextInDraftMode}>{notification.type}</Box>
          </Box>
          <Box sx={styles.contentItemWrapper}>
            <Box sx={styles.contentItemTitleInDraftMode}>Status</Box>
            <span>
              <Chip color={handleNotificationStatusChip(notification.status)} label={notification.status} />
            </span>
          </Box>
        </Stack>
      ) : null}
      <Stack spacing='26px' gridColumn='1'>
        <TextField
          form={form}
          name='title'
          id='title'
          label='Notification Tile'
          placeholder='Enter notification title'
        />
        <TextField
          form={form}
          name='content'
          id='content'
          label='Notification Content'
          placeholder='Enter notification content'
          multiline
          minRows={1.42}
          maxRows={1.42}
          resize='vertical'
        />
        <DatePicker
          form={form}
          name='releaseAt'
          id='releaseAt'
          label='Release At'
          minDate={getTomorrowDate(new Date())}
        />
      </Stack>
      <Stack spacing='40px' alignItems='center'>
        <AndroidNotificationView title={title || 'Title goes here'} content={content || 'Content goes here'} />
        <AppleNotificationView title={title || 'Title goes here'} content={content || 'Content goes here'} />
      </Stack>
    </Box>
  );
};
