export const styles = {
  autocompleteInputWrapper: { position: 'relative' },
  autocompleteLoadingIndicatorWrapper: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: '0',
    cursor: 'not-allowed',
  },
  autocompleteLoadingIndicator: {
    position: 'absolute',
    right: '38px',
    top: '50%',
    transform: 'translateY(-50%)',
    filter: 'grayscale(1)',
    opacity: 0.6,
    pointerEvents: 'none',
  },
  createItemOptionWrapper: {
    display: 'flex',
    alignItems: 'center',
    '& span': { fontWeight: 700, color: 'primary.main' },
  },
  createItemOptionAddIcon: { mr: '6px', fontSize: '20px' },
};
