export const styles = {
  alertRoot: {
    fontWeight: 700,
    p: '12px 20px',
    fontSize: '16px',
    alignItems: 'center',
    '&.MuiAlert-standardSuccess': { bgcolor: 'success.main', color: '#fff' },
    '&.MuiAlert-standardError': { bgcolor: 'error.main', color: '#fff' },
    '&.MuiAlert-standardWarning': {
      bgcolor: 'warning.600',
      color: 'white.main',
      boxShadow: '0px 20px 40px #767EAD26',
    },
    '& .MuiAlert-icon': { mr: '14px' },
    '& .MuiAlert-icon, & .MuiIconButton-root': { color: 'currentColor' },
    '& .MuiAlert-icon, & .MuiAlert-message': { p: 0 },
    '& .MuiAlert-action': { pt: 0 },
  },
};
