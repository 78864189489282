import { useCallback, useEffect, useState } from 'react';
import { CircularProgress, FileUpload, FileUploadButton, ValidationMessage } from '../../../../../components';
import { FormLabel, IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import ImageUploadIcon from '../../../../../assets/images/icons/ImageUpload';
import { getStyles } from './CategoryIconUpload.styles';
import { useDispatch, useSelector } from 'react-redux';
import { storageActions, storageSelector } from '../../../../../redux/slices/storage';
import { deleteFile } from '../../../../../redux/slices/storage/storage.delete';
import fallbackImg from '../../../../../assets/images/fallback-img.png';
import ArrowsExchangeIcon from '../../../../../assets/images/icons/ArrowsExchange';
import { updateCategory } from '../../../../../redux/slices/categories/categories.update';
import { useParams } from 'react-router-dom';
import TrashIcon from '../../../../../assets/images/icons/Trash';

const CategoryIconUpload = ({ form, text, label }) => {
  const fieldName = 'icon';
  const { watch, formState } = form;
  const dispatch = useDispatch();
  const { id: categoryId } = useParams();
  const { storage } = useSelector(storageSelector);
  const {
    loaded,
    total,
    percentage,
    isUploading,
    isUploadedSuccessfully,
    uploadedFileLink: categoryIconLink,
  } = storage.upload[fieldName] || {};
  const categoryIcon = watch(fieldName);
  const { isDeletingFile: isDeletingBookIconFile } = storage.delete[categoryIconLink || categoryIcon] || {};

  const fieldHasError = Boolean(formState.errors[fieldName]);
  const fieldErrorMessage = formState.errors[fieldName]?.message;
  const categoryIconUploadStyles = getStyles({
    isUploadedSuccessfully,
    percentage,
    isUploading,
    isDeletingBookIconFile,
  });
  const categoryIconName = categoryIcon?.[0]?.name || 'Category Icon Image';
  const [isCategoryIconURLExisted, setIsCategoryIconURLExisted] = useState(Boolean(false));

  const getCategoryIconUrl = useCallback(() => {
    const categoryIconImageUrl = categoryIcon;
    const categoryIconFile = categoryIcon?.[0];
    if (categoryIconLink) return categoryIconLink;
    if (categoryIconFile && typeof categoryIconFile === 'object') return URL.createObjectURL(categoryIconFile);
    if (categoryIconImageUrl) return categoryIconImageUrl;
  }, [categoryIcon, categoryIconLink]);

  const generatedCategoryIconUrl = getCategoryIconUrl();

  useEffect(() => {
    setIsCategoryIconURLExisted(Boolean(generatedCategoryIconUrl));
  }, [generatedCategoryIconUrl]);

  const handleCategoryIconDeletion = () => {
    return dispatch(deleteFile(categoryIconLink || categoryIcon));
  };

  const handleDeleteAndReset = () => {
    handleCategoryIconDeletion().then(() => {
      dispatch(updateCategory(categoryId, { [fieldName]: null })).then(() => {
        form.setValue(fieldName, '');
        setIsCategoryIconURLExisted(false);
        dispatch(storageActions.uploadFileReset({ fieldName }));
      });
    });
  };

  return (
    <Box sx={categoryIconUploadStyles.iconFileUploadOuterWrapper}>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <FileUpload
        form={form}
        name={fieldName}
        id='categoryIcon'
        accept='.png, .jpg, .jpeg, .svg'
        {...((categoryIcon || categoryIconLink) && { handleFileDelete: handleCategoryIconDeletion })}
      >
        <Box sx={categoryIconUploadStyles.contentWrapper}>
          <FileUploadButton sx={categoryIconUploadStyles.fileUploadButton} error={fieldHasError}>
            <Fade in={isCategoryIconURLExisted} unmountOnExit>
              <Box sx={categoryIconUploadStyles.uploadStatusWrapper}>
                <Box sx={categoryIconUploadStyles.previewImgWrapper}>
                  <Box
                    component='img'
                    src={getCategoryIconUrl() || fallbackImg}
                    alt={categoryIconName}
                    sx={categoryIconUploadStyles.previewImgIcon}
                  />
                </Box>
                {categoryIcon || categoryIconLink ? (
                  <IconButton
                    variant='square'
                    color='primary'
                    sx={categoryIconUploadStyles.replaceButton}
                    title='Replace the cover'
                  >
                    <ArrowsExchangeIcon />
                  </IconButton>
                ) : null}
                <Fade in={Boolean(!(!isUploading && !isUploadedSuccessfully))} timeout={700} unmountOnExit>
                  <Box display='flex'>
                    {isUploading ? <CircularProgress size={45} /> : null}
                    <Box sx={categoryIconUploadStyles.progressBar} />
                    <Box sx={categoryIconUploadStyles.progressDetails}>
                      <Box sx={categoryIconUploadStyles.progressPercentage}>{Number(percentage)}%</Box>
                      {isUploadedSuccessfully ? '✓ Icon uploaded successfully! 🎉' : `${loaded} of ${total} Uploaded`}
                    </Box>
                  </Box>
                </Fade>
              </Box>
            </Fade>
            <ImageUploadIcon style={{ marginBottom: '14px' }} />
            {text}
          </FileUploadButton>
        </Box>
        <ValidationMessage message={fieldErrorMessage} />
      </FileUpload>
      {(categoryIcon?.includes?.('s3') || (categoryIconLink && isUploadedSuccessfully)) && isCategoryIconURLExisted ? (
        <IconButton
          variant='square'
          color='error'
          title='Delete the cover'
          sx={categoryIconUploadStyles.deleteButton}
          onClick={handleDeleteAndReset}
        >
          {isDeletingBookIconFile ? <CircularProgress size={18} color='error' /> : <TrashIcon />}
        </IconButton>
      ) : null}
    </Box>
  );
};

export default CategoryIconUpload;
