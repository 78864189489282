export const activeNavItemStyles = { bgcolor: '#E5F4FD', color: 'primary.main' };

export const getStyles = (isNestedNavOpened) => ({
  listItemRoot: {
    transition: 'padding 120ms ease',
    borderRadius: '8px',
    color: 'secondary.400',
    pl: '15px',
    pr: '15px',
    cursor: 'pointer',
    userSelect: 'none',
    ...(isNestedNavOpened && activeNavItemStyles),
    '&:hover, &:focus': activeNavItemStyles,
  },
  listItemWrapper: {
    mb: '18px',
    '&:last-of-type': { mb: 0 },
    '& a:hover': { opacity: 1 },
    '& a[aria-current="page"] .MuiListItem-root': activeNavItemStyles,
  },
  listItemIcon: {
    transition: 'all 200ms ease',
    color: 'inherit',
    minWidth: 'initial',
    mr: '18px',
  },
  listItemText: {
    '& .MuiTypography-root': {
      color: 'inherit',
      fontWeight: 700,
    },
  },
  expandIconWrapper: {
    display: 'flex',
    transition: 'transform 120ms ease',
    transform: `rotate(${isNestedNavOpened ? '0' : '180deg'})`,
  },
});
