export const getStyles = (isDrawerOpened) => ({
  logoutButtonWrapper: {
    bgcolor: '#fff',
    padding: `0 ${isDrawerOpened ? '15px' : '10px'}`,
    transition: 'all 120ms ease',
    position: 'absolute',
    left: isDrawerOpened ? 0 : '-5px',
    bottom: 0,
    width: '100%',
    overflow: 'hidden',
  },
  logoutButtonRoot: {
    mt: '20px',
    mb: '20px',
    pl: '15px',
    pr: '15px',
    justifyContent: 'flex-start',
    '& .MuiButton-startIcon': {
      transition: 'all 120ms ease',
      mr: isDrawerOpened ? '18px' : '20px',
      ml: 0,
    },
  },
});
