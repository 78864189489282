import Box from '@mui/material/Box';
import { getStyles } from './UserSettingTab.styles';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../../../../redux/slices/auth';
import ArrowSingleBottom from '../../../../../assets/images/icons/ArrowSingleBottom';
import { Popper } from '../../../../../components';
import { bindTrigger, bindPopper, usePopupState } from 'material-ui-popup-state/hooks';
import MenuList from '@mui/material/MenuList';
import { logout } from '../../../../../redux/slices/auth/auth.logout';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { capitalizeWords } from '../../../../../utils';

const UserSettingTab = () => {
  const { user } = useSelector(authSelector);
  const theme = useTheme();
  const styles = getStyles({ theme });
  const dispatch = useDispatch();
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'userSettingPopper',
  });

  const userFullName = user?.name;
  const userRoleTitle = capitalizeWords(user?.role?.split('_')?.join(' '));
  const userIconText = user?.name
    ?.split(' ')
    ?.map((word) => word.charAt(0).toUpperCase())
    .join('');

  const handleLogout = () => {
    popupState.close();
    dispatch(logout());
  };

  return (
    <>
      <Box sx={styles.userSettingTab} {...bindTrigger(popupState)}>
        <Box sx={styles.userSettingTabIcon}>{userIconText}</Box>
        <Box>
          <Box component='p' sx={styles.userSettingTabName}>
            {userFullName} <ArrowSingleBottom />
          </Box>
          <Box component='p' sx={styles.userSettingTabDescription}>
            {userRoleTitle}
          </Box>
        </Box>
      </Box>
      <Popper placement='bottom-end' {...bindPopper(popupState)} closePopper={popupState.close}>
        <MenuList sx={styles.userSettingTabMenuList}>
          <ListItemButton onClick={handleLogout}>
            <ListItemIcon>
              <LogoutRoundedIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Logout' />
          </ListItemButton>
        </MenuList>
      </Popper>
    </>
  );
};

export default UserSettingTab;
