import { alpha } from '@mui/system';

export function getInputDesignTokens(theme) {
  const { palette } = theme;

  return {
    MuiOutlinedInput: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          backgroundColor: palette.common.white,
          fontSize: '14px',
          fontWeight: 600,
          borderRadius: 8,
          color: 'inherit',
          transition: 'background 120ms ease',
          padding: 0,
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary[200],
          },

          '&:hover': {
            backgroundColor: palette.common.white,
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: palette.secondary[300],
            },
          },
          '&.Mui-focused': {
            backgroundColor: palette.common.white,
            zIndex: 1,
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: palette.primary.main,
              boxShadow: `0 0 0 1px ${palette.primary.main}, 0 0 0 5px ${alpha(palette.primary.main, 0.2)} `,
              borderWidth: 1,
            },
            '&.Mui-error': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: palette.error.main,
                boxShadow: `0 0 0 1px ${palette.error.main}, 0 0 0 5px ${alpha(palette.error.main, 0.2)} `,
              },
            },
          },
          '&.Mui-error ': {
            zIndex: 1,
            backgroundColor: palette.common.white,
            '&:hover ': {
              '.MuiOutlinedInput-notchedOutline': {
                borderColor: palette.error.main,
              },
            },
          },
          '&.Mui-disabled': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: palette.secondary[200],
            },
          },
        },
        notchedOutline: {
          transition: 'border 120ms ease',
          borderColor: palette.grey[950],
        },
        input: {
          '&::placeholder': {
            color: palette.secondary[300],
            opacity: 1,
            textTransform: 'capitalize',
            fontWeight: 500,
          },
        },
        sizeSmall: {
          'input, textarea, & .MuiSelect-outlined, & .MuiAutocomplete-input': {
            padding: '14px 16px !important',
          },
          '&.MuiAutocomplete-inputRoot': {
            padding: '0 !important',
          },
          textarea: {
            lineHeight: 1.7,
          },
        },
      },
    },
  };
}
