import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect, useRef, useState } from 'react';
import { Button, LinearProgress, Box } from '@mui/material';

import { BookStatus } from '../../../../enums';
import { Section } from '../../../../components';
import TopBooksList from './CategoryTopBooks.list';
import { getStyles } from './CategoryTopBooks.styles';
import AddIcon from '../../../../assets/images/icons/Add';
import TopBooksListSkeleton from './CategoryTopBooks.skeleton';
import { booksSelector } from '../../../../redux/slices/books';
import { categoriesSelector } from '../../../../redux/slices/categories';
import { componentPropsAreEqual, dropDownFormat } from '../../../../utils';
import { fetchCategoryTopBooks } from '../../../../redux/slices/categories/categories.top-books';

const CategoryTopBooks = () => {
  const { category, categoryTopBooks, isFetchingCategoryTopBooks } = useSelector(categoriesSelector);
  const dispatch = useDispatch();
  const { books } = useSelector(booksSelector);
  const { id: categoryId } = useParams();
  const [topBooks, setTopBooks] = useState([]);
  const topBooksScrollableWrapper = useRef(null);
  const [newTopBookElement, setNewTopBookElement] = useState(null);
  const booksOptionsList = dropDownFormat(books, { value: 'id', label: 'name' })?.filter(
    (option) => option.status.toUpperCase() === BookStatus.ACTIVE,
  );

  const isThereAtLeastOneNewEmptySelect = topBooks.some((topBook) => topBook.bookId === null);
  const styles = getStyles();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const topBookElement = document.getElementById('new-top-book-element');

    if (topBookElement) setNewTopBookElement(topBookElement);
    else setNewTopBookElement(null);
  });

  useEffect(() => {
    // Scroll to the bottom of top books section to view the latest added book input
    if (newTopBookElement) {
      topBooksScrollableWrapper.current.scrollTop = topBooksScrollableWrapper.current.scrollHeight + 900;
    }
  }, [newTopBookElement]);

  useEffect(() => dispatch(fetchCategoryTopBooks(categoryId)), [dispatch, categoryId]);

  useEffect(() => setTopBooks(categoryTopBooks.list), [categoryTopBooks.list]);

  const handleAddTopBookItemSelect = () => {
    const initialTopBookObject = { bookId: null, id: new Date().toISOString() };
    const updatedTopBooks = [...topBooks, initialTopBookObject];

    setTopBooks(updatedTopBooks);
  };

  return (
    <Section
      title={
        <>
          Top Books of {category?.name ?? <LinearProgress color='secondary' sx={styles.section.categoryNameSkeleton} />}
        </>
      }
      sectionFooter={
        <Button
          sx={styles.section.addBookButton}
          startIcon={<AddIcon />}
          fullWidth
          onClick={handleAddTopBookItemSelect}
          disabled={isThereAtLeastOneNewEmptySelect}
        >
          Add Book
        </Button>
      }
      contentPadding={0}
      footerPadding={0}
    >
      <Box ref={topBooksScrollableWrapper} sx={styles.section.topBooksContentWrapper}>
        <Box>
          {isFetchingCategoryTopBooks ? (
            <TopBooksListSkeleton />
          ) : (
            <TopBooksList topBooks={topBooks} booksOptionsList={booksOptionsList} />
          )}
        </Box>
      </Box>
    </Section>
  );
};

export default memo(CategoryTopBooks, componentPropsAreEqual);
