import { capitalizeWords } from '../utils';
import moment from 'moment';

export class Author {
  constructor(data) {
    this.id = data.id || null;
    this.name = data.name || null;
    this.status = data.status ? capitalizeWords(data.status) : null;
    this.createdAt = data.created_at ? moment(data.created_at).format('DD MMM YYYY') : null;
    this.sharableLink = data.sharable_link || null;
  }
}
