import MuiAutocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { FormLabel } from '@mui/material';
import { ValidationMessage } from '..';
import AutocompletePopper from './AutoComplete.popper';
import ListboxComponent from './AutoComplete.listbox';
import { componentPropsAreEqual } from '../../utils';
import { forwardRef, memo, useState } from 'react';
import { styles } from './Autocomplete.styles';
import { CircularProgress, FormController } from '../../components';
import CreateItemOption from './AutoComplete.create-option';

const filter = createFilterOptions();

const Autocomplete = memo(
  ({
    form,
    name,
    options = [],
    id,
    label,
    placeholder = '',
    loading,
    autoFocus,
    sx,
    listItemHeight,
    popperSx,
    inputRef,
    isCreatable,
    onChange: externalOnChange,
    ...props
  }) => {
    const [{ value = null, onChange, fieldHasError, fieldErrorMessage, fieldErrorType }, setFormControllerValues] =
      useState({});

    return (
      <>
        {form && name ? (
          <FormController form={form} name={name} setFormControllerValues={setFormControllerValues} />
        ) : null}
        <Box sx={{ fontSize: 0 }}>
          {label ? <FormLabel {...(id ? { htmlFor: id } : {})}>{label}</FormLabel> : null}
          <Box sx={styles.autocompleteInputWrapper}>
            <MuiAutocomplete
              /* eslint-disable-next-line react/display-name */
              PopperComponent={forwardRef((props, ref) => (
                <AutocompletePopper ref={ref} sx={popperSx} placement='bottom-start' {...props} />
              ))}
              /* eslint-disable-next-line react/display-name */
              ListboxComponent={forwardRef((props, ref) => (
                <ListboxComponent ref={ref} listItemHeight={listItemHeight} {...props} />
              ))}
              renderInput={(params) => (
                <TextField
                  error={Boolean(fieldHasError)}
                  {...params}
                  placeholder={placeholder}
                  autoFocus={autoFocus}
                  {...(inputRef && { inputRef })}
                  id={id}
                />
              )}
              renderOption={(props, option) => [
                props,
                option.inputValue && isCreatable ? <CreateItemOption inputValue={option.inputValue} /> : option.label,
              ]}
              {...(isCreatable && {
                selectOnFocus: true,
                clearOnBlur: true,
                handleHomeEndKeys: true,
                filterOptions: (options, params) => {
                  const filtered = filter(options, params);

                  const isNotExist = !options.some(
                    (option) => params.inputValue?.toLowerCase?.() === option.label?.toLowerCase?.(),
                  );

                  if (params.inputValue !== '' && isNotExist) filtered.push({ inputValue: params.inputValue });

                  return filtered;
                },
              })}
              isOptionEqualToValue={(option, selectedOption) => {
                return (
                  option?.label?.toLowerCase() ===
                    (selectedOption?.toLowerCase?.() || selectedOption?.label?.toLowerCase?.()) ||
                  option?.value?.toLowerCase() ===
                    (selectedOption?.toLowerCase?.() || selectedOption?.value?.toLowerCase?.())
                );
              }}
              {...(form && name
                ? {
                    value: value,
                    onChange: (event, value) => {
                      onChange(value);
                      externalOnChange?.(value);
                    },
                  }
                : { onChange: externalOnChange })}
              getOptionLabel={(option) => {
                // e.g value selected with enter, right from the input
                if (typeof option === 'string') return option;
                if (option.inputValue) return option.inputValue;

                return option.label;
              }}
              options={options}
              disabled={loading}
              openOnFocus
              sx={sx}
              {...props}
            />
            {loading ? (
              <Box sx={styles.autocompleteLoadingIndicatorWrapper}>
                <CircularProgress size={17} sx={styles.autocompleteLoadingIndicator} />
              </Box>
            ) : null}
          </Box>
          <ValidationMessage message={fieldErrorMessage} type={fieldErrorType} />
        </Box>
      </>
    );
  },
  componentPropsAreEqual,
);

Autocomplete.displayName = 'Autocomplete';

export { createFilterOptions, Autocomplete };
