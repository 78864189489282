export const styles = {
  root: { bgcolor: '#E5F4FD', '&:hover': { bgcolor: '#d4edfc' } },
  activeFiltersCount: {
    width: '18px',
    height: '18px',
    borderRadius: '4px',
    bgcolor: (theme) => theme.palette.primary.main,
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '14px',
    ml: '12px',
  },
};
