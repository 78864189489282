import { memo, useEffect } from 'react';
import { componentPropsAreEqual } from '../../../../../utils';
import { Section, Table, ListCount } from '../../../../../components';
import useClientBookListTable from './ClientBookList.table';
import { booksActions } from '../../../../../redux/slices/books';
import { useDispatch } from 'react-redux';

const ClientBookList = () => {
  const { booksTableColumns, booksTableRows, isFetchingClientListenedBooks } = useClientBookListTable();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(booksActions.resetBooks());
    };
  }, [dispatch]);

  return (
    <Section
      outerHeader
      title='Recently Listened Books'
      toolbar={<ListCount count={booksTableRows?.length} />}
      sx={{ height: '100%' }}
      contentPadding={0}
    >
      <Table
        columns={booksTableColumns}
        rows={booksTableRows}
        isLoading={isFetchingClientListenedBooks}
        containerSx={{
          maxHeight: {
            xs: '600px',
            lg: 'calc(100vh - 80px - 64px - 59px)',
          },
        }}
      />
    </Section>
  );
};

export default memo(ClientBookList, componentPropsAreEqual);
