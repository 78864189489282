import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('A valid email is required!')
    .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i, 'Invalid email address!'),
  password: Yup.string().required('A valid email is required!'),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    email: '',
    password: '',
  },
};
