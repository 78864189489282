export const styles = {
  contentWrapper: { display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(378px, 1fr))', gap: '40px' },
  contentItem: {
    display: 'grid',
    gridTemplateColumns: 'min(200px) 1fr',
    '&:nth-of-type(2)': {
      mb: '40px !important',
    },
  },
  contentItemTitle: { color: 'secondary.400', fontSize: '15px' },
  contentItemText: { fontWeight: '700', fontSize: '14px' },
};
