import PropTypes from 'prop-types';
import Navigation from './FullCalendar.navigation';
import DaysOfWeek from './FullCalendar.days-of-week';
import Days from './FullCalendar.days';
import { Calendar } from './FullCalenadr.calendar';
import { Section } from '..';

FullCalendar.propTypes = {
  yearAndMonth: PropTypes.arrayOf(PropTypes.number).isRequired, // e.g. [2021, 6] for June 2021
  onYearAndMonthChange: PropTypes.func.isRequired,
  renderDay: PropTypes.func,
  monthData: PropTypes.array,
};

export function FullCalendar({
  yearAndMonth: [year, month] = [Calendar.thisYear, Calendar.thisMonth],
  onYearAndMonthChange,
  renderDay = () => null,
  monthData,
}) {
  const calendar = new Calendar({ year, month, ...(monthData ? { monthData } : {}) });

  return (
    <Section contentPadding={0}>
      <Navigation calendar={calendar} onYearAndMonthChange={onYearAndMonthChange} />
      <DaysOfWeek />
      <Days calendar={calendar} monthData={monthData} renderDay={renderDay} />
    </Section>
  );
}
