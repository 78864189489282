import { FiltersPopup } from '../../../../components';
import { filtersConfig } from './NotificationFilters.helpers';
import { fetchNotifications } from '../../../../redux/slices/notifications/notifications.read';
import { parseSearchParams } from '../../../../utils';
import { useDispatch } from 'react-redux';

const NotificationFilters = () => {
  const dispatch = useDispatch();

  return (
    <FiltersPopup
      filtersConfig={filtersConfig}
      onSubmit={() => {
        dispatch(fetchNotifications({ ...parseSearchParams(), page: 0 }));
      }}
      onClear={() => dispatch(fetchNotifications({ ...parseSearchParams(), page: 0 }))}
    />
  );
};

export default NotificationFilters;
