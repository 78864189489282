export function getPaperDesignTokens() {
  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
  };
}
