import { axios } from '../../../services/axios';

import { categoriesActions } from '.';
import { Category } from '../../../models';
import { StatusCodes } from 'http-status-codes';

const {
  fetchCategoriesRequestLoading,
  fetchCategoriesRequestSuccess,
  fetchSingleCategoryRequestLoading,
  fetchSingleCategoryRequestSuccess,
  fetchSingleCategoryRequestFailure,
} = categoriesActions;

export const fetchCategories = () => {
  return async (dispatch) => {
    dispatch(fetchCategoriesRequestLoading(true));

    return axios
      .get('/categories')
      .then((res) => {
        const categories = res.data?.categories?.map((category) => new Category(category));
        if (categories) dispatch(fetchCategoriesRequestSuccess(categories));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(fetchCategoriesRequestLoading(false)));
  };
};

export const fetchSingleCategory = (categoryId) => {
  return async (dispatch) => {
    dispatch(fetchSingleCategoryRequestLoading(true));

    return axios
      .get(`/categories/${categoryId}`)
      .then(async (res) => {
        const category = new Category(res.data?.category);

        if (category) dispatch(fetchSingleCategoryRequestSuccess(category));
      })
      .catch((error) => {
        const statusCode = error?.response?.status;

        if (statusCode === StatusCodes.BAD_REQUEST) {
          dispatch(fetchSingleCategoryRequestFailure(statusCode));
        }
      })
      .finally(() => dispatch(fetchSingleCategoryRequestLoading(false)));
  };
};
