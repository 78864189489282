import isEqual from 'lodash.isequal';
import capitalize from 'capitalize';
import { snackbar } from './snackbar';
import { BookStatus } from '../enums';
import { FIELD_DEFAULT_VALUES, FIELD_VARIANTS } from '../constants';

export function componentPropsAreEqual(prevProps, nextProps) {
  return isEqual(prevProps, nextProps);
}

export function capitalizeWords(str = '') {
  return capitalize.words(str);
}

export function generateOptionsListFromEnumObject(enumObject) {
  if (typeof enumObject !== 'object') throw new Error('Cannot generate an options list from non-object argument');

  return Object.keys(enumObject).map((enumKey) => {
    return { value: enumKey.toUpperCase(), label: capitalizeWords(enumKey) };
  });
}

export function dropDownFormat(arrayOfData, optionObjectStructure) {
  return (
    arrayOfData?.map((item) => ({
      value: String(item?.[optionObjectStructure.value]),
      label: item?.[optionObjectStructure.label],
      ...item,
    })) || []
  );
}

export function bytesToSize(bytes, separator = '', postFix = '') {
  if (bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.min(parseInt(Math.floor(Math.log(bytes) / Math.log(1024)).toString(), 10), sizes.length - 1);
    return `${(bytes / 1024 ** i).toFixed(i ? 1 : 0)}${separator}${sizes[i]}${postFix}`;
  }

  return 'n/a';
}

export const preventLeavePageOn = (condition) => {
  if (condition) {
    window.onbeforeunload = () => true;
  } else {
    window.onbeforeunload = null;
  }

  return () => {
    window.onbeforeunload = null;
  };
};

export function formatSecondsToTime(sec) {
  if (!sec) return '00:00:00';

  const hrs = Math.floor(sec / 3600);
  const min = Math.floor((sec - hrs * 3600) / 60);
  let seconds = sec - hrs * 3600 - min * 60;
  seconds = Math.round(seconds * 100) / 100;

  let result = hrs < 10 ? '0' + hrs : hrs;
  result += ':' + (min < 10 ? '0' + min : min);
  result += ':' + (seconds < 10 ? '0' + seconds : seconds);

  return result;
}

export function getTomorrowDate(date) {
  const currentDate = new Date(date);
  const nextDayDate = currentDate.setDate(currentDate.getDate() + 1);

  return nextDayDate;
}

export const handleBookStatusChip = (status = '') => {
  switch (status.toUpperCase()) {
    case BookStatus.ACTIVE:
      return 'success';
    case BookStatus.INACTIVE:
      return 'error';
    default:
      return 'default';
  }
};

export const generateNewRelativePathWithSearchParams = (params = {}) => {
  const searchParams = new URLSearchParams(window.location.search);

  for (const [paramKey, paramValue] of Object.entries(params)) {
    if (paramValue) searchParams.set(paramKey, paramValue);
    else searchParams.delete(paramKey);
  }

  const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();

  return newRelativePathQuery;
};

export const parseSearchParams = () => {
  const paramsObj = {};
  const search = window.location.search;

  const params = new URLSearchParams(search);

  for (const key of params.keys()) paramsObj[key] = params.get(key);

  return paramsObj;
};

export function getDirtyFieldsValues({ dirtyFields, data }) {
  if (dirtyFields) {
    const changedFields = {};
    Object.keys(dirtyFields).forEach((fieldName) => (changedFields[fieldName] = data[fieldName]));

    return Object.keys(changedFields).length > 0 ? changedFields : null;
  }
}

export const extractParamValueFromFormField = ({ fieldVariant, fieldName, fieldValue }) => {
  switch (fieldVariant) {
    case FIELD_VARIANTS.AUTOCOMPLETE_SINGLE:
      return fieldValue?.value || FIELD_DEFAULT_VALUES[fieldName];
    case FIELD_VARIANTS.DATE_PICKER:
      return fieldValue || FIELD_DEFAULT_VALUES[fieldName];
    default:
      return null;
  }
};

export { snackbar };
