import { axios } from '../../../services/axios';
import { notificationsActions } from '.';
import { snackbar } from '../../../utils';
import { createNotification } from './notifications.create';
import { Notification } from '../../../models';
import { NotificationStatus } from '../../../enums';

const { sendNotificationRequestLoading, sendNotificationRequestSuccess } = notificationsActions;

export const sendNotification = (createdNotification) => {
  return (dispatch) => {
    dispatch(sendNotificationRequestLoading(true));

    return axios
      .post(`/device-notifications/send`, { notification_id: createdNotification.id })
      .then(() => {
        dispatch(sendNotificationRequestSuccess({ sentNotification: createdNotification }));
        snackbar.success('Notification has been sent successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(sendNotificationRequestLoading(false)));
  };
};

export const createThenSendNotification = (createNotificationRequestPayload) => {
  return (dispatch) => {
    dispatch(sendNotificationRequestLoading(true));

    return dispatch(createNotification({ createNotificationRequestPayload })).then((res) => {
      const notification = new Notification({ ...res.data?.notification, status: NotificationStatus.SENT });

      return dispatch(sendNotification(notification)).then(() => {
        snackbar.success('Notification has been sent successfully! 🎉');
      });
    });
  };
};
