import { axios } from '../../../services/axios';

import { clientsActions } from '.';
import { snackbar } from '../../../utils';

const { resetClientPasswordRequestLoading } = clientsActions;

export const resetClientPassword = (clientId, clientEmail) => {
  return async (dispatch) => {
    dispatch(resetClientPasswordRequestLoading(true));

    return axios
      .post('/clients/reset-password', { client_id: clientId })
      .then(() => {
        snackbar.success(`Password reset mail has been sent to [${clientEmail}] successfully 🎉`);
      })
      .finally(() => dispatch(resetClientPasswordRequestLoading(false)));
  };
};
