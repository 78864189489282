import { memo } from 'react';
import TopBookItem from './CategoryTopBooks.item';
import { componentPropsAreEqual } from '../../../../utils';
import EmptyList from '../../../../components/EmptyList/EmptyList';
import OutlinedBookIcon from '../../../../assets/images/icons/OutlinedBook';

const TopBooksList = ({ topBooks, booksOptionsList }) => {
  const filteredBooksOptionsList = booksOptionsList.filter((option) => {
    const isBookNotListedInTopBooksList = topBooks.findIndex((topBook) => option.id === topBook.bookId) === -1;

    return isBookNotListedInTopBooksList ? option : null;
  });

  return topBooks?.length === 0 ? (
    <EmptyList
      title='No top books to show'
      icon={<OutlinedBookIcon />}
      overview='Start adding some by clicking on Add Book button.'
    />
  ) : (
    topBooks.map((topBook, i) => {
      const bookOption = booksOptionsList.find((option) => option.value === topBook.bookId) || null;

      return (
        <TopBookItem
          key={topBook.id}
          bookIndex={i + 1}
          bookId={topBook.bookId}
          categoryTopBookId={topBook.id}
          bookOption={bookOption}
          booksOptionsList={filteredBooksOptionsList}
        />
      );
    })
  );
};

export default memo(TopBooksList, componentPropsAreEqual);
