import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  isFetchingBookOfTheDayList: false,
  bookOfTheDay: { list: [], add: {} },
};

const bookOfTheDaySlice = createSlice({
  name: 'bookOfTheDay',
  initialState,
  reducers: {
    fetchBookOfTheDayListRequestLoading: (state, { payload }) => {
      state.isFetchingBookOfTheDayList = payload;
    },
    fetchBookOfTheDayListRequestSuccess: (state, { payload }) => {
      state.bookOfTheDay.list = payload;
    },
    addBookOfTheDayRequestLoading: (state, { payload }) => {
      const { bookId, date, isAddingBookOfTheDay = false } = payload;

      state.bookOfTheDay.add[`${bookId}_${date}`] = {
        ...state.bookOfTheDay.add[`${bookId}_${date}`],
        isAddingBookOfTheDay: isAddingBookOfTheDay,
      };
    },
    addBookOfTheDayRequestSuccess: (state, { payload }) => {
      const { bookOfTheDay } = payload;
      state.bookOfTheDay.list.push(bookOfTheDay);
    },
  },
});

export const bookOfTheDayActions = bookOfTheDaySlice.actions;
export const bookOfTheDaySelector = (state) => state.bookOfTheDay;
export default bookOfTheDaySlice.reducer;
