import { useFilters } from './FiltersPopup.usefilters';
import { FilterButton } from '../FilterButton/FilterButton';
import { bindPopper, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { Checkbox, Popper } from '../index';
import Box from '@mui/material/Box';
import { Button, Collapse } from '@mui/material';
import { getStyles } from './FiltersPopup.styles';

export const FiltersPopup = ({ filtersConfig, setFiltersFormDefaultValues, onSubmit, onClear }) => {
  const popupState = usePopupState({ variant: 'popper', popupId: 'notificationFiltersPopper' });
  const { form, checkboxesForm, activeFiltersCount, clearFilters, submitFilters, defaultValues } = useFilters({
    filtersConfig,
    setFiltersFormDefaultValues,
    popupState,
    onSubmit,
    onClear,
  });
  const styles = getStyles();

  const handleClosePopper = () => {
    const isConfirmed = () => confirm('You have unsaved changes on some filters, are you sure you want to leave?');

    if (!form.formState.isDirty) popupState.close();
    if (form.formState.isDirty && isConfirmed()) popupState.close();
  };

  return (
    <>
      <FilterButton {...bindTrigger(popupState)} activeFiltersCount={activeFiltersCount} />
      <Popper placement='bottom-start' {...bindPopper(popupState)} closePopper={handleClosePopper}>
        <Box sx={styles.popperInnerWrapper}>
          <Box sx={styles.filtersHeader}>
            <Button size='small' color='secondary' variant='outlined' onClick={clearFilters}>
              Clear
            </Button>
            <b>Filters</b>
            <Button
              size='small'
              variant='contained'
              onClick={form.handleSubmit(submitFilters)}
              disabled={!form.formState.isDirty}
            >
              Done
            </Button>
          </Box>
          <Box>
            {filtersConfig?.map(({ name, label, field }) => {
              const checkboxName = name;
              const checkboxFieldValue = checkboxesForm.watch(checkboxName);
              const styles = getStyles({ checkboxFieldValue });
              const resetFieldValue = () => form.setValue(name, defaultValues[name], { shouldDirty: true });

              return (
                <Box key={name} sx={styles.filterOuterWrapper}>
                  <Checkbox
                    form={checkboxesForm}
                    name={checkboxName}
                    label={label}
                    plain
                    labelSx={styles.checkboxLabelSx}
                    onChange={resetFieldValue}
                  />
                  <Collapse in={Boolean(checkboxFieldValue)}>
                    <Box sx={styles.filterContentWrapper}>
                      {field?.type ? <field.type {...field.props} form={form} name={name} id={name} /> : null}
                    </Box>
                  </Collapse>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Popper>
    </>
  );
};
