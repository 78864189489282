import { memo, useCallback, useEffect } from 'react';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../components';
import AddIcon from '../../../../assets/images/icons/Add';
import { useModal } from '../../../../hooks';
import { formOptions } from '../../forms/AddEditCategoryForm/AddEditCategoryForm.form';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory } from '../../../../redux/slices/categories/categories.create';
import { categoriesSelector } from '../../../../redux/slices/categories';
import { componentPropsAreEqual } from '../../../../utils';
import AddEditCategoryForm from '../../forms/AddEditCategoryForm/AddEditCategoryForm';
import { storageActions } from '../../../../redux/slices/storage';

const AddCategoryModal = ({
  initialValues,
  categoryInitialInputValue,
  resetCategoryInitialInputValue = () => null,
  hideTrigger,
}) => {
  const { modalIsOpen, openModal, closeModal } = useModal();
  const form = useForm(formOptions);
  const { handleSubmit } = form;
  const { isCreatingCategory } = useSelector(categoriesSelector);
  const dispatch = useDispatch();

  const resetFormState = useCallback(() => {
    dispatch(storageActions.uploadFileReset({ fieldName: 'cover' }));
    dispatch(storageActions.uploadFileReset({ fieldName: 'icon' }));
  }, [dispatch]);

  // Set Initial Values
  useEffect(() => {
    if (initialValues) Object.keys(initialValues).forEach((value) => form.setValue(value, initialValues[value]));
  }, [initialValues, form]);

  // Handle AddCategoryModal open/close state as nested modal
  useEffect(() => {
    if (categoryInitialInputValue) openModal();
    else closeModal();
  }, [categoryInitialInputValue, openModal, closeModal]);

  useEffect(() => {
    return () => resetFormState();
  }, [resetFormState]);

  function submitAddCategoryModal(data) {
    const { name, status, brandingColor, cover, icon } = data;
    const requestPayload = {
      name,
      status: status.value.toUpperCase(),
      cover,
      ...(icon && { icon }),
      ...(brandingColor && { branding_color: brandingColor }),
    };

    dispatch(createCategory(requestPayload)).then(() => closeModal());
  }

  return (
    <>
      {hideTrigger ? null : (
        <Button variant='contained' startIcon={<AddIcon />} onClick={openModal}>
          Add Category
        </Button>
      )}

      <Modal
        title='Add Category'
        open={modalIsOpen}
        onSubmit={handleSubmit(submitAddCategoryModal)}
        submitButtonText='Add'
        submitButtonIsLoading={isCreatingCategory}
        closeModal={() => {
          resetCategoryInitialInputValue();
          closeModal();
        }}
        maxWidth='490px'
      >
        <AddEditCategoryForm form={form} />
      </Modal>
    </>
  );
};

export default memo(AddCategoryModal, componentPropsAreEqual);
