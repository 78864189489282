import { makeStyles } from '@mui/styles';
import LoginBackGround from '../../assets/images/loginBackground.png';

export default makeStyles((theme) => ({
  loginPage: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '100%',
    position: 'fixed',
    overflow: 'hidden',
    backgroundImage: `url(${LoginBackGround})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '40% 100%',
    backgroundPosition: 'left',
    backgroundAttachment: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
  },
  loginPage__inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  loginFormWrapper: { width: 410, margin: '0 10px' },
  inputContainer__header: {
    textAlign: 'Left',
    fontSize: 32,
    color: theme.palette.secondary.main,
    marginBottom: 32,
  },
  inputContainer__emailTextFiled: {
    marginBottom: 24,
  },
  inputContainer__passwordTextFiled: {
    marginBottom: 32,
  },
  inputContainer__copyright: {
    fontSize: 14,
    color: theme.palette.secondary.light,
    position: 'absolute',
    bottom: 48,
  },
  loginPage__image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
}));
