import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';
import Box from '@mui/material/Box';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { styles } from './Autocomplete.styles';

const CreateItemOption = ({ inputValue }) => (
  <Box sx={styles.createItemOptionWrapper}>
    <AddRoundedIcon sx={styles.createItemOptionAddIcon} /> Add &ldquo;<span>{inputValue}</span>
    &rdquo;
  </Box>
);

export default memo(CreateItemOption, componentPropsAreEqual);
