import { useController } from 'react-hook-form';
import { useEffect } from 'react';

export default function useFormController(form = {}, name) {
  const { control, reset } = form;

  const {
    field: { value, onChange },
    formState: { errors },
  } = useController({ name, control });

  const fieldHasError = Boolean(errors[name]);
  const fieldErrorMessage = errors[name]?.message;
  const fieldErrorType = errors[name]?.type;

  // Reset form on unmounting
  useEffect(() => () => reset(), [reset]);

  return { value, onChange, fieldHasError, fieldErrorMessage, fieldErrorType, ...form };
}
