import { alpha } from '@mui/system';

export function getMuiCalendarPickerDesignTokens(theme) {
  return {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          '& > div:first-of-type > *': { fontWeight: 700 },
          '& button': {
            color: 'inherit',
            '&:hover, &:focus': {
              backgroundColor: alpha(theme.palette.secondary[200], 0.6),
            },
            '&:active': { transform: 'scale(.93)' },
          },
          '& .MuiPickersDay-root:not(.Mui-selected)': {
            color: 'inherit',
            '&:hover, &:focus': {
              backgroundColor: alpha(theme.palette.secondary[200], 0.6),
            },
          },
          '& .MuiPickersDay-root.Mui-selected': { fontWeight: 700 },
          '& .MuiPickersDay-root.Mui-disabled': { opacity: 0.5 },
          '& .MuiPickersDay-root.MuiPickersDay-today:not(.Mui-selected)': {
            border: `1px solid ${theme.palette.secondary[400]}`,
          },
        },
      },
    },
  };
}
