import { Box } from '@mui/system';
import { styles } from './TableNoResultFound.styles';
import noResultsIllustration from '../../assets/images/no-result.png';

const TableNoResultFound = () => (
  <Box sx={styles.tableNoResultWrapper}>
    <img width={260} height={300} src={noResultsIllustration} alt='No Results' />
    <p>Sorry, We Couldn&apos;t Find Any Matches To Display!</p>
  </Box>
);

export default TableNoResultFound;
