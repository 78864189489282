import { axios } from '../../../services/axios';

import { adminsActions } from '.';
import { snackbar } from '../../../utils';

const { resetAdminPasswordRequestLoading } = adminsActions;

export const resetAdminPassword = ({ adminId, newPassword }) => {
  return async (dispatch) => {
    dispatch(resetAdminPasswordRequestLoading(true));

    return axios
      .post('/admins/reset-password', { user_id: adminId, password: newPassword })
      .then(() => {
        snackbar.success(`Password has been reset successfully 🎉`);
      })
      .finally(() => dispatch(resetAdminPasswordRequestLoading(false)));
  };
};
