import { alpha, lighten } from '@mui/system';

const addBookButtonHeight = '404px';
const replaceBookButtonHeight = `48px`;
const rowGap = `${29 + 4}px`;

export const getStyles = ({ isUploadedSuccessfully, percentage }) => ({
  outerWrapper: {
    position: 'relative',
    display: 'grid',
    width: '100%',
    gridTemplateColumns: '1fr',
    gridTemplateRows: `${addBookButtonHeight} ${rowGap} ${replaceBookButtonHeight}`,
    height: '485px',
  },
  contentWrapper: {
    position: 'absolute',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: `${addBookButtonHeight}  auto`,
    rowGap,
  },
  uploadStatusWrapper: {
    display: 'grid',
    height: '100%',
    position: 'absolute',
    width: '100%',
    borderRadius: 'inherit',
  },
  previewImg: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'transform 300ms ease',
    border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    boxShadow: (theme) => `inset 1px 1px 5px ${alpha(theme.palette.secondary.main, 0.6)}`,
    borderRadius: 'inherit',
    bgcolor: 'secondary.100',

    '&:before': {
      content: "'Image Not Found!'",
      position: 'absolute',
      top: '-1px',
      left: '-1px',
      height: 'calc(100% + 2px)',
      width: 'calc(100% + 2px)',
      display: 'grid',
      placeItems: 'center',
      color: 'error.main',
      bgcolor: (theme) => lighten(theme.palette.error.main, 0.95),
      borderRadius: 'inherit',
      border: (theme) => `1px solid ${alpha(theme.palette.error.main, 0.12)}`,
      zIndex: 15,
      pointerEvents: 'none',
    },
  },
  progressBar: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    transform: `translateX(${Number(isUploadedSuccessfully ? 0 : percentage)}%)`,
    transition: 'transform 200ms ease',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.6),
    opacity: isUploadedSuccessfully ? 0 : 1,
  },
  progressDetails: {
    backgroundColor: (theme) => lighten(theme.palette.secondary[200], 0.1),
    color: 'secondary.main',
    position: 'absolute',
    bottom: '7px',
    left: '7px',
    width: 'calc(100% - 14px)',
    height: '40px',
    fontWeight: '600',
    display: 'grid',
    placeItems: 'center',
    fontSize: '12px',
    paddingTop: '8px',
    borderRadius: '6px',
    zIndex: 1,
  },
  progressPercentage: {
    color: '#fff',
    backgroundColor: (theme) => theme.palette.primary.main,
    position: 'absolute',
    top: '0',
    transform: 'translateY(-50%)',
    borderRadius: '22px',
    display: 'grid',
    placeItems: 'center',
    fontSize: '10px',
    padding: '4px 10px',
    boxShadow: '0 3px 5px rgba(0, 0, 0, .2)',
  },
  fileUploadButton: {
    flexDirection: 'column',
    '& svg': { mb: '14px' },
  },
  replaceBookCoverButton: { alignSelf: 'end' },
  deleteFileButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 11,
  },
});
