export default function FavBookIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <g>
        <path
          d='M12.612 12.202l1.227.9-.466 1.438a1.113 1.113 0 001.718 1.239l1.2-.883 1.2.882a1.112 1.112 0 001.718-1.238l-.466-1.438 1.227-.9a1.112 1.112 0 00-.655-2.01h-1.507l-.459-1.427a1.112 1.112 0 00-2.119 0l-.456 1.426h-1.506a1.112 1.112 0 00-.655 2.011z'
          fill='currentColor'
        />
        <path
          d='M24.01 3H11.146A5.173 5.173 0 006 8.2v15.6a5.173 5.173 0 005.146 5.2H24.01a2.587 2.587 0 002.573-2.6V5.6A2.587 2.587 0 0024.01 3zM8.573 8.2a2.587 2.587 0 012.573-2.6H24.01v13H11.146a4.991 4.991 0 00-2.573.728zm2.573 18.2a2.6 2.6 0 010-5.2H24.01v5.2z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
}
