import Box from '@mui/material/Box';
import { getStyles } from './AppBar.styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/styles';
import MuiAppBar from '@mui/material/AppBar';
import { pages } from '../../../../../nav-config';
import { NavLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import UserSettingTab from '../UserSettingTab/UserSettingTab';

const AppBar = ({ isDrawerOpened, label }) => {
  const theme = useTheme();
  const styles = getStyles({ theme, isDrawerOpened });

  const labelChildren = pages.find((page) => page.title === label)?.children || [];
  const labelHasChildren = labelChildren?.length > 0;

  const labelNavItems = labelChildren.map((navItem) => (
    <Box key={navItem.pathname} sx={styles.navItemWrapper}>
      <NavLink style={{ all: 'unset' }} to={navItem.pathname}>
        <Button key={navItem.title} startIcon={navItem.icon} size='large' sx={styles.navItemButtonRoot}>
          {navItem.title}
        </Button>
      </NavLink>
    </Box>
  ));

  return (
    <MuiAppBar sx={styles.appBarRoot}>
      <Toolbar sx={styles.toolbarRoot}>
        <Typography variant='h6' noWrap sx={styles.labelHeading}>
          {label}
        </Typography>
        <Box sx={styles.labelNavWrapper}>{labelHasChildren ? labelNavItems : null}</Box>
        <UserSettingTab />
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
