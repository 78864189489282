import { memo } from 'react';
import { Box } from '@mui/material';
import { componentPropsAreEqual } from '../../../../utils';
import { styles } from './NotificationAnalytics.styles';
import LinearProgress from '@mui/material/LinearProgress';

const NotificationAnalyticsItem = ({ title, count, icon, isLoading }) => {
  return (
    <Box sx={styles.item.outerWrapper}>
      <Box sx={styles.item.detailsWrapper}>
        <p>{title}</p>
        <h2>
          <Box sx={styles.item.dataSkeletonWrapper}>
            {isLoading ? (
              <>
                <LinearProgress color='secondary' sx={styles.item.dataSkeleton} />
                <Box sx={styles.item.dataSkeletonPlaceholder}>0</Box>
              </>
            ) : (
              count
            )}
            <span>/{count === 1 ? 'User' : 'Users'}</span>
          </Box>
        </h2>
      </Box>
      <Box>{icon ? icon : null}</Box>
    </Box>
  );
};

export default memo(NotificationAnalyticsItem, componentPropsAreEqual);
