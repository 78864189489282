import { Box, FormLabel } from '@mui/material';
import { CopyTextButton, TextField } from '..';
import { styles } from './ShareLinkInput.styles';

export const ShareLinkInput = ({ label, linkText, loading }) => {
  const fallbackText = '---';

  return (
    <Box>
      {label ? <FormLabel>{label}</FormLabel> : null}
      <Box sx={styles.shareLinkInputWrapper}>
        <TextField
          id='bookShareLink'
          inputProps={{ readOnly: true, disabled: true }}
          defaultValue={linkText || fallbackText}
          sx={styles.shareLinkInput}
        />

        {loading ? <Box sx={styles.copyTextButtonLoading}>Loading...</Box> : null}
        <Box sx={styles.copyTextButtonOuterWrapper}>
          <CopyTextButton text={linkText || fallbackText} />
        </Box>
      </Box>
    </Box>
  );
};
