import { axios } from '../../../services/axios';
import { Notification } from '../../../models';
import { notificationsActions } from '.';
import { snackbar } from '../../../utils';
import { store } from '../../store';

const { updateNotificationRequestLoading, updateNotificationRequestSuccess } = notificationsActions;

export const updateNotification = ({ notificationId, payload }) => {
  return (dispatch) => {
    dispatch(updateNotificationRequestLoading(true));

    return axios
      .patch(`/notifications/${notificationId}`, payload)
      .then((res) => {
        const currentStoredNotification = store.getState().notifications.notification;
        const notification = new Notification({
          ...currentStoredNotification,
          ...res.data?.notification,
        });

        dispatch(updateNotificationRequestSuccess({ updatedNotification: notification })); // Update notifications list after new notification has been updated
        snackbar.success('Notification has been updated successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(updateNotificationRequestLoading(false)));
  };
};
