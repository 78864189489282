import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { FormLabel, TextField as MuiTextField } from '@mui/material';
import { memo, useState } from 'react';
import { FormController, ValidationMessage } from '..';
import { styles } from './TextField.styles';
import { CircularProgress } from '../../components';
import * as React from 'react';
import { componentPropsAreEqual } from '../../utils';

export const TextField = memo(({ form = {}, name, id, label, loading, resize, inputProps, ...props }) => {
  const [{ value = '', onChange, fieldHasError, fieldErrorMessage }, setFormControllerValues] = useState({});

  return (
    <>
      {form && name ? (
        <FormController form={form} name={name} setFormControllerValues={setFormControllerValues} />
      ) : null}
      <Box sx={styles.textFieldWrapper}>
        {label ? <FormLabel {...(id ? { htmlFor: id } : {})}>{label}</FormLabel> : null}
        <Box sx={styles.textFieldInputWrapper}>
          <MuiTextField
            id={id}
            {...(form && name && { value, onChange })}
            error={fieldHasError}
            size='small'
            hiddenLabel
            autoComplete='off'
            fullWidth
            disabled={loading}
            {...props}
            inputProps={{ dir: 'auto', sx: { ...(resize && { resize }) }, ...inputProps }}
          />
          {loading ? (
            <Box sx={styles.textFieldLoadingIndicatorWrapper}>
              <CircularProgress size={17} sx={styles.textFieldLoadingIndicator} />
            </Box>
          ) : null}
        </Box>
        <ValidationMessage message={fieldErrorMessage} />
      </Box>
    </>
  );
}, componentPropsAreEqual);

TextField.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

TextField.displayName = 'TextField';
