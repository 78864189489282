import { getCssBaselineTokens } from './CssBaseline';
import { getInputDesignTokens } from './OutlinedInput';
import { getFormLabelDesignTokens } from './FormLabel';
import { getFormHelperTextDesignTokens } from './FormHelperText';
import { getMenuDesignTokens } from './Menu';
import { getModalDesignTokens } from './Modal';
import { getButtonDesignTokens } from './Button';
import { getPaperDesignTokens } from './Paper';
import { getTableDesignTokens } from './Table';
import { getChipDesignTokens } from './Chip';
import { getMuiAutocompleteDesignTokens } from './Autocomplete';
import { getMuiCalendarPickerDesignTokens } from './CalendarPicker';
import { getSelectDesignTokens } from './Select';
import { getMuiCollapseDesignTokens } from './Collapse';

export function getComponentsTokens(theme) {
  return {
    components: {
      ...getCssBaselineTokens(theme),
      ...getButtonDesignTokens(theme),
      ...getInputDesignTokens(theme),
      ...getFormLabelDesignTokens(theme),
      ...getFormHelperTextDesignTokens(theme),
      ...getMenuDesignTokens(theme),
      ...getModalDesignTokens(theme),
      ...getPaperDesignTokens(theme),
      ...getTableDesignTokens(theme),
      ...getChipDesignTokens(theme),
      ...getMuiAutocompleteDesignTokens(theme),
      ...getMuiCalendarPickerDesignTokens(theme),
      ...getSelectDesignTokens(theme),
      ...getMuiCollapseDesignTokens(theme),
    },
  };
}
