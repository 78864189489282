import { lighten } from '@mui/system';

export function getTableDesignTokens(theme) {
  return {
    MuiSnackbarContent: {
      styleOverrides: {
        root: { color: 'red' },
        backgroundColor: 'red',
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&.MuiTableRow-hover:hover': { backgroundColor: lighten(theme.palette.secondary[100], 0.55) },
          '&:last-of-type .MuiTableCell-body': {
            border: 'initial',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: theme.palette.secondary.main,
          padding: '17.5px 25px',
          borderBottom: `1px solid ${lighten(theme.palette.secondary[200], 0.41)}`,
          fontSize: '14px',
          '&.MuiTableCell-head': {
            borderBottom: `1px solid ${theme.palette.secondary[200]}`,
            backgroundColor: theme.palette.secondary[100],
            padding: '11.5px 25px',
            fontSize: '13px',
            fontWeight: 700,
            textTransform: 'uppercase',
          },
        },
      },
    },
  };
}
