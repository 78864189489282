import { Box, Stack } from '@mui/material';
import { ListCount, Section, TableSearchbar, Table } from '../../components';
import AddEditBookModal from './modals/AddBookModal/AddBookModal';
import { memo, useEffect } from 'react';
import { componentPropsAreEqual } from '../../utils';
import useBooksTable from './Books.table';
import { fetchAuthors } from '../../redux/slices/authors/authors.read';
import { fetchCategories } from '../../redux/slices/categories/categories.read';
import { useDispatch, useSelector } from 'react-redux';
import { authorsSelector } from '../../redux/slices/authors';
import { categoriesSelector } from '../../redux/slices/categories';

const Books = () => {
  const { authors } = useSelector(authorsSelector);
  const { categories } = useSelector(categoriesSelector);
  const dispatch = useDispatch();

  const {
    booksTableColumns,
    booksTableRows,
    isFetchingBooks,
    booksTableRowsDraft,
    setBooksTableRows,
    searchCompareFunction,
  } = useBooksTable();

  // Fetch Authors and Categories lists one-off time to access them in create book modal and edit book page
  useEffect(() => {
    if (authors === null) dispatch(fetchAuthors());
    if (categories === null) dispatch(fetchCategories());
  }, [authors, categories, dispatch]);

  return (
    <Section
      contentPadding={0}
      sectionHeader={
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <TableSearchbar
            rowsDraft={booksTableRowsDraft}
            setRows={setBooksTableRows}
            compareFunction={searchCompareFunction}
            placeholder='Search Books..'
          />
          <Stack flexShrink={0} direction='row' spacing='20px' alignItems='center'>
            <ListCount count={booksTableRows?.length} sx={{ height: '48px' }} />
            <AddEditBookModal />
          </Stack>
        </Box>
      }
    >
      <Table columns={booksTableColumns} rows={booksTableRows} isLoading={isFetchingBooks} />
    </Section>
  );
};

export default memo(Books, componentPropsAreEqual);
