import { axios } from '../../../services/axios';
import Url from 'url-parse';
import axiosLib from 'axios';
import { bytesToSize, snackbar } from '../../../utils';
import { storageActions } from '.';

export const uploadFile = (file, fieldName) => {
  return async (dispatch) => {
    dispatch(storageActions.uploadFileReset({ fieldName }));

    return axios.post('storage/create-upload-link', { file_name: encodeURI(file.name) }).then((res) => {
      dispatch(storageActions.uploadFileStart({ fieldName }));

      const parsedUploadLink = new Url(res?.data?.upload_link);
      const uploadedFileLink = `${parsedUploadLink.origin}${parsedUploadLink.pathname}`;
      let progressDetails = {};

      return axiosLib
        .put(parsedUploadLink.href, file, {
          headers: { 'Content-Type': file.type },
          onUploadProgress: ({ loaded, total }) => {
            const percentage = Math.floor((loaded / total) * 100);

            progressDetails = {
              loaded: bytesToSize(loaded),
              total: bytesToSize(total),
              percentage,
              isUploading: percentage !== 100,
              isUploadedSuccessfully: percentage === 100,
            };

            dispatch(storageActions.uploadFileProgress({ fieldName, ...progressDetails }));
          },
        })
        .then(() => {
          dispatch(storageActions.uploadFileSuccess({ fieldName, uploadedFileLink }));
        })
        .catch(() => snackbar.error('Uh-oh! Something went wrong!'));
    });
  };
};
