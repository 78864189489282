export default function ArrowsExchangeIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={19.996} height={14.01} {...props}>
      <path
        d='M19.708 4.294l-4-4a1.004 1.004 0 00-1.42 1.42l2.3 2.29H4.998a1 1 0 000 2h14a1.006 1.006 0 00.71-1.71zm-4.71 3.71h-14a1.006 1.006 0 00-.71 1.71l4 4a1.004 1.004 0 101.42-1.42l-2.3-2.29h11.59a1 1 0 000-2z'
        fill='currentColor'
      />
    </svg>
  );
}
