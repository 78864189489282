export default function TrashIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={18} height={20} {...props}>
      <path
        d='M7 16a1 1 0 001-1V9a1 1 0 00-2 0v6a1 1 0 001 1zM17 4h-4V3a3 3 0 00-3-3H8a3 3 0 00-3 3v1H1a1 1 0 000 2h1v11a3 3 0 003 3h8a3 3 0 003-3V6h1a1 1 0 000-2zM7 3a1 1 0 011-1h2a1 1 0 011 1v1H7zm7 14a1 1 0 01-1 1H5a1 1 0 01-1-1V6h10zm-3-1a1 1 0 001-1V9a1 1 0 00-2 0v6a1 1 0 001 1z'
        fill='currentColor'
      />
    </svg>
  );
}
