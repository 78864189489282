import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Category name is required'),
  status: Yup.mixed().required('Category status is required'),
});

export const formOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    name: '',
    status: null,
  },
};
