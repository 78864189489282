import { Section, Table, ListCount } from '../../../../../components';
import useClientPreviousSubscriptionsTable from './ClientPreviousSubscriptions.table';

const ClientPreviousSubscriptions = ({ clientId }) => {
  const {
    previousSubscriptionsTotalCount,
    previousSubscriptionsTableColumns,
    previousSubscriptionsTableRows,
    isFetchingClientPreviousSubscriptions,
    fetchPageData,
  } = useClientPreviousSubscriptionsTable({ clientId });

  return (
    <Section
      outerHeader
      title='Previous Subscription'
      toolbar={<ListCount count={previousSubscriptionsTotalCount} />}
      sx={{ height: '100%' }}
      contentPadding={0}
    >
      <Table
        hasPaginator={true}
        columns={previousSubscriptionsTableColumns}
        rows={previousSubscriptionsTableRows}
        isLoading={isFetchingClientPreviousSubscriptions}
        dataTotalCount={previousSubscriptionsTotalCount}
        fetchPageData={fetchPageData}
        containerSx={{
          maxHeight: {
            xs: '600px',
            lg: 'calc(100vh - 80px - 64px - 59px)',
          },
        }}
      />
    </Section>
  );
};

export default ClientPreviousSubscriptions;
