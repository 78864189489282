import { memo } from 'react';
import { LoadingButton } from '@mui/lab';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { useModal } from '../../../../hooks';
import { Modal } from '../../../../components';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '../../../../assets/images/icons/Add';
import { componentPropsAreEqual } from '../../../../utils';
import { notificationsSelector } from '../../../../redux/slices/notifications';
import { createNotification } from '../../../../redux/slices/notifications/notifications.create';
import { createThenSendNotification } from '../../../../redux/slices/notifications/notifications.send';
import {
  CreateUpdateNotificationForm,
  createUpdateNotificationFormOptions,
} from '../../forms/CreateUpdateNotificationForm/CreateUpdateNotificationForm';

const CreateNotificationModal = () => {
  const { modalIsOpen, openModal, closeModal } = useModal();
  const form = useForm(createUpdateNotificationFormOptions);
  const { isSendingNotification, isCreatingNotification } = useSelector(notificationsSelector);
  const dispatch = useDispatch();

  function getCreateNotificationRequestPayload(data) {
    const { title, content, releaseAt } = data;

    return {
      title,
      content,
      ...(releaseAt && { release_at: releaseAt }),
    };
  }

  function handleSendNotification(data) {
    const payload = getCreateNotificationRequestPayload(data);
    dispatch(createThenSendNotification(payload)).then(() => closeModal());
  }

  function handleCreateNotification(data) {
    const payload = getCreateNotificationRequestPayload(data);
    dispatch(createNotification({ createNotificationRequestPayload: payload, updateNotificationList: true })).then(() =>
      closeModal(),
    );
  }

  return (
    <>
      <Button variant='contained' startIcon={<AddIcon />} onClick={openModal}>
        Create Notification
      </Button>

      <Modal
        title='Create Notification'
        open={modalIsOpen}
        onSubmit={form.handleSubmit(handleSendNotification)}
        submitButtonText='Send Now'
        submitButtonIsLoading={isSendingNotification}
        submitButtonIsDisabled={isCreatingNotification}
        closeModal={closeModal}
        maxWidth={804}
        additionalActionButtons={
          <LoadingButton
            variant='outlined'
            onClick={form.handleSubmit(handleCreateNotification)}
            loading={isCreatingNotification}
            disabled={isSendingNotification}
          >
            Save as Draft
          </LoadingButton>
        }
      >
        <CreateUpdateNotificationForm form={form} />
      </Modal>
    </>
  );
};

export default memo(CreateNotificationModal, componentPropsAreEqual);
