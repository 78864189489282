import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import { Modal } from '../../../../components';
import { useModal } from '../../../../hooks';
import { TextField } from '../../../../components';
import Stack from '@mui/material/Stack';
import { formOptions } from './ResetAdminPasswordModal.form';
import { useDispatch, useSelector } from 'react-redux';
import { memo } from 'react';
import { adminsSelector } from '../../../../redux/slices/admins';
import { componentPropsAreEqual } from '../../../../utils';
import { resetAdminPassword } from '../../../../redux/slices/admins/admins.reset-password';
import { ADMIN_ROLES } from '../../../../constants/roles';
import { authSelector } from '../../../../redux/slices/auth';

const ResetAdminPasswordModal = ({ adminId }) => {
  const { modalIsOpen, openModal, closeModal } = useModal();
  const form = useForm(formOptions);
  const { handleSubmit } = form;
  const { isResettingAdminPassword } = useSelector(adminsSelector);
  const { user } = useSelector(authSelector);

  const dispatch = useDispatch();

  function submitResetAdminPasswordModal(data) {
    const { password } = data;

    dispatch(resetAdminPassword({ adminId, newPassword: password })).then(() => closeModal());
  }

  return (
    <>
      {user?.role?.toUpperCase?.() === ADMIN_ROLES.SUPER_ADMIN ? (
        <Button size='small' onClick={openModal}>
          Reset Password
        </Button>
      ) : null}

      <Modal
        title='Reset Admin Password'
        open={modalIsOpen}
        onSubmit={handleSubmit(submitResetAdminPasswordModal)}
        submitButtonText='Reset'
        submitButtonIsLoading={isResettingAdminPassword}
        closeModal={closeModal}
      >
        <Stack spacing='26px'>
          <TextField
            form={form}
            type='password'
            name='password'
            id='adminPassword'
            label='New Password'
            placeholder='Enter a new password'
          />{' '}
          <TextField
            form={form}
            type='password'
            name='confirmPassword'
            id='adminConfirmPassword'
            label='Confirm Password'
            placeholder='Enter a password'
          />
        </Stack>
      </Modal>
    </>
  );
};

export default memo(ResetAdminPasswordModal, componentPropsAreEqual);
