export default function GoogleIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' data-name='google logo' width={15.499} height={15.499} {...props}>
      <path
        data-name='Path 1'
        d='m3.449 9.373-.542 2.023-1.98.042A7.8 7.8 0 0 1 .87 4.171l1.763.323.772 1.752a4.645 4.645 0 0 0 .044 3.127Z'
        fill='#fbbb00'
      />
      <path
        data-name='Path 2'
        d='M15.36 6.263a7.779 7.779 0 0 1-2.774 7.523l-2.221-.113-.314-1.962a4.638 4.638 0 0 0 2-2.368H7.89V6.264h7.47Z'
        fill='#518ef8'
      />
      <path
        data-name='Path 3'
        d='M12.654 13.785A7.785 7.785 0 0 1 .927 11.404L3.449 9.34a4.628 4.628 0 0 0 6.67 2.37Z'
        fill='#28b446'
      />
      <path
        data-name='Path 4'
        d='m12.746 1.792-2.517 2.064a4.628 4.628 0 0 0-6.823 2.423L.87 4.2a7.784 7.784 0 0 1 11.876-2.408Z'
        fill='#f14336'
      />
    </svg>
  );
}
