import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { FormController, Popper } from '..';
import { bindPopper, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { SketchPicker } from 'react-color';
import Box from '@mui/material/Box';
import { getStyles } from './ColorPicker.styles';
import { FormLabel } from '@mui/material';
import ColorBrushIcon from '../../assets/images/icons/ColorBrush';
import { componentPropsAreEqual } from '../../utils';
import EditIcon from '../../assets/images/icons/Edit';
import './ColorPicker.sketch-styles.css';

function ColorPicker({ form = {}, name, label, defaultValue, ...props }) {
  const [{ value = '', onChange }, setFormControllerValues] = useState({});
  const popupState = usePopupState({
    variant: 'popper',
    popupId: 'colorPickerPopper',
  });
  const styles = getStyles({ selectedColor: value });

  const handleOnChange = (color) => {
    onChange(color.hex);
  };

  useEffect(() => {
    if (defaultValue) form.setValue(name, defaultValue);
  }, [form, defaultValue, name]);

  return (
    <>
      {form && name ? (
        <FormController form={form} name={name} setFormControllerValues={setFormControllerValues} />
      ) : null}
      <div>
        <FormLabel>{label}</FormLabel>
        <Box sx={styles.previewButton} {...bindTrigger(popupState)}>
          {value === '' ? (
            <ColorBrushIcon width='48px' height='48px' />
          ) : (
            <Box sx={styles.editIcon}>
              <EditIcon />
            </Box>
          )}
        </Box>
        <Popper placement='bottom-start' {...bindPopper(popupState)} closePopper={popupState.close}>
          <SketchPicker color={value} onChange={handleOnChange} {...props} disableAlpha />
        </Popper>
      </div>
    </>
  );
}

ColorPicker.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default memo(ColorPicker, componentPropsAreEqual);
