export const styles = {
  itemsWrapper: { display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))', gridGap: '25px' },
  item: {
    outerWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
      borderRadius: '8px',
      p: '25px',
    },
    detailsWrapper: {
      '& p': { mb: '16px', color: 'secondary.400', fontSize: '14px' },
      '& h2': {
        mb: 0,
        fontSize: '32px',
        '& span': { color: 'secondary.400', fontSize: '14px', fontWeight: '500' },
      },
    },
    dataSkeletonWrapper: { display: 'flex', alignItems: 'center', '& span': { ml: '7px' } },
    dataSkeleton: { width: '70px', height: '20px', borderRadius: '4px', opacity: 0.5 },
    dataSkeletonPlaceholder: { opacity: 0, pointerEvents: 'none', width: 0 },
  },
};
