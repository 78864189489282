import { lighten } from '@mui/system';

export const getStyles = (isDrawerOpened) => ({
  drawerHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '21px',
    marginBottom: '18px',
    '& img': { userSelect: 'none' },
    minHeight: { xs: '80px !important' },
  },
  logoWrapper: {
    overflowX: 'hidden',
    marginTop: '-8px',
    transition: 'all 120ms ease',
    transformOrigin: 'left',
    ...(!isDrawerOpened && { transform: 'scale(.8) translateX(-7px)' }),
  },
  drawerToggleButton: {
    color: 'secondary.main',
    border: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    width: '32px',
    height: '32px',
    position: 'absolute',
    right: '0',
    transform: 'translateX(50%)',
    zIndex: '90',
    background: '#fff',
    borderRadius: '50%',
    '&:hover': {
      bgcolor: (theme) => lighten(theme.palette.secondary.main, 0.93),
    },
    '&:active': {
      transform: 'translateX(50%)',
    },
    ...(!isDrawerOpened && { transform: 'translateX(50%) scale(-1)' }),
  },
});
