export default function ReplaceImageIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18.003} {...props}>
      <path
        d='M17.143.003a.857.857 0 00-.857.857 4.286 4.286 0 10.257 6.643.86.86 0 00-1.131-1.294 2.571 2.571 0 11.214-3.643h-1.055a.857.857 0 100 1.714h2.571A.857.857 0 0018 3.423V.851a.857.857 0 00-.857-.848zm-2.572 10.285a.857.857 0 00-.857.857v.334l-1.286-1.269a2.374 2.374 0 00-3.369 0l-.6.6-2.108-2.133a2.391 2.391 0 00-3.369 0L1.714 9.945v-4.8a.857.857 0 01.857-.857h4.286a.857.857 0 100-1.714H2.571A2.571 2.571 0 000 5.145v10.286a2.571 2.571 0 002.571 2.572h10.286a2.571 2.571 0 002.571-2.571v-4.287a.857.857 0 00-.857-.857zm-12 6a.857.857 0 01-.857-.857v-3.06L4.2 9.86a.677.677 0 01.934 0l2.717 2.717 3.72 3.711zm11.143-.857a.857.857 0 01-.154.454l-3.866-3.866.6-.6a.669.669 0 01.943 0l2.477 2.484z'
        fill='currentColor'
      />
    </svg>
  );
}
