export default function CloseIcon(props) {
  return (
    <svg viewBox='0 0 24 24' width={24} height={24} {...props}>
      <path
        fill='currentColor'
        d='M18.3 5.71a.996.996 0 00-1.41 0L12 10.59 7.11 5.7a.996.996 0 00-1.41 0 .996.996 0 000 1.41L10.59 12 5.7 16.89a.996.996 0 101.41 1.41L12 13.41l4.89 4.89a.996.996 0 101.41-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z'
      />
    </svg>
  );
}
