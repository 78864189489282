import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayout from '../ui/layouts/MainLayout';
import { authSelector } from '../redux/slices/auth';

const AppRoute = ({ path, label, title, component: Component, layout: SpecialLayout, ...rest }) => {
  const { isLoggedIn } = useSelector(authSelector);

  const Layout = SpecialLayout ? SpecialLayout : MainLayout;

  return (
    <Route
      path={path}
      {...rest}
      render={({ routeComponentProps }) => (
        <>
          <Helmet>
            <title>{`${title} | SnackBook`}</title>
          </Helmet>
          <Layout {...(label && { label })} title={title}>
            {!isLoggedIn ? (
              <>
                <Redirect to={'/login'} />
                <Component {...routeComponentProps} />
              </>
            ) : path === '/login' && isLoggedIn ? (
              <>
                <Redirect to={'/'} />
                <Component {...routeComponentProps} />
              </>
            ) : (
              <Component {...routeComponentProps} />
            )}
          </Layout>
        </>
      )}
    />
  );
};

AppRoute.propTypes = {
  path: PropTypes.string,
  label: PropTypes.string,
  title: PropTypes.string,
  component: PropTypes.any,
  layout: PropTypes.any,
};

export default AppRoute;
