import { Box, LinearProgress } from '@mui/material';
import { getStyles } from './Section.styles';
export const SectionDataItem = ({ label, value, isLoading = false }) => {
  const styles = getStyles();

  return (
    <Box sx={styles.sectionDataItem}>
      <Box sx={styles.sectionDataItemTitle}>{label}</Box>
      {isLoading ? (
        <LinearProgress
          color='secondary'
          sx={{
            width: 80,
            height: 12,
            borderRadius: '3px',
            display: 'inline-block',
            verticalAlign: 'middle',
            mr: '14px',
            opacity: 0.35,
          }}
        />
      ) : value !== undefined || value !== null ? (
        <Box sx={styles.sectionDataItemContent}>{value}</Box>
      ) : null}{' '}
    </Box>
  );
};
