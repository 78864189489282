import useStyles from './Login.styles';
import LoginImage from '../../assets/images/loginImage.png';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { authSelector } from '../../redux/slices/auth';
import { login } from '../../redux/slices/auth/auth.login';
import LoadingButton from '@mui/lab/LoadingButton';
import { TextField } from '../../components';
import Stack from '@mui/material/Stack';
import { formOptions } from './Login.form';

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isSendingLoginRequest } = useSelector(authSelector);
  const { handleSubmit, ...form } = useForm(formOptions);

  const onSubmit = ({ email, password }) => {
    dispatch(login(email, password));
  };

  return (
    <div className={classes.loginPage}>
      <div className={classes.loginPage__inputContainer}>
        <div className={classes.loginFormWrapper}>
          <h2 className={classes.inputContainer__header}>Login</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing='32px'>
              <Stack spacing='24px'>
                <TextField form={form} name='email' id='userEmail' label='Email*' placeholder='Mail@snackbook.me' />
                <TextField
                  form={form}
                  name='password'
                  id='userPassword'
                  label='Password*'
                  type='password'
                  placeholder='Password'
                />
              </Stack>
              <LoadingButton type='submit' variant='contained' fullWidth loading={isSendingLoginRequest}>
                Login
              </LoadingButton>
            </Stack>
          </form>
        </div>

        <div className={classes.inputContainer__copyright}>
          © 2020-{new Date().getFullYear()}
          <a
            style={{ textDecoration: 'none', color: 'inherit' }}
            href='https://snackbook.me'
            rel='noopener noreferrer'
            target='_blank'
          >
            {' '}
            SnackBook
          </a>
          , LLC. All rights reserved.
        </div>
      </div>
      <img className={classes.loginPage__image} src={LoginImage} alt={'Snackbook login'} />
    </div>
  );
};

export default Login;
