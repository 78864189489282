import { memo } from 'react';
import { LinearProgress, Box } from '@mui/material';
import { componentPropsAreEqual } from '../../utils';
import { FullCalendarDayContent, FullCalendarDayHeader } from '../../components';
import BookOfTheDayItem from './BookOfTheDay.item';
import { getItemStyles } from './BookOfTheDay.item.styles';

const BookOfTheDayItemWrapper = ({ calendarDayObject, booksOptionsList, isFetchingBookOfTheDayList }) => {
  const styles = getItemStyles({ isFetchingBookOfTheDayList, calendarDayObject });

  return (
    <Box
      sx={{
        ...styles.item.bookOfTheDayDayItemWrapper,
        ...(calendarDayObject.isCurrentMonth
          ? styles.item.bookOfTheDayDayItemOfCurrentMonthWrapper
          : styles.item.bookOfTheDayDayItemOfPrevAndNextMonthWrapper),
      }}
    >
      {calendarDayObject.isCurrentMonth ? (
        <>
          <FullCalendarDayHeader calendarDayObject={calendarDayObject} />
          <FullCalendarDayContent>
            {isFetchingBookOfTheDayList ? (
              <Box sx={styles.item.bookOfTheDayPreviewBookDetailsWrapperSkeleton}>
                <LinearProgress color='secondary' sx={styles.item.bookOfTheDayPreviewBookCoverSkeleton} />
                <LinearProgress color='secondary' sx={styles.item.bookOfTheDayPreviewBookNameSkeleton} />
                <LinearProgress color='secondary' sx={styles.item.bookOfTheDayPreviewBookAuthorNameSkeleton} />
              </Box>
            ) : (
              <BookOfTheDayItem
                booksOptionsList={booksOptionsList}
                date={calendarDayObject.dateIsoString}
                initialBook={calendarDayObject.dayData?.book}
                bookOfTheDayId={calendarDayObject.dayData?.id}
              />
            )}
          </FullCalendarDayContent>
        </>
      ) : null}
    </Box>
  );
};

export default memo(BookOfTheDayItemWrapper, componentPropsAreEqual);
