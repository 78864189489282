import moment from 'moment';
import { Payment } from './payment';

export class Subscription {
  constructor(data) {
    this.id = data?.id || null;
    this.startDate = data?.start_date ? moment(data?.start_date).format('DD MMM YYYY') : null;
    this.endDate = data?.end_date ? moment(data?.end_date).format('DD MMM YYYY') : null;
    this.autoRenewal = data?.auto_renewal === true ? 'Yes' : data?.auto_renewal === false ? 'No' : null;
    this.payment = data?.payment ? new Payment(data?.payment) : null;
  }
}
