export const styles = {
  notFoundGridWrapper: {
    minHeight: '100vh',
    display: 'grid',
    placeItems: 'center',
    placeContent: 'center',
  },
  heroImage: { mb: '50px', maxWidth: '100%', userSelect: 'none' },
  heroHeading: { fontSize: '62px', mb: '16px', lineHeight: 1.2 },
  notFoundTitle: { fontSize: '24px', mb: '12px' },
  notFoundDescription: { color: (theme) => theme.palette.secondary.light, mb: '32px' },
};
