import { Link, useParams } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBooksByCategoryId } from '../../../../redux/slices/books/books.read';
import { useEffect, useMemo, useState } from 'react';
import { booksSelector } from '../../../../redux/slices/books';
import { BookDuration } from '../../../../components';
import Box from '@mui/material/Box';
import { getStyles } from '../CategoryProfile.styles';
import { useTheme } from '@mui/system';
import { pathnames } from '../../../../routes/pathnames';
import { handleBookStatusChip } from '../../../../utils';

export default function useCategoriesTable() {
  const dispatch = useDispatch();
  const { books, isFetchingBooks } = useSelector(booksSelector);
  const [booksTableRows, setBooksTableColumns] = useState(null);
  const { id: categoryId } = useParams();
  const theme = useTheme();
  const styles = useMemo(() => getStyles(theme), [theme]);

  const booksTableColumns = [
    {
      id: 'bookName',
      label: 'Book Name',
      minWidth: 170,
    },
    {
      id: 'duration',
      label: 'Duration',
      minWidth: 100,
    },
    {
      id: 'status',
      label: 'Status',
      minWidth: 100,
      align: 'center',
    },
  ];

  const createData = (bookName, duration, status) => ({
    bookName,
    duration,
    status,
  });

  // Fetch Categories Data
  useEffect(() => {
    dispatch(fetchBooksByCategoryId(categoryId));
  }, [dispatch, categoryId]);

  useEffect(() => {
    if (books) {
      const rows = books.map((book) => {
        const bookName = (
          <Box display='flex' alignItems='center'>
            <Box
              component='img'
              width={40}
              height={60}
              src={book.cover}
              alt='Book Cover'
              loading='lazy'
              sx={styles.categoryBookListTableBookCoverImage}
            />
            <Box>
              <Box mb='7px'>
                <Link to={`${pathnames.books}/${book.id}`}>{book?.name || 'Book Name'}</Link>
              </Box>
              <Box sx={{ fontSize: '12px', color: 'secondary.400' }}>{book?.author?.name || 'Author Name'}</Box>
            </Box>
          </Box>
        );
        return createData(
          bookName,
          <BookDuration duration={book.duration} />,
          <Chip color={handleBookStatusChip(book.status)} label={book.status} />,
        );
      });

      setBooksTableColumns(rows);
    }
  }, [books, styles]);

  return {
    booksTableColumns,
    booksTableRows,
    isFetchingBooks,
  };
}
