export default function ArrowDoubleLeft(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={10.552} height={8.012} {...props}>
      <path
        d='M4.716.296a1 1 0 00-1.42 0l-3 3a1 1 0 000 1.42l3 3a1.004 1.004 0 101.42-1.42l-2.3-2.29 2.3-2.29a1 1 0 000-1.42zm3.2 3.71l2.34-2.29a1.004 1.004 0 00-1.42-1.42l-3 3a1 1 0 000 1.42l3 3a1.004 1.004 0 101.42-1.42z'
        fill='currentColor'
        data-name='Icons/Arrows/angle-double-left'
      />
    </svg>
  );
}
