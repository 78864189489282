import { Box } from '@mui/system';
import { Table, Section, TableSearchbar, ListCount } from '../../components';
import useAuthorsTable from './Authors.table';
import AddAuthorModal from './modals/AddAuthorModal/AddAuthorModal';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';
import { Stack } from '@mui/material';

const Authors = () => {
  const {
    authorsTableColumns,
    authorsTableRows,
    isFetchingAuthors,
    authorsTableRowsDraft,
    setAuthorsTableRows,
    searchCompareFunction,
  } = useAuthorsTable();

  return (
    <Section
      contentPadding={0}
      sectionHeader={
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <TableSearchbar
            rowsDraft={authorsTableRowsDraft}
            setRows={setAuthorsTableRows}
            compareFunction={searchCompareFunction}
            placeholder='Search Authors..'
          />

          <Stack flexShrink={0} direction='row' spacing='20px' alignItems='center'>
            <ListCount count={authorsTableRows?.length} sx={{ height: '48px' }} />
            <AddAuthorModal />
          </Stack>
        </Box>
      }
    >
      <Table columns={authorsTableColumns} rows={authorsTableRows} isLoading={isFetchingAuthors} />
    </Section>
  );
};

export default memo(Authors, componentPropsAreEqual);
