import { pages } from '../../../../../nav-config';
import NestedNav from '../NestedNav/NestedNav';
import AppNavItem from '../AppNavItem/AppNavItem';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import LogoutButton from '../LogoutButton/LogoutButton';
import { getStyles } from '../DrawerNav/DrawerNav.styles';

const DrawerNav = ({ isDrawerOpened, setIsDrawerOpened }) => {
  const styles = getStyles(isDrawerOpened);

  const navListItems = pages.map((navItem) => {
    const { children } = navItem;
    const hasChildren = children?.length > 0;

    if (hasChildren) {
      return (
        <NestedNav
          key={navItem.title}
          {...navItem}
          isDrawerOpened={isDrawerOpened}
          setIsDrawerOpened={setIsDrawerOpened}
        />
      );
    } else {
      return (
        <AppNavItem
          key={navItem.title}
          {...navItem}
          isDrawerOpened={isDrawerOpened}
          setIsDrawerOpened={setIsDrawerOpened}
        />
      );
    }
  });

  return (
    <Box sx={styles.drawerWrapper}>
      <List disablePadding>{navListItems}</List>
      <LogoutButton isDrawerOpened={isDrawerOpened} />
    </Box>
  );
};

export default DrawerNav;
