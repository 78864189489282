export default function AdminsIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={32} height={32} {...props}>
      <path
        d='M19.911 17.065a4.196 4.196 0 0 0 1.125-2.813 4.25 4.25 0 1 0-8.5 0 4.196 4.196 0 0 0 1.125 2.813A6.683 6.683 0 0 0 10 22.25a1.132 1.132 0 1 0 2.25.25 4.5 4.5 0 0 1 8.932 0 1.125 1.125 0 0 0 1.193 1h.125a1.125 1.125 0 0 0 .99-1.238 6.683 6.683 0 0 0-3.578-5.197Zm-3.161-.815a2 2 0 1 1 2-2 2 2 0 0 1-2 2ZM24.625 5.5H8.875A3.375 3.375 0 0 0 5.5 8.875v15.75A3.375 3.375 0 0 0 8.875 28h15.75A3.375 3.375 0 0 0 28 24.625V8.875A3.375 3.375 0 0 0 24.625 5.5Zm1.125 19.125a1.125 1.125 0 0 1-1.125 1.125H8.875a1.125 1.125 0 0 1-1.125-1.125V8.875A1.125 1.125 0 0 1 8.875 7.75h15.75a1.125 1.125 0 0 1 1.125 1.125Z'
        fill='currentColor'
      />
    </svg>
  );
}
