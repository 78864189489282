export default function OutlinedBookIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={70} height={70} {...props}>
      <g data-name='Group 1122' transform='translate(-420 -102.852)'>
        <g data-name='Group 841' fill='currentColor'>
          <path
            data-name='Path 590'
            d='M472.238 133.148a10.144 10.144 0 00-3.923-1.525c-1.947-.4-2.78 2.6-.824 3.008a10.278 10.278 0 012.4.76l-.234 16.764a40.748 40.748 0 00-7.516-.482 15.711 15.711 0 00-5.107 1.06v-16.46a1.431 1.431 0 10-2.85 0v16.512a14.2 14.2 0 00-5.268-1.026 58.039 58.039 0 00-7.535.444c-.072-5.594-.173-11.188-.244-16.782a12 12 0 012.452-.8c1.949-.427 1.117-3.436-.832-3.009a13.855 13.855 0 00-3.991 1.515 1.57 1.57 0 00-.769 1.347q.124 9.736.246 19.474c.01.761.707 1.671 1.55 1.56a72.485 72.485 0 017.46-.62 13.074 13.074 0 014.773.431 4.1 4.1 0 011.69 1.092c.108.123.5.9.277.324.507 1.32 2.616 1.637 2.99 0 .006-.023.015-.047.023-.071a.891.891 0 01.113-.159 6.41 6.41 0 011.161-.856 11.052 11.052 0 015.162-.876 45.583 45.583 0 017.328.7 1.577 1.577 0 001.963-1.5c.088-6.331.277-12.668.264-19a1.972 1.972 0 00-.759-1.825z'
          />
          <path
            data-name='Path 591'
            d='M444.681 127.455a14.315 14.315 0 0110.918-5.469 14.329 14.329 0 0110.89 5.434c1.269 1.555 3.445-.673 2.186-2.215a17.276 17.276 0 00-13.076-6.352 17.255 17.255 0 00-13.1 6.386c-1.255 1.547.92 3.776 2.182 2.216z'
          />
          <path
            data-name='Path 592'
            d='M463.048 131.094c1.263 1.555 3.428-.672 2.175-2.216a12.8 12.8 0 00-9.624-4.913 12.786 12.786 0 00-9.634 4.927c-1.248 1.545.916 3.775 2.175 2.215a9.929 9.929 0 017.459-4.008 9.913 9.913 0 017.449 3.995z'
          />
          <path
            data-name='Path 593'
            d='M455.598 129.04a8.6 8.6 0 00-6.343 3.467c-1.267 1.548.937 3.778 2.216 2.215 1.01-1.233 2.428-2.548 4.128-2.548s3.129 1.321 4.136 2.559c1.274 1.564 3.48-.664 2.216-2.216a8.6 8.6 0 00-6.353-3.477z'
          />
        </g>
      </g>
    </svg>
  );
}
