export const getStyles = ({ checkboxFieldValue } = {}) => ({
  popperInnerWrapper: {
    width: '270px',
  },
  filtersHeader: {
    p: '8px 14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    bgcolor: 'primary.100',
    borderBottom: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    borderRadius: '7px 7px 0 0',
  },
  filterOuterWrapper: {
    '& > label': {
      mt: '-1px',
      borderBlock: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    },
    ...(!checkboxFieldValue && {
      '&:last-of-type > label': {
        borderBottom: 'initial',
        borderRadius: '0 0 7px 7px',
      },
    }),
  },
  checkboxLabelSx: {
    ...(checkboxFieldValue && { bgcolor: 'primary.100' }),
    p: '12px 15px',
    '&:hover': { bgcolor: 'primary.100' },
    '&:active': { backgroundColor: 'primary.200' },
    transition: 'background 120ms ease',
    cursor: 'pointer',
  },
  filterContentWrapper: { p: '12px 15px' },
});
