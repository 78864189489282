export const styles = {
  tableContainer: {
    height: '100%',
    maxHeight: 'calc(100vh - 89px - 80px - 64px)',
    position: 'relative',
    '& a': {
      color: 'inherit',
      font: 'inherit',
      borderBottom: '1px solid transparent',
      paddingBottom: '2px',
      '&:before': {
        content: "''",
        display: 'inline-block',
        width: '12px',
        height: '12px',
        backgroundSize: '12px 12px',
        backgroundImage: (theme) =>
          `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Icons_User-Interface_link-alt' data-name='Icons/User-Interface/link-alt' width='19.866' height='19.866' viewBox='0 0 19.866 19.866'%3E%3Cpath id='link-alt' d='M12.11,15.39,8.23,19.27a2.52,2.52,0,0,1-3.5,0,2.47,2.47,0,0,1,0-3.5l3.88-3.88a1,1,0,0,0-1.42-1.42L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33l3.89-3.88a1,1,0,0,0-1.42-1.42ZM20.69,3.31a4.49,4.49,0,0,0-6.33,0L10.47,7.19a1,1,0,1,0,1.42,1.42l3.88-3.88a2.52,2.52,0,0,1,3.5,0,2.47,2.47,0,0,1,0,3.5l-3.88,3.88a1,1,0,1,0,1.42,1.42l3.88-3.89A4.49,4.49,0,0,0,20.69,3.31ZM8.83,15.17a1.014,1.014,0,0,0,1.42,0l4.92-4.92a1,1,0,1,0-1.42-1.42L8.83,13.75a1,1,0,0,0,0,1.42Z' transform='translate(-2.129 -2.005)' fill='%23${theme.palette.secondary.main.substring(
            1,
          )}'/%3E%3C/svg%3E")`,
        mr: '7px',
        verticalAlign: 'middle',
      },
      '&:hover': {
        color: 'primary.main',
        borderBottom: '1px solid',

        '&:before': {
          backgroundImage: (theme) =>
            `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='Icons_User-Interface_link-alt' data-name='Icons/User-Interface/link-alt' width='19.866' height='19.866' viewBox='0 0 19.866 19.866'%3E%3Cpath id='link-alt' d='M12.11,15.39,8.23,19.27a2.52,2.52,0,0,1-3.5,0,2.47,2.47,0,0,1,0-3.5l3.88-3.88a1,1,0,0,0-1.42-1.42L3.31,14.36a4.48,4.48,0,0,0,6.33,6.33l3.89-3.88a1,1,0,0,0-1.42-1.42ZM20.69,3.31a4.49,4.49,0,0,0-6.33,0L10.47,7.19a1,1,0,1,0,1.42,1.42l3.88-3.88a2.52,2.52,0,0,1,3.5,0,2.47,2.47,0,0,1,0,3.5l-3.88,3.88a1,1,0,1,0,1.42,1.42l3.88-3.89A4.49,4.49,0,0,0,20.69,3.31ZM8.83,15.17a1.014,1.014,0,0,0,1.42,0l4.92-4.92a1,1,0,1,0-1.42-1.42L8.83,13.75a1,1,0,0,0,0,1.42Z' transform='translate(-2.129 -2.005)' fill='%23${theme.palette.primary.main.substring(
              1,
            )}'/%3E%3C/svg%3E")`,
        },
      },
    },
  },
};
