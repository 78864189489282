import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('*Required'),
  content: Yup.string().required('*Required'),
});

export const createUpdateNotificationFormOptions = {
  resolver: yupResolver(validationSchema),
  defaultValues: {
    title: '',
    content: '',
    releaseAt: null,
  },
};
