export default function CircleUserSeenIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={56} height={56} {...props}>
      <g data-name='Group 1174' transform='translate(1389 -4529.421)'>
        <circle
          data-name='Ellipse 15'
          cx={28}
          cy={28}
          r={28}
          transform='translate(-1389 4529.421)'
          fill='rgba(0,144,240,0.2)'
        />
        <g
          data-name='Icon feather-user-check'
          fill='none'
          stroke='#0090f0'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={3}
        >
          <path
            data-name='Path 668'
            d='M-1353.899 4567.892v-2.412a4.822 4.822 0 0 0-4.822-4.822h-8.44a4.822 4.822 0 0 0-4.822 4.822v2.411'
          />
          <path
            data-name='Path 669'
            d='M-1358.118 4551.013a4.822 4.822 0 1 1-4.823-4.822 4.822 4.822 0 0 1 4.822 4.822Z'
          />
          <path data-name='Path 670' d='m-1352.693 4555.836 2.411 2.411 4.823-4.822' />
          <path data-name='Path 671' d='m-1352.693 4548.602 2.411 2.411 4.823-4.822' />
        </g>
      </g>
    </svg>
  );
}
