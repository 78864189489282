import DashboardIcon from './assets/images/icons/Dashboard';
import LibraryIcon from './assets/images/icons/Library';
import BooksIcon from './assets/images/icons/Books';
import AuthorsIcon from './assets/images/icons/Authors';
import UsersIcon from './assets/images/icons/Users';
import CategoriesIcon from './assets/images/icons/Categories';
import FavBookIcon from './assets/images/icons/FavBook';
import { pathnames } from './routes/pathnames';
import AdminsIcon from './assets/images/icons/Admins';
import NotificationsIcon from './assets/images/icons/Notifications';
import ReportsIcon from './assets/images/icons/Reports';

export const NAV_ITEM_TITLES = {
  DASHBOARD: 'Dashboard',
  LIBRARY: 'Library',
  CATEGORIES: 'Categories',
  BOOKS: 'Books',
  AUTHORS: 'Authors',
  USERS: 'Users',
  BOOK_OF_THE_DAY: 'Book of The Day',
  CLIENTS: 'Clients',
  ADMINS: 'Admins',
  NOTIFICATIONS: 'Notifications',
  REPORTS: 'Reports',
};

export const pages = [
  {
    pathname: pathnames.home,
    title: NAV_ITEM_TITLES.DASHBOARD,
    icon: <DashboardIcon />,
  },
  {
    pathname: pathnames.bookOfTheDay,
    title: NAV_ITEM_TITLES.BOOK_OF_THE_DAY,
    icon: <FavBookIcon />,
  },
  {
    title: 'Library',
    icon: <LibraryIcon />,
    children: [
      { pathname: pathnames.books, title: NAV_ITEM_TITLES.BOOKS, icon: <BooksIcon /> },
      {
        pathname: pathnames.categories,
        title: NAV_ITEM_TITLES.CATEGORIES,
        icon: <CategoriesIcon />,
      },
    ],
  },
  {
    pathname: pathnames.authors,
    title: NAV_ITEM_TITLES.AUTHORS,
    icon: <AuthorsIcon />,
  },
  {
    title: 'Users',
    icon: <UsersIcon />,
    children: [
      { pathname: pathnames.clients, title: NAV_ITEM_TITLES.CLIENTS, icon: <UsersIcon /> },
      { pathname: pathnames.admins, title: NAV_ITEM_TITLES.ADMINS, icon: <AdminsIcon /> },
    ],
  },
  {
    pathname: pathnames.notifications,
    title: NAV_ITEM_TITLES.NOTIFICATIONS,
    icon: <NotificationsIcon />,
  },
  {
    pathname: pathnames.reports,
    title: NAV_ITEM_TITLES.REPORTS,
    icon: <ReportsIcon />,
  },
];

export const LABEL_TITLES = pages
  .filter((page) => page.children)
  .reduce((cats, page) => {
    cats[page.title.toUpperCase()] = page.title;
    return cats;
  }, {});
