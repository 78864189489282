import { memo, useEffect } from 'react';
import { componentPropsAreEqual } from '../../../../utils';
import { ListCount, Section, Table } from '../../../../components';
import useCategoryBookListTable from './AuthorBookList.table';
import { useDispatch } from 'react-redux';
import { booksActions } from '../../../../redux/slices/books';

const AuthorBookList = () => {
  const { booksTableColumns, booksTableRows, isFetchingBooks } = useCategoryBookListTable();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(booksActions.resetBooks());
    };
  }, [dispatch]);

  return (
    <Section outerHeader contentPadding={0} title='Author Books' toolbar={<ListCount count={booksTableRows?.length} />}>
      <Table
        columns={booksTableColumns}
        rows={booksTableRows}
        isLoading={isFetchingBooks}
        containerSx={{
          maxHeight: {
            xs: '600px',
            lg: 'calc(100vh - 80px - 66px)',
          },
        }}
      />
    </Section>
  );
};

export default memo(AuthorBookList, componentPropsAreEqual);
