import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

export const initialState = {
  isFetchingClients: false,
  isFetchingSingleClient: false,
  isFetchingClientListenedBooks: false,
  isFetchingClientTotalListenings: false,
  isResettingClientPassword: false,
  isCreatingClient: false,
  isUpdatingClient: false,
  isFetchingClientActiveSubscription: false,
  isFetchingClientPreviousSubscriptions: false,
  clients: null,
  client: null,
  clientIdIsNotValid: false,
  clientListenedBooks: null,
  clientPreviousSubscriptions: null,
};

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    fetchClientsRequestLoading: (state, { payload }) => {
      state.isFetchingClients = payload;
    },
    fetchSingleClientRequestLoading: (state, { payload }) => {
      state.isFetchingSingleClient = payload;
    },
    fetchListenedBooksByClientIdRequestLoading: (state, { payload }) => {
      state.isFetchingClientListenedBooks = payload;
    },
    fetchTotalListeningsByClientIdRequestLoading: (state, { payload }) => {
      state.isFetchingClientTotalListenings = payload;
    },
    createClientRequestLoading: (state, { payload }) => {
      state.isCreatingClient = payload;
    },
    updateClientRequestLoading: (state, { payload }) => {
      state.isUpdatingClient = payload;
    },
    fetchClientsRequestSuccess: (state, { payload }) => {
      const { data, paginatorInfo } = payload;
      state.clients = {
        data,
        paginatorInfo,
      };
    },
    fetchSingleClientRequestSuccess: (state, { payload }) => {
      state.client = payload;
    },
    fetchListenedBooksByClientIdRequestSuccess: (state, { payload }) => {
      state.clientListenedBooks = payload;
    },
    fetchSingleClientRequestFailure: (state, { payload }) => {
      const statusCode = payload;

      if (statusCode === StatusCodes.BAD_REQUEST) state.clientIdIsNotValid = true;
    },
    resetSingleClient: (state) => {
      state.client = null;
    },
    createClientRequestSuccess: (state, { payload }) => {
      const { client } = payload;

      state.clients.unshift(client);
    },
    updateClientRequestSuccess: (state, { payload }) => {
      const { clientId, updatedClient } = payload;

      state.clients = state.clients?.map((client) => {
        if (client.id === clientId) return updatedClient;
        else return client;
      });
    },
    resetClientPasswordRequestLoading: (state, { payload }) => {
      state.isResettingClientPassword = payload;
    },
    resetClientIdIsNotValidToBeFalse: (state) => {
      state.clientIdIsNotValid = false;
    },
    fetchSubscriptionRequestLoading: (state, { payload }) => {
      state.isFetchingClientActiveSubscription = payload;
    },
    fetchClientPreviousSubscriptionsRequestLoading: (state, { payload }) => {
      state.isFetchingClientPreviousSubscriptions = payload;
    },
    fetchSubscriptionRequestSuccess: (state, { payload }) => {
      state.clientActiveSubscription = payload;
    },
    fetchClientPreviousSubscriptionsRequestSuccess: (state, { payload }) => {
      state.clientPreviousSubscriptions = payload;
    },
  },
});

export const clientsActions = clientsSlice.actions;
export const clientsSelector = (state) => state.clients;
export default clientsSlice.reducer;
