import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';
import { Box } from '@mui/material';
import LinkAltIcon from '../../assets/images/icons/LinkAlt';
import { styles } from './CopyTextButton.styles';

/**
 * A reusable component for copy texts to clipboard.
 */

export const CopyTextButton = forwardRef(({ text = null }, ref) => {
  const [doesTextCopied, copyText] = useCopyToClipboard(text);

  return (
    <Box ref={ref} onClick={copyText} sx={styles.copyTextButtonWrapper}>
      <LinkAltIcon /> <span>{doesTextCopied ? 'Copied' : 'Copy'}</span>
    </Box>
  );
});

CopyTextButton.propTypes = {
  text: PropTypes.string.isRequired,
};

CopyTextButton.displayName = 'CopyTextButton';
