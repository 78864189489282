import { memo } from 'react';
import { componentPropsAreEqual } from '../../../../utils';
import { Section, ShareLinkInput } from '../../../../components';
import { useSelector } from 'react-redux';
import { authorsSelector } from '../../../../redux/slices/authors';

const AuthorShareLink = () => {
  const { author, isFetchingSingleAuthor } = useSelector(authorsSelector);

  return (
    <Section title='Author Share Link'>
      <ShareLinkInput label='Share Link' linkText={author?.sharableLink} loading={isFetchingSingleAuthor} />
    </Section>
  );
};

export default memo(AuthorShareLink, componentPropsAreEqual);
