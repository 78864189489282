export function getFormHelperTextDesignTokens() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          marginLeft: 'initial',
          marginRight: 'initial',
          textAlign: 'initial',
          display: 'inline-flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          minHeight: '22px',
          fontSize: '13px',
          marginTop: '6px',
        },
      },
    },
  };
}
