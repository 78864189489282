export default function ExclamationMarkIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 752 752' fill='currentColor' {...props}>
      <defs>
        <clipPath id='a'>
          <path d='M179 139.21h394v473.58H179z' />
        </clipPath>
      </defs>
      <g clipPath='url(#a)'>
        <path d='M366.05 611.61h.71a35.774 35.774 0 0 0 9.235 1.184 39.2 39.2 0 0 0 4.739-.332h.71a36.292 36.292 0 0 0 8.618-2.415l1.09-.425c122.18-48.31 181.71-110.02 181.71-188.72v-211.84a36.008 36.008 0 0 0-13.27-27.91 36.04 36.04 0 0 0-30.016-7.371 187.611 187.611 0 0 1-59.527.285c-36.797-5.16-60.523-16.86-67.863-23.68a35.615 35.615 0 0 0-12.312-8.57l-1.942-.711a36.198 36.198 0 0 0-20.23-1.122 36.203 36.203 0 0 0-17.656 9.93c-6.582 6.535-30.879 18.941-68.242 24.105h-.004a189.351 189.351 0 0 1-59.289-.238 36.04 36.04 0 0 0-43.383 35.281v211.84c0 78.992 59.906 140.89 183.18 189.2zM222.18 420.9V217.26a243.233 243.233 0 0 0 65.777-.71c36.848-5.067 69.617-17.052 88.039-32.017 18.137 14.824 51.148 26.945 88.086 32.11a241.036 241.036 0 0 0 65.734.71v203.64c0 58.867-50.34 107.36-153.91 148.28-103.43-40.965-153.72-89.508-153.72-148.37z' />
      </g>
      <path d='M367.71 257.61h16.527c5.91 0 10.703 4.793 10.703 10.703v143.92c0 5.91-4.793 10.703-10.703 10.703H367.71a10.647 10.647 0 0 1-7.55-3.137c-2-2.011-3.118-4.73-3.106-7.566v-143.97c0-5.887 4.77-10.656 10.656-10.656zM367.95 457.79h16.102c6.016 0 10.891 6.016 10.891 10.891v14.824c0 6.016-4.875 10.891-10.891 10.891H367.95c-6.016 0-10.891-6.016-10.891-10.891v-14.824c0-6.016 4.875-10.891 10.891-10.891z' />
    </svg>
  );
}
