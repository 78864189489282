import { LoadingIndicator, Section } from '../../../../components';
import { LoadingButton } from '@mui/lab';
import AddEditBookForm from '../../AddEditBookForm/AddEditBookForm';
import useAddEditBookForm from '../../AddEditBookForm/useAddEditBookForm';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { booksActions, booksSelector } from '../../../../redux/slices/books';
import { useParams } from 'react-router-dom';
import { fetchSingleBook } from '../../../../redux/slices/books/books.read';
import { useAddImmediateCategory, useAddImmediateAuthor } from '../../../../hooks';
import AddCategoryModal from '../../../../pages/Categories/modals/AddCategoryModal/AddCategoryModal';
import AddAuthorModal from '../../../../pages/Authors/modals/AddAuthorModal/AddAuthorModal';

const BookDetails = () => {
  const { book, isFetchingSingleBook } = useSelector(booksSelector);
  const { form, isUpdatingBook, submitEditBookForm, setEditBookFormInitialValues } = useAddEditBookForm();
  const dispatch = useDispatch();
  const { id: bookId } = useParams();
  const { categoryInitialInputValue, addCategoryModalInitialValues, resetCategoryInitialInputValue } =
    useAddImmediateCategory(form);
  const { addAuthorModalInitialValues, authorInitialInputValue, resetAuthorInitialInputValue } =
    useAddImmediateAuthor(form);

  useEffect(() => {
    dispatch(fetchSingleBook(bookId));
  }, [dispatch, bookId]);

  useEffect(() => {
    return () => {
      dispatch(booksActions.resetSingleBook());
      dispatch(booksActions.resetBookIdIsNotValidToBeFalse());
      form.reset();
    };
  }, [dispatch, form]);

  // Set Edit book form initial values
  useEffect(() => {
    if (book) {
      const { name, status, author, category, description, cover, recordLink, releaseAt } = book;

      setEditBookFormInitialValues({
        name,
        status,
        category,
        author,
        description,
        bookCover: cover,
        bookAudio: recordLink,
        releaseAt,
      });
    }
  }, [book, setEditBookFormInitialValues]);

  return (
    <>
      <Section
        component='form'
        title='Book Details'
        onSubmit={form.handleSubmit(submitEditBookForm)}
        toolbar={
          <LoadingButton
            type='submit'
            variant='contained'
            loading={isUpdatingBook}
            disabled={!form.formState.isDirty}
            sx={{ ml: 'auto' }}
          >
            Save
          </LoadingButton>
        }
      >
        {isFetchingSingleBook ? (
          <LoadingIndicator />
        ) : (
          <AddEditBookForm form={form} isViewPage bookCoverLink={book?.cover} bookRecordLink={book?.recordLink} />
        )}
      </Section>
      <AddCategoryModal
        initialValues={addCategoryModalInitialValues}
        categoryInitialInputValue={categoryInitialInputValue}
        resetCategoryInitialInputValue={resetCategoryInitialInputValue}
        hideTrigger
      />
      <AddAuthorModal
        initialValues={addAuthorModalInitialValues}
        authorInitialInputValue={authorInitialInputValue}
        resetAuthorInitialInputValue={resetAuthorInitialInputValue}
        hideTrigger
      />
    </>
  );
};

export default BookDetails;
