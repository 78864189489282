import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import snackBookLogo from '../../../../../assets/images/snackbook-logo.png';
import IconButton from '@mui/material/IconButton';
import ArrowDoubleLeft from '../../../../../assets/images/icons/ArrowDoubleLeft';
import ArrowDoubleRight from '../../../../../assets/images/icons/ArrowDoubleRight';
import { getStyles } from './DrawerHeader.styles';

const DrawerHeader = ({ isDrawerOpened, toggleDrawer }) => {
  const styles = getStyles(isDrawerOpened);

  return (
    <Box sx={styles.drawerHeaderWrapper}>
      <Box sx={styles.logoWrapper}>
        <Link to='/'>
          <img width={178} src={snackBookLogo} alt='SnackBook' />
        </Link>
      </Box>
      <IconButton onClick={toggleDrawer} sx={styles.drawerToggleButton} disableRipple={false}>
        {isDrawerOpened ? <ArrowDoubleLeft /> : <ArrowDoubleRight />}
      </IconButton>
    </Box>
  );
};

export default DrawerHeader;
