import { lighten } from '@mui/system';

const height = '120px';

export const getStyles = ({ fieldHasError } = {}) => {
  return {
    outerWrapper: { position: 'relative' },
    fileUploadButton: {
      height,
      '& svg': { mr: '16px' },
      '& span': { color: 'primary.main', borderBottom: '1px solid currentColor', lineHeight: 1.3 },
    },
    uploadStatusOuterWrapper: {
      height,
      backgroundColor: (theme) => (fieldHasError ? lighten(theme.palette.error.main, 0.95) : 'secondary.100'),
      border: (theme) => `1px solid ${fieldHasError ? theme.palette.error.main : theme.palette.secondary[200]}`,
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      borderRadius: '8px',
      padding: '34px 15px',
      fontSize: '14px',
    },
    uploadStatusContentWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      mb: '20px',
    },
    uploadStatusAudioName: {
      fontWeight: 700,
      width: '280px',
      overflow: 'hidden',
      display: 'block',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    progressDetails: { fontSize: '12px', mt: '1px' },
    playerWrapper: {
      height,
      backgroundColor: (theme) => (fieldHasError ? lighten(theme.palette.error.main, 0.95) : '#fff'),
      border: (theme) => `1px solid ${fieldHasError ? theme.palette.error.main : theme.palette.secondary[200]}`,
      position: 'absolute',
      top: '0',
      left: '0',
      width: '100%',
      borderRadius: '8px',
      padding: '35px 25px',
      fontSize: '14px',
      zIndex: 10,
    },
    playerDefaultDuration: { width: '50px' },
    playerDefaultCurrentTime: { width: '40px' },
    playerAudioName: {
      fontWeight: 700,
      maxWidth: '300px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      lineHeight: 1.4,
      mt: '-4px',
    },
    replaceAudioButton: {
      position: 'absolute',
      right: '25px',
      top: '50%',
      transform: 'translateY(-50%)',
      '&:active': { transform: 'translateY(-50%) scale(.96)' },
    },
  };
};
