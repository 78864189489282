export const styles = {
  searchbarWrapper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '500px',
    position: 'relative',
    height: '70px',
    margin: '-12px 0 -12px -16px',
    borderRadius: '12px',

    '& svg': {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: '1',
      display: 'block',
      left: '14px',
      pointerEvents: 'none',
      color: 'secondary.300',
    },

    '& input': {
      border: 'initial',
      outline: 'initial',
      flexGrow: '1',
      background: 'transparent',
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      width: '100%',
      padding: '0 43px 0 52px',
      font: 'inherit',
      color: 'inherit',
      borderRadius: '12px',
      fontWeight: 700,

      '::-webkit-input-placeholder': {
        color: 'secondary.300',
        fontWeight: 500,
        transition: 'color 200ms ease',
      },
      '::-moz-placeholder': {
        color: 'secondary.300',
        fontWeight: 500,
        transition: 'color 200ms ease',
      },
      ':-ms-input-placeholder': {
        color: 'secondary.300',
        fontWeight: 500,
        transition: 'color 200ms ease',
      },
      ':-moz-placeholder': {
        color: 'secondary.300',
        fontWeight: 500,
        transition: 'color 200ms ease',
      },

      '&:hover, &:focus': {
        '::-webkit-input-placeholder': {
          color: 'secondary.main',
        },
        '::-moz-placeholder': {
          color: 'secondary.main',
        },
        ':-ms-input-placeholder': {
          color: 'secondary.main',
        },
        ':-moz-placeholder': {
          color: 'secondary.main',
        },
      },
    },
  },
};
