import { alpha } from '@mui/system';

export const styles = {
  copyTextButtonWrapper: {
    color: 'secondary.main',
    fontWeight: 700,
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    '& span': { ml: '8px' },
    cursor: 'pointer',
    p: '4px 10px',
    borderRadius: '6px',
    '&:hover': { bgcolor: (theme) => alpha(theme.palette.secondary[200], 0.5) },
    '&:active': { bgcolor: (theme) => theme.palette.secondary[200], transform: 'scale(.96)' },
    userSelect: 'none',
  },
};
