import Box from '@mui/material/Box';
import { styles } from './PageContent.styles';

const PageContent = ({ children }) => {
  return (
    <Box component='main' sx={styles.pageContentOuterWrapper}>
      {children}
    </Box>
  );
};

export default PageContent;
