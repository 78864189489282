import { Section } from '../../components';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { parseSearchParams } from '../../utils';
import tabs from './data.json';
import { Iframe } from './components/Iframe/Iframe';
import { styles } from './Reports.styles';

const searchParams = parseSearchParams();

export function Reports() {
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const history = useHistory();
  const tabData = tabs[currentTabIndex];

  useEffect(() => {
    const tabValue = parseInt(searchParams.report);
    if (tabValue) setCurrentTabIndex(tabValue);
  }, []);

  useEffect(() => {
    history.push(`?report=${currentTabIndex}`);
  }, [currentTabIndex, history]);

  const handleTabChange = (event, newValue) => setCurrentTabIndex(newValue);

  return (
    <Section>
      <Box sx={styles.container}>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={currentTabIndex}
          onChange={handleTabChange}
          sx={styles.tabsWrapper}
        >
          {tabs.map((tab, i) => (
            <Tab key={i} label={tab.label} sx={styles.tab} />
          ))}
        </Tabs>
        <Iframe src={tabData.src} title={tabData.label} />
      </Box>
    </Section>
  );
}
