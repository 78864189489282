import { createSlice } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';

export const initialState = {
  isFetchingBooks: false,
  books: null,
  isCreatingBook: false,
  isUpdatingBook: false,
  bookIdIsNotValid: false,
  noOfListening: null,
  noOfBookmarks: null,
  book: null,
};

const booksSlice = createSlice({
  name: 'books',
  initialState,
  reducers: {
    fetchBooksRequestLoading: (state, { payload }) => {
      state.isFetchingBooks = payload;
    },
    fetchSingleBookRequestLoading: (state, { payload }) => {
      state.isFetchingSingleBook = payload;
    },
    fetchBooksRequestSuccess: (state, { payload }) => {
      state.books = payload;
    },
    fetchSingleBookRequestSuccess: (state, { payload }) => {
      state.book = payload;
    },
    fetchSingleBookRequestFailure: (state, { payload }) => {
      const statusCode = payload;

      if (statusCode === StatusCodes.BAD_REQUEST) state.bookIdIsNotValid = true;
    },
    resetBookIdIsNotValidToBeFalse: (state) => {
      state.bookIdIsNotValid = false;
    },
    resetBooks: (state) => {
      state.books = null;
    },
    createBookRequestLoading: (state, { payload }) => {
      state.isCreatingBook = payload;
    },
    updateBookRequestLoading: (state, { payload }) => {
      state.isUpdatingBook = payload;
    },
    getNoOfListeningLoading: (state, { payload }) => {
      state.isFetchingNoOfListening = payload;
    },
    getNoOfListeningSuccess: (state, { payload }) => {
      state.noOfListening = payload;
    },
    getNoOfListeningReset: (state) => {
      state.noOfListening = null;
    },
    getNoOfBookmarksLoading: (state, { payload }) => {
      state.isFetchingNoOfBookmarks = payload;
    },
    getNoOfBookmarksSuccess: (state, { payload }) => {
      state.noOfBookmarks = payload;
    },
    getNoOfBookmarksReset: (state) => {
      state.noOfBookmarks = null;
    },
    resetSingleBook: (state) => {
      state.book = null;
    },
    createBookRequestSuccess: (state, { payload }) => {
      const { book } = payload;

      state.books.unshift(book);
    },
    updateBookRequestSuccess: (state, { payload }) => {
      const { bookId, updatedBook } = payload;
      state.book = updatedBook;
      state.books = state.books?.map((book) => {
        if (book.id === bookId) return updatedBook;
        else return book;
      });
    },
  },
});

export const booksActions = booksSlice.actions;
export const booksSelector = (state) => state.books;
export default booksSlice.reducer;
