export const styles = {
  shareLinkInputWrapper: { position: 'relative' },
  shareLinkInput: { color: 'primary.main', '& input': { pr: '120px !important' } },
  copyTextButtonOuterWrapper: { position: 'absolute', right: '6px', top: '50%', transform: 'translateY(-50%)' },
  copyTextButtonLoading: {
    fontSize: '14px',
    fontWeight: '700',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    background: '#fff',
    left: '16px',
  },
};
