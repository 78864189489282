import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams, useRouteMatch } from 'react-router-dom';
import { Box } from '@mui/material';
import { LoadingIndicator, Section } from '../../../../components';
import { useTheme } from '@mui/system';
import { getStyles } from '../ClientProfile.styles';
import OutlinedBookIcon from '../../../../assets/images/icons/OutlinedBook';
import { fetchSingleClient } from '../../../../redux/slices/clients/clients.read';
import { clientsSelector } from '../../../../redux/slices/clients';
import { resetClientPassword } from '../../../../redux/slices/clients/clients.reset-password';
import LoadingButton from '@mui/lab/LoadingButton';
import UserIcon from '../../../../assets/images/icons/User';
import ListIcon from '../../../../assets/images/icons/List';
import DollarIcon from '../../../../assets/images/icons/Dollar';
import RefreshIcon from '../../../../assets/images/icons/Refresh';

const ClientProfileSidebar = () => {
  const { client, isFetchingSingleClient, isResettingClientPassword } = useSelector(clientsSelector);
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = getStyles(theme);
  const { id: clientId } = useParams();
  const match = useRouteMatch();

  const profileNavItemList = [
    { title: 'Account Details', icon: <UserIcon width={26} height={26} />, to: match.url },
    {
      title: 'Active Subscription',
      icon: <DollarIcon width={26} height={26} />,
      to: `${match.url}/active-subscription`,
    },
    {
      title: 'Previous Subscription',
      icon: <RefreshIcon width={26} height={26} />,
      to: `${match.url}/previous-subscription`,
    },
    {
      title: 'Recently Listened Books',
      icon: <ListIcon width={26} height={26} />,
      to: `${match.url}/recently-Listened-books`,
    },
  ];

  useEffect(() => {
    dispatch(fetchSingleClient(clientId));
  }, [dispatch, clientId]);

  return (
    <Section
      contentPadding={0}
      footerPadding={0}
      {...((!isFetchingSingleClient || client) && {
        sectionFooter: (
          <LoadingButton
            sx={styles.resetPasswordButton}
            onClick={() => dispatch(resetClientPassword(clientId, client.email))}
            loading={isResettingClientPassword}
            fullWidth
          >
            Reset Password
          </LoadingButton>
        ),
      })}
    >
      {isFetchingSingleClient || client === null ? (
        <LoadingIndicator />
      ) : (
        <Box sx={styles.clientProfileSidebarContentWrapper}>
          <Box sx={styles.clientNameWrapper}>
            <Box sx={styles.bookIconWrapper}>
              <OutlinedBookIcon />
            </Box>
            {client.name}
          </Box>
          {profileNavItemList.map(({ to, title, icon }) => (
            <Box key={title} sx={styles.clientProfileSidebarNavButtonRoot}>
              <NavLink exact to={to}>
                {icon}
                {title}
              </NavLink>
            </Box>
          ))}
        </Box>
      )}
    </Section>
  );
};

export default ClientProfileSidebar;
