import PropTypes from 'prop-types';
import { memo, useEffect, useState } from 'react';
import { componentPropsAreEqual } from '../../utils';
import { LocalizationProvider, DesktopDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { FormController, ValidationMessage } from '../../components';
import { TextField, FormLabel, Box, useTheme } from '@mui/material';
import { getStyles } from './DatePicker.styles';
import dayjs from 'dayjs';

export const DatePicker = memo(({ form = {}, name, id, label, defaultValue, ...props }) => {
  const [{ value, onChange, fieldHasError, fieldErrorMessage, fieldErrorType }, setFormControllerValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const styles = getStyles({ theme, disabled: props.disabled });
  const getDateValue = (dateValue = value) => (dateValue ? dayjs(dateValue).format('YYYY-MM-DD') : null);

  const handleDatePickerChange = (date) => {
    const formattedDate = getDateValue(date);
    onChange(formattedDate);
  };

  useEffect(() => {
    if (defaultValue) {
      const formattedDate = dayjs(defaultValue).format('YYYY-MM-DD');
      form.setValue(name, formattedDate);
    }
  }, [defaultValue, name, form]);

  return (
    <>
      {form && name ? (
        <FormController form={form} name={name} setFormControllerValues={setFormControllerValues} />
      ) : null}
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          {label ? <FormLabel {...(id ? { htmlFor: id } : {})}>{label}</FormLabel> : null}
          <DesktopDatePicker
            value={getDateValue()}
            mask='____-__-__'
            inputFormat='yyyy-MM-dd'
            onChange={handleDatePickerChange}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            PaperProps={{ elevation: 0, style: styles.datePickerPopperStyle }}
            PopperProps={{ placement: 'bottom-start' }}
            renderInput={(params) => (
              <TextField
                id={id}
                {...params}
                onClick={() => !props.disabled && setIsOpen(true)}
                inputProps={{ ...params.inputProps }}
                sx={styles.datePickerTextField}
                error={fieldHasError}
                autoComplete='off'
                fullWidth
              />
            )}
            {...props}
          />
          <ValidationMessage message={fieldErrorMessage} type={fieldErrorType} />
        </Box>
      </LocalizationProvider>
    </>
  );
}, componentPropsAreEqual);

TextField.propTypes = {
  form: PropTypes.object,
  name: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
};

DatePicker.displayName = 'DatePicker';
