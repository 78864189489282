export function getSelectDesignTokens(theme) {
  return {
    MuiSelect: {
      styleOverrides: {
        select: {
          border: `1px solid ${theme.palette.secondary[200]}`,
          borderRadius: '8px',
          backgroundColor: '#fff',
          padding: '6px 30px 6px 14px !important',
          color: theme.palette.secondary.main,
          '&:focus': {
            backgroundColor: theme.palette.secondary[200],
          },
        },
        icon: {
          right: '4px',

          transition: 'all 120ms ease',
          backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23${theme.palette.secondary.main.substr(
            1,
          )}' d='M8.12 9.29 12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7a.9959.9959 0 0 1 0-1.41c.39-.38 1.03-.39 1.42 0z'%3E%3C/path%3E%3C/svg%3E")`,
          path: {
            display: 'none',
          },
        },
        iconOpen: {
          transform: 'rotate(180deg)',
        },
      },
    },
  };
}
