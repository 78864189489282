import { axios } from '../../../services/axios';
import { categoriesActions } from '.';
import { snackbar } from '../../../utils';
import { Category } from '../../../models';

const { updateCategoryRequestLoading, updateCategoryRequestSuccess } = categoriesActions;

export const updateCategory = (categoryId, updateCategoryPayload) => {
  return async (dispatch) => {
    dispatch(updateCategoryRequestLoading(true));

    return axios
      .patch(`/categories/${categoryId}`, updateCategoryPayload)
      .then((res) => {
        const updatedCategory = new Category(res.data?.category);

        dispatch(updateCategoryRequestSuccess({ categoryId, updatedCategory })); // Update categories list after new category has been updated
        snackbar.success('Category has been updated successfully! 🎉');
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(updateCategoryRequestLoading(false)));
  };
};
