import { useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { memo, useEffect, useRef, useState } from 'react';
import { IconButton, LinearProgress, Stack, Fade, Button, Box } from '@mui/material';

import { getStyles } from './CategoryTopBooks.styles';
import { componentPropsAreEqual } from '../../../../utils';
import TrashIcon from '../../../../assets/images/icons/Trash';
import { Autocomplete, CircularProgress } from '../../../../components';
import { categoriesSelector } from '../../../../redux/slices/categories';
import ArrowsExchangeIcon from '../../../../assets/images/icons/ArrowsExchange';
import { addTopBook, deleteTopBook, replaceTopBook } from '../../../../redux/slices/categories/categories.top-books';

const TopBookItem = ({ bookOption = null, bookIndex, bookId, categoryTopBookId, booksOptionsList }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isNewBook, setIsNewBook] = useState(bookId === null);
  const showSelectedBookPreview = Boolean(!isOpen && (selectedOption || bookOption));
  const dispatch = useDispatch();
  const { id: categoryId } = useParams();
  const { categoryTopBooks } = useSelector(categoriesSelector);
  const bookIdOfSelectedOption = selectedOption?.value;
  const { isAddingTopBook } = categoryTopBooks.add[`${bookIdOfSelectedOption}_${bookIndex}`] || {};
  const { isDeletingTopBook } = categoryTopBooks.delete[`${categoryTopBookId}_${bookIndex}`] || {};
  const inputRef = useRef(null);
  const theme = useTheme();
  const [categoryBooksOptions, setCategoryBooksOptions] = useState([]);

  const styles = getStyles({ isAddingTopBook, isDeletingTopBook, showSelectedBookPreview, theme, isOpen });

  useEffect(() => {
    if (bookOption) setCategoryBooksOptions([bookOption, ...booksOptionsList]);
    else setCategoryBooksOptions(booksOptionsList);
  }, [bookOption, booksOptionsList]);

  useEffect(() => {
    if (!selectedOption && bookOption) setSelectedOption(bookOption);
  }, [bookOption, selectedOption]);

  useEffect(() => {
    if (isNewBook) {
      inputRef.current.focus();
      setIsOpen(true);
    } else {
      inputRef.current.blur();
      setIsOpen(false);
    }
  }, [isNewBook]);

  const handleAutocompleteOnOpen = () => setIsOpen(true);

  const handleAutocompleteOnClose = () => {
    inputRef.current.blur();
    setIsOpen(false);
  };

  const handleAutocompleteOnChange = (event, value) => {
    const isAddMode = selectedOption === null;
    const { value: bookId, label: bookName } = value;

    if (isAddMode) {
      setIsNewBook(false);
      dispatch(addTopBook({ bookId, bookIndex, bookName, categoryId }));
    } else {
      dispatch(replaceTopBook({ categoryTopBookId, bookId, bookIndex, bookName, categoryId }));
    }

    setSelectedOption(value);
  };

  const handleReplaceBookButtonOnClick = () => {
    inputRef.current.focus();
    inputRef.current.select();
    setIsOpen(true);
  };

  const handleDeleteTopBook = () => {
    dispatch(
      deleteTopBook({
        categoryTopBookId,
        bookIndex,
        bookName: selectedOption?.name,
        categoryId,
      }),
    );
  };

  return (
    <Box {...(isNewBook && { id: 'new-top-book-element' })} sx={styles.item.topBookAutocompleteItemWrapper}>
      <Autocomplete
        id='addBookAutocomplete'
        disabled={isAddingTopBook || isDeletingTopBook}
        disableClearable
        listItemHeight={60}
        onOpen={handleAutocompleteOnOpen}
        onClose={handleAutocompleteOnClose}
        renderOption={(props, option) => [
          props,
          <Box key={option.label} sx={styles.item.topBookOption}>
            <Box sx={styles.item.topBookOptionInnerWrapper}>
              <Box
                component='img'
                width={30}
                height={45}
                src={option.cover}
                alt='Book Cover'
                loading='lazy'
                sx={styles.item.topBookOptionCover}
              />
              <Box>
                <Box component='h5' sx={styles.item.topBookOptionName}>
                  {option.label}
                </Box>
                <Box sx={styles.item.topBookOptionAuthor}>{option.author.name}</Box>
              </Box>
            </Box>
            <Box>
              <Button className='chooseBookButton' variant='outlined' size='small'>
                Choose
              </Button>
            </Box>
          </Box>,
        ]}
        value={selectedOption}
        onChange={handleAutocompleteOnChange}
        options={categoryBooksOptions}
        inputRef={(input) => (inputRef.current = input)}
        popperSx={styles.item.autocompletePopper}
        sx={styles.item.autocompleteRoot}
        placeholder='Search/Choose a book'
      />
      {/* Adding book loading indicator */}
      <Fade in={Boolean(isAddingTopBook)} unmountOnExit>
        <Box display='flex'>
          <LinearProgress color='primary' sx={styles.item.addingTopBookLoadingIndicator} />
        </Box>
      </Fade>

      {/* Top book item preview */}
      <Box sx={styles.item.topBookPreviewWrapper}>
        <Box className='counter' sx={styles.item.topBookCounter}>
          <span>{bookIndex}</span>
        </Box>
        <Fade in={showSelectedBookPreview}>
          <Box sx={styles.item.topBookPreviewBookDetailsWrapper}>
            <Box
              component='img'
              width={40}
              height={60}
              src={selectedOption?.cover}
              alt='Book Cover'
              sx={styles.item.topBookPreviewBookCover}
            />
            <Box>
              <Box component='h5' sx={styles.item.topBookPreviewBookName}>
                {selectedOption?.label}
              </Box>
              <Box sx={styles.item.topBookPreviewBookAuthorName}>{selectedOption?.author?.name}</Box>
            </Box>
          </Box>
        </Fade>
      </Box>

      {/* Top book item actions [Delete - Replace] */}
      <Fade in={showSelectedBookPreview} unmountOnExit>
        <Box sx={styles.item.topBookActionsWrapper}>
          <Stack direction='row' spacing='15px'>
            <IconButton
              variant='square'
              color='error'
              title='Delete the book from top books list'
              onClick={handleDeleteTopBook}
            >
              {isDeletingTopBook ? <CircularProgress size={18} color='error' /> : <TrashIcon />}
            </IconButton>
            <IconButton
              variant='square'
              color='primary'
              title='Replace the book with another book'
              onClick={handleReplaceBookButtonOnClick}
            >
              <ArrowsExchangeIcon />
            </IconButton>
          </Stack>
        </Box>
      </Fade>
    </Box>
  );
};

export default memo(TopBookItem, componentPropsAreEqual);
