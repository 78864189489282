export default function CheckmarkIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={24} height={24} {...props}>
      <path
        d='M17.154 9.126l-5.482 7.2a1.2 1.2 0 01-.947.474h-.008a1.2 1.2 0 01-.944-.461l-2.918-3.728a1.2 1.2 0 011.89-1.478l1.96 2.5 4.541-5.963a1.2 1.2 0 111.909 1.453M12 0a12 12 0 1012 12A12 12 0 0012 0'
        fill='currentColor'
      />
    </svg>
  );
}
