export default function CircleSingleUserIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={55.904} height={55.904} {...props}>
      <g data-name='Group 1173'>
        <path
          data-name='Path 673'
          d='M27.952 0A27.952 27.952 0 1 1 0 27.952 27.952 27.952 0 0 1 27.952 0Z'
          fill='rgba(118,126,173,0.16)'
        />
        <g
          data-name='Icon feather-user-check'
          fill='none'
          stroke='#8c87a6'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={3}
        >
          <path
            data-name='Path 668'
            d='M36.969 38.563v-2.4a4.792 4.792 0 0 0-4.792-4.788h-8.385A4.792 4.792 0 0 0 19 36.167v2.4'
          />
          <path data-name='Path 669' d='M32.777 21.792A4.792 4.792 0 1 1 27.985 17a4.792 4.792 0 0 1 4.792 4.792Z' />
        </g>
      </g>
    </svg>
  );
}
