import { openedDrawerWidth } from '../AppBar/AppBar.styles';

const openedMixin = (theme) => ({
  width: openedDrawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const getStyles = ({ theme, isDrawerOpened }) => ({
  drawerRoot: {
    width: openedDrawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: 1300,
    ...(isDrawerOpened && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!isDrawerOpened && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      '& .MuiListItem-root': {
        paddingLeft: '10px',
        '& .MuiListItemIcon-root': { marginRight: '25px' },
      },
    }),
    '& .MuiPaper-root': { overflow: 'initial' },
    [theme.breakpoints.down('md')]: {
      '&.MuiDrawer-docked, &.MuiDrawer-docked .MuiDrawer-paper': { width: 'calc(72px + 1px)' },
    },
  },
  drawerNavWrapper: { overflowX: 'hidden', pb: '90px' },
});
