import { useCallback } from 'react';
import { CircularProgress, FileUpload, FileUploadButton } from '../../../components';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import ImageUploadIcon from '../../../assets/images/icons/ImageUpload';
import Button from '@mui/material/Button';
import ReplaceImageIcon from '../../../assets/images/icons/ReplaceImage';
import { getStyles } from './BookCoverUpload.styles';
import { useDispatch, useSelector } from 'react-redux';
import { storageSelector } from '../../../redux/slices/storage';
import { deleteFile } from '../../../redux/slices/storage/storage.delete';
import fallbackImg from '../../../assets/images/fallback-img.png';

const disabledOverlayZIndex = 10;

const BookCoverUpload = ({ form, initialBookCoverLink }) => {
  const fieldName = 'bookCover';
  const { watch, formState } = form;
  const dispatch = useDispatch();
  const { storage } = useSelector(storageSelector);
  const {
    loaded,
    total,
    percentage,
    isUploading,
    isUploadedSuccessfully,
    uploadedFileLink: bookCoverLink,
  } = storage.upload[fieldName] || {};
  const { isDeletingFile: isDeletingBookCoverFile } = storage.delete[bookCoverLink || initialBookCoverLink] || {};

  const fieldHasError = Boolean(formState.errors[fieldName]);
  const bookCoverUploadStyles = getStyles({ isUploadedSuccessfully, percentage });
  const bookCover = watch(fieldName);
  const bookCoverName = bookCover?.[0]?.name || 'Book Cover Image';

  const getBookCoverUrl = useCallback(() => {
    const bookCoverFile = bookCover?.[0];
    if (bookCoverLink) return bookCoverLink;
    if (bookCoverFile && typeof bookCoverFile === 'object') return URL.createObjectURL(bookCoverFile);
    if (initialBookCoverLink) return initialBookCoverLink;
  }, [bookCover, bookCoverLink, initialBookCoverLink]);

  const isBookCoverURLExisted = Boolean(getBookCoverUrl());
  const isAddBookCoverButtonDisabled = isBookCoverURLExisted;
  const isReplaceBookCoverButtonDisabled = !isBookCoverURLExisted;

  const handleBookCoverDeletion = () => {
    return dispatch(deleteFile(bookCoverLink || initialBookCoverLink));
  };

  return (
    <Box sx={bookCoverUploadStyles.outerWrapper}>
      {/* ------ Handle file input disabled state ------ */}
      <div style={{ zIndex: isAddBookCoverButtonDisabled ? disabledOverlayZIndex : 0 }} />
      <div style={{ zIndex: disabledOverlayZIndex }} />
      <div style={{ zIndex: isReplaceBookCoverButtonDisabled ? disabledOverlayZIndex : 0 }} />
      {/* ------ */}

      <FileUpload
        form={form}
        name={fieldName}
        id='bookCover'
        accept='.png, .jpg, .jpeg'
        {...((initialBookCoverLink || bookCoverLink) && { handleFileDelete: handleBookCoverDeletion })}
      >
        <Box sx={bookCoverUploadStyles.contentWrapper}>
          <FileUploadButton sx={bookCoverUploadStyles.fileUploadButton} error={fieldHasError}>
            <Fade in={isBookCoverURLExisted} unmountOnExit>
              <Box sx={bookCoverUploadStyles.uploadStatusWrapper}>
                <Box
                  component='img'
                  src={getBookCoverUrl() || fallbackImg}
                  alt={bookCoverName}
                  sx={bookCoverUploadStyles.previewImg}
                />
                <Fade
                  in={Boolean(
                    !((initialBookCoverLink && !isUploading && !isUploadedSuccessfully) || isDeletingBookCoverFile),
                  )}
                  timeout={700}
                  unmountOnExit
                >
                  <Box display='flex'>
                    {isUploading ? <CircularProgress size={45} /> : null}
                    <Box sx={bookCoverUploadStyles.progressBar} />
                    <Box sx={bookCoverUploadStyles.progressDetails}>
                      <Box sx={bookCoverUploadStyles.progressPercentage}>{Number(percentage)}%</Box>
                      {isUploadedSuccessfully ? '✓ Cover uploaded successfully! 🎉' : `${loaded} of ${total} Uploaded`}
                    </Box>
                  </Box>
                </Fade>
              </Box>
            </Fade>
            <ImageUploadIcon />
            Add Book Cover
          </FileUploadButton>
          <Button
            component='span'
            variant='outlined'
            color='primary'
            startIcon={<ReplaceImageIcon />}
            fullWidth
            disabled={isReplaceBookCoverButtonDisabled}
            sx={bookCoverUploadStyles.replaceBookCoverButton}
          >
            Replace Image
          </Button>
        </Box>
      </FileUpload>
    </Box>
  );
};

export default BookCoverUpload;
