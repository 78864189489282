export const getStyles = ({ plain }) => ({
  checkboxRoot: {
    padding: 0,
    marginInlineEnd: '12px',
    '&:hover': { bgcolor: 'transparent' },
  },
  formControlLabel: {
    userSelect: 'none',
    width: '100%',
    ...(plain
      ? { m: 0 }
      : {
          background: (theme) => theme.palette.primary[150],
          padding: '12px 14px',
          borderRadius: '8px',
          transition: 'background 120ms ease',
          '&:hover': { backgroundColor: (theme) => theme.palette.primary[200] },
          '&:active': { transform: 'scale(.96)', bgcolor: (theme) => theme.palette.primary[250] },
          '& .MuiFormControlLabel-label': {
            fontSize: '15px',
          },
        }),
  },
});
