import { useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import CategoryDetails from './CategoryDetails/CategoryDetails';
import CategoryBookList from './CategoryBookList/CategoryBookList';
import CategoryTopBooks from './CategoryTopBooks/CategoryTopBooks';
import { getStyles } from './CategoryProfile.styles';
import { useTheme } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesSelector } from '../../../redux/slices/categories';
import { categoriesActions } from '../../../redux/slices/categories';
import { Link } from 'react-router-dom';
import { UnprocessableEntity } from '../../../components';
import { storageActions } from '../../../redux/slices/storage';

const CategoryProfile = () => {
  const theme = useTheme();
  const styles = getStyles(theme);
  const { categoryIdIsNotValid } = useSelector(categoriesSelector);
  const dispatch = useDispatch();

  const resetFormState = useCallback(() => {
    dispatch(storageActions.uploadFileReset({ fieldName: 'cover' }));
    dispatch(storageActions.uploadFileReset({ fieldName: 'icon' }));
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(categoriesActions.resetCategoryIdIsNotValidToBeFalse());
      resetFormState();
    };
  }, [dispatch, resetFormState]);

  if (categoryIdIsNotValid) {
    return (
      <UnprocessableEntity
        message='Oops! The category you’re looking for does not exist.'
        linkComponent={
          <Link to='/library/categories'>
            <span>←</span> Back to Categories
          </Link>
        }
      />
    );
  }

  return (
    <Box sx={styles.categoryProfileGridContainer}>
      <Box>
        <CategoryDetails />
      </Box>
      <Box sx={styles.categoryTopBooksWrapper}>
        <CategoryTopBooks />
      </Box>
      <Box>
        <CategoryBookList />
      </Box>
    </Box>
  );
};

export default CategoryProfile;
