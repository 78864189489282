import { memo } from 'react';
import Box from '@mui/material/Box';
import TimeIcon from '../assets/images/icons/Time';
import { componentPropsAreEqual, formatSecondsToTime } from '../utils';

export const BookDuration = memo(
  ({ duration }) => (
    <Box sx={{ display: 'flex', alignItems: 'center', '& svg': { mr: '8px' } }}>
      <TimeIcon />
      {formatSecondsToTime(duration)}
    </Box>
  ),
  componentPropsAreEqual,
);

BookDuration.displayName = 'BookDuration';
