import { alpha, lighten } from '@mui/system';

const styleVariables = {
  gridItemHeight: '170px',
  gridGap: '3px',
};

const gridSharedStyles = {
  width: '100%',
  boxSizing: 'border-box',
  display: 'grid',
  gridTemplateColumns: 'repeat(7, 1fr)',
  gridColumnGap: styleVariables.gridGap,
  gridRowGap: '1px',
};

export const getStyles = () => ({
  navigation: {
    wrapper: { display: 'flex', padding: '25px', alignItems: 'center' },
    yearMonthTitle: { fontSize: '18px', mr: '40px' },
  },
  daysOfWeek: { ...gridSharedStyles },
  daysOfWeekItem: {
    fontWeight: 700,
    fontSize: '16px',
    textAlign: 'center',
    p: '11px',
    border: (theme) => `1px solid ${lighten(theme.palette.secondary[200], 0.25)}`,
    borderBottomColor: (theme) => lighten(theme.palette.secondary[200], 0.75),
    mx: '-1px',
  },
  daysGrid: {
    ...gridSharedStyles,
    position: 'relative',
  },
  day: {
    height: styleVariables.gridItemHeight,
    border: (theme) => `1px solid ${lighten(theme.palette.secondary[200], 0.25)}`,
    borderBottomColor: (theme) => lighten(theme.palette.secondary[200], 0.65),
    mb: '-1px',
    mx: '-1px',
    position: 'relative',
  },
  dayHeader: {
    color: (theme) => alpha(theme.palette.secondary[400], 0.8),
    textAlign: 'center',
    py: '5px',
    fontSize: '12px',
    userSelect: 'none',
  },
  dayContent: {
    flexGrow: '1',
  },
  dayHeaderDayNumber: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '28px',
    height: '28px',
    borderRadius: '50%',
  },
});
