import { axios } from '../../../services/axios';

import { authorsActions } from '.';
import { Author } from '../../../models';
import { StatusCodes } from 'http-status-codes';

const {
  fetchAuthorsRequestLoading,
  fetchAuthorsRequestSuccess,
  fetchSingleAuthorRequestLoading,
  fetchSingleAuthorRequestSuccess,
  fetchSingleAuthorRequestFailure,
} = authorsActions;

export const fetchAuthors = () => {
  return async (dispatch) => {
    dispatch(fetchAuthorsRequestLoading(true));

    return axios
      .get('/authors')
      .then((res) => {
        const authors = res.data?.authors?.map((author) => new Author(author));
        if (authors) dispatch(fetchAuthorsRequestSuccess(authors));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(fetchAuthorsRequestLoading(false)));
  };
};

export const fetchSingleAuthor = (authorId) => {
  return async (dispatch) => {
    dispatch(fetchSingleAuthorRequestLoading(true));

    return axios
      .get(`/authors/${authorId}`)
      .then(async (res) => {
        const author = new Author(res.data?.author);

        dispatch(fetchSingleAuthorRequestSuccess(author));
      })
      .catch((error) => {
        const statusCode = error?.response?.status;

        if (statusCode === StatusCodes.BAD_REQUEST) {
          dispatch(fetchSingleAuthorRequestFailure(statusCode));
        }
      })
      .finally(() => dispatch(fetchSingleAuthorRequestLoading(false)));
  };
};
