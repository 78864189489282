import { Box } from '@mui/system';
import { Table, Section, TableSearchbar, ListCount } from '../../components';
import useClientsTable from './Clients.table';
import { memo } from 'react';
import { componentPropsAreEqual } from '../../utils';

const Clients = () => {
  const {
    clientsTotalCount,
    clientsTableColumns,
    clientsTableRows,
    isFetchingClients,
    clientsTableRowsDraft,
    setClientsTableRows,
    searchData,
    fetchPageData,
    clientsNameAndEmailSearchDefaultValue,
  } = useClientsTable();

  return (
    <Section
      contentPadding={0}
      sectionHeader={
        <Box display='flex' alignItems='center' justifyContent='space-between'>
          <TableSearchbar
            async
            searchData={searchData}
            searchDefaultValue={clientsNameAndEmailSearchDefaultValue}
            rowsDraft={clientsTableRowsDraft}
            setRows={setClientsTableRows}
            placeholder='Search Clients..'
          />
          <ListCount count={clientsTotalCount} />
        </Box>
      }
    >
      <Table
        hasPaginator={true}
        columns={clientsTableColumns}
        rows={clientsTableRows}
        isLoading={isFetchingClients}
        dataTotalCount={clientsTotalCount}
        fetchPageData={fetchPageData}
      />
    </Section>
  );
};

export default memo(Clients, componentPropsAreEqual);
