import { useDispatch, useSelector } from 'react-redux';
import { fetchAdmins } from '../../redux/slices/admins/admins.read';
import { useCallback, useEffect, useState } from 'react';
import { adminsSelector } from '../../redux/slices/admins';
import ResetAdminPasswordModal from './modals/ResetAdminPasswordModal/ResetAdminPasswordModal';

export default function useAdminsTable() {
  const { admins, isFetchingAdmins } = useSelector(adminsSelector);
  const [adminsTableRows, setAdminsTableRows] = useState(null);
  const [adminsTableRowsDraft, setAdminsTableRowsDraft] = useState(null);
  const adminsTableColumns = [
    {
      id: 'adminName',
      label: 'Admin Name',
      minWidth: 170,
    },
    {
      id: 'adminEmail',
      label: 'Admin Email',
    },
    {
      id: 'actions',
      label: 'Actions',
    },
  ];
  const dispatch = useDispatch();

  const createData = (adminName, adminEmail, actions) => ({ adminName, adminEmail, actions });

  // Fetch Admins Data
  useEffect(() => {
    if (admins === null) dispatch(fetchAdmins());
  }, [dispatch, admins]);

  useEffect(() => {
    if (admins) {
      const rows = admins.map((admin) => {
        return createData(admin.name, admin.email, <ResetAdminPasswordModal adminId={admin.id} />);
      });

      setAdminsTableRows(rows);
      setAdminsTableRowsDraft(rows);
    }
  }, [admins]);

  const searchCompareFunction = useCallback((row, searchInputValue) => {
    const adminName = row.adminName?.toLowerCase?.();
    const searchValue = searchInputValue?.toLowerCase()?.trim();
    return adminName.includes(Boolean(searchValue) ? searchValue : null);
  }, []);

  return {
    adminsTableColumns,
    adminsTableRows,
    isFetchingAdmins,
    adminsTableRowsDraft,
    setAdminsTableRows,
    searchCompareFunction,
  };
}
