export default function TimeIcon(props) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} {...props}>
      <path
        d='M10.4 7.2H8.8V4a.8.8 0 10-1.6 0v4a.8.8 0 00.8.8h2.4a.8.8 0 000-1.6zM8 0a8 8 0 108 8 8 8 0 00-8-8zm0 14.4A6.4 6.4 0 1114.4 8 6.4 6.4 0 018 14.4z'
        fill='currentColor'
      />
    </svg>
  );
}
