import { memo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { componentPropsAreEqual } from '../../utils';
import { getStyles } from './FullCalendar.styles';

export const FullCalendarDayHeader = memo(({ calendarDayObject }) => {
  const styles = getStyles();

  return (
    <Box sx={styles.dayHeader}>
      <Box className='dayHeaderDayNumber' sx={styles.dayHeaderDayNumber}>
        {calendarDayObject.dayOfMonth}
      </Box>
    </Box>
  );
}, componentPropsAreEqual);

FullCalendarDayHeader.propTypes = {
  calendarDayObject: PropTypes.object.isRequired,
};

FullCalendarDayHeader.displayName = 'FullCalendarDayHeader';
