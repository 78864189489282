export const getStyles = (fullWidth, maxWidth) => ({
  dialogRoot: {
    '& .MuiDialog-paper': {
      width: 'calc(100% - 64px)',
      maxWidth: fullWidth ? '100%' : maxWidth ? maxWidth : '446px',
      overflow: 'visible',
    },
  },
  dialogTitle: { fontSize: '20px', p: '20px 30px', lineHeight: '25px' },
  dialogContent: {
    p: '28px 30px',
    borderTop: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    borderBottom: (theme) => `1px solid ${theme.palette.secondary[200]}`,
    position: 'relative',
  },
  dialogActions: { p: '19px 30px' },
  overlayLoaderWrapper: {
    position: 'absolute',
    top: '0',
    width: '100%',
    height: '100%',
    background: 'hsl(0, 0%, 100%, 50%)',
    left: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backdropFilter: 'blur(2px)',
  },
});
