import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useTheme from '@mui/material/styles/useTheme';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { generateNewRelativePathWithSearchParams, parseSearchParams } from '../../utils';
import TablePagination from '@mui/material/TablePagination';

const Paginator = ({ fetchPageData, dataTotalCount = 0 }) => {
  const searchParams = parseSearchParams();
  const [page, setPage] = useState(searchParams.page || 0);
  const [rowsPerPage, setRowsPerPage] = useState(searchParams.limit || 10);
  const history = useHistory();
  const didMount = useRef(false);

  const onPageChange = (event, page) => {
    setPage(page);
    fetchPageData?.({ ...searchParams, page });
    history.push(generateNewRelativePathWithSearchParams({ ...searchParams, page }));
  };

  const handleChangeRowsPerPage = (event) => {
    const rowsPerPage = Number(event.target.value);

    setRowsPerPage(Number(event.target.value));
    setPage(0);
    fetchPageData?.({ ...searchParams, page: 0, limit: rowsPerPage });
    history.push(generateNewRelativePathWithSearchParams({ ...searchParams, page: 0, limit: rowsPerPage }));
  };

  useEffect(() => {
    if (!didMount.current) {
      fetchPageData?.({ ...searchParams, page, limit: rowsPerPage });

      didMount.current = true;
    }
  }, [fetchPageData, history, page, rowsPerPage, searchParams]);

  return dataTotalCount ? (
    <TablePagination
      component='div'
      sx={{
        position: 'sticky',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        '& p': { margin: 'auto' },
        bgcolor: 'secondary.100',
        borderTop: (theme) => `1px solid ${theme.palette.secondary[200]}`,
        '& .MuiIconButton-root': {
          color: 'secondary.main',
          '&.Mui-disabled': {
            color: 'secondary.300',
          },
        },
        '& .MuiTablePagination-displayedRows': {
          padding: '6px 10px',
          borderRadius: '8px',
          bgcolor: 'secondary.200',
          fontWeight: 600,
        },
      }}
      count={dataTotalCount}
      page={Number(page)}
      onPageChange={onPageChange}
      rowsPerPage={Number(rowsPerPage)}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={TablePaginationActions}
    />
  ) : null;
};

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => onPageChange(event, 0);

  const handleBackButtonClick = (event) => onPageChange(event, page - 1);

  const handleNextButtonClick = (event) => onPageChange(event, page + 1);

  const handleLastPageButtonClick = (event) => onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

export default Paginator;
