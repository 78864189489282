import { alpha } from '@mui/system';

export const getStyles = (theme) => ({
  popperPaper: {
    position: 'relative',
    top: '5px',
    borderRadius: '8px',
    boxShadow: `0 9px 28px ${alpha(theme.palette.secondary.main, 0.16)}`,
    border: `1px solid ${theme.palette.secondary[200]}`,
    zIndex: theme.zIndex.header,
    '& .MuiListItemButton-root': {
      backgroundColor: '#fff',
      borderRadius: '6px',
      padding: '12px 10px',
      '& .MuiListItemIcon-root': { color: 'inherit', minWidth: '30px' },
      '& .MuiListItemText-root': { margin: 0, '& .MuiTypography-root': { fontSize: '14px' } },
      '&.Mui-focused': {
        backgroundColor: theme.palette.secondary[100],
      },
      '&:hover': {
        backgroundColor: theme.palette.secondary[100],
      },
      '&:active': {
        backgroundColor: alpha(theme.palette.secondary[200], 0.8),
      },
    },
  },
});
