import EudoxusSansExtraLightWOFF2 from './EudoxusSans-ExtraLight.woff2';
import EudoxusSansLightWOFF2 from './EudoxusSans-Light.woff2';
import EudoxusSansRegularWOFF2 from './EudoxusSans-Regular.woff2';
import EudoxusSansMediumWOFF2 from './EudoxusSans-Medium.woff2';
import EudoxusSansBoldWOFF2 from './EudoxusSans-Bold.woff2';
import EudoxusSansExtraBoldWOFF2 from './EudoxusSans-ExtraBold.woff2';
import EudoxusSansExtraLightWOFF from './EudoxusSans-ExtraLight.woff';
import EudoxusSansLightWOFF from './EudoxusSans-Light.woff';
import EudoxusSansRegularWOFF from './EudoxusSans-Regular.woff';
import EudoxusSansMediumWOFF from './EudoxusSans-Medium.woff';
import EudoxusSansBoldWOFF from './EudoxusSans-Bold.woff';
import EudoxusSansExtraBoldWOFF from './EudoxusSans-ExtraBold.woff';
import EudoxusSansExtraLightTTF from './EudoxusSans-ExtraLight.ttf';
import EudoxusSansLightTTF from './EudoxusSans-Light.ttf';
import EudoxusSansRegularTTF from './EudoxusSans-Regular.ttf';
import EudoxusSansMediumTTF from './EudoxusSans-Medium.ttf';
import EudoxusSansBoldTTF from './EudoxusSans-Bold.ttf';
import EudoxusSansExtraBoldTTF from './EudoxusSans-ExtraBold.ttf';

const fontFaceDeclarations = `
  @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansExtraLightWOFF2}) format('woff2'),
      url(${EudoxusSansExtraLightTTF}) format('ttf'),
      url(${EudoxusSansExtraLightWOFF}) format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansLightWOFF2}) format('woff2'),
      url(${EudoxusSansLightTTF}) format('ttf'),
      url(${EudoxusSansLightWOFF}) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansRegularWOFF2}) format('woff2'),
      url(${EudoxusSansRegularTTF}) format('ttf'),
      url(${EudoxusSansRegularWOFF}) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansMediumWOFF2}) format('woff2'),
      url(${EudoxusSansMediumTTF}) format('ttf'),
      url(${EudoxusSansMediumWOFF}) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansBoldWOFF2}) format('woff2'),
      url(${EudoxusSansBoldTTF}) format('ttf'),
      url(${EudoxusSansBoldWOFF}) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: block;
  }
  
  @font-face {
    font-family: 'Eudoxus Sans';
    src: url(${EudoxusSansExtraBoldWOFF2}) format('woff2'),
      url(${EudoxusSansExtraBoldTTF}) format('ttf'),
      url(${EudoxusSansExtraBoldWOFF}) format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: block;
  }
`;

export default fontFaceDeclarations;
