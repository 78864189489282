import { useSnackbar } from 'notistack';
import { SnackbarProvider as SnackProvider } from 'notistack';
import CustomSnackbar from './CustomSnackbar/CustomSnackbar';

let useSnackbarRef = null;

export const snackbar = {
  success(msg, options) {
    return this.toast(msg, { ...options, variant: 'success' });
  },
  error(msg, options) {
    return this.toast(msg, { ...options, variant: 'error' });
  },
  warning(msg, options) {
    return this.toast(msg, { ...options, variant: 'warning' });
  },
  info(msg, options) {
    return this.toast(msg, { ...options, variant: 'info' });
  },
  toast(msg, options) {
    return useSnackbarRef?.enqueueSnackbar(msg, {
      ...options,
      preventDuplicate: true,
      /* eslint-disable  react/display-name */
      content: (key, message) => (
        <CustomSnackbar
          id={key}
          message={message}
          variant={options.variant}
          {...(options.action && { action: options.action })}
        />
      ),
    });
  },
  dismiss(key) {
    useSnackbarRef?.closeSnackbar(key);
  },
};

const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();

  return null;
};

const SnackbarProvider = ({ children }) => (
  <SnackProvider maxSnack={3} anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}>
    <SnackbarUtilsConfigurator />
    {children}
  </SnackProvider>
);

export default SnackbarProvider;
