import { axios } from '../../../services/axios';
import { bookOfTheDayActions } from '.';
import { BookOfTheDay } from '../../../models';

const { fetchBookOfTheDayListRequestSuccess, fetchBookOfTheDayListRequestLoading } = bookOfTheDayActions;

export const fetchBookOfTheDayList = (month) => {
  return async (dispatch) => {
    dispatch(fetchBookOfTheDayListRequestLoading(true));

    return axios
      .get(`/book-of-the-day?month=${month}`)
      .then((res) => {
        const bookOfTheDayList = res.data?.days_books.map((book) => new BookOfTheDay(book));

        dispatch(fetchBookOfTheDayListRequestSuccess(bookOfTheDayList));
      })
      .catch((error) => console.log(error))
      .finally(() => dispatch(fetchBookOfTheDayListRequestLoading(false)));
  };
};
